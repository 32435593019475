import * as React from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link as MUILink,
  Paper,
  Box,
  Grid,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import { createTheme, ThemeProvider, Theme } from "@mui/material";
import SettingsPowerIcon from '@mui/icons-material/SettingsPower';
import { Link } from "react-router-dom";
import "@mui/styles";
import hashData from "../services/encrypt";
import ResetPassword from "../services/API/ResetPassword";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}


function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <MUILink color="inherit" href="https://www.slicoinsurance.com/">
        SLICO
      </MUILink>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const ResetUser = () => {
  const [errorMsg, setErrorMsg] = React.useState<string>("");
  const [formValues, setFormValues] = React.useState({
    email: '',
    activationCode: '',
    passwordNew: '',
    passwordConfirm: '',
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const CloseAlert = () => {
    setErrorMsg("");
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formValues.passwordNew !== formValues.passwordConfirm) {
      setErrorMsg("Passwords do not Match");
    } else {
      const hash = hashData(formValues.passwordNew, false)
      if (hash) {
        ResetPassword({ Email: formValues.email, ActivationCode: formValues.activationCode, NewPassword: hash }).then((response) => {
          if (response) {
            setErrorMsg(response);
          }
        })
      }
    }
  };

  return <>
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh", width: "100%" }}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
              variant={"circular"}
              alt="SLICO"
              src="/slico_logo.jpg"
              style={{
                width: 200,
                height: 200,
              }}
            />
            <Box
              component="form"
              noValidate={false}
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={handleChange}
                  value={formValues.email}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="activationCode"
                  label="Activation Code"
                  name="activationCode"
                  autoComplete="activation-Code"
                  type="text"
                  onChange={handleChange}
                  value={formValues.activationCode}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="passwordNew"
                  label="New Password"
                  autoComplete="password-New"
                  type="password"
                  id="passwordNew"
                  onChange={handleChange}
                  value={formValues.passwordNew}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="passwordConfirm"
                  label="Confirm Password"
                  type="password"
                  autoComplete="password-Confirm"
                  id="passwordConfirm"
                  onChange={handleChange}
                  value={formValues.passwordConfirm}
                />
              </>
              {errorMsg && (
                <Alert severity="info" onClose={CloseAlert}>
                  <AlertTitle>Info</AlertTitle>
                  {errorMsg}
                </Alert>
              )}
              <Button
                color="error"
                type="submit"
                variant="outlined"
                sx={{ size: "small", mt: 3, mb: 2 }}
                endIcon={<SettingsPowerIcon />}
                fullWidth
              >
                Activate Account
              </Button>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Link color="primary" to="/Login">
                    Sign In
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(/background.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </ThemeProvider >
  </>;
};
export default ResetUser;
