import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IUpdateClaimProps {
  ClaimRef: string;
  PolicyNo: string;
  claimDetails: string;
  ClaimAmount: number;
  token: string;
  AmountOverRide: number;
  OverRideInfo: string;
  OutstandingRemoved: number;
  OverRideOutstanding: string;
  RemoveCardCost: number;
}

const baseURL = apiURL();

const updateClaim = async (props: IUpdateClaimProps) => {
  let value = "";
  const token = props.token;
  const data = {
    ClaimRef: props.ClaimRef,
    PolicyNo: props.PolicyNo,
    ClaimDetails: props.claimDetails,
    ClaimAmount: props.ClaimAmount,
    AmountOverRide: props.AmountOverRide,
    OverRideInfo: props.OverRideInfo,
    OutstandingRemoved: props.OutstandingRemoved,
    OverRideOutstandingInfo: props.OverRideOutstanding,
    RemoveCardCost: props.RemoveCardCost,
  };
  try {
    const response = await axios.post(baseURL + "Claim/updateClaim", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      value = "success";
    }
  } catch (error: AxiosError | any) {
    if (error.response) {
      value = error.response.data;
    } else if (error.request) {
      value = "No response from server";
    } else {
      value = "Something went wrong";
    }
  }
  return value;
};
export default updateClaim;
