import axios from "axios";
import apiURL from "../apiURL";

export interface IClaimEvidenceDataRow {
    filesToShow: string,
    uploadFileTypes: string,
    description: string,
}

export interface IClaimEvidenceData {
    data: IClaimEvidenceDataRow[],
}
interface IGetRegistered {
    token: string;
    ClaimRef: string;
    PolicyNo: string;
}
const baseURL = apiURL()
const GetClaimEvidence = async (props: IGetRegistered) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + `Claim/GetClaimEvidence?PolicyNo=${props.PolicyNo}&ClaimRef=${props.ClaimRef}`,
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IClaimEvidenceData;

}
export default GetClaimEvidence;