import React, { useEffect, useMemo } from 'react';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import useToken from '../../useToken';
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Popover, Select, SelectChangeEvent } from '@mui/material';
import GetMarketerCommission, { ICommissionDataRow } from '../../../services/API/GetMarketerCommission';
import { denseStyle } from '../Marketer/YourCommission';
import Typography from '@mui/material/Typography';
import GetAllMarketerUserData, { IAllMUserDataRow } from '../../../services/API/GetAllMarketerUserData';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

const MarketerFambulReport = () => {
  const columns = useMemo<MRT_ColumnDef<ICommissionDataRow>[]>(
    () => [
      {
        accessorKey: 'MarketerID', //access nested data with dot notation
        header: 'Marketer ID',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.light,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
        size: 50
      },
      {
        accessorKey: 'Fullname', //access nested data with dot notation
        header: 'Full Name',
      },
      {
        accessorKey: 'Gender', //access nested data with dot notation
        header: 'Gender',
        size: 50,
      },
      {
        accessorKey: 'CustomerPaid',
        header: 'Customer Paid',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.error.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {`${Number(cell.getValue()).toLocaleString('en-SL', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + ' SLL'}`}
          </Box>
        ),
        size: 100
      },
      {
        accessorKey: 'Commission',
        header: 'Commission',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {`${Number(cell.getValue()).toLocaleString('en-SL', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + ' SLL'}`}
          </Box>
        ),
      },
      {
        accessorKey: 'PayingCustomer',
        header: '#Paying Customer',
        size: 50
      },
      {
        accessorKey: 'DisplayDate',
        header: 'Period',
      }
    ],
    [],
  );


  const token = useToken().token;
  const [data, setData] = React.useState<ICommissionDataRow[]>([]);
  const [selectedMonth, setSelectedMonth] = React.useState<number | undefined>(new Date().getMonth() + 1); // Current month
  const [selectedYear, setSelectedYear] = React.useState<number | undefined>(new Date().getFullYear()); // Current Year
  const [YearList, setYearList] = React.useState<{ year: number }[]>([])
  const [marketerID, setMarketerID] = React.useState<string>();
  const [marketerIDLong, setMarketerIDLong] = React.useState<string>();
  const [marketerListOptions, setMarketerListOptions] = React.useState<string[]>();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const cleanMarketer = () => {
    setMarketerIDLong(undefined);
    setMarketerID(undefined);

  };
  const handleYearChange = (event: SelectChangeEvent<number>) => {
    cleanMarketer()
    setSelectedYear(Number(event.target.value));
  };
  const handleMonthChange = (event: SelectChangeEvent<number>) => {
    cleanMarketer()
    setSelectedMonth(Number(event.target.value));
  };

  useEffect(() => {
    GetAllMarketerUserData({ token }).then((response) => {
      if (response) {
        const newDate = response.data.map((row: IAllMUserDataRow) => {
          return row.fullname;
        })
        setMarketerListOptions(newDate)
      }
    })
  }, [token])

  useEffect(() => {
    // Generate the list of years for the last 5 years
    const currentYear = new Date().getFullYear();
    const last5Years = Array.from({ length: 5 }, (_, index) => ({ year: currentYear - index }));
    setYearList(last5Years);
  }, []);

  useEffect(() => {
    GetMarketerCommission({ token, selectedMonth, selectedYear, marketerID }).then((response) => {
      if (response) {
        const newData = response.data
        setData(newData);
      }
    });
  }, [token, selectedMonth, selectedYear, marketerID]);

  return <>
    {data && marketerListOptions &&
      <div className="tabs-container" style={{ marginTop: '65px', position: 'fixed', width: '80%', zIndex: 1 }}>
        <Container sx={{ mt: 4, mb: 4 }}>
          <MaterialReactTable
            columns={columns}
            data={data}
            enableColumnFilterModes={false}
            enableMultiRowSelection={false} //use radio buttons instead of checkboxes
            enableDensityToggle={false}
            initialState={{ density: 'compact', pagination: { pageIndex: 0, pageSize: 15 }, }}
            muiTablePaginationProps={{
              rowsPerPageOptions: [5, 10, 20, 50],
            }}
            muiTableContainerProps={{
              sx: {
                height: data.length > 20 ? window.innerHeight - 300 + 'px' : undefined,
                overflow: "auto",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                  width: "0.4em",
                  height: "0.4em",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#1976d2",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#1976d2",
                },
              },
            }}
            enableFullScreenToggle={false}
            enableHiding={false}
            rowCount={data.length}
            renderTopToolbarCustomActions={() => (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                      Fambul Marketer Commission
                    </Typography>
                    <Button
                      aria-describedby={id}
                      variant="text"
                      color="primary"
                      startIcon={<HelpCenterIcon />}
                      onClick={handleClick}
                    />
                  </div>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    sx={{ width: 750 }}
                  >
                    <Typography sx={{ padding: 1, fontStyle: 'italic', backgroundColor: '#f0f0f0', color: 'red', border: '1px solid #1565C0', borderRadius: '10px' }}>
                      This section provides a comprehensive view of commissions earned by all marketers. Use the dropdown menu to select a specific month or year, or filter the results by individual marketers. Gain insights into performance and earnings effortlessly
                    </Typography>
                  </Popover>
                </>
                <Box sx={{ display: 'flex', gap: '0.2rem', flexWrap: 'wrap' }}>
                  <FormControl sx={(theme) => ({ minWidth: '200px', border: `1px solid ${theme.palette.success.dark}`, borderRadius: '10px' })}>
                    <InputLabel id="select-month-label" sx={(theme) => ({ color: theme.palette.success.dark })} >Select Month</InputLabel>
                    <Select
                      labelId="select-month-label"
                      id="select-month"
                      value={selectedMonth}
                      onChange={handleMonthChange}
                    >
                      <MenuItem style={denseStyle} value={1}>January</MenuItem>
                      <MenuItem style={denseStyle} value={2}>February</MenuItem>
                      <MenuItem style={denseStyle} value={3}>March</MenuItem>
                      <MenuItem style={denseStyle} value={4}>April</MenuItem>
                      <MenuItem style={denseStyle} value={5}>May</MenuItem>
                      <MenuItem style={denseStyle} value={6}>June</MenuItem>
                      <MenuItem style={denseStyle} value={7}>July</MenuItem>
                      <MenuItem style={denseStyle} value={8}>August</MenuItem>
                      <MenuItem style={denseStyle} value={9}>September</MenuItem>
                      <MenuItem style={denseStyle} value={10}>October</MenuItem>
                      <MenuItem style={denseStyle} value={11}>November</MenuItem>
                      <MenuItem style={denseStyle} value={12}>December</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={(theme) => ({ minWidth: '200px', border: `1px solid ${theme.palette.secondary.dark}`, borderRadius: '10px' })}>
                    <InputLabel id="select-year-label" sx={(theme) => ({ color: theme.palette.secondary.dark })} >Select Year</InputLabel>
                    <Select
                      labelId="select-year-label"
                      id="select-year"
                      value={selectedYear}
                      onChange={handleYearChange}
                    >
                      {YearList.map((yearItem) => (
                        <MenuItem key={yearItem.year} value={yearItem.year}>
                          {yearItem.year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={(theme) => ({ minWidth: '200px', border: `1px solid ${theme.palette.error.dark}`, borderRadius: '10px' })}>
                    <InputLabel id="select-marketer-label" sx={(theme) => ({ color: theme.palette.error.dark })} >Select Marketer</InputLabel>
                    <Select
                      labelId="select-marketer-label"
                      id="select-marketer"
                      value={marketerIDLong || ''}
                      onChange={(event) => {
                        const newVal = String(event.target.value)
                        setMarketerIDLong(newVal)
                        setMarketerID(newVal.slice(0, 3));
                      }}
                    >
                      {marketerListOptions.map((row) => (
                        <MenuItem key={row} value={row}>
                          {row}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )} />
        </Container>
      </div>
    }
  </>
};
export default MarketerFambulReport;