import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IAddNewPaymentProps {
    groupPolicyNumber: string,
    receiptNumber: string,
    paymentDate: string,
    receivedFrom: string,
    amountPaid: number,
    paymentReason: string,
    chequeNumber: string,
    office: string,
    agentBroker: string,
    insured: string,
    enteredBy: string,
    IsGroupLife: boolean,
    token: string,
}

const baseURL = apiURL()

const AddNewPayment = async (props: IAddNewPaymentProps) => {
    let value = ''
    const token = props.token
    const data = {
        GroupPolicyNumber: props.groupPolicyNumber,
        ReceiptNumber: props.receiptNumber,
        PaymentDate: props.paymentDate,
        ReceivedFrom: props.receivedFrom,
        AmountPaid: props.amountPaid,
        PaymentReason: props.paymentReason,
        ChequeNumber: props.chequeNumber,
        Office: props.office,
        AgentBroker: props.agentBroker,
        Insured: props.insured,
        EnteredBy: props.enteredBy,
        IsGroupLife: props.IsGroupLife,
    }
    try {
        const response = await axios.post(baseURL + "frontDeskPayment", data, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if (response.status === 200) {
            value = "success"
        }
    } catch (error: AxiosError | any) {
        if (error.response) {
            value = error.response.data
        } else if (error.request) {
            value = "No response from server"
        } else {
            value = "Something went wrong"
        }
    }
    return value
}
export default AddNewPayment;

