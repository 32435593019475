import axios from "axios";
import apiURL from "../apiURL";

export interface ILoanRow {
    ClaimID: number,
    amountBorrowed: number,
    amountToPayBack: number,
    amountPaid: number,
    loanBalance: number,
    outStandingAtAproval: number,
    StartDate: Date,
    EndDate: Date,
}

export interface ILoanData {
    data: ILoanRow[],
}

interface ILoanProps {
    token: string;
    PolicyNo: string;
}
const baseURL = apiURL()
const GetCustomerLoan = async (props: ILoanProps) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + `Loan/GetCustomerLoan?PolicyNo=${props.PolicyNo}`,
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as ILoanData;

}
export default GetCustomerLoan;