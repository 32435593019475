import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";
import { checkSessionExpiration } from "../checkSessionExpiration";

export interface ICommissionDataRow {
    MarketerID: string;
    Fullname: string;
    Gender: string;
    CustomerPaid: number;
    Commission: number;
    PayingCustomer: number;
    IYear?: number;
    IMonth?: number;
    DisplayDate?: string;
}

export interface ICommissionData {
    data: ICommissionDataRow[];
}

interface IGetCommissions {
    token: string;
    selectedMonth?: number;
    selectedYear?: number;
    marketerID?: string;
}

const baseURL = apiURL();

const MonthNumberToName = (monthNumber: number) => {
    const getMonthName = (monthNumber: number) => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];

        if (monthNumber >= 1 && monthNumber <= 12) {
            return months[monthNumber - 1];
        } else {
            return 'Invalid Month';
        }
    };
    return getMonthName(monthNumber);
};

const GetMarketerCommission = async (props: IGetCommissions) => {
    const token = props.token;
    const queryString = `?selectedMonth=${props.selectedMonth}&year=${props.selectedYear}` + (props.marketerID ? `&marketerID=${encodeURIComponent(props.marketerID)}` : '');

    // Check session expiration before proceeding
    if (!checkSessionExpiration()) {
        return;
    }

    try {
        const data = await axios({
            method: "get",
            responseType: "json",
            url: `${baseURL}Report/GetMarketerCommission${queryString}`,
            headers: { "Authorization": `Bearer ${token}` }
        });
        const commissionData: ICommissionDataRow[] = data.data.map((row: ICommissionDataRow) => {
            if (props.marketerID && row.IMonth !== undefined && row.IYear !== undefined) {
                const monthName = MonthNumberToName(row.IMonth);
                const displayDate = `${monthName}-${row.IYear}`;

                return {
                    ...row,
                    DisplayDate: displayDate,
                };
            } else {
                if (props.selectedMonth) {
                    const monthName = MonthNumberToName(props.selectedMonth);
                    const displayDate = `${monthName}-${props.selectedYear}`;
                    return {
                        ...row,
                        DisplayDate: displayDate,
                    };
                }
            }
        });
        return { data: commissionData } as ICommissionData;
    } catch (error) {
        // Call the handleAxiosError function with the error and redirect URL
        handleAxiosError(error, "/login"); // Adjust the URL as needed
        return undefined;
    }
};

export default GetMarketerCommission;
