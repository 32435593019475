import axios from "axios";
import apiURL from "../apiURL";

export interface IMarketerCommissionDataRow {
    IDCode: string,
    Paid: string,
    Commission: string,
}

export interface IMarketerCommissionData {
    data: IMarketerCommissionDataRow[],
}
interface IGetCommission {
    token: string;
    marketerID: string;
    selectedMonth: number;
    selectedYear: number;
}
const baseURL = apiURL()
const GetCommission = async (props: IGetCommission) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + `Marketer/getCommission?marketerID=${props.marketerID}&selectedMonth=${props.selectedMonth}&selectedYear=${props.selectedYear}`,
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IMarketerCommissionData;

}
export default GetCommission;