import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";
import { checkSessionExpiration } from "../checkSessionExpiration";

export interface IDuplicateDataRow {
    IDCode: string
    Details: string,
    DuplicateCount: number,
    countPayments: number,
}

export interface IDuplicateData {
    data: IDuplicateDataRow[],
}
interface IGetMissing {
    token: string;
}
const baseURL = apiURL()
const GetDuplicateCustomers = async (props: IGetMissing) => {
    const token = props.token
    // Check session expiration before proceeding
    if (!checkSessionExpiration()) {
        return;
    }
    try {
        const data = await axios({
            method: "get",
            responseType: "json",
            url: baseURL + "Report/GetDuplicateCustomers",
            headers: { "Authorization": `Bearer ${token}` }
        })
        return data as IDuplicateData;
    } catch (error) {
        // Call the handleAxiosError function with the error and redirect URL
        handleAxiosError(error, "/login"); // Adjust the URL as needed
        return undefined;
    }

}
export default GetDuplicateCustomers;