import axios from "axios";
import apiURL from "../apiURL";
import { IGLDependantData } from "../../components/Dashboard/GroupLife/AddNewGLUserStateless";


export interface IGLDependantNewData {
    data: IGLDependantData[],
}
interface IGetDependant {
    token: string;
    glcustomersID: number;
}
const baseURL = apiURL()
const GetGLDependants = async (props: IGetDependant) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + `GroupLifeEdit/getGLDependants?glcustomersID=${props.glcustomersID}`,
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IGLDependantNewData;

}
export default GetGLDependants;