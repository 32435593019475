import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";
import { checkSessionExpiration } from "../checkSessionExpiration";

export interface IFambulCardDataRow {
  fullname: string;
  idCode: string;
  dob: Date;
  regDate: Date;
  details: string;
  telephone: string;
  premium: number;
  totalPayment: number;
  markedAsPrinted: boolean;
  whoPrintNote: string;
  shouldHavepaid: number;
}
export interface IFambulCardData {
  data: IFambulCardDataRow[];
}

interface IGetFambulCard {
  token: string;
}
const GetQualifiedVICard = async (props: IGetFambulCard) => {
  const token = props.token;
  const baseURL = apiURL();

  // Check session expiration before proceeding
  if (!checkSessionExpiration()) {
    return;
  }

  try {
    const data = await axios({
      method: "get",
      responseType: "json",
      url: baseURL + `fambulDetails/getQualifiedVICard`,
      headers: { Authorization: `Bearer ${token}` },
    });

    return data as IFambulCardData;
  } catch (error) {
    // Call the handleAxiosError function with the error and redirect URL
    handleAxiosError(error, "/login"); // Adjust the URL as needed
    return undefined;
  }
};

export default GetQualifiedVICard;
