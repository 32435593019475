import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import AddPaymentInfo from "../services/API/AddPaymentInfo";
import { ISignInData } from "../services/API/signin";
import jwt_decode from "jwt-decode";
import useToken from "../components/useToken";

interface PaymentProps {
  handleClose: (val: boolean) => void;
  type: number;
  PolicyNo: string;
}

const AddPayment = (props: PaymentProps) => {
  const token = useToken().token;
  const [credit, setCredit] = useState<number>(0);
  const userData: ISignInData = jwt_decode(token);

  const handleAddPayment = async () => {
    const PolicyNo = props.PolicyNo;
    const type = props.type;
    const AddedBy = userData.DisplayName;
    await AddPaymentInfo({ PolicyNo, AddedBy, credit, type, token });
    props.handleClose(true);
  };

  return (
    <div>
      <Dialog open={true} onClose={props.handleClose}>
        <DialogTitle>Add Payment</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter credit amount.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="credit"
            label="Credit"
            type="number"
            fullWidth
            variant="standard"
            value={credit}
            onChange={(e) => setCredit(Number(e.target.value))}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              props.handleClose(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleAddPayment}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddPayment;
