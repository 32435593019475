import axios from "axios";
import apiURL from "../apiURL";

export interface IMotorInsuranceRow {
    vehicleInsuranceTypeID: number,
    vehicleInsuranceType: string,
    vehicleInsuranceTypeDesc: string,
    isEnabled: boolean,
}

export interface IMotorInsuranceData {
    data: IMotorInsuranceRow[],
}
interface IGetMedPack {
    token: string;
}
const baseURL = apiURL()
const GetMotorInsurance = async (props: IGetMedPack) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + "motorInsurance",
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IMotorInsuranceData;

}
export default GetMotorInsurance;