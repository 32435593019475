export const claimTypes = [
  {
    number: 1,
    label: "Loans",
    requiredDocuments: [
      "Loan Application",
      "Voters or Nassit I.D",
      "Bank I.D",
      "Slico I.D",
    ],
  },
  {
    number: 2,
    label: "Death",
    requiredDocuments: [
      "Death Certificate",
      "Death Letter",
      "Marriage Certificate",
      "Passport Picture ",
      "I.D card ",
      "Birth Certificate",
      "Passport Picture ",
      "Valid I.D card ",
      "Passport picture",
      "Birth Certificate",
      "School Report Card",
    ],
  },
  {
    number: 3,
    label: "Withdrawal",
    requiredDocuments: [
      "Letter of withdrawal",
      "Voters or Nassit I.D",
      "Bank I.D",
      "Slico I.D",
    ],
  },
  {
    number: 4,
    label: "Retirement",
    requiredDocuments: [
      "Retirement Letter",
      "Slico I.D",
      "Voters or Nassit I.D",
      "Bank I.D",
    ],
  },
  {
    number: 5,
    label: "General",
    requiredDocuments: ["Voters or Nassit I.D", "Bank I.D", "Slico I.D"],
  },
];

export const itemList = [
  { number: 1, label: "Fambul", onlyFor: 1, onlyForDType: 1, onlyForRType: 0 },
  { number: 2, label: "Medical", onlyFor: 0, onlyForDType: 1, onlyForRType: 0 },
  { number: 3, label: "Motor", onlyFor: 0, onlyForDType: 0, onlyForRType: 0 },
  {
    number: 4,
    label: "GroupLife",
    onlyFor: 1,
    onlyForDType: 1,
    onlyForRType: 1,
  },
  { number: 5, label: "CashIT", onlyFor: 0, onlyForDType: 0, onlyForRType: 0 },
  {
    number: 6,
    label: "Fidelity",
    onlyFor: 0,
    onlyForDType: 0,
    onlyForRType: 0,
  },
  { number: 7, label: "CashIV", onlyFor: 0, onlyForDType: 0, onlyForRType: 0 },
  {
    number: 8,
    label: "Burglary",
    onlyFor: 0,
    onlyForDType: 0,
    onlyForRType: 0,
  },
  { number: 9, label: "Fire", onlyFor: 0, onlyForDType: 0, onlyForRType: 0 },
  {
    number: 10,
    label: "Employers",
    onlyFor: 0,
    onlyForDType: 1,
    onlyForRType: 0,
  },
  { number: 11, label: "Marine", onlyFor: 0, onlyForDType: 0, onlyForRType: 0 },
  { number: 12, label: "Public", onlyFor: 0, onlyForDType: 0, onlyForRType: 0 },
  {
    number: 13,
    label: "Professional",
    onlyFor: 0,
    onlyForDType: 0,
    onlyForRType: 0,
  },
  {
    number: 14,
    label: "Contractor",
    onlyFor: 0,
    onlyForDType: 0,
    onlyForRType: 0,
  },
];

export const GroupDeathCover = [
  { premium: 25, cover: 5000 },
  { premium: 50, cover: 8000 },
  { premium: 100, cover: 15000 },
  { premium: 200, cover: 20000 },
  { premium: 400, cover: 40000 },
];

export const fambulDeathCover = [
  { premium: 40, cover: 5000 },
  { premium: 100, cover: 8000 },
  { premium: 200, cover: 15000 },
  { premium: 400, cover: 20000 },
];
