import * as React from "react";
import {
  styled,
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MainListItems from "./AreaList/MainListItems";
import SecondaryListItems from "./AreaList/SecondaryListItems";
import AdminListItems from "./AreaList/AdminListItems";
import {
  Avatar,
  Menu,
  MenuItem,
  Tooltip,
  Button,
  Badge,
  ListItem,
  ListItemText,
  Popover,
  Card,
  CardContent,
} from "@mui/material";
import DashboardPreview from "./DashboardPreview";
import useToken from "../useToken";
import jwt_decode from "jwt-decode";
import { ISignInData } from "../../services/API/signin";
import "@mui/styles";
import { useNavigate } from "react-router-dom";
import UserProfilePage from "../UserProfile/UserProfilePage";
import GetImage from "../../services/API/GetImage";
import { useEffect } from "react";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClaimWizard from "../../Common/ClaimWizard";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import AddExpenseRequest from "./Expense/AddExpenseRequest";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import GetExpenseRequests, {
  IExpenseRequestRow,
} from "../../services/API/GetExpenseRequests";
import InfoIcon from "@mui/icons-material/Info";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const drawerWidth: number = 240;
//SessionTimeout();
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

const getFilteredDataAndCount = (
  data: IExpenseRequestRow[],
  userData: ISignInData
) => {
  let newDate = [];

  if (
    userData.CanPreApproveExpense === "True" ||
    userData.CanApproveExpense === "True"
  ) {
    newDate = data?.filter((request) => request.requestStatusOrder === 2);
  } else if (userData.CanReviewExpense === "True") {
    newDate = data.filter(
      (request) =>
        request.requestStatusOrder === 1 || request.requestStatusOrder === 5
    );
  } else {
    newDate = data.filter((request) => request.approvedBy === null);
  }
  //Hide Request from assigned Person until request is approved
  const filteredData = newDate.filter((item) => {
    const condition = !(
      item.requestAssignedID === Number(userData.EmployeeID) &&
      item.requestStatusOrder === 1
    );
    return condition;
  });
  return {
    newDate: filteredData,
    count: filteredData.length,
  };
};
const DashboardContent = () => {
  const navigate = useNavigate();
  const token = useToken().token;
  const userData: ISignInData = jwt_decode(token);
  const [userImageToDisplay, setUserImageToDisplay] = React.useState<
    string | undefined
  >(sessionStorage.getItem("UserImageToDisplay") ?? undefined);
  const mobileBreakpoint = 600; // Example breakpoint for mobile devices
  const isMobile = window.innerWidth < mobileBreakpoint;
  const [openClaim, onClose] = React.useState(false);
  const [openPR, setOpenPR] = React.useState<boolean>(false);
  const [data, setData] = React.useState<IExpenseRequestRow[]>();
  const [requestRow, setRequestRow] = React.useState<IExpenseRequestRow>();
  const [reload, setReload] = React.useState(false);
  const [badgeContent, setBadgeContent] = React.useState(0);

  useEffect(() => {
    const id = userData.EmployeeID;
    GetExpenseRequests({ token, id }).then((response) => {
      if (response) {
        // Filter out the rows where requestStatus is "Approved" and requestDate is more than a week old
        const filteredData = response.data.filter((item) => {
          const requestDate = new Date(item.requestDate);
          const oneWeekAgo = new Date();
          oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
          return !(
            (item.requestStatus === "Completed" ||
              item.requestStatus === "Approved") &&
            requestDate < oneWeekAgo
          );
        });
        const { newDate, count } = getFilteredDataAndCount(
          filteredData,
          userData
        );
        setData(newDate);
        setBadgeContent(count);
      }
    });
  }, [token, userData.EmployeeID, reload]);

  useEffect(() => {
    if (!userImageToDisplay) {
      const subfolder = "Profile";
      const imageName = userData.UserImageName;
      GetImage({ token, subfolder, imageName })
        .then((imageResponse) => {
          if (imageResponse) {
            const blob = new Blob([imageResponse.data], {
              type: "image/jpeg" || "image/png",
            });
            const imageUrl = URL.createObjectURL(blob);
            sessionStorage.setItem("UserImageToDisplay", imageUrl);
            setUserImageToDisplay(imageUrl);
          }
        })
        .catch((imageError) => {
          console.error("Error fetching image:", imageError);
        });
    }
  }, [token, userData.UserImageName, userImageToDisplay]);

  const logOut = () => {
    sessionStorage.clear();
    navigate("/Login");
  };
  const [open, setOpen] = React.useState(isMobile ? false : true);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [areaToLoad, setAreaToLoad] = React.useState<JSX.Element>(
    <DashboardPreview />
  );
  const [isWizardOpen, setWizardOpen] = React.useState<boolean>(false);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleProfileClicked = () => {
    setAreaToLoad(<UserProfilePage />);
  };
  const handleLogoutClicked = () => {
    logOut();
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleOpenWizard = () => {
    onClose(true);
    setWizardOpen(true);
  };

  const handleCloseWizard = () => {
    setWizardOpen(false);
  };

  const HandleOpenPR = () => {
    setReload(false);
    setRequestRow(undefined);
    setOpenPR(true);
  };
  const handleClose = (val?: boolean) => {
    if (val) setReload(true);
    setRequestRow(undefined);
    setOpenPR(false);
  };

  const [anchorElNotification, setAnchorElNotification] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setAnchorElNotification(null);
  };
  const loadFullRequest = (request: IExpenseRequestRow) => {
    setReload(false);
    setAnchorElNotification(null);
    setRequestRow(request);
    setOpenPR(true);
  };

  const openNotification = Boolean(anchorElNotification);
  const id = open ? "simple-popover" : undefined;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="absolute" open={isMobile ? false : open}>
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                disabled={isMobile}
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                {`SLICO Dashboard  |  `}

                <Button
                  color="error"
                  variant="contained"
                  style={{
                    textTransform: "none",
                  }}
                  startIcon={<BookOnlineIcon />}
                  onClick={HandleOpenPR}
                >
                  Expense Request
                </Button>
              </Typography>

              <Fab
                color="secondary"
                style={{ position: "fixed", bottom: "16px", right: "16px" }}
                onClick={handleOpenWizard}
              >
                <AddIcon />
              </Fab>
              <div
                style={{
                  position: "absolute",
                  right: "70px",
                }}
              >
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Notification">
                    <Badge
                      badgeContent={badgeContent}
                      color="error"
                      onClick={handleClick}
                      sx={{ cursor: "pointer" }}
                    >
                      <NotificationsNoneIcon />
                    </Badge>
                  </Tooltip>
                  {data && data.length > 0 && (
                    <Popover
                      id={id}
                      open={openNotification}
                      anchorEl={anchorElNotification}
                      onClose={handleCloseNotification}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <List
                        sx={{
                          bgcolor: "grey.200",
                          maxHeight: "400px", // Adjust the height as needed
                          width: "400px",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": {
                            width: "1.5px",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "#f1f1f1",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#888",
                          },
                          "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "#555",
                          },
                          cursor: "pointer",
                        }}
                      >
                        {data?.map((request, index) => {
                          let statusColor = "orange"; // Default color
                          if (request.requestStatus === "Awaiting Approval") {
                            statusColor = "dodgerblue"; // A brighter blue for better visibility
                          } else if (request.requestStatus === "Approved") {
                            statusColor = "forestgreen"; // A deep green for a positive status
                          } else if (request.requestStatus === "Completed") {
                            statusColor = "mediumpurple"; // A medium purple for distinction
                          } else if (request.requestStatus === "Rejected") {
                            statusColor = "crimson"; // A strong red for alert
                          } else if (request.requestStatus === "Closed") {
                            statusColor = "dimgray"; // A medium gray for a neutral status
                          } else if (request.requestStatus === "Queried") {
                            statusColor = "#088da5"; // A distinct color for the Queried status
                          }

                          return (
                            <Card
                              key={index}
                              sx={{ marginBottom: 0.5, bgcolor: "#e1edf0" }}
                            >
                              <CardContent>
                                <ListItem
                                  onClick={() => loadFullRequest(request)}
                                  sx={{ padding: 0 }}
                                >
                                  <ListItemText
                                    primary={`Purpose: ${request.purpose.slice(
                                      0,
                                      25
                                    )}...`}
                                    secondary={
                                      <>
                                        <span>
                                          Date:{" "}
                                          {new Date(
                                            request.requestDate
                                          ).toLocaleDateString()}
                                        </span>
                                        <br />
                                        <span>
                                          Raised by: {request.requestBy}
                                        </span>
                                        <br />
                                        <span>
                                          Total Amount:{" "}
                                          {
                                            <Box
                                              component="span"
                                              sx={(theme) => ({
                                                backgroundColor:
                                                  theme.palette.secondary.dark,
                                                borderRadius: "0.25rem",
                                                color: "#fff",
                                                maxWidth: "25ch",
                                                p: "0.25rem",
                                              })}
                                            >
                                              {request.requestAmount.toLocaleString(
                                                "en-SL",
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              ) + " SLE"}
                                            </Box>
                                          }
                                        </span>
                                        <br />
                                        <span>
                                          Assigned to: {request.requestAssigned}
                                        </span>
                                        <br />
                                        <span>
                                          Status:{" "}
                                          {
                                            <Box
                                              component="span"
                                              sx={{
                                                backgroundColor: statusColor,
                                                borderRadius: "0.25rem",
                                                color: "#fff",
                                                maxWidth: "25ch",
                                                p: "0.25rem",
                                              }}
                                            >
                                              {request.requestStatus}
                                              {request.requestStatus ===
                                                "Rejected" &&
                                                request.rejectionNote && (
                                                  <Tooltip
                                                    title={
                                                      request.rejectionNote
                                                    }
                                                    arrow
                                                  >
                                                    <IconButton
                                                      size="small"
                                                      sx={{
                                                        marginLeft: "0.5rem",
                                                        color: "#fff",
                                                      }}
                                                    >
                                                      <InfoIcon />
                                                    </IconButton>
                                                  </Tooltip>
                                                )}
                                            </Box>
                                          }
                                        </span>
                                      </>
                                    }
                                  />
                                </ListItem>
                              </CardContent>
                              <Divider />
                            </Card>
                          );
                        })}
                      </List>
                    </Popover>
                  )}
                  <Typography variant="caption" sx={{ paddingLeft: 2 }}>
                    {userData.DisplayName}
                  </Typography>
                </Box>
              </div>
              <Box sx={{ flexGrow: 0 }} key={userImageToDisplay}>
                <Tooltip title="Personal Info">
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{ p: 0 }}
                    size="large"
                  >
                    <Avatar
                      alt={userData.DisplayName}
                      src={userImageToDisplay}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleProfileClicked}>Profile</MenuItem>
                  <MenuItem onClick={handleLogoutClicked}>Logout</MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer} size="large">
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List
              component="nav"
              sx={(theme) => ({
                overflowX: "hidden",
                overflowY: "auto",
                maxHeight: "calc(100vh - 54px)",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                  width: "0.1em",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.primary.main,
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: theme.palette.primary.main,
                },
              })}
            >
              <MainListItems setButtonClick={setAreaToLoad} />
              <Divider sx={{ my: 0 }} />
              {Number(userData.CanViewReport) === 1 && (
                <>
                  <SecondaryListItems setButtonClick={setAreaToLoad} />
                  <Divider sx={{ my: 0 }} />
                </>
              )}
              {Number(userData.CanViewAdmin) === 1 && (
                <>
                  <AdminListItems setButtonClick={setAreaToLoad} />
                </>
              )}
            </List>
          </Drawer>
          <Box
            component="main"
            sx={(theme) => ({
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.main,
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: theme.palette.primary.main,
              },
            })}
          >
            {areaToLoad}
            {isWizardOpen && (
              <ClaimWizard openUser={openClaim} onClose={handleCloseWizard} />
            )}
            {openPR && (
              <AddExpenseRequest
                openUser={openPR}
                onClose={handleClose}
                requestRow={requestRow}
              />
            )}
          </Box>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default function Dashboard() {
  return <DashboardContent />;
}
