import * as React from "react";
import Box from "@mui/material/Box";
import {
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Autocomplete,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { IActiveUserState } from "./MedUserReducer";
import { MaritalOptions } from "./AddNewUserStateless";

export interface IActiveUserStep1Props {
  state: IActiveUserState,
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleDropDownChange: (e: any) => void,
  updateDependantNames?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleRadioChange?: (selectIndex: number, selectValue: string) => void,
  handleTextChange?: (repIndex: number, repValue: string) => void,
}

const AddNewUserStep1: React.FC<IActiveUserStep1Props> = ({
  state,
  handleChange,
  handleDropDownChange,
}) => {
  return (
    <div key={"DatatoLoad" + JSON.stringify(state.dataToSave)}>
      <Container sx={{ mt: 2 }} >
        <Box sx={{ width: 800, height: 430 }}>
          <React.Fragment>
            <Grid container spacing={2}>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="title"
                  name="title"
                  label="Title"
                  fullWidth
                  autoComplete="title"
                  inputProps={{ maxLength: 9 }}
                  disabled={state.dataToSave.editing}
                  defaultValue={state.dataToSave.title}
                  variant="standard"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="initials"
                  name="initials"
                  label="Initials"
                  fullWidth
                  inputProps={{ maxLength: 9 }}
                  autoComplete="initials"
                  disabled={state.dataToSave.editing}
                  defaultValue={state.dataToSave.initials}
                  variant="standard"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="Sex"
                  defaultValue={state.dataToSave.Sex || "male"}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                    disabled={state.dataToSave.editing}
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                    disabled={state.dataToSave.editing}
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="firstName"
                  name="firstName"
                  label="First name"
                  fullWidth
                  inputProps={{ maxLength: 40 }}
                  defaultValue={state.dataToSave.firstName}
                  autoComplete="given-name"
                  variant="standard"
                  onChange={handleChange}
                  disabled={state.dataToSave.editing}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="middleName"
                  name="middleName"
                  defaultValue={state.dataToSave.middleName}
                  label="Middle name"
                  fullWidth
                  inputProps={{ maxLength: 40 }}
                  autoComplete="middle-name"
                  variant="standard"
                  onChange={handleChange}
                  disabled={state.dataToSave.editing}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="lastName"
                  name="lastName"
                  defaultValue={state.dataToSave.lastName}
                  label="Last name"
                  fullWidth
                  inputProps={{ maxLength: 40 }}
                  autoComplete="family-name"
                  variant="standard"
                  onChange={handleChange}
                  disabled={state.dataToSave.editing}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="email"
                  name="email"
                  defaultValue={state.dataToSave.email}
                  label="Email"
                  type="email"
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                  autoComplete="email-add"
                  variant="standard"
                  onChange={handleChange}
                  disabled={state.dataToSave.editing}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  id="dateOB"
                  name="dateOB"
                  defaultValue={state.dataToSave.dateOB}
                  label="Date Of Birth"
                  type="date"
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                  disabled={state.dataToSave.editing}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="telephone"
                  name="telephone"
                  defaultValue={state.dataToSave.telephone}
                  label="Telephone"
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                  autoComplete="tel-number"
                  variant="standard"
                  onChange={handleChange}
                  disabled={state.dataToSave.editing}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="residentialAddress"
                  name="residentialAddress"
                  defaultValue={state.dataToSave.residentialAddress}
                  label="Address"
                  fullWidth
                  inputProps={{ maxLength: 150 }}
                  autoComplete="residentialAddress-line1"
                  variant="standard"
                  onChange={handleChange}
                  disabled={state.dataToSave.editing}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="city"
                  name="city"
                  label="City"
                  fullWidth
                  inputProps={{ maxLength: 40 }}
                  defaultValue={state.dataToSave.city}
                  autoComplete="city"
                  variant="standard"
                  onChange={handleChange}
                  disabled={state.dataToSave.editing}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="country"
                  name="country"
                  defaultValue={state.dataToSave.country}
                  label="Country"
                  inputProps={{ maxLength: 40 }}
                  fullWidth
                  autoComplete="res-country"
                  variant="standard"
                  onChange={handleChange}
                  disabled={state.dataToSave.editing}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <form>
                  <Autocomplete
                    disablePortal
                    id="MaritalStatus"
                    defaultValue={state.dataToSave.MaritalStatus || "Married"}
                    options={MaritalOptions}
                    sx={{ width: 250 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Marital Status" />
                    )}
                    onChange={handleDropDownChange}
                    disabled={state.dataToSave.editing}
                  />
                </form>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="weightKg"
                  name="weightKg"
                  defaultValue={state.dataToSave.weightKg}
                  label="Weight (Kg)"
                  fullWidth
                  inputProps={{ maxLength: 10 }}
                  autoComplete="weightKg-cus"
                  variant="standard"
                  onChange={handleChange}
                  disabled={state.dataToSave.editing}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="heightCm"
                  name="heightCm"
                  defaultValue={state.dataToSave.heightCm}
                  label="Height (cm)"
                  fullWidth
                  inputProps={{ maxLength: 10 }}
                  autoComplete="heightCm-cus"
                  variant="standard"
                  onChange={handleChange}
                  disabled={state.dataToSave.editing}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="emergencyContactName"
                  name="emergencyContactName"
                  defaultValue={state.dataToSave.emergencyContactName}
                  label="Emergency Contact"
                  fullWidth
                  inputProps={{ maxLength: 25 }}
                  autoComplete="emergencyContactName-Contact"
                  variant="standard"
                  onChange={handleChange}
                  disabled={state.dataToSave.editing}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="emergencyTelephoneNumber"
                  name="emergencyTelephoneNumber"
                  defaultValue={state.dataToSave.emergencyTelephoneNumber}
                  label="Telephone"
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                  autoComplete="emergencyTelephoneNumber-ContTel"
                  variant="standard"
                  onChange={handleChange}
                  disabled={state.dataToSave.editing}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="hasDependant"
                      defaultChecked={state.dataToSave.hasDependant}
                      onChange={handleChange}
                      disabled={state.dataToSave.editing}
                    />
                  }
                  label="Has Dependant"
                />
              </Grid>
            </Grid>
          </React.Fragment>
        </Box>
      </Container>
    </div>
  );
}
export default AddNewUserStep1