import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

interface IApprove {
  token: string;
  expenseTypeID: number;
  originalAmount: number;
  balance: number;
}

const baseURL = apiURL();

const UpdateExpenseType = async (props: IApprove) => {
  let value = "";
  const token = props.token;
  const data = {
    expenseTypeID: props.expenseTypeID,
    originalAmount: props.originalAmount,
    balance: props.balance,
  };
  try {
    const response = await axios.post(
      baseURL + "ExpenseRequest/updateExpenseType",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      value = "success";
    }
  } catch (error: AxiosError | any) {
    if (error.response) {
      value = error.response.data;
    } else if (error.request) {
      value = "No response from server";
    } else {
      value = "Something went wrong";
    }
  }
  return value;
};

export default UpdateExpenseType;
