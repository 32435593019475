import React, { useEffect, useMemo } from 'react';
import MaterialReactTable, {
    type MRT_ColumnDef,
} from 'material-react-table';
import useToken from '../../useToken';
import { Box } from '@mui/material';
import GetGLCustomerPayment, { IGLPaymentsRow } from '../../../services/API/GetGLCustomerPayment';

interface IGLProps {
    PolicyNo: string;
}
export const GLPayments = (props: IGLProps) => {
    const token = useToken().token;
    const [data, setData] = React.useState<IGLPaymentsRow[]>();
    useEffect(() => {
        GetGLCustomerPayment({ token, PolicyNo: props.PolicyNo }).then((response) => {
            if (response) {
                const newData = response.data
                setData(newData);
            }
        })
    }, [token, props.PolicyNo]);
    const columns = useMemo<MRT_ColumnDef<IGLPaymentsRow>[]>(
        () => [
            {
                accessorKey: 'PaymentAmount',
                header: 'Amount Paid',
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor: theme.palette.secondary.light,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '25ch',
                            p: '0.25rem',
                        })}
                    >
                        {`${Number(cell.getValue()).toLocaleString('en-SL', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) + ' SLL'}`}
                    </Box>
                ),
                size: 50,
            },
            {
                accessorKey: 'status',
                header: 'Status',
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor: theme.palette.success.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '25ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue()}
                    </Box>
                ),
                size: 50,
            },
            {
                accessorKey: 'PaymentDate',
                header: 'Payment Date',
                Cell: ({ cell }) => (cell.getValue() as string).slice(0, 10),
                size: 50,
            },
        ],
        [],
    );

    return (
        <>
            {data &&
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    enablePagination={true}
                    initialState={{ density: 'compact', pagination: { pageIndex: 0, pageSize: 10 }, }}
                    muiTablePaginationProps={{
                        rowsPerPageOptions: [5, 10],
                    }}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    enableRowNumbers={false}
                    enableFilters={false}
                />
            }
        </>
    );
};

export default GLPayments;
