import axios from "axios";
import apiURL from "../apiURL";

export interface IMedicalQuestionsRow {
    medicalQuestionID: number,
    medicalQuestionName: string,
    isActiveQuestion: boolean,
}

export interface IMedicalQuestionsData {
    data: IMedicalQuestionsRow[],
}
interface IGetMedPack {
    token: string;
}
const baseURL = apiURL()
const GetMedicalQuestionss = async (props: IGetMedPack) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + "medicalQuestions",
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IMedicalQuestionsData;

}
export default GetMedicalQuestionss;