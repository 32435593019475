import axios, { AxiosError } from "axios";

// Define a reusable function for handling Axios errors
export const handleAxiosError = (error: any, redirectUrl: string): void => {
    if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 401) {
            // Clear session data before redirecting to login
            sessionStorage.clear();
            // Redirect to login page
            window.location.href = `/#${redirectUrl}`; // Adjust the URL as needed
        } else {
            console.error("Error fetching data:", axiosError.message);
        }
    } else {
        console.error("Error fetching data:", error);
    }
};
