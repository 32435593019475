import axios from "axios";
import apiURL from "../apiURL";

export interface IFambulPaymentsRow {
    Credit: number,
    Status: string,
    Date: string,
}

export interface IFambulPaymentsData {
    data: IFambulPaymentsRow[],
}
interface IGetUser {
    token: string;
    idCode: string;
}
const baseURL = apiURL()
const GetFambulPayments = async (props: IGetUser) => {
    const token = props.token
    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + `fambulDetails/getFambulPayments?idCode=${props.idCode}`,
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IFambulPaymentsData;
}
export default GetFambulPayments;