import React, { useEffect, useMemo } from 'react';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import useToken from '../../useToken';
import { Box } from '@mui/material';
import GetUserGroupData, { IUserGroupDataRow } from '../../../services/API/GetUserGroupData';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const UserGroupData = () => {
  const columns = useMemo<MRT_ColumnDef<IUserGroupDataRow>[]>(
    () => [
      {
        accessorKey: 'EmployeeTypeName',
        header: 'Group',
      },
      {
        accessorKey: 'EmployeeTypeDescription',
        header: 'Desc',
      },
      {
        accessorKey: 'CanViewInsurance',
        header: 'View Insurance',
        Cell: ({ cell }) => (
          <Box
            component="span"
          >
            {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
          </Box>
        ),
        width: 50
      },
      {
        accessorKey: 'CanViewAdmin',
        header: 'View Admin',
        Cell: ({ cell }) => (
          <Box
            component="span"
          >
            {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
          </Box>
        ),
        width: 50
      },
      {
        accessorKey: 'CanViewReport',
        header: 'View Report',
        Cell: ({ cell }) => (
          <Box
            component="span"
          >
            {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
          </Box>
        ),
        width: 50
      },
      {
        accessorKey: 'CanEditInsurance',
        header: 'Edit Insurance',
        Cell: ({ cell }) => (
          <Box
            component="span"
          >
            {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
          </Box>
        ),
        width: 50
      },
      {
        accessorKey: 'CanEditAdmin',
        header: 'Edit Admin',
        Cell: ({ cell }) => (
          <Box
            component="span"
          >
            {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
          </Box>
        ),
        width: 50
      },
      {
        accessorKey: 'CanManageOtherInsurance',
        header: 'Other Insurance',
        Cell: ({ cell }) => (
          <Box
            component="span"
          >
            {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
          </Box>
        ),
        width: 50
      },
      {
        accessorKey: 'CanViewMedicalInsurance',
        header: 'View Medical',
        Cell: ({ cell }) => (
          <Box
            component="span"
          >
            {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
          </Box>
        ),
        width: 50
      },
      {
        accessorKey: 'CanEditMedicalInsurance',
        header: 'Edit Medical',
        Cell: ({ cell }) => (
          <Box
            component="span"
          >
            {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
          </Box>
        ),
        width: 50
      },
      {
        accessorKey: 'CanViewMotorInsurance',
        header: 'View Motor',
        Cell: ({ cell }) => (
          <Box
            component="span"
          >
            {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
          </Box>
        ),
        width: 50
      },
      {
        accessorKey: 'CanEditMotorInsurance',
        header: 'Edit Motor',
        Cell: ({ cell }) => (
          <Box
            component="span"
          >
            {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
          </Box>
        ),
        width: 50
      },
      {
        accessorKey: 'IsExternalSaleTeam',
        header: 'External',
        Cell: ({ cell }) => (
          <Box
            component="span"
          >
            {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
          </Box>
        ),
        width: 50
      },
      {
        accessorKey: 'CanViewPaymentReceipt',
        header: 'View Payment',
        Cell: ({ cell }) => (
          <Box
            component="span"
          >
            {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
          </Box>
        ),
        width: 50
      },
      {
        accessorKey: 'CanEditPaymentReceipt',
        header: 'Edit Payments',
        Cell: ({ cell }) => (
          <Box
            component="span"
          >
            {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
          </Box>
        ),
        width: 25
      },
      {
        accessorKey: 'CanAcknowledgePayment',
        header: 'Can Acknowledge',
        Cell: ({ cell }) => (
          <Box
            component="span"

          >
            {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
          </Box>
        ),
        width: 25
      },
      {
        accessorKey: 'CanApproveClaims',
        header: 'Can Approve Claims',
        Cell: ({ cell }) => (
          <Box
            component="span"

          >
            {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
          </Box>
        ),
        width: 25
      },

    ],
    [],
  );

  const token = useToken().token;
  const [data, setData] = React.useState<IUserGroupDataRow[]>([]);

  useEffect(() => {
    GetUserGroupData({ token }).then((response) => {
      if (response) {
        setData(response.data);
      }
    });
  }, [token]);

  return <> <MaterialReactTable
    columns={columns}
    data={data}
    enableMultiRowSelection={false} //use radio buttons instead of checkboxes
    enableDensityToggle={false}
    initialState={{ density: 'compact', pagination: { pageIndex: 0, pageSize: 15 }, }}
    muiTablePaginationProps={{
      rowsPerPageOptions: [5, 10, 20, 50],
    }}
    muiTableContainerProps={{
      sx: {
        overflow: "auto",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          width: "0.4em",
          height: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#1976d2",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#1976d2",
        },
      },
    }}
    enableFullScreenToggle={false}
    enableHiding={false}
    rowCount={data.length} />
  </>
};
export default UserGroupData;