import axios from "axios";
import apiURL from "../apiURL";

export interface IFambulDetailsRow {
  Firstname: string;
  Middlename: string;
  Lastname: string;
  DOB: Date;
  RegDate: Date;
  Gender: string;
  Premium: number;
  Telephone: string;
  BusinessLocation: string;
  Address: string;
  Region: string;
  District: string;
  ImageBinary: string;
}

export interface IFambulDetailData {
  data: IFambulDetailsRow[];
}
interface IGetUser {
  token: string;
  idCode: string;
}
const baseURL = apiURL();
const GetFambulDetails = async (props: IGetUser) => {
  const token = props.token;
  const data = await axios({
    method: "get",
    responseType: "json",
    url: baseURL + `fambulDetails/getFambulDetails?idCode=${props.idCode}`,
    headers: { Authorization: `Bearer ${token}` },
  });
  return data as IFambulDetailData;
};
export default GetFambulDetails;
