import axios from "axios";
import apiURL from "../apiURL";

const baseURL = apiURL();
export interface Item {
  itemID?: number;
  itemName: string;
  quantity: number;
  unitCost: number;
}

export interface IExpenseRequest {
  requestID?: number;
  choice: string;
  department?: string;
  requestBy: string;
  requestDate: Date;
  edrNo: string;
  purpose: string;
  dateLastPurchased: Date;
  requestAmount: number;
  requestAmountGST: number;
  preparedBy: string;
  passedBy?: string;
  passedDate?: Date;
  approvedBy?: string;
  approvedDate?: Date;
  receivedBy?: string;
  receivedDate?: Date;
  EmployeeID: number;
  items: Item[];
  token: string;
  chequeNo: string;
  bank: string;
  extraInfo: string;
  requestType: number;
  requestAssigned: number;
  exRequestDocRef: string | undefined;
}
export interface IExpenseRequestUpdate {
  requestID?: number;
  choice: string;
  department?: string;
  edrNo: string;
  purpose: string;
  dateLastPurchased: Date;
  requestAmount: number;
  requestAmountGST: number;
  passedBy?: string;
  passedDate?: Date | string;
  approvedBy?: string;
  approvedDate?: Date | string;
  receivedBy?: string;
  receivedDate?: Date | string;
  receiverSignature?: string;
  items: Item[];
  token: string;
  chequeNo: string;
  bank: string;
  extraInfo: string;
  exRequestDocRef: string | undefined;
  approving: boolean;
}
export interface IExpenseRequestReject {
  requestID: number;
  rejectReason: string;
  token: string;
  enteredBy: string;
  isRejected: number;
}
export const addExpenseRequest = async (
  expenseRequest: IExpenseRequest
): Promise<string> => {
  const token = expenseRequest.token;
  const data = {
    requestID: 0,
    choice: expenseRequest.choice,
    department: expenseRequest.department,
    requestBy: expenseRequest.requestBy,
    requestDate: expenseRequest.requestDate,
    edrNo: expenseRequest.edrNo,
    purpose: expenseRequest.purpose,
    dateLastPurchased: expenseRequest.dateLastPurchased,
    requestAmount: expenseRequest.requestAmount,
    requestAmountGST: expenseRequest.requestAmountGST,
    preparedBy: expenseRequest.preparedBy,
    itemName1: expenseRequest.items[0]?.itemName || "N/A",
    quantity1: expenseRequest.items[0]?.quantity || 0,
    unitCost1: expenseRequest.items[0]?.unitCost || 0,
    itemName2: expenseRequest.items[1]?.itemName || "N/A",
    quantity2: expenseRequest.items[1]?.quantity || 0,
    unitCost2: expenseRequest.items[1]?.unitCost || 0,
    itemName3: expenseRequest.items[2]?.itemName || "N/A",
    quantity3: expenseRequest.items[2]?.quantity || 0,
    unitCost3: expenseRequest.items[2]?.unitCost || 0,
    passedBy: "N/A",
    passedDate: "N/A",
    approvedBy: "N/A",
    approvedDate: "N/A",
    receivedBy: "N/A",
    receivedDate: "N/A",
    employeeID: expenseRequest.EmployeeID,
    ChequeNo: expenseRequest.chequeNo,
    Bank: expenseRequest.bank,
    ExtraInfo: expenseRequest.extraInfo,
    RequestType: expenseRequest.requestType,
    RequestAssigned: expenseRequest.requestAssigned,
    ExRequestDocRef: expenseRequest.exRequestDocRef,
  };
  try {
    const response = await axios.post(
      `${baseURL}ExpenseRequest/addExpenseRequest`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw new Error(`Failed to add expense request: ${error.message}`);
    } else {
      throw new Error("An unexpected error occurred");
    }
  }
};

export const updateExpenseRequest = async (
  expenseRequest: IExpenseRequestUpdate
): Promise<string> => {
  const token = expenseRequest.token;
  const data = {
    requestID: expenseRequest.requestID,
    choice: expenseRequest.choice,
    department: expenseRequest.department,
    edrNo: expenseRequest.edrNo,
    purpose: expenseRequest.purpose,
    dateLastPurchased: expenseRequest.dateLastPurchased,
    requestAmount: expenseRequest.requestAmount,
    requestAmountGST: expenseRequest.requestAmountGST,
    ItemID1: expenseRequest.items[0]?.itemID || 0,
    itemName1: expenseRequest.items[0]?.itemName || "N/A",
    quantity1: expenseRequest.items[0]?.quantity || 0,
    unitCost1: expenseRequest.items[0]?.unitCost || 0,
    ItemID2: expenseRequest.items[1]?.itemID || 0,
    itemName2: expenseRequest.items[1]?.itemName || "N/A",
    quantity2: expenseRequest.items[1]?.quantity || 0,
    unitCost2: expenseRequest.items[1]?.unitCost || 0,
    ItemID3: expenseRequest.items[2]?.itemID || 0,
    itemName3: expenseRequest.items[2]?.itemName || "N/A",
    quantity3: expenseRequest.items[2]?.quantity || 0,
    unitCost3: expenseRequest.items[2]?.unitCost || 0,
    passedBy: expenseRequest.passedBy,
    passedDate: expenseRequest.passedDate,
    approvedBy: expenseRequest.approvedBy,
    approvedDate: expenseRequest.approvedDate,
    receivedBy: expenseRequest.receivedBy,
    receivedDate: expenseRequest.receivedDate,
    ChequeNo: expenseRequest.chequeNo,
    Bank: expenseRequest.bank,
    ExtraInfo: expenseRequest.extraInfo,
    ExRequestDocRef: expenseRequest.exRequestDocRef,
    receiverSignature: expenseRequest.receiverSignature || "N/A",
    approving: expenseRequest.approving,
  };
  try {
    const response = await axios.post(
      `${baseURL}ExpenseRequest/updateExpenseRequest`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw new Error(`Failed to add expense request: ${error.message}`);
    } else {
      throw new Error("An unexpected error occurred");
    }
  }
};

export const rejectExpenseRequest = async (
  expenseRequest: IExpenseRequestReject
): Promise<string> => {
  const token = expenseRequest.token;
  const data = {
    ExpenseRequestID: expenseRequest.requestID,
    ExpenseRequestNote: expenseRequest.rejectReason,
    EnteredBy: expenseRequest.enteredBy,
    IsRejected: expenseRequest.isRejected,
  };
  try {
    const response = await axios.post(
      `${baseURL}ExpenseRequest/rejectExpenseRequest`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw new Error(`Failed to add expense request: ${error.message}`);
    } else {
      throw new Error("An unexpected error occurred");
    }
  }
};

export const DeleteExpense = async (token: string, requestID: number) => {
  await axios.post(
    baseURL + `ExpenseRequest/deleteExpense?requestID=${requestID}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
