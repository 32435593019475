import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";
import hashData from "../encrypt";
export interface ISignInProps {
  Email: String;
  Password: string;
  rememberEmail?: number;
  isExternal: boolean;
}
export interface ISignInData {
  sub: string;
  jti: string;
  iat: Date;
  EmployeeID: number;
  DisplayName: string;
  UserName: string;
  Email: string;
  exp: number;
  iss: string;
  aud: string;
  CanViewInsurance: number;
  CanViewAdmin: number;
  CanViewReport: number;
  CanEditInsurance: number;
  CanEditAdmin: number;
  CanManageOtherInsurance: number;
  UserImageName: string;
  CanViewMedicalInsurance: string;
  CanEditMedicalInsurance: string;
  CanViewMotorInsurance: string;
  CanEditMotorInsurance: string;
  IsExternalSaleTeam: string;
  CanViewPaymentReceipt: string;
  CanEditPaymentReceipt: string;
  CanAcknowledgePayment: string;
  MarketerID: string;
  CanApproveClaims: string;
  CanRaiseExpense: string;
  CanReviewExpense: string;
  CanApproveExpense: string;
  CanViewSetting: string;
  ApprovalLimit: number;
  CanPreApproveExpense: string;
  CanEditExpenseType: string;
  CanViewMoneyBack: string;
}
const baseURL = apiURL();
const SignIn = (props: ISignInProps) => {
  const hash = hashData(props.Password, props.isExternal);
  return axios
    .post(baseURL + "auth", {
      Email: props.Email,
      password: hash,
    })
    .then((response) => {
      if (response.status === 200) {
        sessionStorage.clear();
        sessionStorage.setItem("token", JSON.stringify(response.data));
        var now = new Date().getTime();
        sessionStorage.setItem("setupTime", now.toString());
        return true;
      }
      return response;
    })
    .catch((error: AxiosError) => {
      let errorMessage;
      if (error.response) {
        errorMessage = error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // eslint-disable-next-line no-console
        console.error(error.request);
        errorMessage = `No response`;
      } else {
        // Something happened in setting up the request that triggered an Error
        // eslint-disable-next-line no-console
        console.error("Error", error);
        errorMessage = `Axios Error`;
      }
      return errorMessage;
    });
};
export default SignIn;
