export interface IFambulDependantData {
    firstname: string,
    middleName: string,
    lastName: string,
    sex: string,
    dob: Date,
    Address: string,
    telephone: string,
    relationship: string,
    sharePercentage: number
    trustee: string,
    trusteeNumber: string
}

export const options = [
    { label: 'Male' },
    { label: 'Female' },
];

export const PremOptions = [
    { label: 2 }, { label: 5 }, { label: 10 }, { label: 15 }, { label: 20 }, { label: 25 }, { label: 30 },
    { label: 35 }, { label: 40 }, { label: 45 }, { label: 50 }
];
export const DepOptions = [
    { label: 0 }, { label: 1 }, { label: 2 }, { label: 3 }, { label: 4 }, { label: 5 }
];
export const DepDistrict = [
    { label: 'Bo' },
    { label: 'Bombali' },
    { label: 'Bonthe' },
    { label: 'Falaba' },
    { label: 'Kailahun' },
    { label: 'Kambia' },
    { label: 'Karene' },
    { label: 'Kenema' },
    { label: 'Koinadugu' },
    { label: 'Kono' },
    { label: 'Moyamba' },
    { label: 'Port Loko' },
    { label: 'Pujehun' },
    { label: 'Tonkolili' },
    { label: 'Western Rural' },
    { label: 'Western Urban' }
];

export const PercentageOptions = [
    { label: 5 }, { label: 10 }, { label: 15 }, { label: 20 }, { label: 25 }, { label: 30 },
    { label: 35 }, { label: 40 }, { label: 45 }, { label: 50 }, { label: 55 }, { label: 60 },
    { label: 65 }, { label: 70 }, { label: 75 }, { label: 80 }, { label: 85 }, { label: 90 }, { label: 95 }, { label: 100 }
];

export const FambulDependantRows: IFambulDependantData[] = [
    {
        firstname: 'N/A',
        middleName: 'N/A',
        lastName: 'N/A',
        sex: 'Male',
        dob: new Date(),
        Address: 'N/A',
        telephone: 'N/A',
        relationship: 'N/A',
        sharePercentage: 100,
        trustee: 'N/A',
        trusteeNumber: 'N/A',
    },
    {
        firstname: 'N/A',
        middleName: 'N/A',
        lastName: 'N/A',
        sex: 'Male',
        dob: new Date(),
        Address: 'N/A',
        telephone: 'N/A',
        relationship: 'N/A',
        sharePercentage: 100,
        trustee: 'N/A',
        trusteeNumber: 'N/A',
    },
    {
        firstname: 'N/A',
        middleName: 'N/A',
        lastName: 'N/A',
        sex: 'Male',
        dob: new Date(),
        Address: 'N/A',
        telephone: 'N/A',
        relationship: 'N/A',
        sharePercentage: 100,
        trustee: 'N/A',
        trusteeNumber: 'N/A',
    },
    {
        firstname: 'N/A',
        middleName: 'N/A',
        lastName: 'N/A',
        sex: 'Male',
        dob: new Date(),
        Address: 'N/A',
        telephone: 'N/A',
        relationship: 'N/A',
        sharePercentage: 100,
        trustee: 'N/A',
        trusteeNumber: 'N/A',
    },
    {
        firstname: 'N/A',
        middleName: 'N/A',
        lastName: 'N/A',
        sex: 'Male',
        dob: new Date(),
        Address: 'N/A',
        telephone: 'N/A',
        relationship: 'N/A',
        sharePercentage: 100,
        trustee: 'N/A',
        trusteeNumber: 'N/A',
    }
]