import axios from "axios";
import apiURL from "../apiURL";


interface INewMarketerID {
    token: string;
}

const baseURL = apiURL();

const GetNewMarketerID = async (props: INewMarketerID): Promise<string> => {
    try {
        const token = props.token;
        const response = await axios.get<string>(
            `${baseURL}UserAdd/GetNewMarketerID`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        return response.data;
    } catch (error) {
        // Handle the error here
        console.error('Error checking for duplicates:', error);
        throw error;
    }
};

export default GetNewMarketerID;
