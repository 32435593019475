import axios from "axios";
import apiURL from "../apiURL";

export interface IMUserDataRow {
    MarketerID: string,
    fullname: string,
    Username: string,
    Email: string,
    AccountStatus: string,
}

export interface IMUserData {
    data: IMUserDataRow[],
}
interface IGetUser {
    token: string;
}
const baseURL = apiURL()
const GetMarketerUserData = async (props: IGetUser) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + "users/getMarketers",
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IMUserData;

}
export default GetMarketerUserData;