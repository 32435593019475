import * as React from "react";
import { useTheme } from "@mui/material/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  Legend,
} from "recharts";
import Title from "./Title";
import { useEffect } from "react";
import GetWeeklySalesData from "../../services/API/GetWeeklySalesData";
import useToken from "../useToken";

export default function Chart() {
  const theme = useTheme();
  const token = useToken().token;
  const [data, setData] = React.useState([]);
  const mobileBreakpoint = 600; // Example breakpoint for mobile devices
  const isMobile = window.innerWidth < mobileBreakpoint;

  useEffect(() => {
    GetWeeklySalesData({ token }).then((response) => {
      if (response) {
        setData(response.data);
      }
    });
  }, [token]);

  return (
    <React.Fragment>
      <Title>Fortnightly Insurance Sales</Title>
      <ResponsiveContainer >
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 32,
            left: 24,
          }}
        >
          <XAxis
            dataKey="regDate"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          />
          <YAxis
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
          >
            <Label
              angle={270}
              position="left"
              style={{
                textAnchor: "middle",
                fill: theme.palette.text.primary,
                ...theme.typography.body1,
              }}
            >
              Sales
            </Label>
          </YAxis>
          <Line
            isAnimationActive={false}
            type="linear"
            dataKey="salesCount"
            name="Sales"
            stroke={theme.palette.primary.main}
            dot={{ fill: 'grey' }}
            legendType="circle"
          />
          <Line
            isAnimationActive={false}
            type="linear"
            dataKey="loginCount"
            name="Logins"
            stroke={theme.palette.error.main}
            dot={{ fill: 'grey' }}
            legendType="circle"
          />

          <Legend
            iconType="circle"
            formatter={(value, entry, index) => {
              return <span style={{ color: entry.color }}>{value}</span>;
            }}
            align="right"
            verticalAlign="top"
            height={36}
            layout="vertical"
            wrapperStyle={{
              position: 'absolute',
              width: 'auto',
              height: '36px',
              right: '0px',
              top: isMobile ? '-15px' : '-30px'
            }}
          />

        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
