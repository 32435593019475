import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";
import { checkSessionExpiration } from "../checkSessionExpiration";

export interface IMotorDataRow {
    vehicleOwnerID?: number
    vehicleOwnerName: string,
    addressDetail: string,
    vehicleOwnerTelephone: string,
    vehicleInsuranceType: string,
    vehicleRegistration: string,
    vehicleInsuranceEndDate: Date,
    vehicleType: string,
    onliveDatabase: string,
    activeCustomer: boolean,
    marketerID: string,
    color: number
    insurancePrice: number
    extraDriver: string,
    notes: string,
    duplicate: number
    vehicleID: number
}

export interface IMotorData {
    data: IMotorDataRow[],
}
interface IGetMotor {
    token: string;
}
const baseURL = apiURL()
const GetMotorData = async (props: IGetMotor) => {
    const token = props.token

    // Check session expiration before proceeding
    if (!checkSessionExpiration()) {
        return;
    }
    try {
        const data = await axios({
            method: "get",
            responseType: "json",
            url: baseURL + "motorData",
            headers: { "Authorization": `Bearer ${token}` }
        })
        return data as IMotorData;
    } catch (error) {
        // Call the handleAxiosError function with the error and redirect URL
        handleAxiosError(error, "/login"); // Adjust the URL as needed
        return undefined;
    }

}
export default GetMotorData;