import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";
import { checkSessionExpiration } from "../checkSessionExpiration";

export interface IDUserDataRow {
    fullname: string,
    email: string,
    Gender: string,
    EmpAddress: string,
    LoginDate: string,
}

export interface IDUserData {
    data: IDUserDataRow[],
}
interface IGetUser {
    token: string;
}
const baseURL = apiURL()
const GetDashboardUserData = async (props: IGetUser) => {
    const token = props.token
    // Check session expiration before proceeding
    if (!checkSessionExpiration()) {
        return;
    }
    try {
        const data = await axios({
            method: "get",
            responseType: "json",
            url: baseURL + "users/getSenior",
            headers: { "Authorization": `Bearer ${token}` }
        })
        return data as IDUserData;
    } catch (error) {
        // Call the handleAxiosError function with the error and redirect URL
        handleAxiosError(error, "/login"); // Adjust the URL as needed
        return undefined;
    }

}
export default GetDashboardUserData;