import { useMemo, useState } from 'react';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import { Box, Button, Dialog, DialogContent, DialogTitle, Popover, Typography } from '@mui/material';
import { IRecentPaymentRow } from '../../services/API/GetRecentPayments';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
interface IOrderDataProps {
  onClose: (val: boolean) => void;
  orderList: IRecentPaymentRow[]
}
const OrdersData = (props: IOrderDataProps) => {
  const columns = useMemo<MRT_ColumnDef<IRecentPaymentRow>[]>(
    () => [
      {
        accessorKey: 'paymentDate', //access nested data with dot notation
        header: 'Payment Date',
      },
      {
        accessorKey: 'fullName',
        header: 'Name',
      },
      {
        accessorKey: 'insuranceType',
        header: 'Insurance Type',
      },
      {
        accessorKey: 'paymentMethod',
        header: 'Payment Method',
      },
      {
        accessorKey: 'premiumAmount',
        header: 'Premium Amount',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {`${Number(cell.getValue()).toLocaleString('en-SL', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + ' SLL'}`}
          </Box>
        ),
      },
    ],
    [],
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return <>
    <Dialog
      PaperProps={{
        sx: {
          height: "850px"
        }
      }}
      fullWidth
      maxWidth='lg'
      open={true}
      onClose={props.onClose}>
      <DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" gutterBottom>
            Recent Customer Payments
          </Typography>
          <Button
            aria-describedby={id}
            variant="text"
            color="primary"
            startIcon={<HelpCenterIcon />}
            onClick={handleClick}
          />
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{ width: 750 }}
        >
          <Typography sx={{ padding: 1, fontStyle: 'italic', backgroundColor: '#f0f0f0', color: 'red', border: '1px solid #1565C0', borderRadius: '10px' }}>
            This section displays a comprehensive list of payments made for various insurance categories, including motor insurance, fambul insurance, medical insurance, group life insurance, and other insurance types.</Typography>
        </Popover>
      </DialogTitle>
      <DialogContent>
        <MaterialReactTable
          columns={columns}
          data={props.orderList}
          enableMultiRowSelection={false} //use radio buttons instead of checkboxes
          enableDensityToggle={false}
          initialState={{ density: 'compact', pagination: { pageIndex: 0, pageSize: window.innerHeight > 720 ? 15 : 10 }, }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [5, 10, 20, 50],
          }}
          muiTableContainerProps={{
            sx: {
              overflow: "auto",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "0.4em",
                height: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#1976d2",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#1976d2",
              },
            },
          }}
          enableFullScreenToggle={false}
          enableHiding={false}
          rowCount={props.orderList.length}
        />
      </DialogContent>
    </Dialog >
  </>
};
export default OrdersData;