export interface IGLDependantData {
    dependantID: number,
    fullname: string,
    relationship: string,
    sharePercent: number,
    dateOfBirth: string,
    telephone: string,
    trustee: string,
}
export const GLDependantRows: IGLDependantData[] = [
    {
        dependantID: -1,
        fullname: 'N/A',
        relationship: 'N/A',
        sharePercent: 0,
        dateOfBirth: 'N/A',
        telephone: 'N/A',
        trustee: 'N/A',
    },
    {
        dependantID: -1,
        fullname: 'N/A',
        relationship: 'N/A',
        sharePercent: 0,
        dateOfBirth: 'N/A',
        telephone: 'N/A',
        trustee: 'N/A',
    },
    {
        dependantID: -1,
        fullname: 'N/A',
        relationship: 'N/A',
        sharePercent: 0,
        dateOfBirth: 'N/A',
        telephone: 'N/A',
        trustee: 'N/A',
    },
    {
        dependantID: -1,
        fullname: 'N/A',
        relationship: 'N/A',
        sharePercent: 0,
        dateOfBirth: 'N/A',
        telephone: 'N/A',
        trustee: 'N/A',
    },
    {
        dependantID: -1,
        fullname: 'N/A',
        relationship: 'N/A',
        sharePercent: 0,
        dateOfBirth: 'N/A',
        telephone: 'N/A',
        trustee: 'N/A',
    }
]

export const DepDistrict = [
    { label: 'N/A' },
    { label: 'Bo' },
    { label: 'Bombali' },
    { label: 'Bonthe' },
    { label: 'Falaba' },
    { label: 'Kailahun' },
    { label: 'Kambia' },
    { label: 'Karene' },
    { label: 'Kenema' },
    { label: 'Koinadugu' },
    { label: 'Kono' },
    { label: 'Moyamba' },
    { label: 'Port Loko' },
    { label: 'Pujehun' },
    { label: 'Tonkolili' },
    { label: 'Western Rural' },
    { label: 'Western Urban' }
];