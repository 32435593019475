import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IUpdateDependantProps {
  Firstname: string;
  Middlename: string;
  Lastname: string;
  Gender: string;
  DOB: Date;
  Address: string;
  Telephone: string;
  Relationship: string;
  PercentageShared: number;
  Trustee: string;
  TrusteeNumber: string;
  NomineeID: number;
  token: string;
  IDCode: string;
}

const baseURL = apiURL();

const UpdateDependant = async (props: IUpdateDependantProps) => {
  let value = "";
  const token = props.token;
  const data = {
    IDCode: props.IDCode,
    Firstname: props.Firstname,
    Middlename: props.Middlename,
    Lastname: props.Lastname,
    Gender: props.Gender,
    DOB: props.DOB,
    Address: props.Address,
    Telephone: props.Telephone,
    Relationship: props.Relationship,
    PercentageShared: props.PercentageShared,
    Trustee: props.Trustee,
    TrusteeNumber: props.TrusteeNumber,
    NomineeID: props.NomineeID,
  };
  try {
    const response = await axios.post(
      baseURL + "fambulDetails/updateDependant",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      value = "success";
    }
  } catch (error: AxiosError | any) {
    if (error.response) {
      value = error.response.data;
    } else if (error.request) {
      value = "No response from server";
    } else {
      value = "Something went wrong";
    }
  }
  return value;
};
export default UpdateDependant;
