import { format } from 'date-fns';
import { useReducer } from 'react';

interface ITextFieldData {
    editing: boolean,
    groupPolicyNumber: string,
    receiptNumber: string,
    paymentDate: string,
    receivedFrom: string,
    amountPaid: number,
    paymentReason: string,
    chequeNumber: string,
    office: string,
    agentBroker: string,
    insured: string,
    enteredBy: string,
    isApproved: boolean,
    approvedBy: string,
    token: string,
}

export interface IPaymentState {
    dataToSave: ITextFieldData;
}
export const initialState: IPaymentState = {
    dataToSave: {
        editing: false,
        groupPolicyNumber: '',
        receiptNumber: '',
        paymentDate: format(new Date(), 'yyyy-MM-dd'),
        receivedFrom: '',
        amountPaid: 0,
        paymentReason: '',
        chequeNumber: '',
        office: '',
        agentBroker: '',
        insured: '',
        enteredBy: '',
        isApproved: false,
        approvedBy: '',
        token: '',
    }
}

export type ActionType =
    | { type: 'UPDATE_FIELD'; payload: { name: string; value: string | boolean } };


export const reducer = (state: IPaymentState, action: ActionType) => {
    switch (action.type) {
        case 'UPDATE_FIELD':
            const { name, value } = action.payload;
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    [name]: typeof value === 'boolean' ? value : typeof value === 'string' ? value.trim() : value
                },
            };
        default:
            return state;
    }
};

export const PaymentReducer = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch({ type: 'UPDATE_FIELD', payload: { name, value: e.target.type === "checkbox" ? e.target.checked : value } });
    };

    return { state, handleChange };
};

