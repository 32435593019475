import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import useToken from "../useToken";
import { useEffect } from "react";
import GetRecentPayment, { IRecentPaymentRow } from "../../services/API/GetRecentPayments";
import OrdersData from "./OrdersData";

const Orders = () => {
  const token = useToken().token;
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<IRecentPaymentRow[]>([]);

  const loadMore = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (data.length === 0)
      GetRecentPayment({ token }).then((response) => {
        if (response) {
          setData(response.data);
        }
      });
  }, [token, data]);

  return (<>
    {data.length > 0 &&
      <>
        <React.Fragment>
          <Title>Recent Payments</Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(0, 5).map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.paymentDate}</TableCell>
                  <TableCell align="right">{`${Number(row.premiumAmount).toLocaleString('en-SL', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + ' SLL'}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Link color="primary" href="#" onClick={loadMore} sx={{ mt: 3 }}>
            See more payments
          </Link>
        </React.Fragment>
        {open && <OrdersData onClose={handleClose} orderList={data} />}
      </>
    }
  </>);
}
export default Orders
