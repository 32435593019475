import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IResetProps {
    Email: string;
    ActivationCode: string;
    NewPassword: string;
}

const baseURL = apiURL();
const ResetPassword = (props: IResetProps) => {
    return axios.post(baseURL + "Account/resetPassword", {
        Email: props.Email,
        ActivationCode: props.ActivationCode,
        NewPassword: props.NewPassword
    })
        .then((response) => {
            return response.data;
        })
        .catch((error: AxiosError) => {
            let errorMessage;
            if (error.response) {
                errorMessage = error.response.data;
            } else if (error.request) {
                console.error(error.request);
                errorMessage = `No response`;
            } else {
                errorMessage = `Axios Error`;
            }
            return errorMessage;
        });
};

export default ResetPassword;
