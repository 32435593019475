export const statusData = [
  { statusID: 0, statusDescription: "All" },
  { statusID: 1, statusDescription: "Awaiting Review" },
  { statusID: 2, statusDescription: "Awaiting Approval" },
  { statusID: 3, statusDescription: "Approved" },
  { statusID: 4, statusDescription: "Completed" },
  { statusID: 5, statusDescription: "Rejected" },
  { statusID: 6, statusDescription: "Closed" },
  { statusID: 7, statusDescription: "Queried" },
];
