import "../../App.css";
import React, { useEffect } from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
  Cell,
} from "recharts";
import useToken from "../useToken";
import Title from "./Title";
import GetMotorChart, {
  IMotorChartDataRow,
} from "../../services/API/GetMotorChart";

export default function MotorChart() {
  const token = useToken().token;
  const [data, setData] = React.useState<IMotorChartDataRow[]>();
  const mobileBreakpoint = 600; // Example breakpoint for mobile devices
  const isMobile = window.innerWidth < mobileBreakpoint;

  useEffect(() => {
    GetMotorChart({ token }).then((response) => {
      if (response) {
        setData(response.data);
      }
    });
  }, [token]);

  return (
    <React.Fragment>
      <Title>Active Motor</Title>
      <BarChart
        width={300}
        height={250}
        data={data}
        style={{
          marginLeft: isMobile
            ? "-50px"
            : window.innerHeight > 720
            ? "0px"
            : "-25px",
        }}
      >
        <Bar dataKey="value">
          {data &&
            data.map((entry, index) => (
              <Cell
                key={entry.name}
                fill={entry.name === "Valid" ? "#106104" : "#a60e03"}
              />
            ))}
        </Bar>
        <CartesianGrid stroke="#ccc" />
        <XAxis dataKey="name" />
        <YAxis />
      </BarChart>
    </React.Fragment>
  );
}
