import React, { useEffect, useMemo } from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import GetFambulPayments, {
  IFambulPaymentsRow,
} from "../../../services/API/GetFambulPayments";
import useToken from "../../useToken";
import { Box, Button } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import GenerateCustomerPayments from "./GenerateCustomerPayments";
import AddPayment from "../../../Common/AddPayment";
interface IFambulProps {
  IDCode: string;
}
export const FambulPayments = (props: IFambulProps) => {
  const token = useToken().token;
  const [data, setData] = React.useState<IFambulPaymentsRow[]>();
  const [addPayment, setPayment] = React.useState<boolean>(false);
  const [reload, setReload] = React.useState<boolean>(false);
  useEffect(() => {
    GetFambulPayments({ token, idCode: props.IDCode }).then((response) => {
      if (response) {
        const newData = response.data;
        setData(newData);
      }
    });
  }, [token, props.IDCode, reload]);
  const columns = useMemo<MRT_ColumnDef<IFambulPaymentsRow>[]>(
    () => [
      {
        accessorKey: "Credit",
        header: "Amount Paid",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.secondary.light,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {`${
              Number(cell.getValue()).toLocaleString("en-SL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " SLL"
            }`}
          </Box>
        ),
        size: 50,
      },
      {
        accessorKey: "Status",
        header: "Status",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {cell.getValue()}
          </Box>
        ),
        size: 50,
      },
      {
        accessorKey: "Date",
        header: "Payment Date",
        size: 50,
      },
    ],
    []
  );
  const handleAdd = () => {
    if (data) {
      const DataToSend = {
        idCode: props.IDCode,
        data: data.filter((row) => row.Status === "Success"),
      };
      GenerateCustomerPayments(DataToSend);
    }
  };
  const handlePaymentAdd = () => {
    setPayment(true);
  };
  const handleClose = (val: boolean) => {
    if (val) {
      setReload(true);
    }
    setPayment(false);
  };
  return (
    <>
      {data && (
        <MaterialReactTable
          columns={columns}
          data={data}
          enablePagination={true}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 10 },
          }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [5, 10],
          }}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableHiding={false}
          enableRowNumbers={false}
          enableFilters={false}
          renderTopToolbarCustomActions={() => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <Button
                color="primary"
                onClick={handleAdd}
                endIcon={<PrintIcon />}
                variant="outlined"
              >
                Print
              </Button>
              <Button
                color="primary"
                onClick={handlePaymentAdd}
                endIcon={<FileDownloadIcon />}
                variant="outlined"
              >
                Add Payment
              </Button>
            </Box>
          )}
        />
      )}
      {addPayment && (
        <AddPayment
          type={1}
          handleClose={handleClose}
          PolicyNo={props.IDCode}
        />
      )}
    </>
  );
};

export default FambulPayments;
