import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IAddNewMotorInsuranceProps {
    vehicleOwnerName: string
    addressDetail: string
    vehicleOwnerTelephone: string
    vehicleInsuranceType: string
    vehicleRegistration: string
    vehicleType: string
    marketerID: string
    vehicleInsuranceEndDate: string
    insuredDrivers: string
    token: string;
    activeCustomer: boolean;
}

const baseURL = apiURL()

const AddNewMotorInsurance = async (props: IAddNewMotorInsuranceProps) => {
    let value = ''
    const token = props.token
    const data = {
        vehicleOwnerName: props.vehicleOwnerName,
        addressDetail: props.addressDetail,
        vehicleOwnerTelephone: props.vehicleOwnerTelephone,
        vehicleInsuranceType: props.vehicleInsuranceType,
        vehicleRegistration: props.vehicleRegistration,
        vehicleType: props.vehicleType,
        vehicleInsuranceEndDate: props.vehicleInsuranceEndDate,
        InsuredDrivers: props.insuredDrivers,
        activeCustomer: props.activeCustomer,
        marketerID: props.marketerID
    }
    try {
        const response = await axios.post(baseURL + "motorData", data, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if (response.status === 200) {
            value = "success"
        }
    } catch (error: AxiosError | any) {
        if (error.response) {
            value = error.response.data
        } else if (error.request) {
            value = "No response from server"
        } else {
            value = "Something went wrong"
        }
    }
    return value
}
export default AddNewMotorInsurance;

