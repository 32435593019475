import { Container, Typography } from "@mui/material";
import React from "react";
import Title from "../Title";

const MarketerMotorReport: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Title>Motor Reports</Title>
      <Typography component="p" variant="h4">
        Coming Soon
      </Typography>
    </Container>
  );
};

export default MarketerMotorReport;
