import React, { useEffect, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { format } from "date-fns";
import GetFambulDetails, {
  IFambulDetailsRow,
} from "../../../services/API/GetFambulDetails";
import useToken from "../../useToken";
import resizeImage from "../../../Common/ResizeImage";
import GetImage from "../../../services/API/GetImage";
import uploadFile, { IUploadFile } from "../../../services/API/UploadFile";
import LockResetIcon from "@mui/icons-material/LockReset";
import updateFambul from "../../../services/API/UpdateFambul";
import CheckIDCode from "../../../services/API/CheckIDCode";
import { ISignInData } from "../../../services/API/signin";
import jwt_decode from "jwt-decode";
interface IFambulProps {
  IDCode: string;
  onClose: (val?: boolean) => void;
}

const FambulDetailsPage = (props: IFambulProps) => {
  const token = useToken().token;
  const [data, setData] = React.useState<IFambulDetailsRow>();
  const [previewImage, setPreviewImage] = React.useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [idNewCode, setIDNewCode] = React.useState<string | undefined>();
  const [locked, setLocked] = React.useState(true);
  const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);
  const userData: ISignInData = jwt_decode(token);
  const [userImageToDisplay, setUserImageToDisplay] = React.useState<
    string | undefined
  >(undefined);
  const [formData, setFormData] = React.useState({
    Firstname: "",
    Middlename: "",
    Lastname: "",
    Premium: 0,
    Telephone: "",
    BusinessLocation: "",
    Address: "",
    Region: "",
    District: "",
  });
  useEffect(() => {
    GetFambulDetails({ token, idCode: props.IDCode }).then((response) => {
      if (response) {
        const newData = response.data[0];
        setData(newData);
        setFormData({
          Firstname: formData.Firstname,
          Middlename: formData.Middlename,
          Lastname: formData.Lastname,
          Premium: formData.Premium,
          Telephone: formData.Telephone,
          BusinessLocation: formData.BusinessLocation,
          Address: formData.Address,
          Region: formData.Region,
          District: formData.District,
        });
      }
    });
  }, [token, props.IDCode]);

  const handleFileInputChange = () => {
    if (fileInputRef.current && fileInputRef.current.files) {
      const file = fileInputRef.current.files[0];
      if (file.size > 30000) {
        resizeImage(file, 300, 300, 30, (resizedFile: File) => {
          const imageUrl = URL.createObjectURL(resizedFile);
          setPreviewImage(imageUrl);
          handleSave(resizedFile);
        });
      } else {
        const imageUrl = URL.createObjectURL(file);
        setPreviewImage(imageUrl);
        handleSave(file);
      }
    }
  };
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSave = async (file: File) => {
    if (file && data) {
      const dataToUpload: IUploadFile = {
        file: file,
        policy: props.IDCode,
        UploadFileTypeID: 1,
        token: token,
      };
      await uploadFile(dataToUpload);
    }
  };

  const handleSaveData = async () => {
    const Firstname = formData.Firstname;
    const Middlename = formData.Middlename;
    const Lastname = formData.Lastname;
    const Premium = formData.Premium;
    const Telephone = formData.Telephone;
    const BusinessLocation = formData.BusinessLocation;
    const Address = formData.Address;
    const Region = formData.Region;
    const District = formData.District;
    const IDCode = props.IDCode;
    const IDNewCode = idNewCode;
    const ChangedBy = userData.DisplayName;
    await updateFambul({
      IDCode,
      Firstname,
      Middlename,
      Lastname,
      Premium,
      Telephone,
      BusinessLocation,
      Address,
      Region,
      District,
      token,
      IDNewCode,
      ChangedBy,
    }).then((res) => {
      if (res && IDNewCode) {
        props.onClose(true);
      }
      setLocked(true);
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData, // Ensure prevData is not undefined
      [name]: value,
    }));
  };

  const isBase64 = (str: string): boolean => {
    const base64Regex = /^[A-Za-z0-9+/=]+$/;
    return base64Regex.test(str);
  };
  const handleIDCode = async (val: string) => {
    if (val !== "") {
      const value = val;
      CheckIDCode({ token, value }).then((response) => {
        if (response === 1) {
          setIsDuplicate(true);
        } else {
          setIsDuplicate(false);
          setIDNewCode(val);
        }
      });
    }
  };

  if (!userImageToDisplay && data && data.ImageBinary !== "none") {
    if (data.ImageBinary.includes("Fambul-")) {
      const imageName = data.ImageBinary;
      const subfolder = "Fambul";
      GetImage({ token, subfolder, imageName }).then((imageResponse) => {
        const blob = new Blob([imageResponse.data], {
          type: "image/jpeg" || "image/png",
        });
        const imageToDisplay = URL.createObjectURL(blob);
        setUserImageToDisplay(imageToDisplay);
      });
    } else {
      const isValidBase64 = isBase64(data.ImageBinary);
      if (isValidBase64) {
        const decodedData = atob(data.ImageBinary);
        const byteArray = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; i++) {
          byteArray[i] = decodedData.charCodeAt(i);
        }
        const blob = new Blob([byteArray], { type: "image/jpeg" });
        const imageToDisplay = URL.createObjectURL(blob);
        setUserImageToDisplay(imageToDisplay);
      }
    }
  }
  return (
    <div className="tabs-container">
      {data && (
        <Card>
          <CardHeader title="Customer Info" />
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container spacing={4} sx={{ alignItems: "center" }}>
              <Grid item xs={12} md={4}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Avatar
                    alt="User Avatar"
                    src={
                      previewImage ||
                      userImageToDisplay ||
                      "/profile/default.jpg"
                    }
                    sx={{ width: 160, height: 160 }}
                  />
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                >
                  <Box
                    component="span"
                    sx={(theme) => ({
                      backgroundColor: theme.palette.success.dark,
                      borderRadius: "0.25rem",
                      color: "#fff",
                      maxWidth: "25ch",
                      p: "0.25rem",
                      marginRight: "10px", // Add margin for spacing between Box and TextField
                    })}
                  >
                    {props.IDCode}
                  </Box>
                  {!locked && Number(userData.CanEditInsurance) === 1 && (
                    <TextField
                      id="IdCode"
                      name="IdCode"
                      label="New ID Code"
                      onChange={(e) => handleIDCode(e.target.value)}
                      variant="outlined"
                      inputProps={{
                        maxLength: 10,
                        height: 10,
                      }}
                      sx={{ width: 150 }}
                      error={isDuplicate}
                      helperText={
                        isDuplicate ? "This IDCode is a duplicate" : ""
                      }
                    />
                  )}
                </div>
                <>
                  <input
                    type="file"
                    accept=".bmp, .jpeg, .jpg, .png"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={handleFileInputChange}
                  />
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    <Button
                      variant="outlined"
                      startIcon={<Edit />}
                      onClick={handleButtonClick}
                    >
                      Change Photo
                    </Button>
                  </Box>
                </>
              </Grid>
              <Grid item xs={12} md={8} sx={{ marginTop: "-50px" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h5">Details</Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="Firstname"
                      name="Firstname"
                      label="First Name"
                      defaultValue={data.Firstname}
                      fullWidth
                      disabled={locked}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="Middlename"
                      name="Middlename"
                      label="Middle Name"
                      defaultValue={data.Middlename}
                      fullWidth
                      disabled={locked}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="Lastname"
                      name="Lastname"
                      label="Last Name"
                      defaultValue={data.Lastname}
                      fullWidth
                      disabled={locked}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="DOB"
                      name="DOB"
                      inputProps={{ maxLength: 20 }}
                      value={formatDate(data.DOB)}
                      disabled={true}
                      label="DOB"
                      type="date"
                      sx={{ width: 200 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="RegDate"
                      name="RegDate"
                      inputProps={{ maxLength: 20 }}
                      value={formatDate(data.RegDate)}
                      disabled={true}
                      label="Reg Date"
                      type="date"
                      sx={{ width: 200 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="Gender"
                      name="Gender"
                      label="Gender"
                      defaultValue={data.Gender}
                      fullWidth
                      disabled={true}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="Premium"
                      name="Premium"
                      label="Premium"
                      defaultValue={data.Premium}
                      fullWidth
                      disabled={locked}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">SLE</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="Telephone"
                      name="Telephone"
                      label="Telephone"
                      defaultValue={data.Telephone}
                      fullWidth
                      disabled={locked}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Typography variant="h5">Addresses</Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="BusinessLocation"
                      name="BusinessLocation"
                      label="Business Location"
                      sx={{ width: 350 }}
                      defaultValue={data.BusinessLocation}
                      fullWidth
                      disabled={locked}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="Address"
                      name="Address"
                      label="Address"
                      defaultValue={data.Address}
                      fullWidth
                      disabled={locked}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="Region"
                      name="Region"
                      label="Region"
                      defaultValue={data.Region}
                      fullWidth
                      disabled={locked}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="District"
                      name="District"
                      label="District"
                      defaultValue={data.District}
                      fullWidth
                      disabled={locked}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
              paddingRight: "10px",
              marginBottom: "10px",
            }}
          >
            {!locked ? (
              <Button
                variant="contained"
                startIcon={<Edit />}
                onClick={handleSaveData}
                disabled={isDuplicate}
              >
                Update Information
              </Button>
            ) : (
              <Button
                variant="contained"
                startIcon={<LockResetIcon />}
                onClick={() => {
                  setLocked(false);
                }}
              >
                Unlock To Edit
              </Button>
            )}
          </Box>
        </Card>
      )}
    </div>
  );
};
const formatDate = (dateString: Date) => {
  // Remove the trailing period from the date string
  const sanitizedDateString = dateString.toString().replace(/\.$/, "");
  const date = new Date(sanitizedDateString);
  // Check if the date is valid
  if (!isNaN(date.getTime())) {
    // Format the date as 'yyyy-MM-dd'
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  } else {
    // Handle the case where the date is invalid
    return ""; // or another default value
  }
};

export default FambulDetailsPage;
