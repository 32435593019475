import React, { useEffect, useRef } from 'react';
import {
    Autocomplete,
    Avatar,
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    DialogContentText,
    Grid,
    Popover,
    TextField,
    Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { Edit } from '@mui/icons-material';
import { format } from 'date-fns';
import { DepDistrict, DepOptions, PercentageOptions, PremOptions, options } from './FambulStateless';
import useToken from '../../useToken';
import { Container } from '@mui/material';
import { BootstrapDialog, BootstrapDialogTitle } from '../Medical/AddNewUserStepper';
import resizeImage from '../../../Common/ResizeImage';
import uploadFile, { IUploadFile } from '../../../services/API/UploadFile';
import GetMarketerNewID from '../../../services/API/GetMarketerIDCode';
import { FambulReducer, IFambulReducerData } from './FambulReducer';
import AddFambulCustomer, { IFambulDataProps } from '../../../services/API/AddFambulCustomer';
import SearchIcon from '@mui/icons-material/Search';
import CheckIDCode from '../../../services/API/CheckIDCode';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Popper from '@mui/material/Popper';
import { ISignInData } from '../../../services/API/signin';
import jwt_decode from "jwt-decode";
interface TabPanelProps {
    openUser: boolean;
    onClose: () => void;
    marketerID?: string
}

const isOver18 = (dob: Date): boolean => {
    const today = new Date();
    const age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    return age > 18 || (age === 18 && monthDiff >= 0);
};

const isValidUserData = (userData: IFambulReducerData): boolean => {
    // Check if NewIDCode is not the default
    if (userData.NewIDCode === '') {
        return false;
    }

    // Check if other string fields are not the default
    if (
        userData.Firstname === '' ||
        userData.Lastname === '' ||
        userData.Telephone === '' ||
        userData.BusinessLocation === '' ||
        userData.Address === ''
    ) {
        return false;
    }
    // All checks passed
    return true;
};

const AddFambul = (props: TabPanelProps) => {
    const token = useToken().token;
    const { state, handleChange, handleDropDownChange, updateDependant } = FambulReducer();
    const [loading, setLoading] = React.useState(false);
    const [displayError, setDisplayError] = React.useState<number>(0);
    const [chosenFile, setChosenFile] = React.useState<File>();
    const [previewImage, setPreviewImage] = React.useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [newIDCode, setNewIDCode] = React.useState<string>();
    const mobileBreakpoint = 600; // Example breakpoint for mobile devices
    const isMobile = window.innerWidth < mobileBreakpoint;
    const [selectedDependant, setSelectedDependant] = React.useState<number>(0);
    const [marketerID, setMarketerID] = React.useState<string | undefined>(props.marketerID ?? undefined);
    const [iDCodeValue, setIDCodeValue] = React.useState<string>('');
    const [iDCodeToUSe, setIDCodeToUSe] = React.useState<string | undefined>();
    const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const userData: ISignInData = jwt_decode(token);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleIDCode = async () => {
        if (iDCodeValue !== '') {
            const value = iDCodeValue
            CheckIDCode({ token, value }).then((response) => {
                if (response === 1) {
                    setIsDuplicate(true)
                } else {
                    setIDCodeToUSe(iDCodeValue)
                }
            })
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
        props.onClose();
    };

    useEffect(() => {
        const fetchData = async () => {
            if (marketerID && !iDCodeToUSe) {
                GetMarketerNewID({ token, marketerID }).then((response) => {
                    if (response) {
                        setNewIDCode(response);
                        handleDropDownChange('NewIDCode', response)
                    }
                })
            } else if (iDCodeToUSe) {
                handleDropDownChange('NewIDCode', iDCodeToUSe)
                setNewIDCode(iDCodeToUSe)
                setMarketerID(iDCodeToUSe.slice(0, 3))
            }
        }
        fetchData()
    }, [token, marketerID, iDCodeToUSe]);

    const handleFileInputChange = () => {
        if (fileInputRef.current && fileInputRef.current.files) {
            const file = fileInputRef.current.files[0];
            if (file.size > 30000) {
                resizeImage(file, 300, 300, 30, (resizedFile: File) => {
                    const imageUrl = URL.createObjectURL(resizedFile);
                    setPreviewImage(imageUrl);
                    setChosenFile(resizedFile);
                });
            } else {
                const imageUrl = URL.createObjectURL(file);
                setPreviewImage(imageUrl);
                setChosenFile(file);
            }
        }
    };
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleSave = async () => {
        const data = state.dataToSave
        const dependantList = data.dependantNames
            .slice(0, selectedDependant) // Include elements up to selectedDependant
            .map((dep, i) => {
                return `${i}|${dep.firstname}¬${dep.middleName}#${dep.lastName}<${dep.sex}>${new Date(dep.dob).toLocaleDateString('en-GB')}*${dep.Address}-${dep.telephone}%${dep.relationship}^${dep.sharePercentage}@${dep.trustee}]${dep.trusteeNumber}`;
            })
            .join(',');

        if (isValidUserData(data) && chosenFile) {
            if (data && newIDCode) {
                setLoading(true);
                if (chosenFile) {
                    const dataToUpload: IUploadFile = {
                        file: chosenFile,
                        policy: newIDCode,
                        UploadFileTypeID: 1,
                        token: token
                    };
                    await uploadFile(dataToUpload);
                }

                const dateString = data.RegDate;
                const dateObject = new Date(dateString);
                const formattedDate = dateObject.getFullYear() + '-' +
                    ('0' + (dateObject.getMonth() + 1)).slice(-2) + '-' +
                    ('0' + dateObject.getDate()).slice(-2);

                const FambulData: IFambulDataProps = {
                    NewIDCode: data.NewIDCode,
                    Firstname: data.Firstname,
                    Middlename: data.Middlename,
                    Lastname: data.Lastname,
                    DOB: data.DOB,
                    RegDate: formattedDate,
                    Gender: data.Gender,
                    Premium: data.Premium,
                    Telephone: data.Telephone,
                    BusinessLocation: data.BusinessLocation,
                    Address: data.Address,
                    District: data.District,
                    DependantNames: selectedDependant === 0 ? 'none' : dependantList,
                    WhoEntered: userData.DisplayName,
                    token: token
                };
                const Data = await AddFambulCustomer(FambulData)
                if (Data === "success") {
                    setLoading(false);
                    handleClose()
                }
            }
        } else if (!isValidUserData(data)) {
            setDisplayError(1)
        } else if (!isOver18(new Date(data.DOB))) {
            setDisplayError(2)
        } else if (!chosenFile) {
            setDisplayError(3)
        }
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 8, mb: 9, }} >
            <BootstrapDialog
                open={props.openUser}
                fullWidth
                maxWidth="lg"
                disableEscapeKeyDown={true}
                hideBackdrop={true}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    {`Add Fambul Customer`}
                    {!props.marketerID &&
                        <>
                            <Button aria-describedby={id} variant="text" color="primary" startIcon={<HelpCenterIcon />} onClick={handleClick} />
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                sx={{ width: 750 }}
                            >
                                <Typography sx={{ padding: 1, fontStyle: 'italic', backgroundColor: '#f0f0f0', color: 'red', border: '1px solid #1565C0', borderRadius: '10px' }}>
                                    Select a marketer to add, and a new code will be automatically generated. Alternatively, you can manually input a code, which will undergo duplicate checking to ensure its uniqueness.
                                </Typography>
                            </Popover>
                        </>
                    }
                </BootstrapDialogTitle>
                <div className="scroll-container" style={{ overflow: isMobile ? 'scroll' : 'auto', maxHeight: '80vh' }}>
                    <Card>
                        {loading &&
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={loading}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>}
                        <CardContent sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: isMobile ? 'column' : 'row', // Change layout for mobile
                        }} >
                            <Grid container spacing={4} sx={{ alignItems: 'center' }}>
                                <Grid item xs={12} md={4} >
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Avatar alt="User Avatar" src={previewImage ?? '/profile/default.jpg'} sx={{ width: 160, height: 160 }} />
                                    </Box>
                                    <div style={{ textAlign: 'center', paddingTop: '10px' }}>
                                        <Box
                                            component="span"
                                            sx={(theme) => ({
                                                backgroundColor: theme.palette.success.dark,
                                                borderRadius: '0.25rem',
                                                color: '#fff',
                                                maxWidth: '25ch',
                                                p: '0.25rem',
                                            })}
                                        >
                                            {newIDCode}
                                        </Box>
                                    </div>
                                    <>
                                        <input
                                            type="file"
                                            accept=".bmp, .jpeg, .jpg, .png"
                                            style={{ display: "none" }}
                                            ref={fileInputRef}
                                            onChange={handleFileInputChange}
                                            disabled={marketerID === undefined}
                                        />
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                            <Button variant="outlined"
                                                startIcon={<Edit />}
                                                onClick={handleButtonClick}>
                                                Change Photo
                                            </Button>
                                        </Box>
                                    </>
                                    <>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                            <form>
                                                <Autocomplete
                                                    id="Dependant"
                                                    value={DepOptions.find(option => option.label === selectedDependant)}
                                                    options={DepOptions}
                                                    getOptionLabel={(option) => String(option.label)}
                                                    sx={{ width: 100 }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Dependant" variant="standard" />
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        setSelectedDependant(newValue ? newValue.label : 0);
                                                    }}
                                                    disabled={marketerID === undefined}
                                                />
                                            </form>
                                        </Box>
                                    </>
                                    <>
                                        {!marketerID &&
                                            <>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, marginLeft: '25px' }}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={8}>
                                                            <Autocomplete
                                                                disablePortal
                                                                id="marketer"
                                                                value={marketerID}
                                                                disabled={state.dataToSave.editing}
                                                                options={state.dataToSave.marketerListOptions}
                                                                sx={{ width: 270 }}
                                                                disableClearable
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label="marketer" />
                                                                )}
                                                                onChange={(event, newValue) => {
                                                                    setMarketerID(newValue.slice(0, 3));
                                                                }}
                                                                PopperComponent={(props) => (
                                                                    <Popper {...props} placement="top-start" />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, marginLeft: '25px' }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                id="IdCode"
                                                                name="IdCode"
                                                                label="ID Code"
                                                                onChange={(e) => setIDCodeValue(e.target.value)}
                                                                variant="outlined"
                                                                inputProps={{
                                                                    maxLength: 10,
                                                                }}
                                                                sx={{ width: 150 }}
                                                                error={isDuplicate}
                                                                helperText={isDuplicate ? 'This IDCode is a duplicate' : ''}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Button
                                                                variant="outlined"
                                                                startIcon={<SearchIcon />}
                                                                onClick={handleIDCode}
                                                                disabled={iDCodeValue.length !== 10}
                                                                sx={{ height: '100%' }}
                                                            >
                                                                Check
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </>
                                        }
                                    </>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="h5">Details</Typography>
                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="Firstname"
                                                name="Firstname"
                                                label="First Name"
                                                defaultValue={state.dataToSave.Firstname}
                                                fullWidth
                                                onChange={handleChange}
                                                disabled={marketerID === undefined}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="Middlename"
                                                name="Middlename"
                                                label="Middle Name"
                                                defaultValue={state.dataToSave.Middlename}
                                                fullWidth
                                                onChange={handleChange}
                                                disabled={marketerID === undefined}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="Lastname"
                                                name="Lastname"
                                                label="Last Name"
                                                defaultValue={state.dataToSave.Lastname}
                                                fullWidth
                                                onChange={handleChange}
                                                disabled={marketerID === undefined}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="DOB"
                                                name="DOB"
                                                inputProps={{ maxLength: isMobile ? undefined : 20 }}
                                                value={format(new Date(state.dataToSave.DOB), 'yyyy-MM-dd')}
                                                label="DOB"
                                                type="date"
                                                sx={{ width: isMobile ? undefined : 200 }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={handleChange}
                                                disabled={marketerID === undefined}
                                            />
                                        </Grid>
                                        {iDCodeToUSe &&
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="RegDate"
                                                    name="RegDate"
                                                    inputProps={{ maxLength: isMobile ? undefined : 20 }}
                                                    value={format(new Date(state.dataToSave.RegDate), 'yyyy-MM-dd')}
                                                    label="Reg Date"
                                                    type="date"
                                                    sx={{ width: isMobile ? undefined : 200 }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={handleChange}
                                                    disabled={marketerID === undefined}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12} md={4}>
                                            <form>
                                                <Autocomplete
                                                    disablePortal
                                                    id="Gender"
                                                    value={state.dataToSave.Gender}
                                                    options={options.map(option => option.label)}
                                                    disableClearable
                                                    sx={{ width: isMobile ? undefined : 150 }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Gender" />
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        const name = "Gender"
                                                        const value = newValue ? newValue : 0
                                                        handleDropDownChange(name, value);
                                                    }}
                                                    disabled={marketerID === undefined}
                                                />
                                            </form>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <form>
                                                <Autocomplete
                                                    disablePortal
                                                    id="Premium"
                                                    value={state.dataToSave.Premium}
                                                    getOptionLabel={(option) => String(option)}
                                                    options={PremOptions.map(option => option.label)}
                                                    disableClearable
                                                    sx={{ width: isMobile ? undefined : 100 }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Premium" />
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        const name = "Premium"
                                                        const value = newValue ? newValue : 0
                                                        handleDropDownChange(name, value);
                                                    }}
                                                    disabled={marketerID === undefined}
                                                />
                                            </form>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="Telephone"
                                                name="Telephone"
                                                label="Telephone"
                                                defaultValue={state.dataToSave.Telephone}
                                                fullWidth
                                                onChange={handleChange}
                                                disabled={marketerID === undefined}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                        <Typography variant="h5">Addresses</Typography>
                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={isMobile ? 4 : 12} sx={{ marginTop: isMobile ? '10px' : 0 }}>
                                            <TextField
                                                id="BusinessLocation"
                                                name="BusinessLocation"
                                                label="Business Location"
                                                multiline={isMobile}
                                                sx={{ width: isMobile ? undefined : 350 }}
                                                defaultValue={state.dataToSave.BusinessLocation}
                                                fullWidth
                                                onChange={handleChange}
                                                disabled={marketerID === undefined}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="Address"
                                                name="Address"
                                                label="Address"
                                                defaultValue={state.dataToSave.Address}
                                                fullWidth
                                                onChange={handleChange}
                                                disabled={marketerID === undefined}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <form>
                                                <Autocomplete
                                                    disablePortal
                                                    id="District"
                                                    value={state.dataToSave.District}
                                                    options={DepDistrict.map(option => option.label)}
                                                    disableClearable
                                                    sx={{ width: isMobile ? undefined : 150 }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="District" />
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        const name = "District"
                                                        const value = newValue ? newValue : 0
                                                        handleDropDownChange(name, value);
                                                    }}
                                                    disabled={marketerID === undefined}
                                                />
                                            </form>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ marginTop: '10px' }}>
                                        {Array.from({ length: selectedDependant }).map((_, index) => (
                                            <React.Fragment key={index}>
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    sx={{ paddingBottom: '10px' }}
                                                >
                                                    <Box sx={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
                                                        <Typography color='red' variant="h5">{`Dependant ${index + 1}`}</Typography>
                                                    </Box>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            id={`Depfirstname-${index}`}
                                                            name={`Depfirstname-${index}`}
                                                            label="First Name"
                                                            defaultValue={state.dataToSave.dependantNames[index].firstname}
                                                            fullWidth
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                const name = "firstname"
                                                                const { value } = e.target;
                                                                updateDependant(index, name, value);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            id={`Depmiddlename-${index}`}
                                                            name={`Depmiddlename-${index}`}
                                                            label="Middle Name"
                                                            defaultValue={state.dataToSave.dependantNames[index].middleName}
                                                            fullWidth
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                const name = "middleName"
                                                                const { value } = e.target;
                                                                updateDependant(index, name, value);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            id={`Deplastname-${index}`}
                                                            name={`Deplastname-${index}`}
                                                            label="Last Name"
                                                            defaultValue={state.dataToSave.dependantNames[index].lastName}
                                                            fullWidth
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                const name = "lastName"
                                                                const { value } = e.target;
                                                                updateDependant(index, name, value);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            id={`DepDOB-${index}`}
                                                            name={`DepDOB-${index}`}
                                                            inputProps={{ maxLength: isMobile ? undefined : 20 }}
                                                            value={format(new Date(state.dataToSave.dependantNames[index].dob), 'yyyy-MM-dd')}
                                                            label="DOB"
                                                            type="date"
                                                            sx={{ width: 200 }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                const name = "dob"
                                                                const { value } = e.target;
                                                                updateDependant(index, name, value);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <form>
                                                            <Autocomplete
                                                                disablePortal
                                                                id={`DepGender-${index}`}
                                                                value={state.dataToSave.dependantNames[index].sex}
                                                                options={options.map(option => option.label)}
                                                                disableClearable
                                                                sx={{ width: isMobile ? undefined : 150 }}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label="Gender" />
                                                                )}
                                                                onChange={(event, newValue) => {
                                                                    const name = "sex"
                                                                    updateDependant(index, name, newValue);
                                                                }}
                                                            />
                                                        </form>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            id={`DepTelephone-${index}`}
                                                            name={`DepTelephone-${index}`}
                                                            label="Telephone"
                                                            defaultValue={state.dataToSave.dependantNames[index].telephone}
                                                            fullWidth
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                const name = "telephone"
                                                                const { value } = e.target;
                                                                updateDependant(index, name, value);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            id={`DepAddress-${index}`}
                                                            name={`DepAddress-${index}`}
                                                            label="Address"
                                                            defaultValue={state.dataToSave.dependantNames[index].Address}
                                                            fullWidth
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                const name = "Address"
                                                                const { value } = e.target;
                                                                updateDependant(index, name, value);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            id={`DepRelationship-${index}`}
                                                            name={`DepRelationship-${index}`}
                                                            label="Relationship"
                                                            defaultValue={state.dataToSave.dependantNames[index].relationship}
                                                            fullWidth
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                const name = "relationship"
                                                                const { value } = e.target;
                                                                updateDependant(index, name, value);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <form>
                                                            <Autocomplete
                                                                disablePortal
                                                                id={`DepPercentageShared-${index}`}
                                                                value={state.dataToSave.dependantNames[index].sharePercentage}
                                                                options={PercentageOptions.map(option => option.label)}
                                                                getOptionLabel={(option) => String(option)}
                                                                disableClearable
                                                                sx={{ width: isMobile ? undefined : 100 }}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label='Share %' />
                                                                )}
                                                                onChange={(event, newValue) => {
                                                                    const name = "sharePercentage"
                                                                    updateDependant(index, name, String(newValue));
                                                                }}
                                                            />
                                                        </form>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            id={`Deptrustee-${index}`}
                                                            name={`Deptrustee-${index}`}
                                                            label="Trustee"
                                                            defaultValue={state.dataToSave.dependantNames[index].trustee}
                                                            fullWidth
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                const name = "trustee"
                                                                const { value } = e.target;
                                                                updateDependant(index, name, value);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            id={`DepTrusteeNumber-${index}`}
                                                            name={`DepTrusteeNumber-${index}`}
                                                            label="TrusteeNumber"
                                                            defaultValue={state.dataToSave.dependantNames[index].trusteeNumber}
                                                            fullWidth
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                const name = "trusteeNumber"
                                                                const { value } = e.target;
                                                                updateDependant(index, name, value);
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        ))}
                                    </Box>
                                </Grid>
                            </Grid>
                            {displayError === 1 && <DialogContentText color={'red'}>
                                All Customer fields Must be completed
                            </DialogContentText>}
                            {displayError === 2 && <DialogContentText color={'red'}>
                                DOB Must be over 18
                            </DialogContentText>}
                            {displayError === 3 && <DialogContentText color={'red'}>
                                Image Not selected
                            </DialogContentText>}
                        </CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, paddingRight: '10px', marginBottom: '10px' }}>
                            <Button
                                variant="outlined"
                                startIcon={<SaveIcon />}
                                onClick={handleSave}
                                disabled={marketerID === undefined}
                            >
                                Save
                            </Button>
                        </Box>
                    </Card >
                </div >
            </BootstrapDialog>
        </Container >

    );
}
export default AddFambul;
