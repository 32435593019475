import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import GetCustomerLoan, { ILoanRow } from "../services/API/GetCustomerLoan";
import GetCustomerLoanPayments, {
  ILoanPaymentsRow,
} from "../services/API/GetCustomerLoanPayments";
import useToken from "../components/useToken";
import { ISignInData } from "../services/API/signin";
import jwt_decode from "jwt-decode";
import AddLoanPayment from "../services/API/AddLoanPayment";
import AddLoan from "../services/API/AddLoan";

interface ILoanProps {
  policyNo: string;
}
const LoanDetails = (props: ILoanProps) => {
  const token = useToken().token;
  const userData: ISignInData = jwt_decode(token);
  const [loanDetails, setLoanDetails] = useState<ILoanRow[]>([]);
  const [paymentHistory, setPaymentHistory] = useState<ILoanPaymentsRow[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [loanAmount, setLoanAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [reload, setReload] = useState(false);

  const handleAddPayment = () => {
    setOpenDialog(true);
  };

  const handleAddLoan = () => {
    setOpenDialogAdd(true);
  };

  const handleSavePayment = async () => {
    const ClaimID = loanDetails[0].ClaimID;
    const PolicyNo = props.policyNo;
    const AddedBy = userData.DisplayName;
    const AmountPaid = Number(paymentAmount);
    await AddLoanPayment({ ClaimID, PolicyNo, AddedBy, AmountPaid, token });
    setOpenDialog(false);
    setPaymentAmount("");
    setReload(true);
  };

  const handleSaveLoan = async () => {
    const PolicyNo = props.policyNo;
    const Whoentered = userData.DisplayName;
    const LoanAmount = loanAmount;
    const RemainingAmount = remainingAmount;
    await AddLoan({ PolicyNo, Whoentered, LoanAmount, RemainingAmount, token });
    setOpenDialogAdd(false);
    setLoanAmount(0);
    setRemainingAmount(0);
    setReload(true);
  };

  useEffect(() => {
    // Function to fetch loan details based on the policy number
    const PolicyNo = props.policyNo;
    const fetchLoanDetails = async () => {
      try {
        GetCustomerLoan({ token, PolicyNo }).then((response) => {
          if (response) {
            const newData = response;
            setLoanDetails(newData.data);
          }
        });
        GetCustomerLoanPayments({ token, PolicyNo }).then((response) => {
          if (response) {
            const newData = response.data;
            setPaymentHistory(newData);
          }
        });
      } catch (error) {
        console.error("Error fetching loan details:", error);
      }
    };

    fetchLoanDetails();
  }, [props.policyNo, reload, token]);
  const sumAmountPaid = paymentHistory.reduce(
    (total, payment) => total + payment.Amountpaid,
    0
  );
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          Loan Details
        </Typography>
        {loanDetails.length > 0 ? (
          <Box>
            <Box borderBottom={1} borderColor="primary.main" mb={1}>
              <Typography color="primary" variant="h6">
                Amount borrowed: SLE {loanDetails[0].amountBorrowed.toFixed(2)}
              </Typography>
            </Box>
            <Box borderBottom={1} borderColor="primary.main" mb={1}>
              <Typography color="primary" variant="h6">
                Amount plus interest (10%): SLE{" "}
                {loanDetails[0].amountToPayBack.toFixed(2)}
              </Typography>
            </Box>
            <Box borderBottom={1} borderColor="primary.main" mb={1}>
              <Typography color="primary" variant="h6">
                Amount Paid: SLE {sumAmountPaid.toFixed(2)}
              </Typography>
            </Box>
            <Box borderBottom={1} borderColor="primary.main" mb={1}>
              <Typography color="primary" variant="h6">
                Balance: SLE{" "}
                {(loanDetails[0].loanBalance - sumAmountPaid).toFixed(2)}
              </Typography>
            </Box>
            <Box borderBottom={1} borderColor="secondary.main" mb={1}>
              <Typography color="secondary" variant="h6">
                Outstanding at time of Loan: SLE{" "}
                {loanDetails[0].outStandingAtAproval.toFixed(2)}
              </Typography>
            </Box>
            <Box borderBottom={1} borderColor="secondary.main" mb={1}>
              <Typography color="secondary" variant="h6">
                {`StartDate: ${loanDetails[0].StartDate.toString().slice(
                  0,
                  10
                )} => EndDate: ${loanDetails[0].EndDate.toString().slice(
                  0,
                  10
                )}`}
              </Typography>
            </Box>
            <div
              style={{
                border: "0px solid #ccc",
                maxWidth: "500px",
                overflowY: "auto",
                maxHeight: "150px",
              }}
            >
              <Typography variant="h6" component="h3">
                Payment History
              </Typography>
              {paymentHistory.map((row, index: number) => (
                <div key={index}>
                  <Typography>
                    Date: {row.Datepaid.toString().slice(0, 10)}, Amount: SLE{" "}
                    {row.Amountpaid.toFixed(2)}
                  </Typography>
                </div>
              ))}
              <Button variant="contained" onClick={handleAddPayment}>
                Add Payment
              </Button>
            </div>
          </Box>
        ) : (
          <>
            <Typography>No loans taken</Typography>
            <Button variant="contained" onClick={handleAddLoan}>
              Add Loan Details
            </Button>
          </>
        )}
      </CardContent>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add Payment</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Payment Amount"
            type="number"
            value={paymentAmount}
            onChange={(e) => setPaymentAmount(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleSavePayment}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogAdd} onClose={() => setOpenDialogAdd(false)}>
        <DialogTitle>Add Loan Info</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Loan Amount Excluding 10% Interest"
            type="number"
            value={loanAmount}
            onChange={(e) => setLoanAmount(Number(e.target.value))}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            label="Amount Remaining"
            type="number"
            value={remainingAmount}
            onChange={(e) => setRemainingAmount(Number(e.target.value))}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialogAdd(false)}>Cancel</Button>
          <Button onClick={handleSaveLoan}>Add</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default LoanDetails;
