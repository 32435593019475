import React, { useEffect, useRef } from 'react';
import {
    Autocomplete,
    Avatar,
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import useToken from '../useToken';
import jwt_decode from "jwt-decode";
import GetUserProfile from '../../services/API/GetUserProfile';
import LockResetIcon from '@mui/icons-material/LockReset';
import { ISignInData } from '../../services/API/signin';
import { format } from 'date-fns';
import UpdateUserProfile, { IEditUserProfileData, IUpdateUserProfileProps } from '../../services/API/UpdateUserProfile';
import { UserProfileReducer } from './UserProfileReducer';
import uploadFile, { IUploadFile } from '../../services/API/UploadFile';
import GetImage from '../../services/API/GetImage';
import resizeImage from '../../Common/ResizeImage';


const UserProfilePage = () => {
    const UserImageToDisplay: string | undefined = sessionStorage.getItem('UserImageToDisplay') ?? undefined;

    const options = [
        { label: 'Male' },
        { label: 'Female' },
    ];
    const token = useToken().token;
    const { state, handleChange, updateStateForExistingUser, unlockToEdit } = UserProfileReducer();
    const [loading, setLoading] = React.useState(false);
    const userData: ISignInData = jwt_decode(token);
    const userid = Number(userData.EmployeeID)
    const [chosenFile, setChosenFile] = React.useState<File>();
    const [previewImage, setPreviewImage] = React.useState<string | null>(null);
    useEffect(() => {
        GetUserProfile({ token, userid }).then((response) => {
            if (response) {
                const newData = response.data[0]
                updateStateForExistingUser(newData);
            }
        })
    }, [token, userid]);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileInputChange = () => {
        if (fileInputRef.current && fileInputRef.current.files) {
            const file = fileInputRef.current.files[0];
            if (file.size > 30000) {
                resizeImage(file, 300, 300, 30, (resizedFile: File) => {
                    const imageUrl = URL.createObjectURL(resizedFile);
                    setPreviewImage(imageUrl);
                    setChosenFile(resizedFile);
                });
            } else {
                const imageUrl = URL.createObjectURL(file);
                setPreviewImage(imageUrl);
                setChosenFile(file);
            }
        }
    };
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleLock = (val: boolean) => {
        unlockToEdit(val)
    };

    const handleSave = async () => {
        const data = state.dataToSave
        if (data) {
            setLoading(true);
            if (chosenFile && data) {
                const dataToUpload: IUploadFile = {
                    file: chosenFile,
                    userid: data.EmployeeID,
                    UploadFileTypeID: 16,
                    token: token
                };
                await uploadFile(dataToUpload);
            }
            const userData: IEditUserProfileData = {
                EmployeeID: data.EmployeeID,
                FirstName: data.FirstName,
                MiddleNames: data.MiddleNames,
                LastName: data.LastName,
                DateOfBirth: data.DateOfBirth,
                Gender: data.Gender,
                Email: data.Email,
                TelephoneNumber1: data.TelephoneNumber1,
                FirstLineAddress: data.FirstLineAddress,
                City: data.City,
                Country: data.Country,
                ExtraInfromation: data.ExtraInfromation,
                JobTtile: data.JobTtile,
                UserImageName: data.UserImageName,
            };
            const dataToSend: IUpdateUserProfileProps = {
                userProfileData: userData,
                token: token
            };
            await UpdateUserProfile(dataToSend).then((response) => {
                const subfolder = 'Profile'
                const imageName = data.UserImageName
                GetImage({ token, subfolder, imageName })
                    .then((imageResponse) => {
                        if (imageResponse) {
                            sessionStorage.removeItem("UserImageToDisplay");
                        }
                    })
                    .catch((imageError) => {
                        console.error('Error fetching image:', imageError);
                    });
            });
            handleLock(false)
            setLoading(false);
        }
    };
    return (
        <div className="tabs-container" style={{ marginTop: '75px', marginLeft: '15px', marginRight: '15px' }}>
            {state.dataToSave.EmployeeID > 0 &&
                <Card>
                    {loading &&
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>}
                    <CardHeader title="User Profile" />
                    <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ height: '360px' }}>
                            <Grid container spacing={4} sx={{ alignItems: 'center' }}>
                                <Grid item xs={12} md={4}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Avatar alt="User Avatar" src={previewImage || UserImageToDisplay} sx={{ width: 160, height: 160 }} />
                                    </Box>
                                    <div style={{ textAlign: 'center' }}>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            {state.dataToSave.JobTtile}
                                        </Typography>
                                    </div>
                                    <>
                                        <input
                                            type="file"
                                            accept=".jpeg, .jpg, .png"
                                            style={{ display: "none" }}
                                            ref={fileInputRef}
                                            onChange={handleFileInputChange}
                                        />
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                            <Button variant="outlined" disabled={!state.dataToSave.editing} startIcon={<Edit />} onClick={handleButtonClick}>
                                                Change Photo
                                            </Button>
                                        </Box>
                                    </>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="h5">Details</Typography>
                                    </Box>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="FirstName"
                                                name="FirstName"
                                                label="First Name"
                                                defaultValue={state.dataToSave.FirstName}
                                                fullWidth
                                                disabled={!state.dataToSave.editing}
                                                onChange={handleChange} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="MiddleNames"
                                                name="MiddleNames"
                                                label="Middle Name"
                                                defaultValue={state.dataToSave.MiddleNames}
                                                fullWidth
                                                disabled={!state.dataToSave.editing}
                                                onChange={handleChange} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="LastName"
                                                name="LastName"
                                                label="Last Name"
                                                defaultValue={state.dataToSave.LastName}
                                                fullWidth disabled={!state.dataToSave.editing}
                                                onChange={handleChange} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="DateOfBirth"
                                                name="DateOfBirth"
                                                inputProps={{ maxLength: 20 }}
                                                value={format(new Date(state.dataToSave.DateOfBirth), 'yyyy-MM-dd')}
                                                disabled={!state.dataToSave.editing}
                                                label="Birthday"
                                                type="date"
                                                sx={{ width: 300 }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <form>
                                                <Autocomplete
                                                    disablePortal
                                                    id="Gender"
                                                    value={{ label: state.dataToSave.Gender }}
                                                    disabled={true}
                                                    options={options}
                                                    disableClearable
                                                    sx={{ width: 280 }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Gender" />
                                                    )}
                                                />
                                            </form>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField label="Email" defaultValue={state.dataToSave.Email} fullWidth disabled={true} onChange={handleChange} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="TelephoneNumber1"
                                                name="TelephoneNumber1"
                                                label="Phone"
                                                defaultValue={state.dataToSave.TelephoneNumber1}
                                                fullWidth
                                                disabled={!state.dataToSave.editing}
                                                onChange={handleChange} />
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                        <Typography variant="h5">Address</Typography>
                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="FirstLineAddress"
                                                name="FirstLineAddress"
                                                label="Address"
                                                defaultValue={state.dataToSave.FirstLineAddress}
                                                fullWidth disabled={!state.dataToSave.editing}
                                                onChange={handleChange} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="City"
                                                name="City"
                                                label="City"
                                                defaultValue={state.dataToSave.City}
                                                fullWidth
                                                disabled={!state.dataToSave.editing}
                                                onChange={handleChange} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="Country"
                                                name="Country"
                                                label="Country"
                                                defaultValue={state.dataToSave.Country}
                                                fullWidth disabled={!state.dataToSave.editing}
                                                onChange={handleChange} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, paddingRight: '10px', marginBottom: '10px' }}>
                        {state.dataToSave.editing ? (
                            <Button variant="contained" startIcon={<Edit />} onClick={handleSave}>
                                Update Information
                            </Button>
                        ) : (
                            <Button variant="contained" startIcon={<LockResetIcon />} onClick={() => { handleLock(true) }}>
                                Unlock To Edit
                            </Button>
                        )}
                    </Box>

                </Card >
            }
        </div >
    );
};

export default UserProfilePage;
