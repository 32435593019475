import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";
import { checkSessionExpiration } from "../checkSessionExpiration";

export interface IClaimDataRow {
  PolicyNo: string;
  FullName: string;
  ClaimDetails: string;
  ClaimDate: Date;
  RegDate: Date;
  Whoentered: string;
  Approved: boolean;
  ApproveBy: string;
  ClaimRef: string;
  Insurancetype: number;
  ClaimType: number;
  ClaimAmount: number;
  Rejected: boolean;
  RejectedBy: string;
  Status: string;
  ClaimTypeName: string;
  totalContribution: number;
  Premium: number;
  Address: string;
  maxLoanAvaliable: number;
  numberOfWeekdayOnScheme: number;
  AmountOverRide: boolean;
  OverRideInfo: string;
  ApproveRejectDate: Date;
  OverRideOutstandingInfo: string;
  OutstandingRemoved: boolean;
  loanBalance: number;
  RemoveCardCost: boolean;
}

export interface IClaimData {
  data: IClaimDataRow[];
}
interface IGetMissing {
  token: string;
}
const baseURL = apiURL();
const GetClaims = async (props: IGetMissing) => {
  const token = props.token;

  // Check session expiration before proceeding
  if (!checkSessionExpiration()) {
    return;
  }

  try {
    const data = await axios({
      method: "get",
      responseType: "json",
      url: baseURL + `Claim/getClaims`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return data as IClaimData;
  } catch (error) {
    // Call the handleAxiosError function with the error and redirect URL
    handleAxiosError(error, "/login"); // Adjust the URL as needed
    return undefined;
  }
};
export default GetClaims;
