import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface IDialogProps {
    open: boolean;
    setOpen?: () => void;
    setSave?: () => void;
    headerText: string;
    mainText: string
    showNO?: boolean;
    showYes?: boolean;
    cancelText?: string;
    actionText?: string;
}

export default function AlertDialog(props: IDialogProps) {
    const showNO = props.showNO !== undefined ? props.showNO : true
    const showYes = props.showYes !== undefined ? props.showYes : true
    const cancelText = props.cancelText ? props.cancelText : 'No'
    const actionText = props.actionText ? props.actionText : 'Yes'
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.setOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableEscapeKeyDown={true}
                keepMounted
            >
                <DialogTitle id="alert-dialog-title" bgcolor="#CCCCCC" >
                    {props.headerText}
                </DialogTitle>
                <DialogContent style={{ paddingTop: '20px' }}>
                    <DialogContentText id="alert-dialog-description">
                        {props.mainText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {showNO && <Button onClick={props.setOpen} variant="outlined">{cancelText}</Button>}
                    {showYes && <Button onClick={props.setSave} variant="outlined">{actionText}</Button>}
                </DialogActions>
            </Dialog>
        </div >
    );
}