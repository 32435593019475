import * as React from "react";

import Typography from "@mui/material/Typography";
import Title from "./Title";
import useToken from "../useToken";
import GetDashBoardData from "../../services/API/GetDashboardData";

export default function Deposits() {
  const token = useToken().token;
  const [dateFrom, setDateFrom] = React.useState<string>('');
  const [totalPaidFY, setTotalPaidFY] = React.useState<string>('');

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await GetDashBoardData({ token });

      if (response) {
        setDateFrom(response.dateFrom);
        const newAmount = Number(response.totalPaidFY).toLocaleString('en-SL', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + ' SLL';
        setTotalPaidFY(newAmount);
      } else {
        console.error("Dashboard data not found.");
      }
    };

    fetchData();
  }, [token]);

  return (
    <div key={Math.random() * 99999999}>
      <React.Fragment >
        <Title>Rolling Year Payments</Title>
        <Typography component="p" variant="h4">
          {totalPaidFY}
        </Typography>
        <Typography color="text.secondary" sx={{ flex: 1 }}>
          {dateFrom}
        </Typography>
      </React.Fragment >
    </div>
  );
}
