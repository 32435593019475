
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Container, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { IActiveUserStep1Props } from "./AddNewUserStep1";


const AddNewUserStep3: React.FC<IActiveUserStep1Props> = ({
  state,
  handleRadioChange,
  handleTextChange,
}) => {
  return (
    <div>
      <Container sx={{ mt: 2 }}>
        <Box sx={{
          width: 800, height: 40 * state.dataToSave.medQuestions.length,
          overflow: "hidden",
        }}>
          {handleRadioChange && handleTextChange && state.dataToSave.medQuestions.map((item, index) => (
            <React.Fragment key={index}>
              <Grid
                container
                spacing={3}
                sx={{
                  '--Grid-borderWidth': '0px',
                  borderTop: 'var(--Grid-borderWidth) solid',
                  borderLeft: 'var(--Grid-borderWidth) solid',
                  borderColor: 'lightblue',
                  '& > div': {
                    borderRight: 'var(--Grid-borderWidth) solid',
                    borderBottom: 'var(--Grid-borderWidth) solid',
                    borderColor: 'lightblue',
                  },
                }}
              >
                <Grid item xs={6}>
                  <ListItemText
                    id={`list-${index}`}
                    primary={item.medicalQuestionName} />
                </Grid>
                <Grid item xs>
                  <RadioGroup
                    row
                    id={`radio-buttons-${index}`}
                    name={`radio-buttons-${index}`}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    defaultValue={state.dataToSave.medSelectedValues[index] || "No"}
                    onChange={(event) => handleRadioChange(index, event.target.value)}
                  >
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                      disabled={state.dataToSave.editing}
                    />
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                      disabled={state.dataToSave.editing}
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs alignItems="flex-start">
                  <TextField
                    required
                    id={`response-${index}`}
                    name={`response-${index}`}
                    label="More Info"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                    size="small"
                    autoComplete="moreInfo"
                    variant="standard"
                    defaultValue={state.dataToSave.medResponses[index] || ""}
                    onChange={(event) => handleTextChange(index, event.target.value)}
                    disabled={state.dataToSave.editing}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Box>
      </Container>
    </div>

  );
}
export default AddNewUserStep3
