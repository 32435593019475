import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

interface IApproveCard {
  token: string;
  idCodeToPrinted: string;
  acknowledgedBy: string;
}

const baseURL = apiURL();

const PrintGLCard = async (props: IApproveCard) => {
  let value = "";
  const token = props.token;
  const data = {
    acknowledgedBy: props.acknowledgedBy,
    idCode: props.idCodeToPrinted,
  };
  try {
    const response = await axios.post(
      baseURL + "GroupLifeEdit/markedAsPrinted",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      value = "success";
    }
  } catch (error: AxiosError | any) {
    if (error.response) {
      value = error.response.data;
    } else if (error.request) {
      value = "No response from server";
    } else {
      value = "Something went wrong";
    }
  }
  return value;
};

export default PrintGLCard;
