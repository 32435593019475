import axios from "axios";
import apiURL from "../apiURL";

export interface IAllMUserDataRow {
    marketerID: string,
    fullname: string,
}

export interface IAllMUserData {
    data: IAllMUserDataRow[],
}
interface IGetUser {
    token: string;
}
const baseURL = apiURL()
const GetAllMarketerUserData = async (props: IGetUser) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + "users/getAllMarketers",
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IAllMUserData;

}
export default GetAllMarketerUserData;