import React, { useEffect, useMemo } from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import useToken from "../../useToken";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import GetClaims, { IClaimDataRow } from "../../../services/API/GetClaims";
import ClaimDetails from "./ClaimDetails";
import { claimTypes } from "../../../Common/ClaimsData";

const InsuranceClaims = () => {
  const columns = useMemo<MRT_ColumnDef<IClaimDataRow>[]>(
    () => [
      {
        accessorKey: "PolicyNo", //access nested data with dot notation
        header: "PolicyNo",
      },
      {
        accessorKey: "FullName",
        header: "FullName",
      },
      {
        accessorKey: "ClaimDate",
        header: "Claim Date",
        Cell: ({ cell }) => (cell.getValue() as string).slice(0, 10),
      },
      {
        accessorKey: "ClaimTypeName",
        header: "Claim Type",
      },
      {
        accessorKey: "Status",
        header: "Status",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() === "Approved"
                  ? theme.palette.success.dark
                  : cell.getValue() === "Rejected"
                  ? theme.palette.error.dark
                  : theme.palette.info.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {cell.getValue()}
          </Box>
        ),
        width: 50,
      },
    ],
    []
  );

  const token = useToken().token;
  const [data, setData] = React.useState<IClaimDataRow[]>([]);
  const [filteredData, setFilteredData] = React.useState<IClaimDataRow[]>([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rowSelection, setRowSelection] = React.useState<
    IClaimDataRow | undefined
  >(undefined);
  const [reload, setReload] = React.useState(false);
  const [selectedClaim, setSelectedClaim] = React.useState<number>(0);

  const handleClaimTypeChange = (event: SelectChangeEvent<number>) => {
    setSelectedClaim(Number(event.target.value));
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  useEffect(() => {
    GetClaims({ token }).then((response) => {
      if (response) {
        const newData = response.data;
        setData(newData);
        setFilteredData(newData);
      }
    });
  }, [token, reload]);

  useEffect(() => {
    let newData;
    if (selectedClaim === 0) {
      newData = data;
    } else {
      newData = data.filter((item) => item.ClaimType === selectedClaim);
    }
    setFilteredData(newData);
  }, [selectedClaim]);

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  const HandleRowSelected = (row: IClaimDataRow) => {
    setRowSelection(row);
  };
  const handleClose = (val?: boolean) => {
    if (val) {
      setReload(true);
    }
    setRowSelection(undefined);
  };

  const allOption = { label: "All", number: 0 };
  const menuItems = [allOption, ...claimTypes];

  return (
    <>
      <div
        className="tabs-container"
        style={{
          marginTop: "65px",
          position: "fixed",
          width: "80%",
          zIndex: 1,
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <MaterialReactTable
            columns={columns}
            data={filteredData}
            enableMultiRowSelection={false} //use radio buttons instead of checkboxes
            enableDensityToggle={false}
            initialState={{
              density: "compact",
              pagination: { pageIndex: 0, pageSize: 15 },
            }}
            muiTablePaginationProps={{
              rowsPerPageOptions: [5, 10, 20, 50],
            }}
            muiTableBodyRowProps={({ row }) => ({
              onClick: () => HandleRowSelected(row.original),
              sx: { cursor: "pointer" },
            })}
            muiTableContainerProps={{
              sx: {
                height:
                  data.length > 10
                    ? window.innerHeight - 300 + "px"
                    : undefined,
                overflow: "auto",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                  width: "0.4em",
                  height: "0.4em",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#1976d2",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#1976d2",
                },
              },
            }}
            enableFullScreenToggle={false}
            enableHiding={false}
            rowCount={filteredData.length}
            renderTopToolbarCustomActions={() => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  p: "0.5rem",
                  flexWrap: "wrap",
                }}
              >
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6" gutterBottom>
                      Insurance Claims
                    </Typography>
                    <Button
                      aria-describedby={id}
                      variant="text"
                      color="primary"
                      startIcon={<HelpCenterIcon />}
                      onClick={handleClick}
                    />
                  </div>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{ width: 750 }}
                  >
                    <Typography
                      sx={{
                        padding: 1,
                        fontStyle: "italic",
                        backgroundColor: "#f0f0f0",
                        color: "red",
                        border: "1px solid #1565C0",
                        borderRadius: "10px",
                      }}
                    >
                      In the section shows insurance claims, click on a row to
                      review details and approve.
                    </Typography>
                  </Popover>
                  <Box
                    sx={{ display: "flex", gap: "0.2rem", flexWrap: "wrap" }}
                  >
                    <Button
                      color="primary"
                      //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                      onClick={handleExportData}
                      startIcon={<FileDownloadIcon />}
                      variant="outlined"
                    >
                      Export Data
                    </Button>
                    <FormControl
                      sx={(theme) => ({
                        minWidth: "200px",
                        border: `1px solid ${theme.palette.secondary.dark}`,
                        borderRadius: "10px",
                      })}
                    >
                      <InputLabel
                        id="select-year-label"
                        sx={(theme) => ({
                          color: theme.palette.secondary.dark,
                        })}
                      >
                        Select Claim Type
                      </InputLabel>
                      <Select
                        labelId="select-year-label"
                        id="select-year"
                        value={selectedClaim}
                        onChange={handleClaimTypeChange}
                      >
                        {menuItems.map((claimType) => (
                          <MenuItem
                            key={claimType.label}
                            value={claimType.number}
                          >
                            {claimType.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </>
              </Box>
            )}
          />
        </Container>
      </div>
      {rowSelection && (
        <ClaimDetails onClose={handleClose} row={rowSelection} />
      )}
    </>
  );
};
export default InsuranceClaims;
