export const genderList = [
    {number: 1,label: 'Male' },
    {number: 2,label: 'Female'}
]
    
export const jobTypes = [
    { number: 2, label: 'Senior Head Office'},
    { number: 3, label: 'Head Office' },
    { number: 4, label: 'Medical User'},
    { number: 6, label: 'Office Staff' },
    { number: 7, label: 'Junior Office Staff'},
    { number: 8, label: 'Front Desk User' },
]
