import axios from "axios";
import apiURL from "../apiURL";

export interface IUserProfileDataRow {
    EmployeeID: number,
    FirstName: string,
    MiddleNames: string,
    LastName: string,
    DateOfBirth: Date,
    Gender: string,
    Email: string,
    TelephoneNumber1: string,
    FirstLineAddress: string,
    City: string,
    Country: string,
    UserImageName: string,
    JobTtile: string,
    ExtraInfromation: string,
}

export interface IUserProfileData {
    data: IUserProfileDataRow[],
}
interface IGetUser {
    token: string;
    userid: number;
}
const baseURL = apiURL()
const GetUserProfile = async (props: IGetUser) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + `users/getUserProfile?employeeID=${props.userid}`,
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IUserProfileData;

}
export default GetUserProfile;