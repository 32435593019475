import React, { useEffect, useState } from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, Box, MenuItem } from '@mui/material';
import useToken from '../../useToken';
import GetNewMarketerID from '../../../services/API/GetNewMarketerID';
import RegisterNewMarketer from '../../../services/API/RegisterNewMarketer';
import hashData from '../../../services/encrypt';
import { genderList } from './OfiiceData';

interface IRegister {
    handleClose: () => void,
}

interface FormData {
    firstName: string;
    middleName: string;
    lastName: string;
    gender: string;
    telephone: string;
    email: string;
    username: string;
    password: string;
    verifyPassword: string;
}
const RegisterMarketer = (props: IRegister) => {
    const [formData, setFormData] = useState<FormData>({
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        telephone: '',
        email: '',
        username: '',
        password: '',
        verifyPassword: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [newMarketerID, setNewMarketerID] = useState<string>();
    const token = useToken().token;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        GetNewMarketerID({ token }).then((response) => {
            if (response) {
                setNewMarketerID(response);
            }
        });
    }, [token]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Check if passwords match
        for (const key in formData) {
            if (formData[key as keyof FormData] === '') {
                setErrorMessage('Please fill in all fields.');
                return; // Exit function if any field is empty
            }
        }

        if (formData.password !== formData.verifyPassword) {
            setErrorMessage('Passwords do not match');
            return; // Exit function if passwords do not match
        }

        // Call service to register user
        try {
            const UserPassword = hashData(formData.password, true)
            if (newMarketerID && UserPassword) {
                const MarketerID = newMarketerID
                const Firstname = formData.firstName
                const Middlename = formData.middleName
                const Lastname = formData.lastName
                const Gender = formData.gender
                const Telephone = formData.telephone
                const Username = formData.username
                const Email = formData.email
                await RegisterNewMarketer({ token, MarketerID, Firstname, Middlename, Lastname, Gender, Telephone, Username, Email, UserPassword })
                props.handleClose(); // Close the modal after successful registration
            }
        } catch (error) {
            console.error('Error registering user:', error);
        }
    };

    return (
        <>
            <Dialog open={true} onClose={props.handleClose}>
                <DialogTitle>Register Marketer </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}  >
                                <div style={{ textAlign: 'center', paddingTop: '10px' }}>
                                    <Box
                                        component="span"
                                        sx={(theme) => ({
                                            backgroundColor: theme.palette.success.dark,
                                            borderRadius: '0.25rem',
                                            color: '#fff',
                                            maxWidth: '25ch',
                                            p: '0.25rem',
                                        })}
                                    >
                                        The Marketer Code is {newMarketerID}
                                    </Box>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="firstName"
                                    label="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="middleName"
                                    label="Middle Name"
                                    value={formData.middleName}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="lastName"
                                    label="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    name="gender"
                                    label="Gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                    fullWidth
                                >
                                    {genderList.map((option) => (
                                        <MenuItem key={option.number} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="telephone"
                                    label="Telephone"
                                    value={formData.telephone}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="username"
                                    label="Username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="email"
                                    label="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="password"
                                    label="Password"
                                    type="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="verifyPassword"
                                    label="Re-enter Password"
                                    type="password"
                                    value={formData.verifyPassword}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            {errorMessage && (
                                <Grid item xs={12}>
                                    <Typography color="error">{errorMessage}</Typography>
                                </Grid>
                            )}
                        </Grid>
                        <DialogActions>
                            <Button onClick={props.handleClose}>Cancel</Button>
                            <Button type="submit" variant="contained" color="primary">
                                Register
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default RegisterMarketer;
