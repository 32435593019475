import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MarketerMotorReport from "../Reports/MarketerMotorReport";
import MarketerFambulReport from "../Reports/MarketerFambulReport";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import { Badge, Collapse } from "@mui/material";
import FambulMissingCustomer from "../Reports/FambulMissingCustomer";
import FambulDuplicateCustomer from "../Reports/FambulDuplicateCustomer";
import InsuranceClaims from "../Reports/InsuranceClaims";
import StarsIcon from "@mui/icons-material/Stars";
import Groups2Icon from "@mui/icons-material/Groups2";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import GetDashBoardData from "../../../services/API/GetDashboardData";
import useToken from "../../useToken";
import { ISideCounts } from "./MainListItems";
interface ISecondaryListItemsProps {
  setButtonClick: (button: JSX.Element) => void;
}

const SecondaryListItems = (props: ISecondaryListItemsProps) => {
  const [open, setOpen] = React.useState(false);
  const [openIns, setOpenIns] = React.useState(false);
  const [openCust, setOpenCust] = React.useState(false);
  const token = useToken().token;
  const [counts, setCounts] = React.useState<ISideCounts>({
    claimsNeedAction: 0,
  });

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await GetDashBoardData({ token });
      if (response) {
        setCounts({
          claimsNeedAction: response.claimsNeedAction
            ? response.claimsNeedAction
            : 0,
        });
      } else {
        console.error("Dashboard data not found.");
      }
    };

    fetchData();
  }, [token]);

  const loadInsReport = () => {
    if (openIns) {
      setOpenIns(false);
    } else {
      setOpenIns(true);
      setOpenCust(false);
      setOpen(false);
    }
  };
  const loadClaims = () => {
    props.setButtonClick(<InsuranceClaims />);
  };
  const loadCustReport = () => {
    if (openCust) {
      setOpenCust(false);
    } else {
      setOpenCust(true);
      setOpenIns(false);
      setOpen(false);
    }
  };
  const loadMissingCustomer = () => {
    props.setButtonClick(<FambulMissingCustomer />);
  };
  const loadDupReport = () => {
    props.setButtonClick(<FambulDuplicateCustomer />);
  };
  const loadMarketerReport = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
      setOpenIns(false);
      setOpenCust(false);
    }
  };
  const loadFambulReport = () => {
    props.setButtonClick(<MarketerFambulReport />);
  };
  const loadMotorReport = () => {
    props.setButtonClick(<MarketerMotorReport />);
  };

  return (
    <div>
      <React.Fragment>
        <ListSubheader component="div" inset>
          Reports
        </ListSubheader>
        <ListItemButton onClick={loadInsReport} sx={{ paddingBottom: 0.5 }}>
          <ListItemIcon sx={{ minWidth: "36px" }}>
            <Badge badgeContent={counts.claimsNeedAction} color="error">
              <AssignmentIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Insurance" />
          {openIns ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse
          in={openIns}
          timeout="auto"
          unmountOnExit
          sx={{ paddingLeft: "25px" }}
        >
          <ListItemButton onClick={loadClaims}>
            <ListItemIcon sx={{ minWidth: "36px" }}>
              <Badge badgeContent={counts.claimsNeedAction} color="error">
                <SpeakerNotesIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText
              primary="Claims"
              primaryTypographyProps={{ style: { fontSize: "0.775rem" } }}
            />
          </ListItemButton>
        </Collapse>
        <ListItemButton onClick={loadCustReport} sx={{ paddingBottom: 0.5 }}>
          <ListItemIcon sx={{ minWidth: "36px" }}>
            <Groups2Icon />
          </ListItemIcon>
          <ListItemText primary="Customers" />
          {openCust ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse
          in={openCust}
          timeout="auto"
          unmountOnExit
          sx={{ paddingLeft: "25px" }}
        >
          <ListItemButton onClick={loadMissingCustomer}>
            <ListItemIcon sx={{ minWidth: "36px" }}>
              <StarBorder />
            </ListItemIcon>
            <ListItemText
              primary="Missing"
              primaryTypographyProps={{ style: { fontSize: "0.775rem" } }}
            />
          </ListItemButton>
          <ListItemButton onClick={loadDupReport}>
            <ListItemIcon sx={{ minWidth: "36px" }}>
              <StarBorder />
            </ListItemIcon>
            <ListItemText
              primary="Duplicate"
              primaryTypographyProps={{ style: { fontSize: "0.775rem" } }}
            />
          </ListItemButton>
        </Collapse>
        <ListItemButton
          onClick={loadMarketerReport}
          sx={{ paddingBottom: 0.5 }}
        >
          <ListItemIcon sx={{ minWidth: "36px" }}>
            <StarsIcon />
          </ListItemIcon>
          <ListItemText primary="Marketer" />
          {open ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          sx={{ paddingLeft: "25px" }}
        >
          <ListItemButton onClick={loadFambulReport}>
            <ListItemIcon sx={{ minWidth: "36px" }}>
              <StarBorder />
            </ListItemIcon>
            <ListItemText
              primary="Fambul"
              primaryTypographyProps={{ style: { fontSize: "0.775rem" } }}
            />
          </ListItemButton>
          <ListItemButton onClick={loadMotorReport}>
            <ListItemIcon sx={{ minWidth: "36px" }}>
              <StarBorder />
            </ListItemIcon>
            <ListItemText
              primary="Motor"
              primaryTypographyProps={{ style: { fontSize: "0.775rem" } }}
            />
          </ListItemButton>
        </Collapse>
      </React.Fragment>
    </div>
  );
};
export default SecondaryListItems;
