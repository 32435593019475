
export const MaritalOptions = ["Married", "Single", "Separated", "Divorced", "Widowed"];
export interface IDependantData {
    fullname: string,
    relationship: string,
    sex: string,
    dob: string,
}
export const DependantRows: IDependantData[] = [
    {
        fullname: '',
        relationship: '',
        sex: 'male',
        dob: '',
    },
    {
        fullname: '',
        relationship: '',
        sex: 'male',
        dob: '',
    },
    {
        fullname: '',
        relationship: '',
        sex: 'male',
        dob: '',
    },
    {
        fullname: '',
        relationship: '',
        sex: 'male',
        dob: '',
    }
]