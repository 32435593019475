import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IAddLoanPaymentProps {
  PolicyNo: string;
  AddedBy: string;
  credit: number;
  type: number;
  token: string;
}

const baseURL = apiURL();

const AddPaymentInfo = async (props: IAddLoanPaymentProps) => {
  let value = "";
  const token = props.token;
  const data = {
    PolicyNo: props.PolicyNo,
    UserName: props.AddedBy,
    Credit: props.credit,
  };
  try {
    const response = await axios.post(
      baseURL +
        `${props.type === 1 ? "FambulDetails" : "GroupLifeEdit"}/addPayment`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      value = "success";
    }
  } catch (error: AxiosError | any) {
    if (error.response) {
      value = error.response.data;
    } else if (error.request) {
      value = "No response from server";
    } else {
      value = "Something went wrong";
    }
  }
  return value;
};
export default AddPaymentInfo;
