import { useEffect, useReducer } from 'react';
import { FambulDependantRows, IFambulDependantData } from './FambulStateless';
import GetAllMarketerUserData, { IAllMUserDataRow } from '../../../services/API/GetAllMarketerUserData';
import useToken from '../../useToken';
import { ISignInData } from '../../../services/API/signin';
import jwt_decode from "jwt-decode";

export interface IFambulReducerData {
    NewIDCode: string,
    Firstname: string,
    Middlename: string,
    Lastname: string,
    DOB: Date,
    RegDate: Date,
    Gender: string,
    Premium: number,
    Telephone: string,
    BusinessLocation: string,
    Address: string,
    Region: string,
    District: string,
    dependantNames: IFambulDependantData[],
    editing: boolean,
    marketerList: IAllMUserDataRow[],
    marketerListOptions: string[],
}

export interface IPaymentState {
    dataToSave: IFambulReducerData;
}


export const initialState: IPaymentState = {
    dataToSave: {
        NewIDCode: '',
        Firstname: '',
        Middlename: '',
        Lastname: '',
        DOB: new Date(),
        RegDate: new Date(),
        Gender: 'Male',
        Premium: 2,
        Telephone: '',
        BusinessLocation: '',
        Address: '',
        Region: '',
        District: 'Western Urban',
        dependantNames: FambulDependantRows,
        marketerList: [],
        marketerListOptions: [],
        editing: false,
    }
}

export type ActionType =
    | { type: 'UPDATE_FIELD'; payload: { name: string; value: string | number | boolean } }
    | { type: 'UPDATE_EXISTING_USER'; payload: IFambulReducerData }
    | { type: 'UNLOCK_EDIT'; payload: { lock: boolean } }
    | { type: 'SET_DROPDOWN_STATUS'; payload: { label: number | string } }
    | { type: 'UPDATE_DEPENDANT_NAMES'; payload: { index: number; depName: string; depValue: string } }
    | { type: 'SET_MARKETER_LIST'; payload: IAllMUserDataRow[] };


export const reducer = (state: IPaymentState, action: ActionType) => {
    switch (action.type) {
        case 'UPDATE_FIELD':
            const { name, value } = action.payload;
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    [name]: typeof value === 'boolean' ? value : typeof value === 'string' ? value.trim() : value
                },
            };
        case 'UNLOCK_EDIT':
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    editing: action.payload.lock
                },
            };
        case 'UPDATE_DEPENDANT_NAMES':
            const { index, depName, depValue } = action.payload;
            const updatedDependantNames: IFambulDependantData[] = state.dataToSave.dependantNames.map((dep: any, row: number) => {
                if (row === Number(index)) {
                    return {
                        ...dep,
                        [depName]: depName === 'sharePercentage' || depName === 'dob' ? depValue : depValue.trim()
                    }
                } else {
                    return dep;
                }
            });
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    dependantNames: updatedDependantNames
                },
            };
        case 'SET_MARKETER_LIST':
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave, marketerList: action.payload, marketerListOptions: action.payload.map((row: IAllMUserDataRow) => {
                        return row.fullname;
                    })
                },
            };
        default:
            return state;
    }
};

export const FambulReducer = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const token = useToken().token;
    const userData: ISignInData = jwt_decode(token);
    const marketerID = userData.MarketerID
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch({ type: 'UPDATE_FIELD', payload: { name, value: value } });
    };

    const updateStateForExistingUser = (existingUser: IFambulReducerData) => {
        dispatch({ type: 'UPDATE_EXISTING_USER', payload: existingUser });
    }

    const unlockToEdit = (value: boolean) => {
        dispatch({ type: 'UNLOCK_EDIT', payload: { lock: value } });
    }

    const updateDependant = (index: number, depName: string, depValue: string) => {
        dispatch({ type: 'UPDATE_DEPENDANT_NAMES', payload: { index: index, depName: depName, depValue: depValue } });
    };

    const handleDropDownChange = (name: string, label: number | string) => {
        dispatch({ type: 'UPDATE_FIELD', payload: { name, value: label } });
    };

    const fetchMarketerPackage = (token: string) => {
        GetAllMarketerUserData({ token }).then((response) => {
            if (response) {
                dispatch({ type: 'SET_MARKETER_LIST', payload: response.data });
            }
        });
    };

    useEffect(() => {
        if (marketerID === '000') {
            fetchMarketerPackage(token);
        }
    }, [token, marketerID]);
    return { state, handleChange, updateStateForExistingUser, unlockToEdit, handleDropDownChange, updateDependant };
};

