import React, { useState } from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, Box, MenuItem } from '@mui/material';
import useToken from '../../useToken';
import { genderList, jobTypes } from './OfiiceData';
import AddOfficeOnly from '../../../services/API/AddOfficeOnly';
import GenerateActivationCode from '../../../services/API/GenerateActivationCode';
import GetDuplicateEmail from '../../../services/API/GetDuplicateEmail';


interface IRegister {
    handleClose: () => void,
}

interface FormData {
    firstName: string;
    middleName: string;
    lastName: string;
    gender: string;
    telephone: string;
    address: string;
    city: string;
    country: string;
    jobTitle: string;
    jobType: number;
    dob: string;
}
const AddOfficeOnlyUser = (props: IRegister) => {
    const [formData, setFormData] = useState<FormData>({
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        telephone: '',
        address: '',
        city: '',
        country: '',
        jobTitle: '',
        jobType: 0,
        dob: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const token = useToken().token;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const checkEmailExists = async (email: string) => {
        try {
            const response = await GetDuplicateEmail({ token, email })
            if (response > 0) {
                return true; // Assuming the response contains a boolean value indicating whether the email exists
            } else {
                return false
            }
        } catch (error) {
            return false;
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Check if passwords match
        for (const key in formData) {
            if (formData[key as keyof FormData] === '') {
                setErrorMessage('Please fill in all fields.');
                return; // Exit function if any field is empty
            }
        }

        const emailExists = await checkEmailExists(formData.firstName + '.' + formData.lastName + '@slicoinsurance.com');
        if (emailExists) {
            setErrorMessage('Email already exists in the system.');
            return;
        }
        // Call service to register user
        try {
            const Firstname = formData.firstName
            const Middlename = formData.middleName
            const Lastname = formData.lastName
            const Gender = formData.gender
            const Telephone = formData.telephone
            const Email = formData.firstName + '.' + formData.lastName + '@slicoinsurance.com'
            const Address = formData.address
            const City = formData.city
            const Country = formData.country
            const JobTitle = formData.jobTitle
            const JobType = formData.jobType
            const DOB = formData.dob
            await AddOfficeOnly({ token, Firstname, Middlename, Lastname, Gender, Telephone, Address, City, Country, Email, JobTitle, JobType, DOB })
                .then((response) => {
                    if (response === "success") {
                        GenerateActivationCode({ Email: Email })
                    }
                })
            props.handleClose(); // Close the modal after successful registration

        } catch (error) {
            console.error('Error registering user:', error);
        }
    };
    return (
        <>
            <Dialog open={true} onClose={props.handleClose}>
                <DialogTitle>Add New  </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}  >
                                <div style={{ textAlign: 'center', paddingTop: '10px' }}>
                                    <Box
                                        component="span"
                                        sx={(theme) => ({
                                            backgroundColor: theme.palette.error.dark,
                                            borderRadius: '0.25rem',
                                            color: '#fff',
                                            maxWidth: '25ch',
                                            p: '0.25rem',
                                        })}
                                    >
                                        For Office Staffs ONLY. Activation code will be send to SLICO Email
                                    </Box>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    name="firstName"
                                    label="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    name="middleName"
                                    label="Middle Name"
                                    value={formData.middleName}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    name="lastName"
                                    label="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="dob"
                                    name="dob"
                                    defaultValue={formData.dob}
                                    label="Date Of Birth"
                                    type="date"
                                    sx={{ width: 220 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    name="gender"
                                    label="Gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                    fullWidth
                                >
                                    {genderList.map((option) => (
                                        <MenuItem key={option.number} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="telephone"
                                    label="Telephone"
                                    value={formData.telephone}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    select
                                    name="jobType"
                                    label="Role"
                                    value={formData.jobType}
                                    onChange={handleChange}
                                    fullWidth
                                >
                                    {jobTypes.map((option) => (
                                        <MenuItem key={option.label} value={option.number}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="email"
                                    label="Email"
                                    value={formData.firstName + '.' + formData.lastName + '@slicoinsurance.com'}
                                    disabled={true}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="address"
                                    label="Address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="city"
                                    label="City"
                                    value={formData.city}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="country"
                                    label="Country"
                                    value={formData.country}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="jobTitle"
                                    label="Job Title"
                                    value={formData.jobTitle}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            {errorMessage && (
                                <Grid item xs={12}>
                                    <Typography color="error">{errorMessage}</Typography>
                                </Grid>
                            )}
                        </Grid>
                        <DialogActions>
                            <Button onClick={props.handleClose}>Cancel</Button>
                            <Button type="submit" variant="contained" color="primary">
                                Add
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddOfficeOnlyUser;
