import axios from "axios";
import apiURL from "../apiURL";

export interface IAckDataRow {
    DailyID: number,
    DailyDate: Date,
    Amount: number,
    SubmitBy: string,
    PaymentType: string,
    Acknowledged: boolean
}

export interface IAckData {
    data: IAckDataRow[],
}
interface IGetUser {
    token: string;
}
const baseURL = apiURL()
const GetAcknowledgmentData = async (props: IGetUser) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + "FrontDesk/getFrontDeskDailySum",
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IAckData;

}
export default GetAcknowledgmentData;