import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IRegisterMarketerProps {
    token: string,
    MarketerID: string,
    Firstname: string,
    Middlename: string,
    Lastname: string,
    Gender: string,
    Telephone: string,
    Username: string,
    Email: string,
    UserPassword: string,
}

const baseURL = apiURL()

const RegisterNewMarketer = async (props: IRegisterMarketerProps) => {
    let value = ''
    const token = props.token
    const data = {
        MarketerID: props.MarketerID.toString(),
        Firstname: props.Firstname,
        Middlename: props.Middlename,
        Lastname: props.Lastname,
        Gender: props.Gender,
        Telephone: props.Telephone,
        Username: props.Username,
        Email: props.Email,
        UserPassword: props.UserPassword,
    }
    try {
        const response = await axios.post(baseURL + "UserAdd/registerMarketer", data, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if (response.status === 200) {
            value = "success"
        }
    } catch (error: AxiosError | any) {
        if (error.response) {
            value = error.response.data
        } else if (error.request) {
            value = "No response from server"
        } else {
            value = "Something went wrong"
        }
    }
    return value
}
export default RegisterNewMarketer;

