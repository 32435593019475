import React, { useState } from 'react';
import {
    TextField,
    Button,
    Typography,
    Grid,
    LinearProgress,
} from '@mui/material';
import GetFambulCatalogue from '../../../services/API/GetFambulCatalogue';
import useToken from '../../useToken';
import { ExportToCsv } from 'export-to-csv';
import SearchFambul from '../../../services/API/SearchFambul';
import FambulDetails from './FambulDetails';
import { IFambulDataRow } from '../../../services/API/GetFambulData';
import AlertDialog from '../../../Common/AlertDialog';
import { IDialogSave } from '../FrontDeskPayment/PaymentData';
import UploadFambulPayment from '../../../Common/UploadFambulPayment';

const SearchEngine: React.FC = () => {
    const [searchValue, setSearchValue] = useState('');
    const [, setSearchResults] = useState<IFambulDataRow[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCat, setIsLoadingCat] = useState(false);
    const token = useToken().token;
    const [open, onClose] = useState(false);
    const [rowSelection, setRowSelection] = useState<IFambulDataRow | undefined>(undefined);
    const [showDaily, setShowDaily] = React.useState<IDialogSave>({
        open: false,
        headerText: '',
        mainText: ''
    });
    const [openUpload, setOpenUpload] = useState(false);

    const handleClose = () => {
        setSearchResults(undefined)
        setRowSelection(undefined)
        setSearchValue('')
        onClose(false);
    };
    const HandleRowSelected = (row: IFambulDataRow) => {
        onClose(true)
        setRowSelection(row)
    }
    const handleUploadClose = () => {
        setOpenUpload(false)
    };
    const handleUpload = () => {
        setOpenUpload(true)
    };
    const handleSearch = async () => {
        if (searchValue !== '') {
            setIsLoading(true);
            // Make your API call here with searchValue
            try {
                const response = await SearchFambul({ token, searchValue });
                const data = await response;

                setSearchResults(data.data);

                if (data.data.length === 1) {
                    // Automatically select the single result
                    HandleRowSelected(data.data[0]);
                } else if (data.data.length > 1) {
                    setShowDaily({
                        open: true,
                        headerText: `Information!`,
                        mainText: `The are ${data.data.length} records matching "${searchValue}" Customer code. This will need to be fixed`
                    })
                } else {
                    setShowDaily({
                        open: true,
                        headerText: `Information!`,
                        mainText: `The are no records matching "${searchValue}" Customer code.`
                    })
                }
            } catch (error) {
                console.error('API call error:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleCancel = () => {
        setSearchResults(undefined)
        setRowSelection(undefined)
        setSearchValue('')
        setShowDaily({
            open: false,
            headerText: '',
            mainText: ''
        });
    };
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,

    };
    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportData = async () => {
        setIsLoadingCat(true);
        const newData = await GetFambulCatalogue({ token });
        csvExporter.generateCsv(newData.data);
        setIsLoadingCat(false);
    };

    return (
        <>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4}>
                    <div style={{ border: "1px solid lightgrey", padding: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom color="green">Export Entire Fambul catalogue</Typography>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleExportData}
                            disabled={isLoadingCat}
                        >
                            Export Catalogue
                        </Button>
                    </div>
                    {isLoadingCat && <LinearProgress />}
                </Grid>
                <Grid item xs={4}>
                    <div style={{ border: "1px solid lightgrey", padding: "10px" }}>
                        <Typography variant="h5" gutterBottom color="green">Search Entire Fambul catalogue</Typography>
                        <Typography variant="subtitle1" gutterBottom color="green">Enter the 10 digit Customer code</Typography>
                        <TextField
                            label="Search"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            variant="outlined"
                            inputProps={{
                                maxLength: 10,
                            }}
                        />
                        <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSearch}
                                disabled={searchValue.length !== 10 || isLoading}
                            >
                                Search
                            </Button>
                        </div>
                        {isLoading && <LinearProgress />}
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div style={{ border: "1px solid lightgrey", padding: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom color="green">Upload Payment</Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleUpload}
                            disabled={isLoadingCat}
                        >
                            Upload Payment
                        </Button>
                    </div>
                    {isLoadingCat && <LinearProgress />}
                </Grid>
            </Grid>
            {
                showDaily && <AlertDialog
                    open={showDaily.open}
                    showNO={true}
                    showYes={false}
                    cancelText={'OK'}
                    setOpen={handleCancel}
                    headerText={showDaily.headerText}
                    mainText={showDaily.mainText} />
            }
            {open && rowSelection && <FambulDetails openUser={open} onClose={handleClose} idCode={searchValue} />}
            {openUpload && <UploadFambulPayment handleClose={handleUploadClose}/>}
        </>
    );
};

export default SearchEngine;
