import axios from "axios";
import apiURL from "../apiURL";

export interface IMedicalPackageRow {
    medicalInsurancePackageID: number,
    medicalInsurancePackage: string,
    medicalInsurancePackageDesc: string,
    activePackage: boolean,
    currentPremuim: number
}

export interface IMedicalPackageData {
    data: IMedicalPackageRow[],
}
interface IGetMedPack {
    token: string;
}
const baseURL = apiURL()
const GetMedicalPackages = async (props: IGetMedPack) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + "medicalPackage",
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IMedicalPackageData;

}
export default GetMedicalPackages;