import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";
import { checkSessionExpiration } from "../checkSessionExpiration";

export interface IPaymentDataRow {
    frontDeskPaymentID: number,
    groupPolicyNumber: string,
    receiptNumber: string,
    paymentDate: Date,
    receivedFrom: string,
    amountPaid: number,
    paymentReason: string,
    chequeNumber: string,
    office: string,
    agentBroker: string,
    insured: string,
    enteredBy: string,
    isApproved: boolean,
    approvedBy: string,
    isGroupLife: boolean,
}

export interface IPaymentData {
    data: IPaymentDataRow[],
}
interface IGetPayment {
    token: string;
}
const baseURL = apiURL()
const GetPaymentData = async (props: IGetPayment) => {
    const token = props.token
    // Check session expiration before proceeding
    if (!checkSessionExpiration()) {
        return;
    }

    try {
        const data = await axios({
            method: "get",
            responseType: "json",
            url: baseURL + "frontDeskPayment",
            headers: { "Authorization": `Bearer ${token}` }
        })
        return data as IPaymentData;
    } catch (error) {
        // Call the handleAxiosError function with the error and redirect URL
        handleAxiosError(error, "/login"); // Adjust the URL as needed
        return undefined;
    }

}
export default GetPaymentData;