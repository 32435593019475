import { useReducer } from 'react';
import { IUserProfileDataRow } from '../../services/API/GetUserProfile';

interface ITextFieldData {
    EmployeeID: number,
    FirstName: string,
    MiddleNames: string,
    LastName: string,
    DateOfBirth: Date,
    Gender: string,
    Email: string,
    TelephoneNumber1: string,
    FirstLineAddress: string,
    City: string,
    Country: string,
    UserImageName: string,
    JobTtile: string,
    ExtraInfromation: string,
    editing: boolean,
}

export interface IPaymentState {
    dataToSave: ITextFieldData;
}


export const initialState: IPaymentState = {
    dataToSave: {
        EmployeeID: 0,
        FirstName: '',
        MiddleNames: '',
        LastName: '',
        DateOfBirth: new Date,
        Gender: '',
        Email: '',
        TelephoneNumber1: '',
        FirstLineAddress: '',
        City: '',
        Country: '',
        UserImageName: '',
        JobTtile: '',
        ExtraInfromation: '',
        editing: false,
    }
}

export type ActionType =
    | { type: 'UPDATE_FIELD'; payload: { name: string; value: string | boolean } }
    | { type: 'UPDATE_EXISTING_USER'; payload: IUserProfileDataRow }
    | { type: 'UNLOCK_EDIT'; payload: { lock: boolean } };


export const reducer = (state: IPaymentState, action: ActionType) => {
    switch (action.type) {
        case 'UPDATE_FIELD':
            const { name, value } = action.payload;
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    [name]: typeof value === 'boolean' ? value : typeof value === 'string' ? value.trim() : value
                },
            };
        case 'UNLOCK_EDIT':
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    editing: action.payload.lock
                },
            };
        case 'UPDATE_EXISTING_USER':
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    EmployeeID: action.payload.EmployeeID,
                    FirstName: action.payload.FirstName,
                    MiddleNames: action.payload.MiddleNames,
                    LastName: action.payload.LastName,
                    DateOfBirth: action.payload.DateOfBirth,
                    Gender: action.payload.Gender,
                    Email: action.payload.Email,
                    TelephoneNumber1: action.payload.TelephoneNumber1,
                    FirstLineAddress: action.payload.FirstLineAddress,
                    City: action.payload.City,
                    Country: action.payload.Country,
                    UserImageName: action.payload.UserImageName,
                    JobTtile: action.payload.JobTtile,
                    ExtraInfromation: action.payload.ExtraInfromation,
                    editing: false,
                }
            };
        default:
            return state;
    }
};

export const UserProfileReducer = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch({ type: 'UPDATE_FIELD', payload: { name, value: value } });
    };

    const updateStateForExistingUser = (existingUser: IUserProfileDataRow) => {
        dispatch({ type: 'UPDATE_EXISTING_USER', payload: existingUser });
    }

    const unlockToEdit = (value: boolean) => {
        dispatch({ type: 'UNLOCK_EDIT', payload: { lock: value } });
    }
    return { state, handleChange, updateStateForExistingUser, unlockToEdit };
};

