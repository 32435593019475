import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IGLProps {
  glcustomersID?: number;
  title: string;
  email: string;
  dob: string;
  glPremiumLevel: string;
  gender: string;
  residentialAddress: string;
  telephoneNumber: string;
  occupation: string;
  employer: string;
  payrollNumber: string;
  policyNumber: string;
  firstName: string;
  lastName: string;
  countDep: number;
  country: string;
  city: string;
  dependantNames: string;
  token: string;
  Whoentered: string;
  isFambulLife: number;
}

const baseURL = apiURL();

const AddEditGLCustomers = async (props: IGLProps) => {
  let value = "";
  const token = props.token;
  const data = {
    glcustomersID: props.glcustomersID,
    title: props.title,
    email: props.email,
    dob: props.dob,
    glPremiumLevel: props.glPremiumLevel,
    gender: props.gender,
    residentialAddress: props.residentialAddress,
    telephoneNumber: props.telephoneNumber,
    occupation: props.occupation,
    employer: props.employer,
    payrollNumber: props.payrollNumber,
    policyNumber: props.policyNumber,
    firstName: props.firstName,
    lastName: props.lastName,
    countDep: props.countDep,
    country: props.country,
    city: props.city,
    dependantNames: props.dependantNames,
    Whoentered: props.Whoentered,
    isFambulLife: props.isFambulLife,
  };
  try {
    const response = await axios.post(
      baseURL + "GroupLifeEdit/AddEditGLCustomers",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      value = "success";
    }
  } catch (error: AxiosError | any) {
    if (error.response) {
      value = error.response.data;
    } else if (error.request) {
      value = "No response from server";
    } else {
      value = "Something went wrong";
    }
  }
  return value;
};
export default AddEditGLCustomers;
