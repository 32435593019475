import axios from "axios";
import apiURL from "../apiURL";

export interface IMarketerRegDataRow {
    IDCode: string,
    name: string,
    Telephone: string,
    RegDate: string,
    totalPayment: string,
    lastPayDate: string,
    Arrear: string,
}

export interface IMarketerRegData {
    data: IMarketerRegDataRow[],
}
interface IGetRegistered {
    token: string;
    marketerID: string;
}
const baseURL = apiURL()
const GetAllRegistered = async (props: IGetRegistered) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + `Marketer/getAllRegistered?marketerID=${props.marketerID}`,
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IMarketerRegData;

}
export default GetAllRegistered;