import React, { useEffect, useMemo } from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import useToken from "../../useToken";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import FambulDetails from "./FambulDetails";
import GetQualifiedVICard, {
  IFambulCardDataRow,
} from "../../../services/API/GetQualifiedVICard";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CancelIcon from "@mui/icons-material/Cancel";
import { IDialogSave } from "../FrontDeskPayment/PaymentData";
import AlertDialog from "../../../Common/AlertDialog";
import { ISignInData } from "../../../services/API/signin";
import jwt_decode from "jwt-decode";
import PrintedFambulCard from "../../../services/API/PrintedFambulCard";
import InfoIcon from "@mui/icons-material/Info";

export const filterData = [
  { filterID: 0, filterStatus: "All" },
  { filterID: 1, filterStatus: "Cards Not Printed" },
  { filterID: 2, filterStatus: "Cards Printed" },
];

const QualifiedV1Card = () => {
  const columns = useMemo<MRT_ColumnDef<IFambulCardDataRow>[]>(
    () => [
      {
        accessorKey: "fullname",
        header: "Full Name",
      },
      {
        accessorKey: "idCode",
        header: "Unique Code",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {cell.getValue()}
          </Box>
        ),
        size: 150,
      },
      {
        accessorKey: "regDate",
        header: "Reg Date",
        Cell: ({ cell }) => (cell.getValue() as string).slice(0, 10),
        size: 100,
      },
      {
        accessorKey: "premium",
        header: "Premium",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.secondary.light,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {`${
              Number(cell.getValue()).toLocaleString("en-SL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " SLL"
            }`}
          </Box>
        ),
        size: 150,
      },
      {
        accessorKey: "totalPayment",
        header: "Total Paid",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.main,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {`${
              Number(cell.getValue()).toLocaleString("en-SL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " SLL"
            }`}
          </Box>
        ),
        size: 150,
      },
      {
        accessorKey: "shouldHavepaid",
        header: "Must Have Paid",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.error.main,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {`${
              Number(cell.getValue()).toLocaleString("en-SL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " SLL"
            }`}
          </Box>
        ),
        size: 150,
      },
      {
        accessorKey: "markedAsPrinted",
        header: "Card Printed",
        Cell: ({ cell }) => (
          <Box component="span">
            {cell.getValue() ? (
              <AssignmentTurnedInIcon
                color="success"
                sx={{
                  width: 50,
                  height: 20,
                }}
              />
            ) : (
              <CancelIcon
                color="error"
                sx={{
                  width: 50,
                  height: 20,
                }}
              />
            )}
          </Box>
        ),
      },
      {
        accessorKey: "idCode",
        header: "Info",
        Cell: ({ cell }) =>
          !cell.row.original.markedAsPrinted ? (
            <Button
              color="error"
              disabled={cell.row.original.markedAsPrinted}
              variant="contained"
              sx={{ width: 150, height: 25 }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent row click event
                handleApprove(
                  cell.row.original.idCode,
                  cell.row.original.fullname
                );
              }}
            >
              Mark as Printed
            </Button>
          ) : (
            <Tooltip title={cell.row.original.whoPrintNote} arrow>
              <IconButton
                size="small"
                sx={{
                  marginLeft: "0.5rem",
                  color: "#fff",
                }}
              >
                <InfoIcon
                  color="success"
                  sx={{
                    width: 50,
                    height: 20,
                  }}
                />
              </IconButton>
            </Tooltip>
          ),
      },
    ],
    []
  );

  const handleApprove = (idCode: string, fullname: string) => {
    setShowDaily({
      open: true,
      headerText: `ID Card Printed`,
      mainText: `You are about to confirm that the ID code "${idCode}" for customer "${fullname}" has been printed.`,
    });
    setIdCodeToPrinted(idCode);
  };
  const [idCodeToPrinted, setIdCodeToPrinted] = React.useState<string>();
  const [showDaily, setShowDaily] = React.useState<IDialogSave>({
    open: false,
    headerText: "",
    mainText: "",
  });
  const [selectedFilter, setSelectedFilter] = React.useState<number>(1);
  const token = useToken().token;
  const userData: ISignInData = jwt_decode(token);
  const [data, setData] = React.useState<IFambulCardDataRow[]>([]);
  const [filteredData, setFilteredData] = React.useState<IFambulCardDataRow[]>(
    []
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rowSelection, setRowSelection] = React.useState<
    IFambulCardDataRow | undefined
  >(undefined);
  const [reload, setReload] = React.useState(false);

  const handleCancel = () => {
    setShowDaily({
      open: false,
      headerText: "",
      mainText: "",
    });
  };
  useEffect(() => {
    GetQualifiedVICard({ token }).then((response) => {
      if (response) {
        setReload(false);
        setData(response.data);
        setFilteredData(response.data); // Set the filtered data on initial load
      }
    });
  }, [token, reload]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const HandleRowSelected = (row: IFambulCardDataRow) => {
    setRowSelection(row);
  };
  const handleClose = (val?: boolean) => {
    if (val) {
      setReload(true);
    }
    setRowSelection(undefined);
  };
  const handleSubmit = () => {
    if (idCodeToPrinted) {
      PrintedFambulCard({
        idCodeToPrinted: idCodeToPrinted,
        acknowledgedBy: userData.DisplayName,
        token: token,
      });
      setShowDaily({
        open: false,
        headerText: "",
        mainText: "",
      });
      setReload(true);
    }
  };

  const handleFilterChange = (event: SelectChangeEvent<number>) => {
    setSelectedFilter(Number(event.target.value));
  };

  useEffect(() => {
    let newData = data;
    if (selectedFilter !== 0) {
      newData = newData.filter(
        (item) => (item.markedAsPrinted ? 2 : 1) === selectedFilter
      );
    }
    setFilteredData(newData);
  }, [selectedFilter, data]);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={filteredData}
        enableRowVirtualization
        enableMultiRowSelection={false} //use radio buttons instead of checkboxes
        enableDensityToggle={false}
        initialState={{
          density: "compact",
          pagination: { pageIndex: 0, pageSize: 20 },
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 20, 50],
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => HandleRowSelected(row.original),
          sx: { cursor: "pointer" },
        })}
        muiTableContainerProps={{
          sx: {
            height:
              filteredData.length > 20
                ? window.innerHeight - 300 + "px"
                : undefined,
            overflow: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "0.4em",
              height: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#1976d2",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#1976d2",
            },
          },
        }}
        enableFullScreenToggle={false}
        enableHiding={false}
        rowCount={filteredData.length}
        renderTopToolbarCustomActions={() => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.3rem", // Reduced gap
              p: "0.3rem", // Reduced padding
              flexWrap: "wrap",
              height: "50px", // Reduced height
            }}
          >
            <>
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}
              >
                {" "}
                {/* Added gap here */}
                <Typography variant="subtitle1" gutterBottom>
                  {" "}
                  {/* Changed variant to reduce font size */}
                  Qualified for Fambul V1 card
                </Typography>
                <Button
                  aria-describedby={id}
                  variant="text"
                  color="primary"
                  startIcon={<HelpCenterIcon />}
                  onClick={handleClick}
                  sx={{ minWidth: "auto", padding: "0 0.5rem" }} // Adjusted button padding
                />
                <FormControl
                  sx={(theme) => ({
                    minWidth: "140px", // Reduced width
                    border: `1px solid ${theme.palette.secondary.dark}`,
                    borderRadius: "8px", // Reduced border radius
                    marginLeft: "0.5rem", // Added margin for spacing
                  })}
                >
                  <InputLabel id="dropdown-label">Filter</InputLabel>
                  <Select
                    labelId="dropdown-label"
                    name="Filter"
                    value={selectedFilter}
                    label="Filter"
                    onChange={handleFilterChange}
                    sx={{ fontSize: "0.8rem" }} // Reduced font size
                  >
                    {filterData &&
                      filterData.map((item) => (
                        <MenuItem key={item.filterID} value={item.filterID}>
                          {item.filterStatus}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                sx={{ width: 600 }} // Reduced width
              >
                <Typography
                  sx={{
                    padding: 0.5, // Reduced padding
                    fontStyle: "italic",
                    backgroundColor: "#f0f0f0",
                    color: "red",
                    border: "1px solid #1565C0",
                    borderRadius: "8px", // Reduced border radius
                    fontSize: "0.875rem", // Reduced font size
                  }}
                >
                  This section shows all customers that have qualified for a
                  Fambul card.
                </Typography>
              </Popover>
            </>
          </Box>
        )}
      />
      {showDaily && (
        <AlertDialog
          open={showDaily.open}
          setOpen={handleCancel}
          setSave={handleSubmit}
          headerText={showDaily.headerText}
          mainText={showDaily.mainText}
        />
      )}
      {rowSelection && (
        <FambulDetails
          openUser={true}
          onClose={handleClose}
          idCode={rowSelection.idCode}
        />
      )}
    </>
  );
};
export default QualifiedV1Card;
