import * as React from "react";
import Button from "@mui/material/Button";
import { BootstrapDialog, BootstrapDialogTitle } from "./AddNewGLUserStepper";
import useToken from "../../useToken";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { GroupLifeReducer } from "./GroupLifeReducer";
import { IGroupLifeDataRow } from "../../../services/API/GetGroupLifeData";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Paper,
  Autocomplete,
  Container,
  Avatar,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  DialogContent,
} from "@mui/material";
import resizeImage from "../../../Common/ResizeImage";
import { Edit } from "@mui/icons-material";
import AddEditGLCustomers, {
  IGLProps,
} from "../../../services/API/AddEditGLCustomers";
import { DepDistrict } from "./AddNewGLUserStateless";
import jwt_decode from "jwt-decode";
import { ISignInData } from "../../../services/API/signin";
import GetGLDependants from "../../../services/API/GetGLDependant";
import { generateRandomString } from "../../../Common/generateRandomString";
import uploadFile, { IUploadFile } from "../../../services/API/UploadFile";
import GetImage from "../../../services/API/GetImage";
import GetOtherDocs from "../../../services/API/GetOtherDocs";
import LoanDetails from "../../../Common/LoanDetails";
import GLPayments from "./GLPayments";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddPayment from "../../../Common/AddPayment";
interface IAddNewProps {
  openUser: boolean;
  onClose: (reload?: boolean) => void;
  existingUser?: IGroupLifeDataRow;
  isFambulLife?: number;
}

const AddNewGLUser = (props: IAddNewProps) => {
  const token = useToken().token;
  const {
    state,
    handleChange,
    updateDependantNames,
    updateStateForExistingUser,
    handleDropDownChange,
    existingDependant,
  } = GroupLifeReducer();
  const [errors, setError] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [filesToUpload, setFilesToUpload] = React.useState<File[]>([]);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const profileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [previewImage, setPreviewImage] = React.useState<string | null>(null);
  const [chosenFile, setChosenFile] = React.useState<File>();
  const userData: ISignInData = jwt_decode(token);
  const [userImageToDisplay, setUserImageToDisplay] = React.useState<
    string | undefined
  >(undefined);
  const [filesToDisplay, setFilesToDisplay] = React.useState<
    { url: string; isImage: boolean }[] | undefined
  >();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState("");
  const [addPayment, setPayment] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchData = async () => {
      if (props.existingUser && props.existingUser?.otherDocs) {
        try {
          const claimEvidenceResponse = await GetOtherDocs({
            token,
            OtherDocs: props.existingUser.otherDocs,
            PolicyNo: props.existingUser.policyNumber,
          });

          if (claimEvidenceResponse) {
            const claimEvidenceData = claimEvidenceResponse.data;

            // Assuming claimEvidenceData is an array of rows
            const filePromises = claimEvidenceData.map(async (row) => {
              const imageResponse = await GetImage({
                token,
                subfolder: row.uploadFileTypes,
                imageName: row.filesToShow,
              });

              if (imageResponse) {
                const fileType = row.filesToShow.toLowerCase().endsWith(".pdf")
                  ? "application/pdf"
                  : "image/jpeg" || "image/png";
                const blob = new Blob([imageResponse.data], { type: fileType });
                const fileUrl = URL.createObjectURL(blob);
                return { url: fileUrl, isImage: checkIfImage(row.filesToShow) };
              }

              return null; // or handle error scenario if needed
            });

            // Wait for all promises to resolve
            const filesData = await Promise.all(filePromises);

            // Filter out null values (failed requests)
            const validFilesData = filesData.filter(
              (file) => file !== null
            ) as { url: string; isImage: boolean }[];

            setFilesToDisplay(validFilesData);
          }
        } catch (error) {
          // Handle errors here
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();
  }, [token, props.existingUser]);

  React.useEffect(() => {
    if (props.existingUser?.glcustomersID) {
      const glcustomersID = props.existingUser?.glcustomersID;
      GetGLDependants({ token, glcustomersID }).then((response) => {
        if (response) {
          existingDependant(response.data);
        }
      });
    }
  }, [token, props.existingUser?.glcustomersID]);

  React.useEffect(() => {
    if (
      props.existingUser?.fileName &&
      props.existingUser?.fileName !== "none"
    ) {
      const imageName = props.existingUser?.fileName;
      const subfolder = "GroupLife";
      GetImage({ token, subfolder, imageName }).then((imageResponse) => {
        const blob = new Blob([imageResponse.data], {
          type: "image/jpeg" || "image/png",
        });
        const imageToDisplay = URL.createObjectURL(blob);
        setUserImageToDisplay(imageToDisplay);
      });
    }
  }, []);

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  React.useEffect(() => {
    async function loadData() {
      if (props.existingUser) {
        await updateStateForExistingUser(props.existingUser);
      }
    }
    loadData();
  }, []);

  const handleClose = () => {
    props.onClose();
  };

  const handleErrrClose = () => {
    setError(false);
  };

  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any
  ) => {
    setActiveTab(value);
  };

  const handleFileInputChange = () => {
    if (profileInputRef.current && profileInputRef.current.files) {
      const file = profileInputRef.current.files[0];
      if (file.size > 30000) {
        resizeImage(file, 300, 300, 30, (resizedFile: File) => {
          const imageUrl = URL.createObjectURL(resizedFile);
          setPreviewImage(imageUrl);
          setChosenFile(resizedFile);
        });
      } else {
        const imageUrl = URL.createObjectURL(file);
        setPreviewImage(imageUrl);
        setChosenFile(file);
      }
    }
  };
  const handleEvidenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFilesToUpload(Array.from(files));
    }
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleButtonClick = () => {
    if (profileInputRef.current) {
      profileInputRef.current.click();
    }
  };

  const handlePaymentAdd = () => {
    setPayment(true);
  };
  const handleCloseAdd = (val: boolean) => {
    setPayment(false);
  };

  const okToSave = () => {
    let notCompleted = false;
    const states = state.dataToSave;
    if (
      states.title === "" ||
      states.firstName === "" ||
      states.lastName === "" ||
      states.email === "" ||
      states.gender === "" ||
      states.telephoneNumber === "" ||
      states.residentialAddress === "" ||
      states.country === "" ||
      states.city === "" ||
      states.occupation === "" ||
      states.employer === "" ||
      (props.existingUser === undefined && chosenFile === undefined)
      //this section handles if we have an existing user, then they should already have an image.. if not then
    ) {
      notCompleted = true;
    }
    return notCompleted;
  };

  const handleSave = async () => {
    if (okToSave()) {
      setError(true);
    } else {
      const states = state.dataToSave;
      const dependantList = states.dependantNames
        .filter((dep) => dep.fullname !== "" && dep.fullname !== "N/A") // Include elements with non-empty fullname
        .map((dep, i) => {
          return `${dep.dependantID}|${dep.fullname}¬${dep.relationship}#${
            dep.sharePercent
          }<${dep.telephone}>${new Date(dep.dateOfBirth).toLocaleDateString(
            "en-GB"
          )}*${dep.trustee}`;
        })
        .join(",");

      const dataToSend: IGLProps = {
        glcustomersID: states.glcustomersID ? states.glcustomersID : -1,
        title: states.title,
        email: states.email,
        dob: new Date(states.dob).toISOString().slice(0, 10),
        glPremiumLevel: states.glPremiumLevel,
        gender: states.gender,
        residentialAddress: states.residentialAddress,
        telephoneNumber: states.telephoneNumber,
        occupation: states.occupation,
        employer: states.employer,
        payrollNumber: states.payrollNumber,
        policyNumber: states.policyNumber,
        firstName: states.firstName,
        lastName: states.lastName,
        countDep: states.countDep,
        country: states.country,
        city: states.city,
        dependantNames: dependantList,
        Whoentered: userData.DisplayName,
        isFambulLife: props.isFambulLife ? props.isFambulLife : 0,
        token: token,
      };

      const fileTypeID = 4;
      let uniqueClaimRef = generateRandomString(15);
      if (props.existingUser?.otherDocs) {
        uniqueClaimRef = props.existingUser?.otherDocs;
      }
      if (chosenFile) {
        const dataToUpload: IUploadFile = {
          file: chosenFile,
          policy: states.policyNumber,
          UploadFileTypeID: fileTypeID,
          token: token,
        };
        await uploadFile(dataToUpload);
      }
      if (filesToUpload.length > 0) {
        for (let i = 0; i < filesToUpload.length; i++) {
          const dataToUpload: IUploadFile = {
            file: filesToUpload[i],
            policy: states.policyNumber,
            UploadFileTypeID: fileTypeID,
            token: token,
            OtherDocs: uniqueClaimRef,
          };
          await uploadFile(dataToUpload);
        }
      }
      try {
        await AddEditGLCustomers(dataToSend);
        props.onClose(true);
      } catch (error) {}
    }
  };
  return (
    <>
      <Container sx={{ mt: 8, mb: 9 }}>
        <BootstrapDialog
          open={true}
          fullWidth
          maxWidth="md"
          disableEscapeKeyDown={true}
          hideBackdrop={true}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            wizard="true"
          >
            {props.existingUser ? "Existing Customer" : "New Customer"}
          </BootstrapDialogTitle>
          {props.existingUser ? (
            <>
              <Tabs value={activeTab} onChange={handleTabChange} centered>
                <Tab label="Details" />
                <Tab label="Dependants" />
                <Tab label="Files" />
                <Tab label="Payments" />
                <Tab label="Loans" />
              </Tabs>
            </>
          ) : (
            <>
              <Tabs value={activeTab} onChange={handleTabChange} centered>
                <Tab label="Details" />
                <Tab label="Dependants" />
                <Tab label="Files" />
              </Tabs>
            </>
          )}

          {activeTab === 0 && (
            <Container sx={{ mt: 2 }}>
              <Box sx={{ width: 800, height: 420, overflow: "hidden" }}>
                <React.Fragment>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sm={4} md={2}>
                      <TextField
                        required
                        id="title"
                        name="title"
                        label="Title"
                        fullWidth
                        sx={{ width: 100 }}
                        autoComplete="title"
                        inputProps={{ maxLength: 5 }}
                        disabled={state.dataToSave.editing}
                        defaultValue={state.dataToSave.title}
                        variant="standard"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={3}>
                      <TextField
                        required
                        id="firstName"
                        name="firstName"
                        label="FirstName"
                        fullWidth
                        inputProps={{ maxLength: 50 }}
                        autoComplete="firstName"
                        disabled={state.dataToSave.editing}
                        defaultValue={state.dataToSave.firstName}
                        variant="standard"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={3}>
                      <TextField
                        required
                        id="lastName"
                        name="lastName"
                        label="LastName"
                        fullWidth
                        inputProps={{ maxLength: 50 }}
                        autoComplete="lastName"
                        disabled={state.dataToSave.editing}
                        defaultValue={state.dataToSave.lastName}
                        variant="standard"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="gender"
                        value={state.dataToSave.gender || "male"}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Male"
                          disabled={state.dataToSave.editing}
                        />
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Female"
                          disabled={state.dataToSave.editing}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <TextField
                        required
                        id="email"
                        name="email"
                        label="Email"
                        fullWidth
                        inputProps={{ maxLength: 40 }}
                        defaultValue={state.dataToSave.email}
                        autoComplete="email"
                        variant="standard"
                        onChange={handleChange}
                        disabled={state.dataToSave.editing}
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <TextField
                        required
                        id="telephoneNumber"
                        name="telephoneNumber"
                        defaultValue={state.dataToSave.telephoneNumber}
                        label="Telephone"
                        fullWidth
                        inputProps={{ maxLength: 40 }}
                        autoComplete="middle-name"
                        variant="standard"
                        onChange={handleChange}
                        disabled={state.dataToSave.editing}
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <TextField
                        id="dob"
                        name="dob"
                        defaultValue={state.dataToSave.dob}
                        label="Date Of Birth"
                        type="date"
                        sx={{ width: 220 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                        disabled={state.dataToSave.editing}
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <TextField
                        required
                        id="residentialAddress"
                        name="residentialAddress"
                        defaultValue={state.dataToSave.residentialAddress}
                        label="Address"
                        fullWidth
                        inputProps={{ maxLength: 20 }}
                        autoComplete="tel-number"
                        variant="standard"
                        onChange={handleChange}
                        disabled={state.dataToSave.editing}
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <form>
                        <Autocomplete
                          disablePortal
                          id="city"
                          value={state.dataToSave.city}
                          options={DepDistrict.map((option) => option.label)}
                          disableClearable
                          sx={{ width: 200 }}
                          PaperComponent={({ children }) => (
                            <Paper style={{ borderRadius: "0 0 4px 4px" }}>
                              {children}
                            </Paper>
                          )}
                          ListboxProps={{ style: { maxHeight: 200 } }}
                          renderInput={(params) => (
                            <TextField {...params} label="City/District" />
                          )}
                          onChange={(event, newValue) => {
                            const name = "city";
                            const value = newValue ? newValue : "";
                            handleDropDownChange(name, value);
                          }}
                        />
                      </form>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <TextField
                        required
                        id="country"
                        name="country"
                        label="Country"
                        fullWidth
                        inputProps={{ maxLength: 40 }}
                        defaultValue={state.dataToSave.country}
                        autoComplete="country"
                        variant="standard"
                        onChange={handleChange}
                        disabled={state.dataToSave.editing}
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={6}>
                      <TextField
                        required
                        id="policyNumber"
                        name="policyNumber"
                        defaultValue={state.dataToSave.policyNumber}
                        label="Policy#"
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        autoComplete="heightCm-cus"
                        variant="standard"
                        onChange={handleChange}
                        disabled={state.dataToSave.editing}
                        error={state.dataToSave.isDuplicate}
                        helperText={
                          state.dataToSave.isDuplicate
                            ? "This registration is a duplicate"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={6}>
                      <form>
                        <Autocomplete
                          disablePortal
                          id="glPremiumLevel"
                          value={state.dataToSave.glPremiumLevel}
                          options={state.dataToSave.premiumOption}
                          getOptionLabel={(option) => String(option)}
                          disableClearable
                          sx={{ width: 200 }}
                          PaperComponent={({ children }) => (
                            <Paper style={{ borderRadius: "0 0 4px 4px" }}>
                              {children}
                            </Paper>
                          )}
                          ListboxProps={{ style: { maxHeight: 200 } }}
                          renderInput={(params) => (
                            <TextField {...params} label="Premium Level" />
                          )}
                          onChange={(event, newValue) => {
                            const name = "glPremiumLevel";
                            const value = newValue ? newValue : "";
                            handleDropDownChange(name, value);
                          }}
                        />
                      </form>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      mt: 2,
                      height: state.dataToSave.hasDependant ? 320 : 0,
                      width: "100%",
                    }}
                  >
                    <div style={{ border: "1px solid blue", padding: "10px" }}>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        color="green"
                      >
                        Employment Details
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={2} sm={4} md={4}>
                          <TextField
                            required
                            id="occupation"
                            name="occupation"
                            defaultValue={state.dataToSave.occupation}
                            label="Occupation"
                            inputProps={{ maxLength: 40 }}
                            fullWidth
                            autoComplete="occupation"
                            variant="standard"
                            onChange={handleChange}
                            disabled={state.dataToSave.editing}
                          />
                        </Grid>
                        <Grid item xs={2} sm={4} md={6}>
                          <TextField
                            required
                            id="employer"
                            name="employer"
                            defaultValue={state.dataToSave.employer}
                            label="Employer"
                            fullWidth
                            inputProps={{ maxLength: 40 }}
                            autoComplete="employer"
                            variant="standard"
                            onChange={handleChange}
                            disabled={state.dataToSave.editing}
                          />
                        </Grid>
                        <Grid item xs={2} sm={4} md={2}>
                          <TextField
                            required
                            id="payrollNumber"
                            name="payrollNumber"
                            defaultValue={state.dataToSave.payrollNumber}
                            label="PayrollNumber"
                            fullWidth
                            inputProps={{ maxLength: 10 }}
                            autoComplete="heightCm-cus"
                            variant="standard"
                            onChange={handleChange}
                            disabled={state.dataToSave.editing}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </React.Fragment>
              </Box>
            </Container>
          )}

          {activeTab === 1 && (
            <Container sx={{ mt: 2 }}>
              <Box sx={{ width: 800, height: 430 }}>
                <React.Fragment>
                  <Box
                    sx={{
                      mt: 2,
                      height: state.dataToSave.hasDependant ? 320 : 0,
                      width: "100%",
                    }}
                  >
                    <div>
                      <Typography variant="subtitle1" gutterBottom color="red">
                        Dependants Section
                      </Typography>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <div key={index} style={{ paddingBottom: "15px" }}>
                          <React.Fragment key={index}>
                            <Grid container spacing={0}>
                              <Grid item xs={6} sm={2}>
                                <TextField
                                  required
                                  id={`fullname-${index}`}
                                  name={`fullname-${index}`}
                                  defaultValue={
                                    state.dataToSave.dependantNames[index]
                                      .fullname
                                  }
                                  label={
                                    state.dataToSave.dependantNames[index]
                                      .dependantID > 0
                                      ? ""
                                      : "fullname"
                                  }
                                  variant={
                                    state.dataToSave.dependantNames[index]
                                      .dependantID > 0
                                      ? "filled"
                                      : "standard"
                                  }
                                  inputProps={{ maxLength: 40 }}
                                  size="small"
                                  style={{
                                    fontSize: "5px",
                                    padding: "0px",
                                    width: 120,
                                  }}
                                  disabled={state.dataToSave.editing}
                                  onChange={updateDependantNames}
                                />
                              </Grid>
                              <Grid item xs={6} sm={2}>
                                <TextField
                                  required
                                  id={`relationship-${index}`}
                                  name={`relationship-${index}`}
                                  defaultValue={
                                    state.dataToSave.dependantNames[index]
                                      .relationship
                                  }
                                  label={
                                    state.dataToSave.dependantNames[index]
                                      .dependantID > 0
                                      ? ""
                                      : "Relationship"
                                  }
                                  inputProps={{ maxLength: 20 }}
                                  variant={
                                    state.dataToSave.dependantNames[index]
                                      .dependantID > 0
                                      ? "filled"
                                      : "standard"
                                  }
                                  size="small"
                                  style={{
                                    fontSize: "5px",
                                    padding: "0px",
                                    width: 100,
                                  }}
                                  disabled={state.dataToSave.editing}
                                  onChange={updateDependantNames}
                                />
                              </Grid>
                              <Grid item xs={6} sm={2}>
                                <TextField
                                  required
                                  id={`sharePercent-${index}`}
                                  name={`sharePercent-${index}`}
                                  defaultValue={
                                    state.dataToSave.dependantNames[index]
                                      .sharePercent
                                  }
                                  label={
                                    state.dataToSave.dependantNames[index]
                                      .dependantID > 0
                                      ? ""
                                      : "%"
                                  }
                                  inputProps={{ maxLength: 20 }}
                                  variant={
                                    state.dataToSave.dependantNames[index]
                                      .dependantID > 0
                                      ? "filled"
                                      : "standard"
                                  }
                                  size="small"
                                  style={{
                                    fontSize: "5px",
                                    padding: "0px",
                                    width: 80,
                                  }}
                                  disabled={state.dataToSave.editing}
                                  onChange={updateDependantNames}
                                />
                              </Grid>
                              <Grid item xs={6} sm={2}>
                                <TextField
                                  required
                                  id={`telephone-${index}`}
                                  name={`telephone-${index}`}
                                  defaultValue={
                                    state.dataToSave.dependantNames[index]
                                      .telephone
                                  }
                                  label={
                                    state.dataToSave.dependantNames[index]
                                      .dependantID > 0
                                      ? ""
                                      : "Telephone"
                                  }
                                  inputProps={{ maxLength: 20 }}
                                  variant={
                                    state.dataToSave.dependantNames[index]
                                      .dependantID > 0
                                      ? "filled"
                                      : "standard"
                                  }
                                  size="small"
                                  style={{
                                    fontSize: "5px",
                                    padding: "0px",
                                    width: 100,
                                  }}
                                  disabled={state.dataToSave.editing}
                                  onChange={updateDependantNames}
                                />
                              </Grid>
                              <Grid item xs={6} sm={2}>
                                <TextField
                                  required
                                  id={`trustee-${index}`}
                                  name={`trustee-${index}`}
                                  defaultValue={
                                    state.dataToSave.dependantNames[index]
                                      .trustee
                                  }
                                  label={
                                    state.dataToSave.dependantNames[index]
                                      .dependantID > 0
                                      ? ""
                                      : "Trustee"
                                  }
                                  inputProps={{ maxLength: 20 }}
                                  variant={
                                    state.dataToSave.dependantNames[index]
                                      .dependantID > 0
                                      ? "filled"
                                      : "standard"
                                  }
                                  size="small"
                                  style={{
                                    fontSize: "5px",
                                    padding: "0px",
                                    width: 100,
                                  }}
                                  disabled={state.dataToSave.editing}
                                  onChange={updateDependantNames}
                                />
                              </Grid>
                              <Grid item sm={2}>
                                <TextField
                                  id={`dateOfBirth-${index}`}
                                  name={`dateOfBirth-${index}`}
                                  defaultValue={formatDate(
                                    state.dataToSave.dependantNames[index]
                                      .dateOfBirth
                                  )}
                                  label="DOB"
                                  type="date"
                                  sx={{ width: 150, height: 50 }}
                                  disabled={state.dataToSave.editing}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  style={{ fontSize: "5px", padding: "0px" }}
                                  onChange={updateDependantNames}
                                />
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        </div>
                      ))}
                    </div>
                  </Box>
                </React.Fragment>
              </Box>
            </Container>
          )}

          {activeTab === 2 && (
            <div style={{ padding: "15px" }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Avatar
                  alt="User Avatar"
                  src={
                    previewImage || userImageToDisplay || "/profile/default.jpg"
                  }
                  sx={{ width: 160, height: 160 }}
                />
              </Box>
              <div style={{ textAlign: "center", paddingTop: "10px" }}>
                <Box
                  component="span"
                  sx={(theme) => ({
                    backgroundColor: theme.palette.success.dark,
                    borderRadius: "0.25rem",
                    color: "#fff",
                    maxWidth: "25ch",
                    p: "0.25rem",
                  })}
                >
                  {state.dataToSave.policyNumber}
                </Box>
              </div>
              <>
                <input
                  type="file"
                  accept=".bmp, .jpeg, .jpg, .png"
                  style={{ display: "none" }}
                  ref={profileInputRef}
                  onChange={handleFileInputChange}
                />
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <Button
                    variant="outlined"
                    startIcon={<Edit />}
                    onClick={handleButtonClick}
                  >
                    Change Pofile Photo
                  </Button>
                </Box>
              </>
              <Typography variant="subtitle1" gutterBottom color="red">
                If you need to upload any files relating to this customer then
                please do that here.
              </Typography>
              <Grid container spacing={2}>
                {filesToDisplay &&
                  filesToDisplay.map((imageUrl, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <Card>
                        {imageUrl.isImage ? (
                          <CardActionArea
                            onClick={() => handleImageClick(imageUrl.url)}
                          >
                            <CardMedia
                              component="img"
                              alt={`Image ${index + 1}`}
                              height="140"
                              image={imageUrl.url}
                            />
                          </CardActionArea>
                        ) : (
                          <CardContent>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {`File ${index + 1}`}
                            </Typography>
                            <Button
                              onClick={() => handleDownload(imageUrl.url)}
                            >
                              Download
                            </Button>
                          </CardContent>
                        )}
                      </Card>
                    </Grid>
                  ))}
              </Grid>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "30px",
                  paddingBottom: "30px",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleUploadButtonClick}
                  style={{ marginRight: "10px" }}
                >
                  Upload Files
                </Button>
                <label htmlFor="files-upload">
                  {filesToUpload.length > 0
                    ? `${filesToUpload.length} files selected`
                    : "No files selected"}
                </label>
                <input
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="image/*, application/pdf"
                  id="files-upload"
                  type="file"
                  onChange={handleEvidenceChange}
                  multiple // Allow multiple file selection
                />
              </div>
              <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Expanded Image</DialogTitle>
                <DialogContent>
                  <img
                    src={selectedImage}
                    alt="Expanded"
                    style={{ width: "100%", height: "auto" }}
                  />
                </DialogContent>
              </Dialog>
            </div>
          )}
          {activeTab === 3 && props.existingUser?.policyNumber && (
            <>
              <Box display="flex" justifyContent="flex-end" pr={2} mb={2}>
                <Button
                  color="primary"
                  onClick={handlePaymentAdd}
                  endIcon={<FileDownloadIcon />}
                  variant="outlined"
                  sx={{ width: 250 }}
                >
                  Add Payment
                </Button>
              </Box>
              <GLPayments PolicyNo={String(props.existingUser?.policyNumber)} />
              {addPayment && (
                <AddPayment
                  type={2}
                  handleClose={handleCloseAdd}
                  PolicyNo={props.existingUser.policyNumber}
                />
              )}
            </>
          )}
          {activeTab === 4 && props.existingUser?.policyNumber && (
            <LoanDetails policyNo={String(props.existingUser?.policyNumber)} />
          )}
          {activeTab !== 3 && activeTab !== 4 && (
            <div style={{ display: "flex" }}>
              <Button
                variant="contained"
                onClick={() =>
                  activeTab === 2
                    ? handleSave()
                    : activeTab === 0
                    ? setActiveTab(1)
                    : setActiveTab(2)
                }
                fullWidth={false}
                sx={{ left: "700px", bottom: "10px", width: "100px" }}
              >
                {activeTab === 2 ? `Save All` : `Next`}
              </Button>
              <Button
                variant="contained"
                onClick={() =>
                  activeTab === 0
                    ? handleClose()
                    : activeTab === 2
                    ? setActiveTab(1)
                    : setActiveTab(0)
                }
                fullWidth={false}
                sx={(theme) => ({
                  backgroundColor: theme.palette.secondary.dark,
                  right: "80px",
                  bottom: "10px",
                  width: "100px",
                })}
              >
                {activeTab === 0 ? `Close` : `Back`}
              </Button>
            </div>
          )}
        </BootstrapDialog>
      </Container>

      {
        <Dialog open={errors} onClose={handleErrrClose}>
          <DialogTitle>Information</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The form is incomplete. Please make sure the following fields are
              completed, use N/A if not applicable. Profile Image MUST be
              provided
            </DialogContentText>
            <ul>
              <li>Title</li>
              <li>First Name</li>
              <li>Last Name</li>
              <li>Gender</li>
              <li>Email</li>
              <li>Telephone</li>
              <li>Residential Address</li>
              <li>Country</li>
              <li>City</li>
              <li>Occupation</li>
              <li>Employer Name</li>
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleErrrClose}>Ok</Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
};
const checkIfImage = (fileName: string): boolean => {
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"]; // Add more if needed
  const lowerCaseFileName = fileName.toLowerCase();
  return imageExtensions.some((ext) => lowerCaseFileName.endsWith(ext));
};

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  // Check if the date is valid
  if (!isNaN(date.getTime())) {
    // Assuming you want the date in the format YYYY-MM-DD
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  } else {
    // Handle the case where the date is invalid
    return ""; // or another default value
  }
};

const handleDownload = (fileUrl: string) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = getFileNameFromUrl(fileUrl); // Assuming you have a function to extract the file name from the URL
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const getFileNameFromUrl = (url: string) => {
  const pathSegments = url.split("/");
  return pathSegments[pathSegments.length - 1];
};
export default React.memo(AddNewGLUser);
