import { useState } from "react";

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    if(tokenString && tokenString.length>0) {
      const userToken = JSON.parse(tokenString);
      return userToken
    }
    return undefined;
  };


  const [token, setToken] = useState(getToken());

  const saveToken = () => {
    //sessionStorage.setItem('token', JSON.stringify(userToken));
    setToken(token);
  };

  return {
    setToken: saveToken,
    token
  }
}