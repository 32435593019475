import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IUploadFile {
    token: string;
    file: File;
    policy?: string;
    userid?: number;
    UploadFileTypeID: number;
    marketerID?: string;
    ClaimRef?: string;
    OtherDocs?: string;
    Description?: string;
}

const baseURL = apiURL();

const uploadFile = async (props: IUploadFile) => {
    let value = 'success';
    const extensionToNumber: { [key: string]: number } = {
        'jpg': 1,
        'jpeg': 1,
        'png': 3,
        'pdf': 4,
        'doc': 5,
        'xls': 6
    };
    const fileName = props.file!.name;
    const fileExtension = fileName.split('.').pop()!.toLowerCase(); // Get the file extension and convert to lowercase
    const uploadFileMetaTypeID = extensionToNumber[fileExtension] || 0; // Default to 0 if not found

    try {
        const formData = new FormData();
        formData.append('File', props.file);
        formData.append('UploadFileTypeID', String(props.UploadFileTypeID));
        formData.append('UploadFileMetaTypeID', String(uploadFileMetaTypeID));
        formData.append('Policy', props.policy || '');
        formData.append('UserId', String(props.userid || 0));
        formData.append('MarketerID', props.marketerID || '');
        formData.append('ClaimRef', props.ClaimRef || 'None');
        formData.append('OtherDocs', props.OtherDocs || 'None');
        formData.append('Description', props.Description || 'None');
        const token = props.token;

        const response = await axios.post(`${baseURL}Upload/UploadFile`, formData, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "multipart/form-data", // Specify the content type
            },
        });

        if (response.status !== 200) {
            value = "Upload failed";
        }
    } catch (error: AxiosError | any) {
        if (error.response) {
            value = error.response.data || "Unknown error";
        } else if (error.request) {
            value = "No response from server";
        } else {
            value = "Something went wrong";
        }
    }

    return value;
};

export default uploadFile;