import axios from "axios";
import apiURL from "../apiURL";

export interface IHOUserDataRow {
    MarketerID: string,
    fullname: string,
    Gender: string,
    Telephone: string
    RegDate: string
}

export interface IHOUserData {
    data: IHOUserDataRow[],
}
interface IGetUser {
    token: string;
}
const baseURL = apiURL()
const GetEmployeeUserData = async (props: IGetUser) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + "users/getHeadoffice",
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IHOUserData;

}
export default GetEmployeeUserData;