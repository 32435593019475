import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IClaimsProps {
  ClaimRef: string;
  PolicyNo: string;
  claimDetails: string;
  Whoentered: string;
  token: string;
  ClaimType: number;
  ClaimAmount: number;
  AmountOverRide: number;
  OverRideInfo: string;
  checkedOutstanding: number;
  OverRideOutstanding: string;
  checkedDeduct: number;
}

const baseURL = apiURL();

const AddClaim = async (props: IClaimsProps) => {
  let value = "";
  const token = props.token;
  const data = {
    ClaimRef: props.ClaimRef,
    PolicyNo: props.PolicyNo,
    Whoentered: props.Whoentered,
    claimDetails: props.claimDetails,
    ClaimType: props.ClaimType,
    ClaimAmount: props.ClaimAmount,
    AmountOverRide: props.AmountOverRide,
    OverRideInfo: props.OverRideInfo,
    OutstandingRemoved: props.checkedOutstanding,
    OverRideOutstandingInfo: props.OverRideOutstanding,
    RemoveCardCost: props.checkedDeduct,
  };
  try {
    const response = await axios.post(baseURL + "Claim/addClaim", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      value = "success";
    }
  } catch (error: AxiosError | any) {
    if (error.response) {
      value = error.response.data;
    } else if (error.request) {
      value = "No response from server";
    } else {
      value = "Something went wrong";
    }
  }
  return value;
};
export default AddClaim;
