
export const MaritalOptions = ["Married", "Single", "Separated", "Divorced", "Widowed"];
export interface IDriverData {
    fullname: string,
    driverTelephone: string,
}
export const driversListRow: IDriverData[] = [
    {
        fullname: '',
        driverTelephone: '',
    },
    {
        fullname: '',
        driverTelephone: '',
    },
    {
        fullname: '',
        driverTelephone: '',
    },
    {
        fullname: '',
        driverTelephone: '',
    }
]