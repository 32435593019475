import axios from "axios";
import apiURL from "../apiURL";

export interface IVehicleTypeRow {
    vehicleTypeId: number,
    vehicleType: string
    vehicleCapacity: string
    insurancePrice: number,
}

export interface IVehicleTypesData {
    data: IVehicleTypeRow[],
}
interface IGetMedPack {
    token: string;
}
const baseURL = apiURL()
const GetVehicleTypes = async (props: IGetMedPack) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + "vehicleTypes",
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IVehicleTypesData;

}
export default GetVehicleTypes;