import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { IClaimDataRow } from "../../../services/API/GetClaims";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../Medical/AddNewUserStepper";
import { format } from "date-fns";
import updateClaim from "../../../services/API/UpdateClaim";
import useToken from "../../useToken";
import jwt_decode from "jwt-decode";
import { ISignInData } from "../../../services/API/signin";
import AlertDialog from "../../../Common/AlertDialog";
import { IDialogSave } from "../FrontDeskPayment/PaymentData";
import ApproveClaim from "../../../services/API/ApproveClaim";
import GetClaimEvidence from "../../../services/API/GetClaimEvidence";
import GetImage from "../../../services/API/GetImage";
import uploadFile, { IUploadFile } from "../../../services/API/UploadFile";
import {
  GroupDeathCover,
  claimTypes,
  fambulDeathCover,
} from "../../../Common/ClaimsData";
import RejectClaim from "../../../services/API/RejectClaim";

interface IClaims {
  row: IClaimDataRow;
  onClose: (val?: boolean) => void;
}

const ClaimDetails = (props: IClaims) => {
  const token = useToken().token;
  const userData: ISignInData = jwt_decode(token);
  const [checked, setChecked] = useState<boolean>(props.row.AmountOverRide);
  const [checkedOutstanding, setCheckedOutstanding] = useState<boolean>(
    props.row.OutstandingRemoved
  );
  const [checkedDeduct, setCheckedDeduct] = useState<boolean>(
    props.row.RemoveCardCost
  );
  const [formData, setFormData] = useState({
    PolicyNo: props.row.PolicyNo,
    FullName: props.row.FullName,
    ClaimDetails: props.row.ClaimDetails,
    ClaimDate: new Date(props.row.ClaimDate),
    RegDate: new Date(props.row.RegDate),
    Whoentered: props.row.Whoentered,
    Approved: props.row.Approved,
    ApproveBy: props.row.ApproveBy,
    ClaimRef: props.row.ClaimRef,
    Insurancetype: props.row.Insurancetype,
    ClaimType: props.row.ClaimType,
    ClaimAmount: props.row.ClaimAmount,
    Rejected: props.row.Rejected,
    RejectedBy: props.row.RejectedBy,
    totalContribution: props.row.totalContribution,
    Premium: props.row.Premium,
    Address: props.row.Address,
    maxLoanAvaliable: props.row.maxLoanAvaliable,
    numberOfWeekdayOnScheme: props.row.numberOfWeekdayOnScheme,
    outStanding: Number(
      (
        props.row.totalContribution -
        (props.row.Premium || 0) * props.row.numberOfWeekdayOnScheme
      ).toFixed(2)
    ),
    AmountOverRide: props.row.AmountOverRide,
    OverRideInfo: props.row.OverRideInfo,
    ApproveRejectDate: new Date(props.row.ApproveRejectDate),
    OutstandingRemoved: props.row.OutstandingRemoved,
    OverRideOutstandingInfo: props.row.OverRideOutstandingInfo,
    loanBalance: props.row.loanBalance,
  });

  interface IDialogSaveEx extends IDialogSave {
    type?: number; //1 for approve 0 for reject
  }
  const [showDaily, setShowDaily] = React.useState<IDialogSaveEx>({
    open: false,
    headerText: "",
    mainText: "",
  });
  const [activeTab, setActiveTab] = useState(0);
  const [filesToDisplay, setFilesToDisplay] = useState<
    { url: string; isImage: boolean; description: string }[] | undefined
  >();
  const [evidence, setEvidence] = useState<File[]>([]);
  const [fileCounts, setFileCounts] = useState<{ [key: string]: number }>({});
  const fileInputRefs = useRef<{
    [key: string]: React.MutableRefObject<HTMLInputElement | null>;
  }>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const claimEvidenceResponse = await GetClaimEvidence({
          token,
          ClaimRef: formData.ClaimRef,
          PolicyNo: formData.PolicyNo,
        });

        if (claimEvidenceResponse) {
          const claimEvidenceData = claimEvidenceResponse.data;

          // Assuming claimEvidenceData is an array of rows
          const filePromises = claimEvidenceData.map(async (row) => {
            const imageResponse = await GetImage({
              token,
              subfolder: row.uploadFileTypes,
              imageName: row.filesToShow,
            });

            if (imageResponse) {
              const fileType = row.filesToShow.toLowerCase().endsWith(".pdf")
                ? "application/pdf"
                : "image/jpeg" || "image/png";
              const blob = new Blob([imageResponse.data], { type: fileType });
              const fileUrl = URL.createObjectURL(blob);
              return {
                url: fileUrl,
                isImage: checkIfImage(row.filesToShow),
                description: row.description,
              };
            }

            return null; // or handle error scenario if needed
          });

          // Wait for all promises to resolve
          const filesData = await Promise.all(filePromises);

          // Filter out null values (failed requests)
          const validFilesData = filesData.filter((file) => file !== null) as {
            url: string;
            isImage: boolean;
            description: string;
          }[];

          setFilesToDisplay(validFilesData);
        }
      } catch (error) {
        // Handle errors here
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token, formData.ClaimRef, formData.PolicyNo]);

  const selectedClaimTypeObj = claimTypes.find(
    (claimType) => claimType.number === formData.ClaimType
  );

  const Calnumber = formData.Insurancetype === 1 ? 240 : 12;
  useEffect(() => {
    selectedClaimTypeObj?.requiredDocuments.forEach((document) => {
      fileInputRefs.current[document] = React.createRef<HTMLInputElement>();
    });
  }, [selectedClaimTypeObj]);
  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any
  ) => {
    setActiveTab(value);
  };
  const handleCancel = () => {
    setShowDaily({
      open: false,
      headerText: "",
      mainText: "",
    });
  };

  const handleApproveReject = (approve: boolean) => {
    setShowDaily({
      open: true,
      headerText: `Are you Sure?`,
      mainText: approve
        ? `You are about to approve the claim for Policy Number "${formData.PolicyNo}". Please make sure all required documents have been accounted for. This Loan will show up in the customer Payment section.`
        : `You are about to reject the claim for Policy Number "${formData.PolicyNo}". Please make sure you have added the reason in the claims details.`,
      type: approve ? 1 : 0,
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "ClaimAmount") {
      if (!isNaN(Number(value))) {
        // If it's a number, check if it exceeds the maximum loan available
        const maxLoanAvailable = formData.maxLoanAvaliable || 0;
        if (parseFloat(value) <= maxLoanAvailable || checked) {
          // If it's within the allowed range, update the state
          setFormData({
            ...formData,
            [name]: value,
          });
        } else {
          // If it exceeds the maximum, set the loan amount to the maximum available
          setFormData({
            ...formData,
            [name]: maxLoanAvailable.toString(),
          });
        }
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleEvidenceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    document: string
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setEvidence({
        ...evidence,
        [document]: Array.from(files),
      });
      setFileCounts((prevCounts) => ({
        ...prevCounts,
        [document]: files.length,
      }));
    }
  };

  const handleSubmitMoreEvidence = async () => {
    const filesToUpload: { file: File; document: string }[] = [];
    for (const document in evidence) {
      if (evidence.hasOwnProperty(document)) {
        const files = evidence[document];
        if (Array.isArray(files) && files.length > 0) {
          // Push each file and its corresponding document to filesToUpload
          filesToUpload.push(
            ...Array.from(files).map((file: File) => ({ file, document }))
          );
        }
      }
    }

    for (const { file, document } of filesToUpload) {
      const dataToUpload: IUploadFile = {
        file,
        policy: formData.PolicyNo,
        UploadFileTypeID: formData.Insurancetype,
        token,
        ClaimRef: formData.ClaimRef,
        Description: document,
      };
      await uploadFile(dataToUpload);
    }
    handleClose(true);
  };

  const handleUploadButtonClick = (document: string) => {
    // Get the reference to the corresponding file input based on the document name
    const fileInputRefForDocument = fileInputRefs.current[document];
    if (fileInputRefForDocument && fileInputRefForDocument.current) {
      fileInputRefForDocument.current.click();
    }
  };

  const getClaimAmount = () => {
    let claimAmount = Number(formData.ClaimAmount);
    if (formData.ClaimType === 2) {
      claimAmount =
        getAmount() -
        formData.loanBalance -
        (checkedOutstanding
          ? 0
          : formData.outStanding >= 0
          ? 0
          : Math.abs(formData.outStanding));
    }
    if (formData.ClaimType === 3 || formData.ClaimType === 4) {
      claimAmount =
        (formData.totalContribution ?? 0) * 0.4 +
        (formData.ClaimType === 4
          ? 0.15 * ((formData.totalContribution ?? 0) * 0.4)
          : 0) -
        formData.loanBalance -
        (checkedOutstanding
          ? 0
          : formData.outStanding >= 0
          ? 0
          : Math.abs(formData.outStanding));
    }
    return claimAmount;
  };

  const handleUpdate = async () => {
    const ClaimRef = formData.ClaimRef;
    const PolicyNo = formData.PolicyNo;
    const claimDetails = formData.ClaimDetails;
    const ClaimAmount = getClaimAmount();
    const OverRideInfo = checked
      ? `Loan Amount was overridden from ${
          formData.maxLoanAvaliable.toLocaleString("en-SL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " SLE"
        } to ${
          formData.ClaimAmount.toLocaleString("en-SL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " SLE"
        } by ${userData.DisplayName}`
      : "0";
    const AmountOverRide = checked ? 1 : 0;
    const OverRideOutstanding = checkedOutstanding
      ? `Outstanding has been removed by ${userData.DisplayName}`
      : "0";
    const OutstandingRemoved = checkedOutstanding ? 1 : 0;
    const RemoveCardCost = checkedDeduct ? 1 : 0;
    await updateClaim({
      ClaimRef,
      PolicyNo,
      claimDetails,
      ClaimAmount,
      token,
      OverRideInfo,
      AmountOverRide,
      OverRideOutstanding,
      OutstandingRemoved,
      RemoveCardCost,
    });
    handleClose(true);
  };

  const Approve = async () => {
    const ClaimRef = formData.ClaimRef;
    const PolicyNo = formData.PolicyNo;
    const ApproveBy = userData.DisplayName;
    const ClaimDetails = formData.ClaimDetails;
    const ClaimAmount = getClaimAmount();
    const OutStandingAtAproval = Number(Math.abs(formData.outStanding));
    const OverRideInfo = checked
      ? `Loan Amount was overridden from ${
          formData.maxLoanAvaliable.toLocaleString("en-SL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " SLE"
        } to ${
          formData.ClaimAmount.toLocaleString("en-SL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " SLE"
        } by ${userData.DisplayName}`
      : "0";
    const AmountOverRide = checked ? 1 : 0;
    const OverRideOutstanding = checkedOutstanding
      ? `Outstanding has been removed by ${userData.DisplayName}`
      : "0";
    const OutstandingRemoved = checkedOutstanding ? 1 : 0;
    const RemoveCardCost = checkedDeduct ? 1 : 0;

    await ApproveClaim({
      ClaimRef,
      PolicyNo,
      ApproveBy,
      ClaimDetails,
      ClaimAmount,
      token,
      OutStandingAtAproval,
      OverRideInfo,
      AmountOverRide,
      OverRideOutstanding,
      OutstandingRemoved,
      RemoveCardCost,
    });
    handleClose(true);
  };

  const Reject = async () => {
    const ClaimRef = formData.ClaimRef;
    const PolicyNo = formData.PolicyNo;
    const RejectedBy = userData.DisplayName;
    const ClaimDetails = formData.ClaimDetails;
    const ClaimAmount = getClaimAmount();
    await RejectClaim({
      ClaimRef,
      PolicyNo,
      RejectedBy,
      ClaimDetails,
      ClaimAmount,
      token,
    });
    handleClose(true);
  };

  const handleClose = (val?: boolean) => {
    props.onClose(val);
  };

  const formatDate = (date: Date) => {
    return format(date, "dd-MM-yyyy HH:mm");
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState({
    imageUrl: "",
    name: "",
  });

  // Function to handle click on an image
  const handleImageClick = (imageUrl: string, name: string) => {
    setSelectedImage({ imageUrl, name });
    setOpenDialog(true);
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getAmount = () => {
    if (formData.Insurancetype === 4) {
      return (
        GroupDeathCover.find((cover) => cover.premium === formData.Premium)
          ?.cover ?? 0
      );
    } else {
      return (
        fambulDeathCover.find(
          (cover) => cover.premium / 20 === formData.Premium
        )?.cover ?? 0
      );
    }
  };

  const renderClaimTypeSection = () => {
    const sizeGrid = formData.ClaimType === 4 ? 2.4 : 3;
    switch (formData.ClaimType) {
      case 1:
        return (
          <>
            <Grid item xs={12} sx={{ paddingLeft: 2 }}>
              <>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <FormGroup sx={{ paddingTop: 2 }}>
                      <FormControlLabel
                        sx={{ paddingLeft: 1, marginY: -1 }}
                        label="Overide Amount"
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={(e) => {
                              setChecked(e.target.checked);
                              if (!e.target.checked) {
                                // Reset claimAmount when the checkbox is unchecked
                                setFormData({
                                  ...formData,
                                  ClaimAmount: formData.maxLoanAvaliable,
                                });
                              }
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                            disabled={formData.Approved || formData.Rejected}
                          />
                        }
                      />
                      <FormControlLabel
                        sx={{ paddingLeft: 1, marginY: -1 }}
                        label="Remove Outstanding"
                        control={
                          <Checkbox
                            checked={checkedOutstanding}
                            onChange={(e) => {
                              setCheckedOutstanding(e.target.checked);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                            disabled={formData.Approved || formData.Rejected}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      name="ClaimAmount"
                      label="Loan Amount (Must be 50% or less of Total Contribution)"
                      variant="outlined"
                      value={formData.ClaimAmount}
                      onChange={handleInputChange}
                      margin="normal"
                      type="number"
                      inputProps={{
                        inputMode: "numeric",
                      }}
                      InputProps={{
                        inputProps: {
                          max: formData.maxLoanAvaliable || 0,
                        },
                      }}
                      disabled={formData.Approved || formData.Rejected}
                    />
                  </Grid>
                </Grid>
                {formData.AmountOverRide && (
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    color="red"
                    component="p"
                  >
                    {formData.OverRideInfo}
                  </Typography>
                )}
                {formData.OutstandingRemoved && (
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    color="red"
                    component="p"
                  >
                    {formData.OverRideOutstandingInfo}
                  </Typography>
                )}
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    <TextField
                      variant="outlined"
                      label="10%"
                      value={(Number(formData.ClaimAmount) * 0.1).toFixed(2)}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={2.5}>
                    <TextField
                      variant="outlined"
                      label="Loan + 10%"
                      value={(
                        Number(formData.ClaimAmount) +
                        Number(formData.ClaimAmount) * 0.1
                      ).toFixed(2)}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={2.5}>
                    <TextField
                      variant="outlined"
                      label="New Premium"
                      value={
                        //The new premium is the claim amount plus the interest divided by the 240 for fambul or 12 for group life
                        (
                          (Number(formData.ClaimAmount) +
                            Number(formData.ClaimAmount) * 0.1) /
                            Calnumber +
                          (formData.Premium || 0)
                        ).toFixed(2)
                      }
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={2.5}>
                    <TextField
                      variant="outlined"
                      label="Outstanding"
                      value={
                        formData.outStanding >= 0
                          ? 0
                          : Math.abs(formData.outStanding)
                      }
                      disabled={true}
                      style={{
                        backgroundColor:
                          formData.outStanding >= 0 ? "#90ee90" : "#ffcccb",
                      }}
                    />
                  </Grid>
                  <Grid item xs={2.5}>
                    <TextField
                      variant="outlined"
                      label="Repayment"
                      value={
                        //The Repayment is the loan plus interest and any oustandign if the have any or is not overridden
                        (
                          Number(formData.ClaimAmount) +
                          Number(formData.ClaimAmount) * 0.1 +
                          (checkedOutstanding
                            ? 0
                            : formData.outStanding >= 0
                            ? 0
                            : Math.abs(formData.outStanding))
                        ).toFixed(2)
                      }
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </>
            </Grid>
          </>
        );
      case 2:
        return (
          <div style={{ paddingTop: 10 }}>
            <Grid container spacing={0}>
              <Grid item xs={3} sx={{ paddingLeft: 2 }}>
                <Grid container spacing={0}>
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      label="Cover Amount"
                      value={getAmount()}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">SLE</Typography>
                          </InputAdornment>
                        ),
                      }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="h4" color="green">
                      -
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container spacing={0}>
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      label="Out Loan"
                      value={formData.loanBalance}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">SLE</Typography>
                          </InputAdornment>
                        ),
                      }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="h4" color="green">
                      -
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <TextField
                      variant="outlined"
                      label="Out Balance"
                      value={
                        formData.outStanding >= 0
                          ? 0
                          : Math.abs(formData.outStanding)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">SLE</Typography>
                          </InputAdornment>
                        ),
                      }}
                      disabled={true}
                      style={{
                        backgroundColor:
                          formData.outStanding >= 0 ? "#90ee90" : "#ffcccb",
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h4" color="green">
                      =
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label="Amount to Payout"
                  value={
                    getAmount() -
                    formData.loanBalance -
                    (checkedOutstanding
                      ? 0
                      : formData.outStanding >= 0
                      ? 0
                      : Math.abs(formData.outStanding))
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">SLE</Typography>
                      </InputAdornment>
                    ),
                  }}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </div>
        );
      case 3:
      case 4:
        return (
          <div style={{ paddingTop: 10 }}>
            <Grid container spacing={1} sx={{ paddingLeft: 2 }}>
              <Grid item xs={sizeGrid}>
                <Grid container spacing={0}>
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      label="40% Of Contribution"
                      value={(formData.totalContribution ?? 0) * 0.4}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">SLE</Typography>
                          </InputAdornment>
                        ),
                      }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {formData.ClaimType === 3 ? (
                      <Typography variant="h4" color="green">
                        -
                      </Typography>
                    ) : (
                      <Typography variant="h4" color="green">
                        +
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {formData.ClaimType === 4 && (
                <Grid item xs={sizeGrid}>
                  <Grid container spacing={0.5}>
                    <Grid item xs={11}>
                      <TextField
                        variant="outlined"
                        label="15%"
                        value={(formData.totalContribution ?? 0) * 0.4 * 0.15}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography variant="caption">SLE</Typography>
                            </InputAdornment>
                          ),
                        }}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="h4" color="green">
                        -
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={sizeGrid}>
                <Grid container spacing={0.5}>
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      label="Out Loan"
                      value={formData.loanBalance}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">SLE</Typography>
                          </InputAdornment>
                        ),
                      }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="h4" color="green">
                      -
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={sizeGrid}>
                <Grid container spacing={0}>
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      label="Out Balance"
                      value={
                        formData.outStanding >= 0
                          ? 0
                          : Math.abs(formData.outStanding)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">SLE</Typography>
                          </InputAdornment>
                        ),
                      }}
                      disabled={true}
                      sx={{
                        backgroundColor:
                          formData.outStanding >= 0 ? "#90ee90" : "#ffcccb",
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="h4" color="green">
                      =
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={sizeGrid}>
                <TextField
                  variant="outlined"
                  label="Amount to Payout"
                  value={
                    (formData.totalContribution ?? 0) * 0.4 +
                    (formData.ClaimType === 4
                      ? 0.15 * ((formData.totalContribution ?? 0) * 0.4)
                      : 0) -
                    formData.loanBalance -
                    (checkedOutstanding
                      ? 0
                      : formData.outStanding >= 0
                      ? 0
                      : Math.abs(formData.outStanding)) -
                    (checkedDeduct ? 50 : 0)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">SLE</Typography>
                      </InputAdornment>
                    ),
                  }}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </div>
        );
      default:
        return null;
    }
  };
  const claimTypeName = claimTypes.find(
    (type) => type.number === formData.ClaimType
  );
  return (
    <Container sx={{ mt: 8, mb: 9 }}>
      <BootstrapDialog
        open={true}
        fullWidth
        disableEscapeKeyDown={true}
        hideBackdrop={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          wizard="true"
        >
          {`Claims - ${claimTypeName?.label}`}
        </BootstrapDialogTitle>
        <Tabs value={activeTab} onChange={handleTabChange} centered>
          <Tab label="Details" />
          <Tab label="Evidence" />
        </Tabs>

        {activeTab === 0 && (
          <Grid container spacing={2} sx={{ padding: 1 }}>
            <Grid item xs={3}>
              <TextField
                label="Policy No"
                name="PolicyNo"
                value={formData.PolicyNo}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Full Name"
                name="FullName"
                value={formData.FullName}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Reg Date"
                name="RegDate"
                value={formatDate(formData.RegDate).slice(0, 10)}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Claim Date"
                name="ClaimDate"
                value={formatDate(formData.ClaimDate).slice(0, 10)}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="Premium"
                value={
                  formData.Premium.toLocaleString("en-SL", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + " SLE"
                }
                disabled={true}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                label="Total Contribution"
                value={
                  formData.totalContribution.toLocaleString("en-SL", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + " SLE"
                }
                disabled={true}
              />
            </Grid>
            {formData.ClaimType !== 1 && (
              <Grid item xs={5}>
                <FormControlLabel
                  sx={{ paddingLeft: 1, marginY: -1 }}
                  label="Remove Outstanding"
                  control={
                    <Checkbox
                      checked={checkedOutstanding}
                      onChange={(e) => {
                        setCheckedOutstanding(e.target.checked);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={formData.Approved || formData.Rejected}
                    />
                  }
                />
                {formData.ClaimType === 3 && (
                  <FormControlLabel
                    sx={{ paddingLeft: 1, marginY: -1 }}
                    required
                    control={
                      <Checkbox
                        checked={checkedDeduct}
                        onChange={(e) => {
                          setCheckedDeduct(e.target.checked);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={formData.Approved || formData.Rejected}
                      />
                    }
                    label="-50 SLE"
                  />
                )}
              </Grid>
            )}
            <Grid item xs={formData.ClaimType !== 1 ? 7 : 12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Address"
                value={formData.Address}
                disabled={true}
              />
            </Grid>
            <Grid container spacing={0}>
              {renderClaimTypeSection()}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Claim Details"
                name="ClaimDetails"
                value={formData.ClaimDetails}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Created By"
                name="Created By"
                value={formData.Whoentered}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              {(formData.Approved || formData.Rejected) && (
                <TextField
                  label="Approve/Rejected By"
                  name="Approve By"
                  value={`${formData.ApproveBy || formData.RejectedBy} on ${
                    formData.ApproveRejectDate
                      ? formatDate(formData.ApproveRejectDate).slice(0, 10)
                      : ""
                  }`}
                  disabled
                  fullWidth
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdate}
                fullWidth
              >
                Update
              </Button>
            </Grid>
            {!formData.Approved && !formData.Rejected && (
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  disabled={userData.CanApproveClaims === "False"}
                  color="error"
                  onClick={() => {
                    handleApproveReject(false);
                  }}
                  fullWidth
                >
                  Save & Reject
                </Button>
              </Grid>
            )}
            {!formData.Approved && !formData.Rejected && (
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  disabled={userData.CanApproveClaims === "False"}
                  color="success"
                  onClick={() => {
                    handleApproveReject(true);
                  }}
                  fullWidth
                >
                  Save & Approve
                </Button>
              </Grid>
            )}
          </Grid>
        )}

        <div style={{ padding: "25px" }}>
          {activeTab === 1 && (
            <div>
              <Grid container spacing={2}>
                {filesToDisplay &&
                  filesToDisplay.map((imageUrl, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <Card>
                        {imageUrl.isImage ? (
                          <>
                            {imageUrl.description.slice(0, 18)}
                            <CardActionArea
                              onClick={() =>
                                handleImageClick(
                                  imageUrl.url,
                                  imageUrl.description
                                )
                              }
                            >
                              <CardMedia
                                component="img"
                                alt={`Image ${index + 1}`}
                                height="140"
                                image={imageUrl.url}
                              />
                            </CardActionArea>
                          </>
                        ) : (
                          <CardContent>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {imageUrl.description.slice(0, 18)}
                            </Typography>
                            <Button
                              onClick={() => handleDownload(imageUrl.url)}
                            >
                              Download
                            </Button>
                          </CardContent>
                        )}
                      </Card>
                    </Grid>
                  ))}
              </Grid>
              <>
                {selectedClaimTypeObj &&
                  selectedClaimTypeObj.requiredDocuments.map(
                    (document: string, index: number) => {
                      return (
                        <div
                          key={index}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => handleUploadButtonClick(document)}
                            style={{ marginRight: "10px" }}
                            size="small"
                          >
                            Upload {document}
                          </Button>
                          <label htmlFor={`evidence-upload-${index}`}>
                            <div style={{ marginTop: "5px" }} />
                            {fileCounts[document] && fileCounts[document] > 0
                              ? `file Added`
                              : "No files selected"}
                          </label>
                          <input
                            ref={fileInputRefs.current[document]}
                            style={{ display: "none" }}
                            accept="image/*, application/pdf"
                            id={`evidence-upload-${index}`}
                            type="file"
                            onChange={(event) =>
                              handleEvidenceChange(event, document)
                            }
                          />
                        </div>
                      );
                    }
                  )}
                {Object.keys(fileCounts).length > 0 && (
                  <Button
                    variant="contained"
                    onClick={handleSubmitMoreEvidence}
                    style={{ marginRight: "10px" }}
                  >
                    Save New Evidence
                  </Button>
                )}
              </>
              <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{selectedImage.name}</DialogTitle>
                <DialogContent>
                  <img
                    src={selectedImage.imageUrl}
                    alt="Expanded"
                    style={{ width: "100%", height: "auto" }}
                  />
                </DialogContent>
              </Dialog>
            </div>
          )}
        </div>
        {showDaily && (
          <AlertDialog
            open={showDaily.open}
            setOpen={handleCancel}
            setSave={showDaily.type === 1 ? Approve : Reject}
            headerText={showDaily.headerText}
            mainText={showDaily.mainText}
          />
        )}
      </BootstrapDialog>
    </Container>
  );
};

const checkIfImage = (fileName: string): boolean => {
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"]; // Add more if needed
  const lowerCaseFileName = fileName.toLowerCase();
  return imageExtensions.some((ext) => lowerCaseFileName.endsWith(ext));
};

const handleDownload = (fileUrl: string) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = getFileNameFromUrl(fileUrl); // Assuming you have a function to extract the file name from the URL
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Helper function to get the file name from a URL
const getFileNameFromUrl = (url: string) => {
  const pathSegments = url.split("/");
  return pathSegments[pathSegments.length - 1];
};
export default ClaimDetails;
