import axios from "axios";
import apiURL from "../apiURL";

export interface IFambulDependantsRow {
  Firstname: string;
  Middlename: string;
  Lastname: string;
  Gender: string;
  DOB: Date;
  Address: string;
  Telephone: string;
  Relationship: string;
  PercentageShared: number;
  Trustee: string;
  TrusteeNumber: string;
  NomineeID: number;
  IDCode: string;
}

export interface IFambulDependantsData {
  data: IFambulDependantsRow[];
}
interface IGetUser {
  token: string;
  idCode: string;
}
const baseURL = apiURL();
const GetFambulDependants = async (props: IGetUser) => {
  const token = props.token;
  const data = await axios({
    method: "get",
    responseType: "json",
    url: baseURL + `fambulDetails/getFambulDependants?idCode=${props.idCode}`,
    headers: { Authorization: `Bearer ${token}` },
  });
  return data as IFambulDependantsData;
};
export default GetFambulDependants;
