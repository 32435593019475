import axios from "axios";
import apiURL from "../apiURL";

export interface IGLPremiumsRow {
    GLPremiumsID: number,
    GLPremiumLevel: string,
}

export interface IGLPremiumsData {
    data: IGLPremiumsRow[],
}
interface IGetRegistered {
    token: string;
}
const baseURL = apiURL()
const GetGLPremiums = async (props: IGetRegistered) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + `GroupLifeEdit/GetGLPremiums`,
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IGLPremiumsData;

}
export default GetGLPremiums;