import React, { useEffect } from 'react';
import useToken from '../../useToken';
import { Card, CardContent, Container, Typography } from '@mui/material';
import { BootstrapDialog, BootstrapDialogTitle } from '../Medical/AddNewUserStepper';
import GetAllRegistered, { IMarketerRegDataRow } from '../../../services/API/GetAllRegistered';

interface TabPanelProps {
    openUser: boolean;
    onClose: () => void;
    marketerID: string
}
const CustomerCard = (data: IMarketerRegDataRow) => {
    return (
        <Card key={data.IDCode} sx={{ backgroundColor: '#f5f5f5', borderRadius: '12px', marginBottom: '16px' }}>
            <CardContent>
                <Typography variant="subtitle2" gutterBottom>
                    <span style={{ fontWeight: 'bold' }}>
                        Customer ID:
                    </span>
                    <span style={{ backgroundColor: 'green', borderRadius: '4px', padding: '2px' }}>
                        {data.IDCode}
                    </span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    <span style={{ fontWeight: 'bold' }}>Name:</span> {data.name}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    <span style={{ fontWeight: 'bold' }}>Telephone:</span> {data.Telephone}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    <span style={{ fontWeight: 'bold' }}>Reg Date:</span> {data.RegDate.slice(0, 10)}
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    <span style={{ fontWeight: 'bold' }}>
                        Total Payment:
                    </span>
                    <span style={{ backgroundColor: 'yellow', borderRadius: '4px', padding: '2px' }}>
                        {data.totalPayment ? Number(data.totalPayment).toLocaleString('en-SL', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) + ' SLL' : Number(0).toLocaleString('en-SL', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) + ' SLL'}
                    </span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    <span style={{ fontWeight: 'bold' }}>
                        Outstanding:
                    </span>
                    <span style={{ backgroundColor: `${Number(data.Arrear) > 0 ? 'red' : 'lightgreen'}`, borderRadius: '4px', padding: '2px' }}>
                        {Number(data.Arrear).toLocaleString('en-SL', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) + ' SLL'}
                    </span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    <span style={{ fontWeight: 'bold' }}>Last Paid:</span> {data.lastPayDate ? data.lastPayDate.slice(0, 10) : ''}
                </Typography>
            </CardContent>
        </Card>
    );
};

const YourFambulList = (props: TabPanelProps) => {
    const token = useToken().token;
    const [data, setData] = React.useState<IMarketerRegDataRow[] | null>(null);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<string | null>(null);
    const mobileBreakpoint = 600;
    const isMobile = window.innerWidth < mobileBreakpoint;
    const marketerID = props.marketerID;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await GetAllRegistered({ token, marketerID });
                setData(response.data);
            } catch (error) {
                setError('Error fetching data. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [token, marketerID]);

    const handleClose = () => {
        props.onClose();
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 8, mb: 9 }}>
            <BootstrapDialog
                open={props.openUser}
                fullWidth
                maxWidth="lg"
                disableEscapeKeyDown={true}
                hideBackdrop={true}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {'Your Insured'}
                </BootstrapDialogTitle>
                <div className="scroll-container" style={{ overflow: isMobile ? 'scroll' : 'auto', maxHeight: '80vh', padding: '5px' }}>
                    {loading && <Typography>Loading...</Typography>}
                    {error && <Typography color="error">{error}</Typography>}
                    {data && data.map((rowData) => (
                        CustomerCard(rowData)
                    ))}
                </div>
            </BootstrapDialog>
        </Container>
    );
};

export default YourFambulList;
