import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";

const baseURL = apiURL();

export interface IGetRequestAssignToRow {
  requestID: number;
  employeeID: number;
  fullName: string;
}

export interface IGetRequestAssignToData {
  data: IGetRequestAssignToRow[];
}
interface IGetRequestAssignToList {
  token: string;
  requestID: number;
}
const GetRequestAssignTo = async (props: IGetRequestAssignToList) => {
  const token = props.token;

  try {
    const data = await axios({
      method: "get",
      responseType: "json",
      url:
        baseURL +
        `ExpenseRequest/GetRequestAssignTo?requestID=${props.requestID}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return data as IGetRequestAssignToData;
  } catch (error) {
    // Call the handleAxiosError function with the error and redirect URL
    handleAxiosError(error, "/login"); // Adjust the URL as needed
    return undefined;
  }
};
export default GetRequestAssignTo;
