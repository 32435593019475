import axios from "axios";
import apiURL from "../apiURL";

export interface IOtherDocsDataRow {
    filesToShow: string,
    uploadFileTypes: string,
}

export interface IOtherDocsData {
    data: IOtherDocsDataRow[],
}
interface IGetRegistered {
    token: string;
    OtherDocs: string;
    PolicyNo: string;
}
const baseURL = apiURL()
const GetOtherDocs = async (props: IGetRegistered) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + `GroupLifeEdit/GetOtherDocs?PolicyNo=${props.PolicyNo}&OtherDocs=${props.OtherDocs}`,
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IOtherDocsData;

}
export default GetOtherDocs;