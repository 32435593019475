import axios from "axios";
import apiURL from "../apiURL";

export interface IUserGroupDataRow {
    EmployeeTypeID: number,
    EmployeeTypeName: string,
    EmployeeTypeDescription: string,
    IsEnable: boolean,
    CanViewInsurance: boolean,
    CanViewAdmin: boolean,
    CanViewReport: boolean,
    CanEditInsurance: boolean,
    CanEditAdmin: boolean,
    CanManageOtherInsurance: boolean,
    CanViewMedicalInsurance: boolean,
    CanEditMedicalInsurance: boolean,
    CanViewMotorInsurance: boolean,
    CanEditMotorInsurance: boolean,
    IsExternalSaleTeam: boolean,
    CanViewPaymentReceipt: boolean,
    CanEditPaymentReceipt: boolean,
    CanAcknowledgePayment: boolean,
    CanApproveClaims: boolean
}

export interface IUserGroupData {
    data: IUserGroupDataRow[],
}
interface IGetUser {
    token: string;
}
const baseURL = apiURL()
const GetUserGroupData = async (props: IGetUser) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + "users/getGroups",
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IUserGroupData;

}
export default GetUserGroupData;