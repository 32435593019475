import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import useToken from "./components/useToken";
import { Box } from "@mui/material";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import ResetUser from "./components/ResetUser";
import ForgottenPassword from "./components/ForgottenPassword";
import IconLabelTabs from './components/Dashboard/Medical/MedicalInsurance';
import MarketerProfilePage from './components/UserProfile/MarketerProfilePage';

const ProtectedRoute = () => {
  const { token } = useToken(); // determine if authorized, from context or however you're doing it
  return token ? <Outlet /> : <Navigate to="/login" />;
}

const App: React.FC = () => {
  return (
    <Box sx={(theme) => ({
      overflow: "auto",
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: theme.palette.primary.main,
      },
    })}>
      <Routes>
        <Route path="" element={<ProtectedRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/MarketerProfilePage" element={<MarketerProfilePage />} />
          <Route path="/MedicalInsurance" element={<IconLabelTabs />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/ResetUser" element={<ResetUser />} />
        <Route path="/ForgottenPassword" element={<ForgottenPassword />} />
      </Routes>
    </Box>
  );
};

export default App;
