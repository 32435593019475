import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import GetFambulData, {
  IFambulDataRow,
} from "../../../services/API/GetFambulData";
import useToken from "../../useToken";
import MaterialReactTable, {
  type MRT_ColumnDef,
  type MRT_Virtualizer,
} from "material-react-table";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import FambulDetails from "./FambulDetails";
import AddFambul from "./AddFambul";

const FambulData = () => {
  const columns = useMemo<MRT_ColumnDef<IFambulDataRow>[]>(
    () => [
      {
        accessorKey: "fullname",
        header: "Full Name",
        Cell: ({ renderedCellValue, row }) => (
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Avatar
              alt="Remy Sharp"
              src={
                row.original.imageBinary === 1
                  ? "/profile/default.jpg"
                  : "/profile/noImage.png"
              }
              sx={{ width: "1rem", height: "1rem" }}
            />
            {renderedCellValue}
          </Box>
        ),
        size: 200,
      },
      {
        accessorKey: "idCode",
        header: "Unique Code",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {cell.getValue()}
          </Box>
        ),
        size: 150,
      },
      {
        accessorKey: "dob",
        header: "DOB",
        Cell: ({ cell }) => (cell.getValue() as string).slice(0, 10),
        size: 100,
      },
      {
        accessorKey: "regDate",
        header: "Reg Date",
        Cell: ({ cell }) => (cell.getValue() as string).slice(0, 10),
        size: 100,
      },
      {
        accessorKey: "telephone",
        header: "Phone Number",
      },
      {
        accessorKey: "premium",
        header: "Premium",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.secondary.light,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {`${
              Number(cell.getValue()).toLocaleString("en-SL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " SLL"
            }`}
          </Box>
        ),
        size: 150,
      },
      {
        accessorKey: "countPayments",
        header: "# Payments",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() === 0
                  ? theme.palette.error.dark
                  : theme.palette.success.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {cell.getValue()}
          </Box>
        ),
        size: 150,
      },
      {
        accessorKey: "totalPayment",
        header: "Total Amount",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.secondary.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {`${
              Number(cell.getValue()).toLocaleString("en-SL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " SLL"
            }`}
          </Box>
        ),
        size: 150,
      },
      {
        accessorKey: "shouldHavePaid",
        header: "Expected Amount",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: isCompliant
                ? theme.palette.success.light
                : theme.palette.error.main,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {`${
              Number(cell.getValue()).toLocaleString("en-SL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " SLL"
            }`}
          </Box>
        ),
        size: 150,
      },
      {
        accessorKey: "lastPayDate",
        header: "Last Pay Date",
        size: 150,
      },
    ],
    []
  );
  const token = useToken().token;
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [fambulData, setFambulData] = useState<IFambulDataRow[]>([]);
  const tableContainerRef = useRef<HTMLDivElement>(null); //we can get access to the underlying TableContainer element and react to its scroll events
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
  const [rowSelection, setRowSelection] = useState<IFambulDataRow | undefined>(
    undefined
  );
  const [addNewCustomer, setAddNewCustomer] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [isCompliant, setIsCompliant] = useState<number>(1);
  const [choice, setChoice] = useState("Complaint");

  const fetchData = useCallback(
    async (pageToFetch: number) => {
      setIsLoading(true);
      try {
        const newData = await GetFambulData({
          token,
          page: pageToFetch,
          isCompliant,
        });
        if (newData) {
          if (newData.data.length === 0) {
            setHasMore(false);
          } else {
            setReload(false);
            setFambulData((prevData) => [...prevData, ...newData.data]);
            setPage(pageToFetch + 1);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [token, page, isCompliant, reload]
  );

  const handleClose = (val?: boolean) => {
    if (val) {
      setPage(1); // Reset page
      setFambulData([]); // Reset data
      setHasMore(true); // Reset hasMore
      fetchData(1);
      setReload(true);
    }
    setAddNewCustomer(false);
    setRowSelection(undefined);
    setOpen(false);
  };

  const handleRowSelected = (row: IFambulDataRow) => {
    setOpen(true);
    setRowSelection(row);
  };

  // const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
  const totalFetched = fambulData.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 400 &&
          !isLoading &&
          hasMore
        ) {
          fetchData(page);
        }
      }
    },
    [isLoading, hasMore, fetchData]
  );

  useEffect(() => {
    const containerElement = tableContainerRef.current;
    if (containerElement) {
      const handleScroll = () => fetchMoreOnBottomReached(containerElement);
      containerElement.addEventListener("scroll", handleScroll);
      return () => {
        containerElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    setPage(1); // Reset page
    setFambulData([]); // Reset data
    setHasMore(true); // Reset hasMore
    fetchData(1);
  }, [isCompliant]);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    const filteredData = fambulData.map((item) => ({
      fullname: item.fullname,
      idCode: item.idCode,
      dob: item.dob,
      regDate: item.regDate,
      telephone: item.telephone,
      premium: item.premium,
      countPayments: item.countPayments,
      totalPayment: item.totalPayment,
      shouldHavePaid: item.shouldHavePaid,
      lastPayDate: item.lastPayDate,
    }));
    csvExporter.generateCsv(filteredData);
  };

  const handleAdd = () => {
    setAddNewCustomer(true);
    setOpen(true);
  };

  const handleChangeDropDown = (event: any) => {
    const value = event.target.value;
    setChoice(value);
    setIsCompliant(value === "Complaint" ? 1 : 0);
  };

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={fambulData}
        initialState={{ density: "compact" }}
        enablePagination={false}
        enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleRowSelected(row.original),
          sx: { cursor: "pointer" },
        })}
        muiTableContainerProps={{
          ref: tableContainerRef, //get access to the table container element
          sx: {
            height:
              fambulData.length > 20
                ? window.innerHeight - 300 + "px"
                : undefined,
            overflow: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "0.4em",
              height: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#1976d2",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#1976d2",
            },
          },
          onScroll: (event: React.UIEvent<HTMLDivElement>) => {
            const target = event.target as HTMLDivElement;
            fetchMoreOnBottomReached(target);
          },
        }}
        renderBottomToolbarCustomActions={() => (
          <Typography>Fetched {totalFetched} rows.</Typography>
        )}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //get access to the virtualizer instance
        rowVirtualizerProps={{ overscan: 4 }}
        enableMultiRowSelection={false} //use radio buttons instead of checkboxes
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableHiding={false}
        enableRowNumbers={false}
        enableGlobalFilter={false} // disable global search
        enableFilters={false} // disable column filters
        renderTopToolbarCustomActions={() => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.3rem", // Reduced gap
              p: "0.3rem", // Reduced padding
              flexWrap: "wrap",
              height: "50px", // Reduced height
            }}
          >
            <Button
              color="primary"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleAdd}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
            >
              Add New
            </Button>
            <Button
              color="primary"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
            >
              {"Export Data"}
            </Button>
            <FormControl
              sx={(theme) => ({
                minWidth: "200px",
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: "8px",
              })}
            >
              <InputLabel
                id="select-year-label"
                sx={(theme) => ({ color: theme.palette.secondary.dark })}
              >
                Fambul Type
              </InputLabel>
              <Select
                labelId="dropdown-label"
                name="choice"
                value={choice}
                label="Expense Type"
                sx={{ fontSize: "0.8rem" }}
                onChange={handleChangeDropDown}
              >
                <MenuItem value="Complaint">Complaint</MenuItem>
                <MenuItem value="Non-Complaint">Non-Complaint</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
      />
      {open && rowSelection && (
        <FambulDetails
          openUser={open}
          onClose={handleClose}
          idCode={rowSelection.idCode}
        />
      )}
      {open && addNewCustomer && (
        <AddFambul openUser={open} onClose={handleClose} />
      )}
    </>
  );
};
export default FambulData;
