import * as React from "react";
import { styled } from "@mui/material/styles";
import { DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NoteIcon from "@mui/icons-material/Note";
import { IExpenseRequestRow } from "../services/API/GetExpenseRequests";
import NotesDialog from "./NotesDialog";

export const SlimScrollbarDialogContent = styled(DialogContent)(
  ({ theme }) => ({
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "10px", // Increased width of the scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#009aa6", // Color of the scrollbar thumb
      borderRadius: "8px", // Rounded corners of the thumb
    },
  })
);

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: (val?: boolean) => void;
  headerColor: string;
  expenseData?: IExpenseRequestRow | undefined;
}

export function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, headerColor, expenseData, ...other } = props;
  const [openNotesDialog, setOpenNotesDialog] = React.useState(false);

  const handleOpenNotesDialog = () => {
    setOpenNotesDialog(true);
  };

  const handleCloseNotesDialog = () => {
    setOpenNotesDialog(false);
  };

  return (
    <>
      <DialogTitle
        sx={(theme) => ({
          background: headerColor ? headerColor : theme.palette.grey[300],
        })}
        {...other}
      >
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[900],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        {expenseData && (
          <Tooltip title="View Notes" arrow>
            <IconButton
              aria-label="notes"
              onClick={handleOpenNotesDialog}
              sx={{
                position: "absolute",
                right: 48, // Adjust the position as needed
                top: 8,
                color: (theme) => theme.palette.grey[100],
              }}
            >
              <NoteIcon />
            </IconButton>
          </Tooltip>
        )}
      </DialogTitle>
      <NotesDialog
        open={openNotesDialog}
        onClose={handleCloseNotesDialog}
        onCloseParent={onClose}
        expenseData={expenseData ?? undefined}
      />
    </>
  );
}
