import React, { useEffect, useMemo } from 'react';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import useToken from '../../useToken';
import { Box, Button } from '@mui/material';
import GetMedicalData, { IMedicalCustomer } from '../../../services/API/GetMedicalData';
import AddNewUser from "./AddNewUser";
import jwt_decode from "jwt-decode";
import { ISignInData } from '../../../services/API/signin';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';

interface IMedDataProps {
  onClose: (val: boolean) => void;
}

const MedicalData = (props: IMedDataProps) => {
  const columns = useMemo<MRT_ColumnDef<IMedicalCustomer>[]>(
    () => [
      {
        accessorKey: 'fullname', //access nested data with dot notation
        header: 'Fullname',
      },
      {
        accessorKey: 'city',
        header: 'City',
      },
      {
        accessorKey: 'dateOfBirth',
        header: 'Date Of Birth',
      },
      {
        accessorKey: 'gender',
        header: 'Gender',
      },
      {
        accessorKey: 'package', //normal accessorKey
        header: 'Current Package',
      },
      {
        accessorKey: 'premium',
        header: 'Premium',
      },
      {
        accessorKey: 'insuranceStatus',
        header: 'Status',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: cell.getValue() === 'Active' ? theme.palette.success.dark : theme.palette.error.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
    ],
    [],
  );
  const token = useToken().token;
  const [rowSelection, setRowSelection] = React.useState<IMedicalCustomer | undefined>(undefined);
  const [data, setData] = React.useState<IMedicalCustomer[]>([]);
  const [open, onClose] = React.useState(false);
  const userData: ISignInData = jwt_decode(token);
  const handleClose = () => {
    onClose(false);
  };
  const HandleRowSelected = (row: IMedicalCustomer) => {
    onClose(true)
    setRowSelection(row)
  }
  useEffect(() => {
    GetMedicalData({ token }).then((response) => {
      if (response) {
        setData(response.data);
      }
    });
  }, [token]);

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };
  return <> <MaterialReactTable
    columns={columns}
    data={data}
    enableMultiRowSelection={false} //use radio buttons instead of checkboxes
    muiTableBodyRowProps={({ row }) => ({
      onClick: () => HandleRowSelected(row.original),
      sx: { cursor: 'pointer' },
    })}
    enableDensityToggle={false}
    initialState={{ density: 'compact', pagination: { pageIndex: 0, pageSize: window.innerHeight > 720 ? 15 : 10 }, }}
    muiTablePaginationProps={{
      rowsPerPageOptions: [5, 10, 20, 50],
    }}
    muiTableContainerProps={{
      sx: {
        overflow: "auto",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          width: "0.4em",
          height: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#1976d2",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#1976d2",
        },
      },
    }}
    enableFullScreenToggle={false}
    enableHiding={false}
    rowCount={data.length}
    renderTopToolbarCustomActions={() => (
      <Box
        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
      >
        <Button
          color="info"
          onClick={() => props.onClose(true)}
          variant="contained"
          disabled={userData.CanEditMedicalInsurance === "False"}
        >
          Add New Customer
        </Button>
        <Button
          color="primary"
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
          variant="contained"
        >
          Export Medical Data
        </Button>
      </Box>
    )} />
    {open && <AddNewUser openUser={open} onClose={handleClose} existingUser={rowSelection} />}
  </>
};
export default MedicalData;