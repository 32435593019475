import * as React from "react";
import Tab from "@mui/material/Tab";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { Box } from "@mui/material";
import MotorData from "./MotorData";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

export default function IconLabelTabs() {
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div className="tabs-container" style={{ marginTop: '65px', position: 'fixed', width: '100%', zIndex: 1 }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              icon={<GroupAddIcon />}
              label="Active Customers"
              value="1"
            />
            <Tab
              icon={<RecordVoiceOverIcon />}
              label="Potential Customers"
              value="2"
              style={{ backgroundColor: 'Amber' }}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <main style={{ height: window.innerHeight - 150 + 'px', overflowX: 'hidden', marginRight: '230px' }}>
            <MotorData isActive={true} />
          </main>
        </TabPanel>
        <TabPanel value="2">
          <main style={{
            height: window.innerHeight - 150 + 'px', overflowX: 'hidden', marginRight: '230px'
          }}>
            <MotorData isActive={false} />
          </main>
        </TabPanel>
      </TabContext>
    </div >
  );
}
