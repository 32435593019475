import GetGLDuplicateCount from "../../../services/API/GetGLDuplicateCount";
import GetGLPremiums, {
  IGLPremiumsRow,
} from "../../../services/API/GetGLPremiums";
import { IGroupLifeDataRow } from "../../../services/API/GetGroupLifeData";
import useToken from "../../useToken";
import { GLDependantRows, IGLDependantData } from "./AddNewGLUserStateless";
import { useReducer, useEffect } from "react";
interface ITextFieldData {
  editing: boolean;
  glcustomersID?: number;
  title: string;
  email: string;
  dob: string;
  glPremiumLevel: string;
  gender: string;
  residentialAddress: string;
  telephoneNumber: string;
  occupation: string;
  employer: string;
  payrollNumber: string;
  policyNumber: string;
  firstName: string;
  lastName: string;
  countDep: number;
  country: string;
  city: string;
  hasDependant: boolean;
  dependantNames: IGLDependantData[];
  isDuplicate: boolean;
  premiumOption: string[];
}

export interface IActiveUserState {
  dataToSave: ITextFieldData;
}
export const initialState: IActiveUserState = {
  dataToSave: {
    title: "",
    editing: false,
    firstName: "",
    lastName: "",
    email: "",
    glcustomersID: -1,
    glPremiumLevel: "",
    countDep: 0,
    dob: new Date().toISOString().slice(0, 10),
    gender: "male",
    residentialAddress: "",
    city: "",
    country: "",
    telephoneNumber: "",
    occupation: "",
    employer: "",
    payrollNumber: "",
    policyNumber: "",
    hasDependant: false,
    dependantNames: GLDependantRows,
    isDuplicate: false,
    premiumOption: [],
  },
};

export type ActionType =
  | { type: "TOGGLE_DEPENDANT"; payload: boolean }
  | { type: "UPDATE_FIELD"; payload: { name: string; value: string | boolean } }
  | {
      type: "UPDATE_DEPENDANT_NAMES";
      payload: { depName: string; depValue: string };
    }
  | { type: "UPDATE_EXISTING_USER"; payload: IGroupLifeDataRow }
  | {
      type: "UPDATE_DUPLICATE";
      payload: { duplicate: number; policyNumber: string };
    }
  | { type: "SET_PREMIUM_LIST"; payload: IGLPremiumsRow[] }
  | { type: "SET_DEPENDANT_LIST"; payload: IGLDependantData[] };

export const reducer = (state: IActiveUserState, action: ActionType) => {
  switch (action.type) {
    case "TOGGLE_DEPENDANT":
      return {
        ...state,
        dataToSave: { ...state.dataToSave, hasDependant: action.payload },
      };
    case "UPDATE_FIELD":
      const { name, value } = action.payload;
      return {
        ...state,
        dataToSave: {
          ...state.dataToSave,
          [name]: typeof value === "string" ? value.trim() : value,
        },
      };
    case "UPDATE_DEPENDANT_NAMES":
      const { depName, depValue } = action.payload;
      const nameInData = depName.split("-")[0];
      const rowInData = depName.split("-")[1];
      const updatedDependantNames: IGLDependantData[] =
        state.dataToSave.dependantNames.map((dep: any, index: number) => {
          if (index === Number(rowInData)) {
            return {
              ...dep,
              [nameInData]: depValue.trim(),
            };
          } else {
            return dep;
          }
        });
      return {
        ...state,
        dataToSave: {
          ...state.dataToSave,
          dependantNames: updatedDependantNames,
        },
      };
    case "UPDATE_EXISTING_USER":
      return {
        ...state,
        dataToSave: {
          ...state.dataToSave,
          editing: false,
          glcustomersID: action.payload.glcustomersID,
          title: action.payload.title,
          email: action.payload.email,
          dob: new Date(action.payload.dob).toISOString().slice(0, 10),
          glPremiumLevel: action.payload.glPremiumLevel,
          gender: action.payload.gender,
          residentialAddress: action.payload.residentialAddress,
          telephoneNumber: action.payload.telephoneNumber,
          occupation: action.payload.occupation,
          employer: action.payload.employer,
          payrollNumber: action.payload.payrollNumber,
          policyNumber: action.payload.policyNumber,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          countDep: action.payload.countDep,
          country: action.payload.country,
          city: action.payload.city,
          hasDependant: action.payload.countDep > 0,
        },
      };
    case "UPDATE_DUPLICATE":
      const { duplicate, policyNumber } = action.payload;
      return {
        ...state,
        dataToSave: {
          ...state.dataToSave,
          isDuplicate: duplicate >= 1,
          policyNumber: policyNumber,
        },
      };

    case "SET_DEPENDANT_LIST":
      const { payload } = action;
      if (payload.length > 0) {
        const updatedGLDependantRows = payload;
        //if updatedGLDependantRows is 1 row then remove 1 from GLDependantRows and so on
        const startIndex = 0;
        const numberOfItemsToRemove = GLDependantRows.length;
        const newGLDependantRows = GLDependantRows.slice(
          startIndex,
          numberOfItemsToRemove
        );
        // Replace the first rows of newGLDependantRows with updatedGLDependantRows
        newGLDependantRows.splice(
          0,
          updatedGLDependantRows.length,
          ...updatedGLDependantRows
        );
        return {
          ...state,
          dataToSave: {
            ...state.dataToSave,
            dependantNames: newGLDependantRows,
          },
        };
      } else {
        return state;
      }
    case "SET_PREMIUM_LIST":
      return {
        ...state,
        dataToSave: {
          ...state.dataToSave,
          premiumOption: action.payload.map((row) => {
            return row.GLPremiumLevel;
          }),
        },
      };
    default:
      return state;
  }
};
export const GroupLifeReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const token = useToken().token;
  const glcustomersID = state.dataToSave.glcustomersID;
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "policyNumber") {
      try {
        const policyNumber = value;
        if (glcustomersID) {
          const response = await GetGLDuplicateCount({
            token,
            policyNumber,
            glcustomersID,
          });
          const count = response;
          dispatch({
            type: "UPDATE_DUPLICATE",
            payload: { duplicate: count, policyNumber: value },
          });
        }
      } catch (error) {
        console.error("Error checking for duplicates:", error);
      }
    } else {
      dispatch({ type: "UPDATE_FIELD", payload: { name, value: value } });
    }
  };

  const toggleDependant = (value: boolean) => {
    dispatch({ type: "TOGGLE_DEPENDANT", payload: value });
  };

  const updateDependantNames = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({
      type: "UPDATE_DEPENDANT_NAMES",
      payload: { depName: name, depValue: value },
    });
  };

  const updateStateForExistingUser = (existingUser: IGroupLifeDataRow) => {
    dispatch({ type: "UPDATE_EXISTING_USER", payload: existingUser });
  };

  const handleDropDownChange = (name: string, label: string) => {
    dispatch({ type: "UPDATE_FIELD", payload: { name, value: label } });
  };

  const fetchPremiums = (token: string) => {
    GetGLPremiums({ token }).then((response) => {
      if (response) {
        dispatch({ type: "SET_PREMIUM_LIST", payload: response.data });
      }
    });
  };

  useEffect(() => {
    fetchPremiums(token);
  }, [token]);

  const existingDependant = (data: IGLDependantData[]) => {
    dispatch({ type: "SET_DEPENDANT_LIST", payload: data });
  };
  return {
    state,
    handleChange,
    toggleDependant,
    updateDependantNames,
    updateStateForExistingUser,
    handleDropDownChange,
    existingDependant,
  };
};
