import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IAddLoanPaymentProps {
  PolicyNo: string;
  Whoentered: string;
  LoanAmount: number;
  RemainingAmount: number;
  token: string;
}

const baseURL = apiURL();

const AddLoan = async (props: IAddLoanPaymentProps) => {
  let value = "";
  const token = props.token;
  const data = {
    PolicyNo: props.PolicyNo,
    Whoentered: props.Whoentered,
    LoanAmount: props.LoanAmount,
    RemainingAmount: props.RemainingAmount,
  };
  try {
    const response = await axios.post(
      baseURL + "Claim/AddAllInOneClaim",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      value = "success";
    }
  } catch (error: AxiosError | any) {
    if (error.response) {
      value = error.response.data;
    } else if (error.request) {
      value = "No response from server";
    } else {
      value = "Something went wrong";
    }
  }
  return value;
};
export default AddLoan;
