import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface INotesRow {
  ExpenseRequestID: number;
  ExpenseRequestNote: string;
  EnteredBy: string;
  Date: Date;
  Type: number;
}

export interface INotesAdd {
  ExpenseRequestID: number;
  ExpenseRequestNote: string;
  EnteredBy: string;
  token: string;
  Type: number;
}

export interface INotesData {
  data: INotesRow[];
}

export interface IGetNotes {
  token: string;
  ExpenseRequestID: number;
}

const baseURL = apiURL();

export const getExpenseNotes = async (
  props: IGetNotes
): Promise<INotesData> => {
  const { token, ExpenseRequestID } = props;

  const data = await axios({
    method: "get",
    responseType: "json",
    url:
      baseURL +
      `ExpenseRequest/getExpenseNotes?ExpenseRequestID=${ExpenseRequestID}`,
    headers: { Authorization: `Bearer ${token}` },
  });
  return data as INotesData;
};

export const addExpenseNote = async (props: INotesAdd) => {
  let value = "";
  const token = props.token;
  const data = {
    ExpenseRequestID: props.ExpenseRequestID,
    ExpenseRequestNote: props.ExpenseRequestNote,
    EnteredBy: props.EnteredBy,
    Type: props.Type,
  };
  try {
    const response = await axios.post(
      baseURL + "ExpenseRequest/addExpenseNote",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      value = "success";
    }
  } catch (error: AxiosError | any) {
    if (error.response) {
      value = error.response.data;
    } else if (error.request) {
      value = "No response from server";
    } else {
      value = "Something went wrong";
    }
  }
  return value;
};
