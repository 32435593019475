import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";
import { checkSessionExpiration } from "../checkSessionExpiration";

export interface IDashboardRow {
  dateFrom: string;
  totalPaidFY: number;
  medical?: number;
  fambul?: number;
  motor?: number;
  groupLife?: number;
  others?: number;
  frontDesk?: number;
  claimsNeedAction?: number;
  expense?: number;
}

interface IProps {
  token: string;
}

const baseURL = apiURL();

const GetDashBoardData = async (
  props: IProps
): Promise<IDashboardRow | undefined> => {
  const token = props.token;

  // Check session expiration before proceeding
  if (!checkSessionExpiration()) {
    return;
  }

  try {
    const response = await axios({
      method: "get",
      responseType: "json",
      url: baseURL + "dashBoardData",
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data[0] as IDashboardRow;
  } catch (error) {
    // Call the handleAxiosError function with the error and redirect URL
    handleAxiosError(error, "/login"); // Adjust the URL as needed
    return undefined;
  }
};

export default GetDashBoardData;
