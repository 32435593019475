import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IFambulDataProps {
    NewIDCode: string,
    Firstname: string,
    Middlename: string,
    Lastname: string,
    DOB: Date,
    RegDate: string,
    Gender: string,
    Premium: number,
    Telephone: string,
    BusinessLocation: string,
    Address: string,
    District: string,
    DependantNames?: string,
    token: string,
    WhoEntered: string,
}


const baseURL = apiURL()

const AddFambulCustomer = async (props: IFambulDataProps) => {
    let value = ''
    const token = props.token
    const data = {
        NewIDCode: props.NewIDCode,
        Firstname: props.Firstname,
        Middlename: props.Middlename,
        Lastname: props.Lastname,
        DOB: props.DOB,
        Gender: props.Gender,
        Premium: props.Premium,
        Telephone: props.Telephone,
        BusinessLocation: props.BusinessLocation,
        Address: props.Address,
        District: props.District,
        DependantNames: props.DependantNames,
        RegDate: props.RegDate,
        WhoEntered: props.WhoEntered,
    }
    try {
        const response = await axios.post(baseURL + "Marketer/AddFambul", data, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if (response.status === 200) {
            value = "success"
        }
    } catch (error: AxiosError | any) {
        if (error.response) {
            value = error.response.data
        } else if (error.request) {
            value = "No response from server"
        } else {
            value = "Something went wrong"
        }
    }
    return value
}
export default AddFambulCustomer;

