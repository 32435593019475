import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";


interface IApprove {
    token: string;
    vehicleID: number
    vehicleOwnerID: number
}

const baseURL = apiURL();

const DeleteDuplicateMotor = async (props: IApprove) => {
    let value = ''
    const token = props.token
    const data = {
        VehicleID: props.vehicleID,
        VehicleOwnerID: props.vehicleOwnerID,
    }
    try {
        const response = await axios.post(baseURL + "motor/deleteDups", data, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if (response.status === 200) {
            value = "success"
        }
    } catch (error: AxiosError | any) {
        if (error.response) {
            value = error.response.data
        } else if (error.request) {
            value = "No response from server"
        } else {
            value = "Something went wrong"
        }
    }
    return value
}

export default DeleteDuplicateMotor;
