import axios from "axios";
import apiURL from "../apiURL";

export interface IMarketerProfileDataRow {
    DisplayName: string,
    Email: string,
    Telephone: string,
    registerCount: number,
    UserImageName: string,
}

export interface IMarketerProfileData {
    data: IMarketerProfileDataRow[],
}
interface IGetUser {
    token: string;
    marketerID: string;
}
const baseURL = apiURL()
const GetMarketerProfile = async (props: IGetUser) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + `Marketer/getMarketerProfile?marketerID=${props.marketerID}`,
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IMarketerProfileData;

}
export default GetMarketerProfile;