import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IRejectClaimsProps {
    ClaimRef: string,
    PolicyNo: string,
    RejectedBy: string,
    ClaimDetails: string,
    ClaimAmount: number,
    token: string,
}

const baseURL = apiURL()

const RejectClaim = async (props: IRejectClaimsProps) => {
    let value = ''
    const token = props.token
    const data = {
        ClaimRef: props.ClaimRef,
        PolicyNo: props.PolicyNo,
        RejectedBy: props.RejectedBy,
        ClaimDetails: props.ClaimDetails,
        ClaimAmount: props.ClaimAmount
    }
    try {
        const response = await axios.post(baseURL + "Claim/RejectClaim", data, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if (response.status === 200) {
            value = "success"
        }
    } catch (error: AxiosError | any) {
        if (error.response) {
            value = error.response.data
        } else if (error.request) {
            value = "No response from server"
        } else {
            value = "Something went wrong"
        }
    }
    return value
}
export default RejectClaim;

