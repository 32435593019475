import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";

const baseURL = apiURL();

export interface IGetExpenseTypesRow {
  expenseTypeID: number;
  expenseType: string;
  originalAmount: number;
  balance: number;
  dateLastUpdated: string;
}

export interface IGetExpenseTypesRowData {
  data: IGetExpenseTypesRow[];
}
interface IGetExpenseTypes {
  token: string;
  expenseTypeID: number;
}
const GetExpenseTypes = async (props: IGetExpenseTypes) => {
  const token = props.token;

  try {
    const data = await axios({
      method: "get",
      responseType: "json",
      url:
        baseURL +
        `ExpenseRequest/GetExpenseTypes?expenseTypeID=${props.expenseTypeID}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return data as IGetExpenseTypesRowData;
  } catch (error) {
    // Call the handleAxiosError function with the error and redirect URL
    handleAxiosError(error, "/login"); // Adjust the URL as needed
    return undefined;
  }
};
export default GetExpenseTypes;
