import React, { useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import apiURL from '../services/apiURL';
import useToken from '../components/useToken';


interface IUpload {
  handleClose: () => void,
}
interface AxiosError extends Error {
  response?: {
    data?: any;
    status?: number;
    headers?: any;
    config?: any;
  };
  request?: any;
  config?: any;
  isAxiosError: boolean;
}
interface IMessage {
  header: string
  message: string
}
const UploadFambulPayment = (props: IUpload) => {
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>("");
  const [error, setError] = useState<IMessage>({ header: "", message: "" });

  const onDrop = (acceptedFiles: File[]) => {
    // Do something with the file
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
      setFileName(acceptedFiles[0].name);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const baseURL = apiURL();
  const token = useToken().token;

  const handleSubmit = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        // Send the file to the backend for processing
        await axios.post(`${baseURL}UploadPayment/uploadPayment`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        setError({ header: 'Info', message: 'File uploaded successfully!' });
      } catch (error: any) {
        if ((error as AxiosError).response?.data === "An error occurred: Worksheet position out of range.") {
          setError({ header: 'Error', message: "Error uploading file. Please make sure you only have one sheet and is named 'Sheet1' and try again." });
        } else {
          setError({ header: 'Error', message: "Error uploading file. Make sure all fields are in the right format and try again." });
        }
      }
    } else {
      setError({ header: 'Error', message: "No file selected." });
    }
  };

  const handleCloseErrorDialog = () => {
    setError({ header: "", message: "" });
    props.handleClose()
  };

  return (
    <div>
      <Dialog open={true} onClose={props.handleClose}>
        <DialogTitle>Upload Fambul Payment</DialogTitle>
        <DialogContent>
          <div {...getRootProps()} style={{ border: '1px dashed black', padding: '20px', textAlign: 'center' }}>
            <input {...getInputProps()} />
            <p>{fileName ? `Selected File: ${fileName}` : "Drag 'n' drop an Excel file here, or click to select one"}</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={file === undefined}>Upload</Button>
        </DialogActions>
      </Dialog>
      {error.message && (
        <Dialog open={true} onClose={handleCloseErrorDialog}>
          <DialogTitle>{error.header}</DialogTitle>
          <DialogContent>{error.message}</DialogContent>
          <DialogActions>
            <Button onClick={handleCloseErrorDialog}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default UploadFambulPayment;