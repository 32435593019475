import "../../App.css";
import React, { useEffect } from "react";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import GetFambulChart, {
  IFambulChartDataRow,
} from "../../services/API/GetFambulChart";
import useToken from "../useToken";
import Title from "./Title";

const COLORS = ["#FF5733", "#FFC300", "#36A2EB", "#4BC0C0", "#9966FF"];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
export default function FambulChart() {
  const mobileBreakpoint = 600; // Example breakpoint for mobile devices
  const isMobile = window.innerWidth < mobileBreakpoint;
  const token = useToken().token;
  const [data, setData] = React.useState<IFambulChartDataRow[]>();

  useEffect(() => {
    GetFambulChart({ token }).then((response) => {
      if (response) {
        setData(response.data);
      }
    });
  }, [token]);

  const total = data && data.reduce((acc, cur) => acc + cur.value, 0);
  return (
    <React.Fragment>
      <Title>Life Customers - Total: {total}</Title>
      <PieChart width={300} height={250}>
        <Tooltip />
        <Pie
          data={data}
          cx={isMobile ? 120 : 150}
          cy={120}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data &&
            data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
        </Pie>
        <Legend
          iconType="line"
          formatter={(value, entry, index) => {
            return <span style={{ color: entry.color }}>{value}</span>;
          }}
          align="right"
          verticalAlign="top"
          height={36}
          layout="vertical"
          wrapperStyle={{
            position: "absolute",
            width: "auto",
            height: "36px",
            right: isMobile
              ? "30px"
              : window.innerHeight > 720
              ? "0px"
              : "25px",
            top: "-15px",
          }}
        />
      </PieChart>
    </React.Fragment>
  );
}
