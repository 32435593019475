import { IMedicalCustomer } from '../../../services/API/GetMedicalData';
import GetMedicalPackages, { IMedicalPackageRow } from '../../../services/API/GetMedicalPackages';
import GetMedicalQuestions, { IMedicalQuestionsRow } from '../../../services/API/GetMedicalQuestions';
import useToken from '../../useToken';
import { DependantRows, IDependantData, MaritalOptions } from './AddNewUserStateless';
import { useReducer, useEffect } from 'react';

interface IValidation {
    error: boolean;
    errorMsg: string
}
interface ITextFieldData {
    editing: boolean,
    medicalCustomersID?: number,
    insuranceStatus?: string,
    title: string,
    initials: string,
    firstName: string,
    middleName: string,
    lastName: string,
    email: string,
    dateOB: string,
    telephone: string,
    residentialAddress: string,
    country: string,
    weightKg: string,
    heightCm: string,
    insuranceExpiryDate: string,
    premium: number,
    newPremium: string,
    city: string,
    emergencyContactName: string,
    emergencyTelephoneNumber: string,
    Sex: string,
    MaritalStatus: string,
    Package: string,
    hasDependant: boolean,
    occupation: string,
    officeTelephone: string,
    employerName: string,
    employerAddress: string,
    passportNo: string,
    issuecountry: string,
    passportExpdate: string,
    dependantNames: IDependantData[],
    medPackage: IMedicalPackageRow[] | undefined,
    medOptions: string[],
    medQuestions: IMedicalQuestionsRow[],
    medSelectedValues: string[],
    medResponses: string[],
    validation: IValidation,
    specialCharater: string
}

export interface IActiveUserState {
    dataToSave: ITextFieldData;
}
export const initialState: IActiveUserState = {
    dataToSave: {
        title: '',
        editing: false,
        initials: '',
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        dateOB: new Date().toISOString().slice(0, 10),
        telephone: '',
        residentialAddress: '',
        country: '',
        weightKg: '',
        heightCm: '',
        insuranceExpiryDate: new Date().toISOString().slice(0, 10),
        premium: 0,
        newPremium: '',
        city: '',
        emergencyContactName: '',
        emergencyTelephoneNumber: '',
        Sex: "male",
        MaritalStatus: '',
        Package: '',
        hasDependant: false,
        occupation: '',
        officeTelephone: '',
        employerName: '',
        employerAddress: '',
        passportNo: '',
        issuecountry: '',
        passportExpdate: new Date().toISOString().slice(0, 10),
        dependantNames: DependantRows,
        medPackage: undefined,
        medOptions: [],
        medQuestions: [],
        medSelectedValues: [],
        medResponses: [],
        validation: { error: false, errorMsg: '' },
        specialCharater: "/^[^|¬#>]*$/"
    }
}

export type ActionType =
    | { type: 'SET_DROPDOWN_STATUS'; payload: { name: any; value: any } }
    | { type: 'TOGGLE_DEPENDANT'; payload: boolean }
    | { type: 'UPDATE_FIELD'; payload: { name: string; value: string | boolean } }
    | { type: 'SET_MED_PACKAGE'; payload: any }
    | { type: 'UPDATE_DEPENDANT_NAMES'; payload: { depName: string; depValue: string } }
    | { type: 'SET_MED_QUESTIONS'; payload: IMedicalQuestionsRow[] }
    | { type: 'UPDATE_MEDQUESTION_SELECTED'; payload: { selectIndex: number, selectValue: string } }
    | { type: 'UPDATE_MEDQUESTION_RESPONSES'; payload: { repIndex: number, repValue: string } }
    | { type: 'UPDATE_EXISTING_USER'; payload: IMedicalCustomer }
    | { type: 'SET_VALIDATION'; payload: { error: boolean, errorMsg: string } };

export const reducer = (state: IActiveUserState, action: ActionType) => {
    switch (action.type) {
        case 'SET_DROPDOWN_STATUS':
            let updatedDataToSave = { ...state.dataToSave };
            if (action.payload.name === 'Package' && updatedDataToSave.medPackage) {
                const matchingPackage = updatedDataToSave.medPackage.find(row => row.medicalInsurancePackage === action.payload.value);
                if (matchingPackage) {
                    updatedDataToSave = {
                        ...updatedDataToSave,
                        newPremium: matchingPackage.currentPremuim.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })
                    };
                }
            }
            return {
                ...state,
                dataToSave: {
                    ...updatedDataToSave,
                    [action.payload.name]: action.payload.value
                }
            };
        case 'TOGGLE_DEPENDANT':
            return { ...state, dataToSave: { ...state.dataToSave, hasDependant: action.payload } };
        case 'UPDATE_FIELD':
            const { name, value } = action.payload;
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    [name]: typeof value === 'boolean' ? value : typeof value === 'string' ? value.trim() : value
                },
            };
        case 'SET_MED_PACKAGE':
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave, medPackage: action.payload, medOptions: action.payload.map((row: IMedicalPackageRow) => {
                        return row.medicalInsurancePackage;
                    })
                },
            };
        case 'SET_MED_QUESTIONS':
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    medQuestions: action.payload.filter((row) => row.isActiveQuestion === true),
                    medSelectedValues: new Array(state.dataToSave.medQuestions.length).fill("No"),
                    medResponses: new Array(state.dataToSave.medQuestions.length).fill("")
                }
            };

        case 'UPDATE_DEPENDANT_NAMES':
            const { depName, depValue } = action.payload;
            const nameInData = depName.split("-")[0];
            const rowInData = depName.split("-")[1];
            const updatedDependantNames = state.dataToSave.dependantNames.map((dep: any, index: number) => {
                if (index === Number(rowInData)) {
                    return {
                        ...dep,
                        [nameInData]: depValue.trim()
                    }
                } else {
                    return dep;
                }
            });
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    dependantNames: updatedDependantNames
                },
            };
        case 'UPDATE_MEDQUESTION_SELECTED':
            const { selectIndex, selectValue } = action.payload;
            const newSelectedValues = [...state.dataToSave.medSelectedValues];
            newSelectedValues[selectIndex] = selectValue;
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    medSelectedValues: newSelectedValues
                }
            };
        case 'UPDATE_MEDQUESTION_RESPONSES':
            const { repIndex, repValue } = action.payload;
            const newResponses = [...state.dataToSave.medResponses];
            newResponses[repIndex] = repValue;
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    medResponses: newResponses
                }
            };
        case 'UPDATE_EXISTING_USER':
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    editing: true,
                    medicalCustomersID: action.payload.medicalCustomersID,
                    insuranceStatus: action.payload.insuranceStatus,
                    title: action.payload.title,
                    initials: action.payload.initials,
                    firstName: action.payload.firstName,
                    middleName: action.payload.middleNames,
                    lastName: action.payload.lastName,
                    email: action.payload.email,
                    dateOB: action.payload.dateOfBirth,
                    telephone: action.payload.telephoneNumber,
                    residentialAddress: action.payload.residentialAddress,
                    country: action.payload.country,
                    weightKg: action.payload.weightKg,
                    heightCm: action.payload.heightCm,
                    insuranceExpiryDate: action.payload.insuranceExpiryDate,
                    premium: action.payload.premium,
                    city: action.payload.city,
                    emergencyContactName: action.payload.emergencyContactName,
                    emergencyTelephoneNumber: action.payload.emergencyTelephoneNumber,
                    Sex: action.payload.gender,
                    MaritalStatus: action.payload.maritalStatus,
                    Package: action.payload.package,
                    occupation: action.payload.occupation,
                    officeTelephone: action.payload.officeTelephone,
                    employerName: action.payload.employerName,
                    employerAddress: action.payload.employerAddress,
                    passportNo: action.payload.passportNo,
                    issuecountry: action.payload.issuecountry,
                    passportExpdate: action.payload.passportExpdate
                }
            };
        case 'SET_VALIDATION':
            return { ...state, dataToSave: { ...state.dataToSave, validation: action.payload } };
        default:
            return state;
    }
};

export const MedUserReducer = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch({ type: 'UPDATE_FIELD', payload: { name, value: e.target.type === "checkbox" ? e.target.checked : value } });
    };

    const handleDropDownChange = (e: any) => {
        const text = e.target.id;
        const splitText = text.split("-");
        const splitText2 = text.split("-")[0];
        const optionIndex = parseInt(splitText[2]);
        let selectedOption
        if (splitText2 === "Package") {
            selectedOption = state.dataToSave.medOptions[optionIndex];
        } else {
            selectedOption = MaritalOptions[optionIndex];
        };
        dispatch({ type: 'SET_DROPDOWN_STATUS', payload: { name: splitText2, value: selectedOption } });

    };

    const toggleDependant = (value: boolean) => {
        dispatch({ type: 'TOGGLE_DEPENDANT', payload: value });
    };

    const fetchMedPackage = (token: string) => {
        GetMedicalPackages({ token }).then((response) => {
            if (response) {
                dispatch({ type: 'SET_MED_PACKAGE', payload: response.data.filter((rows) => rows.activePackage === true) });
            }
        });
    };

    const token = useToken().token;
    useEffect(() => {
        fetchMedPackage(token);
    }, [token]);


    const updateDependantNames = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch({ type: 'UPDATE_DEPENDANT_NAMES', payload: { depName: name, depValue: value } });
    };

    const fetchMedQuestions = (token: string) => {
        GetMedicalQuestions({ token }).then((response) => {
            if (response) {
                dispatch({ type: 'SET_MED_QUESTIONS', payload: response.data });
            }
        });
    };

    useEffect(() => {
        fetchMedQuestions(token);
    }, [token]);

    const handleRadioChange = (selectIndex: number, selectValue: string) => {
        dispatch({ type: 'UPDATE_MEDQUESTION_SELECTED', payload: { selectIndex: selectIndex, selectValue: selectValue } });
    };

    const handleTextChange = (repIndex: number, repValue: string) => {
        dispatch({ type: 'UPDATE_MEDQUESTION_RESPONSES', payload: { repIndex: repIndex, repValue: repValue } });
    };

    const updateStateForExistingUser = (existingUser: IMedicalCustomer) => {
        dispatch({ type: 'UPDATE_EXISTING_USER', payload: existingUser });
    }
    const setValidation = (error: boolean, errorMsg: string) => {
        dispatch({ type: 'SET_VALIDATION', payload: { error: error, errorMsg: errorMsg } });
    }
    return { state, handleChange, handleDropDownChange, toggleDependant, updateDependantNames, handleRadioChange, handleTextChange, updateStateForExistingUser, setValidation };
};

