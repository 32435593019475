import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";
import { checkSessionExpiration } from "../checkSessionExpiration";

export interface IMissingDataRow {
    invalidCode: string
    Paid: number,
    countPayments: number,
    lastPayDate: string
}

export interface IMissingData {
    data: IMissingDataRow[],
}
interface IGetMissing {
    token: string;
}
const baseURL = apiURL()
const GetMissingCustomers = async (props: IGetMissing) => {
    const token = props.token
    // Check session expiration before proceeding
    if (!checkSessionExpiration()) {
        return;
    }

    try {
        const data = await axios({
            method: "get",
            responseType: "json",
            url: baseURL + "Report/GetMissingCustomers",
            headers: { "Authorization": `Bearer ${token}` }
        })
        return data as IMissingData;
    } catch (error) {
        // Call the handleAxiosError function with the error and redirect URL
        handleAxiosError(error, "/login"); // Adjust the URL as needed
        return undefined;
    }

}
export default GetMissingCustomers;