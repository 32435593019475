import React, { useEffect, useMemo } from 'react';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import useToken from '../../useToken';
import GetMissingCustomers, { IMissingDataRow } from '../../../services/API/GetMissingCustomers';
import { Box, Button, Container, Popover, Typography } from '@mui/material';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';

const FambulMissingCustomer = () => {
  const columns = useMemo<MRT_ColumnDef<IMissingDataRow>[]>(
    () => [
      {
        accessorKey: 'invalidCode', //access nested data with dot notation
        header: 'invalid Code',
      },
      {
        accessorKey: 'Paid',
        header: 'Amount Paid',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {`${Number(cell.getValue()).toLocaleString('en-SL', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + ' SLL'}`}
          </Box>
        ),
      },
      {
        accessorKey: 'countPayments',
        header: 'Number of Payments',
      },
      {
        accessorKey: 'lastPayDate',
        header: 'Last Pay Date',
      }
    ],
    [],
  );

  const token = useToken().token;
  const [data, setData] = React.useState<IMissingDataRow[]>([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  useEffect(() => {
    GetMissingCustomers({ token }).then((response) => {
      if (response) {
        const newData = response.data
        setData(newData);
      }
    });
  }, [token]);

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  return <>
    <div className="tabs-container" style={{ marginTop: '65px', position: 'fixed', width: '80%', zIndex: 1 }}>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableMultiRowSelection={false} //use radio buttons instead of checkboxes
          enableDensityToggle={false}
          initialState={{ density: 'compact', pagination: { pageIndex: 0, pageSize: 15 }, }}
          muiTablePaginationProps={{
            rowsPerPageOptions: [5, 10, 20, 50],
          }}
          muiTableContainerProps={{
            sx: {
              height: data.length > 20 ? window.innerHeight - 300 + 'px' : undefined,
              overflow: "auto",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "0.4em",
                height: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#1976d2",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#1976d2",
              },
            },
          }}
          enableFullScreenToggle={false}
          enableHiding={false}
          rowCount={data.length}
          renderTopToolbarCustomActions={() => (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h6" gutterBottom>
                    Fambul Missing Customers
                  </Typography>
                  <Button
                    aria-describedby={id}
                    variant="text"
                    color="primary"
                    startIcon={<HelpCenterIcon />}
                    onClick={handleClick}
                  />
                </div>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  sx={{ width: 750 }}
                >
                  <Typography sx={{ padding: 1, fontStyle: 'italic', backgroundColor: '#f0f0f0', color: 'red', border: '1px solid #1565C0', borderRadius: '10px' }}>
                    {`This section displays paying customers who are missing records in our system. To resolve this, obtain the customer details and manually add them in the 'Fambul => Add New' section using the provided code.`}</Typography>
                </Popover>
                <Box
                  sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                  <Button
                    color="primary"
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                    variant="outlined"
                  >
                    Export Data
                  </Button>
                </Box>
              </>
            </Box>
          )}
        />
      </Container>
    </div>
  </>
};
export default FambulMissingCustomer;