import React, { useEffect, useMemo } from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import GetFambulDependants, {
  IFambulDependantsRow,
} from "../../../services/API/GetFambulDependants";
import useToken from "../../useToken";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddEditDependent from "./AddEditDependent";
interface IFambulProps {
  IDCode: string;
}
export const FambulDependants = (props: IFambulProps) => {
  const token = useToken().token;
  const [data, setData] = React.useState<IFambulDependantsRow[]>();
  const [openForm, setOpenForm] = React.useState<boolean>(false);
  const [reload, setReload] = React.useState<boolean>(false);
  const [rowSelection, setRowSelection] = React.useState<
    IFambulDependantsRow | undefined
  >(undefined);

  const HandleRowSelected = (row: IFambulDependantsRow) => {
    setOpenForm(true);
    setRowSelection(row);
  };
  useEffect(() => {
    GetFambulDependants({ token, idCode: props.IDCode }).then((response) => {
      if (response) {
        const newData = response.data;
        setData(newData);
      }
    });
  }, [token, reload]);

  const handleClose = (val: boolean) => {
    setOpenForm(false);
    setRowSelection(undefined);
    if (val) {
      setReload(true);
    }
  };

  const handleAdd = () => {
    setOpenForm(true);
  };
  const columns = useMemo<MRT_ColumnDef<IFambulDependantsRow>[]>(
    () => [
      {
        accessorKey: "Firstname",
        header: "First Name",
        size: 20,
      },
      {
        accessorKey: "Middlename",
        header: "Middle Name",
        size: 20,
      },
      {
        accessorKey: "Lastname",
        header: "Last Name",
        size: 20,
      },
      {
        accessorKey: "Gender",
        header: "Gender",
        size: 10,
      },
      {
        accessorKey: "DOB",
        header: "DOB",
        size: 10,
      },
      {
        accessorKey: "Relationship",
        header: "Relationship",
        size: 20,
      },
      {
        accessorKey: "Trustee",
        header: "Trustee",
        size: 10,
      },
      {
        accessorKey: "PercentageShared",
        header: "Share%",
        size: 5,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.secondary.light,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {cell.getValue() + "%"}
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <>
      {data && (
        <MaterialReactTable
          columns={columns}
          data={data}
          enablePagination={false}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableHiding={false}
          enableRowNumbers={false}
          enableFilters={false}
          initialState={{ density: "compact" }}
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => HandleRowSelected(row.original),
            sx: { cursor: "pointer" },
          })}
          renderTopToolbarCustomActions={() => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <Button
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleAdd}
                startIcon={<FileDownloadIcon />}
                variant="outlined"
              >
                Add New
              </Button>
            </Box>
          )}
        />
      )}
      {openForm && (
        <AddEditDependent
          openUser={openForm}
          onClose={handleClose}
          existingUser={rowSelection}
          IDCode={props.IDCode}
        />
      )}
    </>
  );
};

export default FambulDependants;
