import AppBar from "@mui/material/AppBar";
import Paper from "@mui/material/Paper";
import React from "react";
import AddNewUser from "./AddNewUser";
import MedicalData from "./MedicalData";
import { useEffect } from 'react';

const MedicalUsers = () => {
  const [open, onClose] = React.useState(false);
  const [reload, setReload] = React.useState(false);

  const handleClose = (reload?: boolean) => {
    if (reload) {
      setReload(reload)
    }
    onClose(false);
  };

  useEffect(() => {
    console.log("")
  }, [reload]);

  return <>
    <Paper sx={{ maxWidth: '100%', margin: "auto", overflow: "hidden" }}>
      <AppBar
        position="static"
        color="default"
        elevation={1}
        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
      >
        <MedicalData onClose={onClose} />
      </AppBar>
    </Paper>
    {open && <AddNewUser openUser={open} onClose={handleClose} />}
  </>;
};
export default MedicalUsers;
