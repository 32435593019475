import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IEditMotorInsuranceProps {
    VehicleOwnerID: number
    VehicleOwnerName: string
    VehicleOwnerAddress: string
    VehicleOwnerTelephone: string
    VehicleInsuranceEndDate: string
    ActiveCustomer: boolean
    marketerID: string
    VehicleType: string
    Notes: string
    token: string;
}

const baseURL = apiURL()

const EditMotorInsurance = async (props: IEditMotorInsuranceProps) => {
    let value = ''
    const token = props.token
    const data = {
        VehicleOwnerID: props.VehicleOwnerID,
        VehicleOwnerName: props.VehicleOwnerName,
        VehicleOwnerAddress: props.VehicleOwnerAddress,
        VehicleOwnerTelephone: props.VehicleOwnerTelephone,
        VehicleInsuranceEndDate: props.VehicleInsuranceEndDate,
        ActiveCustomer: props.ActiveCustomer,
        marketerID: props.marketerID,
        VehicleType: props.VehicleType,
        Notes: props.Notes,
    }
    try {
        const response = await axios.post(baseURL + "motor/updateMotor", data, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if (response.status === 200) {
            value = "success"
        }
    } catch (error: AxiosError | any) {
        if (error.response) {
            value = error.response.data
        } else if (error.request) {
            value = "No response from server"
        } else {
            value = "Something went wrong"
        }
    }
    return value
}
export default EditMotorInsurance;

