import axios from "axios";
import apiURL from "../apiURL";
import { IFambulDataRow } from "./GetFambulData";

export interface IFambulData {
    data: IFambulDataRow[],
}

interface IGetFambul {
    token: string;
}
const GetFambulCatalogue = async (props: IGetFambul) => {
    const token = props.token
    const baseURL = apiURL()

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + "Engine/getFambulCatalogue",
        headers: { "Authorization": `Bearer ${token}` }
    })

    return data as IFambulData;
}

export default GetFambulCatalogue;
