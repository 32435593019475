import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IAddOfficeOnlyProps {
    token: string,
    Firstname: string,
    Middlename: string,
    Lastname: string,
    Gender: string,
    Telephone: string,
    Email: string,
    Address: string,
    City: string,
    Country: string,
    JobTitle: string,
    JobType: number,
    DOB: string,
}

const baseURL = apiURL()

const AddOfficeOnly = async (props: IAddOfficeOnlyProps) => {
    let value = ''
    const token = props.token
    const data = {
        Firstname: props.Firstname,
        Middlename: props.Middlename,
        Lastname: props.Lastname,
        Gender: props.Gender,
        Telephone: props.Telephone,
        Email: props.Email,
        Address: props.Address,
        City: props.City,
        Country: props.Country,
        JobTitle: props.JobTitle,
        JobType: props.JobType,
        DOB: props.DOB,
    }
    try {
        const response = await axios.post(baseURL + "OfficeStaff/addOfficeOnly", data, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if (response.status === 200) {
            value = "success"
        }
    } catch (error: AxiosError | any) {
        if (error.response) {
            value = error.response.data
        } else if (error.request) {
            value = "No response from server"
        } else {
            value = "Something went wrong"
        }
    }
    return value
}
export default AddOfficeOnly;

