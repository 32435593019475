export const checkSessionExpiration = (): boolean => {
  const setupTime = sessionStorage.getItem("setupTime");
  const expirationTime = 23 * 60 * 60 * 1000; // 1 day in milliseconds
  const currentTime = new Date().getTime();
  if (!setupTime || currentTime - parseInt(setupTime) > expirationTime) {
    // If setup time has expired or not set, redirect to login
    sessionStorage.clear();
    // Redirect to login page
    window.location.href = `/#/login`; // Adjust the URL as needed
    return false;
  }
  return true;
};
