import * as React from "react";
import Tab from "@mui/material/Tab";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Box } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import GroupLifeData from "./GroupLifeData";
import SearchEngine from "./SearchEngine";
export default function GroupLifeInsurance() {
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div
      className="tabs-container"
      style={{ marginTop: "65px", position: "fixed", width: "100%", zIndex: 1 }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              icon={<GroupAddIcon />}
              label="Group Life Customers"
              value="1"
            />
            <Tab icon={<GroupAddIcon />} label="Search" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <main
            style={{
              height: "calc(100vh - 65px)",
              overflow: "hidden",
              marginRight: "230px",
            }}
          >
            <GroupLifeData isFambulLife={0} />
          </main>
        </TabPanel>
        <TabPanel value="2">
          <main
            style={{
              height: "calc(100vh - 65px)",
              overflow: "hidden",
              marginRight: "230px",
            }}
          >
            <SearchEngine />
          </main>
        </TabPanel>
      </TabContext>
    </div>
  );
}
