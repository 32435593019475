import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import GetGroupLifeData, {
  IGroupLifeDataRow,
} from "../../../services/API/GetGroupLifeData";
import useToken from "../../useToken";
import MaterialReactTable, {
  type MRT_ColumnDef,
  type MRT_Virtualizer,
} from "material-react-table";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import AddNewGLUser from "./AddNewGLUser";
import InfoIcon from "@mui/icons-material/Info";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CancelIcon from "@mui/icons-material/Cancel";
import { IDialogSave } from "../FrontDeskPayment/PaymentData";
import AlertDialog from "../../../Common/AlertDialog";
import { ISignInData } from "../../../services/API/signin";
import jwt_decode from "jwt-decode";
import PrintGLCard from "../../../services/API/PrintGLCard";
import { filterData } from "../Fambul/QualifiedV1Card";

interface IGLProps {
  isFambulLife: number;
}

const GroupLifeData = (props: IGLProps) => {
  const columns = useMemo<MRT_ColumnDef<IGroupLifeDataRow>[]>(
    () => [
      {
        accessorKey: "policyNumber",
        header: "PIN Code",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: "fullname",
        header: "Full Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "dob",
        header: "DOB",
        Cell: ({ cell }) => (cell.getValue() as string).slice(0, 10),
      },
      {
        accessorKey: "glPremiumLevel",
        header: "Premium Level",
      },
      {
        accessorKey: "gender",
        header: "Gender",
      },
      {
        accessorKey: "markedAsPrinted",
        header: "Card Printed",
        Cell: ({ cell }) => (
          <Box component="span">
            {cell.getValue() ? (
              <AssignmentTurnedInIcon
                color="success"
                sx={{
                  width: 50,
                  height: 20,
                }}
              />
            ) : (
              <CancelIcon
                color="error"
                sx={{
                  width: 50,
                  height: 20,
                }}
              />
            )}
          </Box>
        ),
      },
      {
        accessorKey: "policyNumber",
        header: "Info",
        Cell: ({ cell }) =>
          !cell.row.original.markedAsPrinted ? (
            <Button
              color="error"
              disabled={cell.row.original.markedAsPrinted}
              variant="contained"
              sx={{ width: 150, height: 25 }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent row click event
                handleApprove(
                  cell.row.original.policyNumber,
                  cell.row.original.fullname
                );
              }}
            >
              Mark as Printed
            </Button>
          ) : (
            <Tooltip title={cell.row.original.whoPrintNote} arrow>
              <IconButton
                size="small"
                sx={{
                  marginLeft: "0.5rem",
                  color: "#fff",
                }}
              >
                <InfoIcon
                  color="success"
                  sx={{
                    width: 50,
                    height: 20,
                  }}
                />
              </IconButton>
            </Tooltip>
          ),
      },
    ],
    []
  );
  const token = useToken().token;
  const isFambulLife = props.isFambulLife;
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [GroupLifeData, setGroupLifeData] = useState<IGroupLifeDataRow[]>([]);
  const [filteredData, setFilteredData] = useState<IGroupLifeDataRow[]>([]);
  const tableContainerRef = useRef<HTMLDivElement>(null); //we can get access to the underlying TableContainer element and react to its scroll events
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method
  const [rowSelection, setRowSelection] = useState<
    IGroupLifeDataRow | undefined
  >(undefined);
  const [open, onClose] = useState(false);
  const [policyToPrint, setPolicyToPrint] = useState<string>();
  const [reload, setReload] = useState(false);
  const [showDaily, setShowDaily] = useState<IDialogSave>({
    open: false,
    headerText: "",
    mainText: "",
  });
  const userData: ISignInData = jwt_decode(token);
  const [selectedFilter, setSelectedFilter] = useState<number>(0);

  const handleFilterChange = (event: SelectChangeEvent<number>) => {
    setSelectedFilter(Number(event.target.value));
  };

  const fetchData = useCallback(
    async (pageToFetch: number) => {
      setIsLoading(true);
      try {
        const newData = await GetGroupLifeData({
          token,
          page: pageToFetch,
          isFambulLife,
        });
        if (newData) {
          if (newData.data.length === 0) {
            setHasMore(false);
          } else {
            setGroupLifeData((prevData) => [...prevData, ...newData.data]);
            setFilteredData((prevData) => [...prevData, ...newData.data]);
            setPage(pageToFetch + 1);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [token, page, isFambulLife, reload]
  );

  const handleClose = () => {
    setRowSelection(undefined);
    onClose(false);
  };
  const handleAdd = () => {
    onClose(true);
  };
  const HandleRowSelected = (row: IGroupLifeDataRow) => {
    onClose(true);
    setRowSelection(row);
  };

  const handleApprove = (policy: string, fullname: string) => {
    setShowDaily({
      open: true,
      headerText: `ID Card Printed`,
      mainText: `You are about to confirm that the Policy# "${policy}" for customer "${fullname}" has been printed.`,
    });
    setPolicyToPrint(policy);
  };

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  const totalFetched = filteredData.length;

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 400 &&
          !isLoading &&
          hasMore
        ) {
          fetchData(page);
        }
      }
    },
    [isLoading, hasMore]
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    const filterData = filteredData.map((item) => ({
      PolicyNumber: item.policyNumber,
      Fullname: item.fullname,
      Email: item.email,
      DOB: item.dob,
      PremiumLevel: item.glPremiumLevel,
      Gender: item.gender,
      ResidentialAddress: item.residentialAddress,
      PhoneNumber: item.telephoneNumber,
      Occupation: item.occupation,
    }));
    csvExporter.generateCsv(filterData);
  };

  const handleCancel = () => {
    setShowDaily({
      open: false,
      headerText: "",
      mainText: "",
    });
  };

  const handleSubmit = () => {
    if (policyToPrint) {
      PrintGLCard({
        idCodeToPrinted: policyToPrint,
        acknowledgedBy: userData.DisplayName,
        token: token,
      });
      setShowDaily({
        open: false,
        headerText: "",
        mainText: "",
      });
      setPage(1); // Reset page
      setGroupLifeData([]); // Reset data
      setHasMore(true); // Reset hasMore
      fetchData(1);
      setReload(true);
    }
  };

  useEffect(() => {
    let newData = GroupLifeData;
    if (selectedFilter !== 0) {
      newData = newData.filter(
        (item) => (item.markedAsPrinted ? 2 : 1) === selectedFilter
      );
    }
    setFilteredData(newData);
  }, [selectedFilter, GroupLifeData]);
  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={filteredData}
        initialState={{ density: "compact" }}
        enablePagination={false}
        enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => HandleRowSelected(row.original),
          sx: { cursor: "pointer" },
        })}
        muiTableContainerProps={{
          ref: tableContainerRef, //get access to the table container element
          sx: {
            height: window.innerHeight - 300 + "px",
            overflow: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "0.4em",
              height: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#1976d2",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#1976d2",
            },
          },
          onScroll: (event: React.UIEvent<HTMLDivElement>) => {
            const target = event.target as HTMLDivElement;
            fetchMoreOnBottomReached(target);
          },
        }}
        renderBottomToolbarCustomActions={() => (
          <Typography>Fetched {totalFetched} rows.</Typography>
        )}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //get access to the virtualizer instance
        rowVirtualizerProps={{ overscan: 4 }}
        enableMultiRowSelection={false} //use radio buttons instead of checkboxes
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableHiding={false}
        enableRowNumbers={false}
        renderTopToolbarCustomActions={() => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.3rem", // Reduced gap
              p: "0.3rem", // Reduced padding
              flexWrap: "wrap",
              height: "50px", // Reduced height
            }}
          >
            <Button
              color="success"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleAdd}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
            >
              {`New ${props.isFambulLife === 1 ? "Fambul V2" : "Group Life"}`}
            </Button>
            <Button
              color="primary"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
            >
              Export Data
            </Button>
            <FormControl
              sx={(theme) => ({
                minWidth: "200px",
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: "8px",
              })}
            >
              <InputLabel id="dropdown-label">Filter</InputLabel>
              <Select
                labelId="dropdown-label"
                name="Filter"
                value={selectedFilter}
                label="Filter"
                sx={{ fontSize: "0.8rem" }}
                onChange={handleFilterChange}
              >
                {filterData &&
                  filterData.map((item) => (
                    <MenuItem key={item.filterID} value={item.filterID}>
                      {item.filterStatus}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
      />
      {open && !rowSelection && (
        <AddNewGLUser
          openUser={open}
          onClose={handleClose}
          isFambulLife={props.isFambulLife}
        />
      )}
      {showDaily && (
        <AlertDialog
          open={showDaily.open}
          setOpen={handleCancel}
          setSave={handleSubmit}
          headerText={showDaily.headerText}
          mainText={showDaily.mainText}
        />
      )}
      {rowSelection && (
        <AddNewGLUser
          openUser={open}
          onClose={handleClose}
          existingUser={rowSelection}
          isFambulLife={props.isFambulLife}
        />
      )}
    </>
  );
};
export default GroupLifeData;
