import React, { useEffect, useMemo } from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import useToken from "../../useToken";
import { Box, Button } from "@mui/material";
import GetAcknowledgmentData, {
  IAckDataRow,
} from "../../../services/API/GetAcknowledgmentData";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { ISignInData } from "../../../services/API/signin";
import jwt_decode from "jwt-decode";
import AlertDialog from "../../../Common/AlertDialog";
import { IDialogSave } from "./PaymentData";
import { format } from "date-fns";
import ApproveDailySum from "../../../services/API/ApproveDailySum";

const AcknowledgedData = () => {
  const columns = useMemo<MRT_ColumnDef<IAckDataRow>[]>(
    () => [
      {
        accessorKey: "DailyDate",
        header: "Date",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {(cell.getValue() as string).slice(0, 10)}
          </Box>
        ),
      },
      {
        accessorKey: "Amount",
        header: "Amount",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.secondary.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {`${
              Number(cell.getValue()).toLocaleString("en-SL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " SLL"
            }`}
          </Box>
        ),
      },
      {
        accessorKey: "SubmitBy",
        header: "Submit By",
      },
      {
        accessorKey: "PaymentType",
        header: "Payment Type",
      },
      {
        accessorKey: "Acknowledged",
        header: "Acknowledged",
        Cell: ({ cell }) => (
          <Box component="span">
            {cell.getValue() ? (
              <CheckBoxOutlinedIcon color="success" />
            ) : (
              <ClearOutlinedIcon color="error" />
            )}
          </Box>
        ),
      },
      {
        accessorKey: "DailyID",
        header: "",
        Cell: ({ cell }) => (
          <Button
            sx={{ width: 150, height: 25 }}
            color="error"
            disabled={
              cell.row.original.Acknowledged ||
              userData.CanAcknowledgePayment === "False"
            }
            variant="contained"
            onClick={() =>
              handleApprove(
                Number(cell.getValue()),
                cell.row.original.Amount,
                cell.row.original.DailyDate,
                cell.row.original.PaymentType
              )
            }
          >
            Acknowledge
          </Button>
        ),
      },
    ],
    []
  );

  const handleApprove = (
    DailyID: number,
    amount: number,
    date: Date,
    PaymentType: string
  ) => {
    const newDate = format(new Date(date), "yyyy-MM-dd");
    setShowDaily({
      open: true,
      headerText: `${PaymentType}  ${newDate} Acknowledgment`,
      mainText: `You are about to acknowledge receipt of "${amount.toLocaleString(
        "en-SL",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )} SLL" for ${newDate}. Please make sure you have collected this amount before Acknowledging.`,
    });
    setDailyID(DailyID);
  };
  const token = useToken().token;
  const userData: ISignInData = jwt_decode(token);
  const [data, setData] = React.useState<IAckDataRow[]>([]);
  const [dailyID, setDailyID] = React.useState<number>();
  const [reload, setReload] = React.useState<boolean>(false);
  const [showDaily, setShowDaily] = React.useState<IDialogSave>({
    open: false,
    headerText: "",
    mainText: "",
  });

  useEffect(() => {
    GetAcknowledgmentData({ token }).then((response) => {
      if (response) {
        setData(response.data);
      }
    });
  }, [token, reload]);
  const handleCancel = () => {
    setShowDaily({
      open: false,
      headerText: "",
      mainText: "",
    });
  };
  const handleSubmit = () => {
    if (dailyID) {
      ApproveDailySum({
        dailyID: dailyID,
        acknowledgedBy: userData.DisplayName,
        token: token,
      });
      setShowDaily({
        open: false,
        headerText: "",
        mainText: "",
      });
      setReload(true);
    }
  };

  return (
    <>
      {" "}
      <MaterialReactTable
        columns={columns}
        data={data}
        enableMultiRowSelection={false} //use radio buttons instead of checkboxes
        enableDensityToggle={false}
        initialState={{
          density: "compact",
          pagination: {
            pageIndex: 0,
            pageSize: window.innerHeight > 720 ? 15 : 10,
          },
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 20, 50],
        }}
        muiTableContainerProps={{
          sx: {
            overflow: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "0.4em",
              height: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#1976d2",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#1976d2",
            },
          },
        }}
        enableFullScreenToggle={false}
        enableHiding={false}
        rowCount={data.length}
      />
      {showDaily && (
        <AlertDialog
          open={showDaily.open}
          setOpen={handleCancel}
          setSave={handleSubmit}
          headerText={showDaily.headerText}
          mainText={showDaily.mainText}
        />
      )}
    </>
  );
};
export default AcknowledgedData;
