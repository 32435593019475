import * as React from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link as MUILink,
  Paper,
  Box,
  Grid,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import { createTheme, ThemeProvider, Theme } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import SignIn, { ISignInProps } from "../services/API/signin";
import "@mui/styles";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useNavigate } from 'react-router-dom';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}


function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <MUILink color="inherit" href="https://www.slicoinsurance.com/">
        SLICO
      </MUILink>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const Login = () => {
  const [errorMsg, setErrorMsg] = React.useState<string>("");
  const [showloading, setloading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const CloseAlert = () => {
    setErrorMsg("");
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setloading(true)
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const loginName = data.get("email")?.toString();
    const Password = data.get("password")?.toString();
    if (loginName && Password) {
      const isExternal = loginName.includes('@');
      const params: ISignInProps = {
        Email: loginName,
        Password: Password,
        rememberEmail: 0,
        isExternal: !isExternal
      };
      SignIn(params).then((response) => {
        setloading(false)
        if (response === "Invalid credentials") {
          sessionStorage.clear();
          setErrorMsg(response);
        } else if (response === "Acount Deactivated, Please Contact Admin") {
          sessionStorage.clear();
          setErrorMsg(response);
        } else {
          if (!isExternal) {
            navigate('/MarketerProfilePage')
          } else {
            navigate('/Dashboard');
          }
        }
      });
    }
  };

  return <>
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
              variant={"circular"}
              alt="SLICO"
              src="/slico_logo.jpg"
              style={{
                width: 200,
                height: 200,
              }}
            />
            <Box
              component="form"
              noValidate={false}
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email / User ID"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {errorMsg && (
                <Alert severity="error" onClose={CloseAlert}>
                  <AlertTitle>Error</AlertTitle>
                  {errorMsg}
                </Alert>
              )}
              <Button
                type="submit"
                variant="outlined"
                sx={{ size: "small", mt: 3, mb: 2 }}
                endIcon={<SendIcon />}
                fullWidth
              >
                Sign In
              </Button>
              {showloading &&
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={showloading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Link color="primary" to="/ForgottenPassword">
                    Forgot password
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Link color="primary" to="/ResetUser">
                    Activate Account
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(/background.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </ThemeProvider>
  </>;
};
export default Login;
