import React from "react";
import Box from "@mui/material/Box";
import {
  Autocomplete, Container, Grid, TextField, Typography, FormControlLabel, Radio,
  RadioGroup,
} from "@mui/material";
import { IActiveUserStep1Props } from "./AddNewUserStep1";


const AddNewUserStep1: React.FC<IActiveUserStep1Props> = ({
  state,
  handleChange,
  handleDropDownChange,
  updateDependantNames,
}) => {
  return (
    <div key={state.dataToSave.newPremium}>
      <Container sx={{ mt: 2 }}>
        <Box sx={{ width: 800, height: !state.dataToSave.hasDependant ? 320 : 610, overflow: "hidden", }}>
          <React.Fragment>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  id="occupation"
                  name="occupation"
                  label="Occupation"
                  fullWidth
                  inputProps={{ maxLength: 40 }}
                  autoComplete="occupation"
                  disabled={state.dataToSave.editing}
                  defaultValue={state.dataToSave.occupation}
                  variant="standard"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  required
                  id="officeTelephone"
                  name="officeTelephone"
                  label="Telephone"
                  inputProps={{ maxLength: 20 }}
                  disabled={state.dataToSave.editing}
                  defaultValue={state.dataToSave.officeTelephone}
                  fullWidth
                  autoComplete="tel-number"
                  variant="standard"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  id="employerName"
                  name="employerName"
                  inputProps={{ maxLength: 40 }}
                  defaultValue={state.dataToSave.employerName}
                  label="Employer name"
                  fullWidth
                  disabled={state.dataToSave.editing}
                  autoComplete="employerName-name"
                  variant="standard"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  required
                  id="employerAddress"
                  name="employerAddress"
                  inputProps={{ maxLength: 40 }}
                  defaultValue={state.dataToSave.employerAddress}
                  label="Employer Address"
                  fullWidth
                  disabled={state.dataToSave.editing}
                  autoComplete="employer-address"
                  variant="standard"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="passportNo"
                  name="passportNo"
                  inputProps={{ maxLength: 18 }}
                  defaultValue={state.dataToSave.passportNo}
                  label="ID/Passport No"
                  fullWidth
                  disabled={state.dataToSave.editing}
                  autoComplete="passport-No"
                  variant="standard"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  required
                  id="issuecountry"
                  name="issuecountry"
                  inputProps={{ maxLength: 40 }}
                  defaultValue={state.dataToSave.issuecountry}
                  label="Issue Country"
                  fullWidth
                  disabled={state.dataToSave.editing}
                  autoComplete="issue-country"
                  variant="standard"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  id="passportExpdate"
                  name="passportExpdate"
                  inputProps={{ maxLength: 20 }}
                  defaultValue={state.dataToSave.passportExpdate}
                  disabled={state.dataToSave.editing}
                  label="Passport Exipry Date"
                  type="date"
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <form>
                  <Autocomplete
                    disablePortal
                    id="Package"
                    defaultValue={state.dataToSave.Package || "SL Standard"}
                    disabled={state.dataToSave.editing}
                    options={state.dataToSave.medOptions}
                    sx={{ width: 250 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Package" />
                    )}
                    onChange={handleDropDownChange}
                  />
                </form>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  id="insuranceExpiryDate"
                  name="insuranceExpiryDate"
                  defaultValue={state.dataToSave.insuranceExpiryDate}
                  label="Insurance Exipry Date"
                  type="date"
                  sx={{ width: 220 }}
                  disabled={state.dataToSave.editing}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <TextField
                  key={state.dataToSave.newPremium}
                  required
                  type="string"
                  id="newPremium"
                  name="newPremium"
                  label="Premium"
                  defaultValue={state.dataToSave.newPremium}
                  fullWidth
                  disabled={true}
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, height: state.dataToSave.hasDependant ? 320 : 0, width: "100%" }}>
              {state.dataToSave.hasDependant && (
                <div style={{ border: "1px solid #33FFF3", padding: "10px" }}>
                  <Typography variant="subtitle1" gutterBottom color="red">
                    Dependants Section - Please Add them here
                  </Typography>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <div key={index} style={{ paddingBottom: '15px' }}>
                      <React.Fragment key={index}>
                        <Grid container spacing={0}>
                          <Grid item xs={6} sm={3}>
                            <TextField
                              required
                              id={`fullname-${index}`}
                              name={`fullname-${index}`}
                              defaultValue={state.dataToSave.dependantNames[index].fullname}
                              label="fullname"
                              variant="standard"
                              inputProps={{ maxLength: 40, pattern: "[^|¬#>]" }}
                              size="small"
                              style={{ fontSize: "5px", padding: "0px", width: 200 }}
                              disabled={state.dataToSave.editing}
                              onChange={updateDependantNames}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                              required
                              id={`relationship-${index}`}
                              name={`relationship-${index}`}
                              defaultValue={state.dataToSave.dependantNames[index].relationship}
                              label="Relationship"
                              inputProps={{ maxLength: 20, pattern: "[^|¬#>]" }}
                              variant="standard"
                              size="small"
                              style={{ fontSize: "5px", padding: "0px" }}
                              disabled={state.dataToSave.editing}
                              onChange={updateDependantNames}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name={`sex-${index}`}
                              defaultValue={state.dataToSave.dependantNames[index].sex || "male"}
                              onChange={updateDependantNames}
                            >
                              <FormControlLabel
                                value="male"
                                control={<Radio />}
                                label="Male"
                                disabled={state.dataToSave.editing}
                              />
                              <FormControlLabel
                                value="female"
                                control={<Radio />}
                                label="Female"
                                disabled={state.dataToSave.editing}
                              />
                            </RadioGroup>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                              id={`dob-${index}`}
                              name={`dob-${index}`}
                              defaultValue={state.dataToSave.dependantNames[index].dob}
                              label="DOB"
                              type="date"
                              sx={{ width: 140, height: 50 }}
                              disabled={state.dataToSave.editing}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              style={{ fontSize: "5px", padding: "0px" }}
                              onChange={updateDependantNames}
                            />
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    </div>
                  ))}
                </div>)}
            </Box>
          </React.Fragment>
        </Box>
      </Container >
    </div >
  );
}
export default AddNewUserStep1