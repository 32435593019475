import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";
import { checkSessionExpiration } from "../checkSessionExpiration";

export interface IRecentPaymentRow {
    paymentDate: string,
    fullName: string,
    insuranceType: string,
    paymentMethod: string,
    premiumAmount: number
}

export interface IRecentPaymentData {
    data: IRecentPaymentRow[],
}
interface IGetMotor {
    token: string;
}
const baseURL = apiURL()
const GetRecentPayment = async (props: IGetMotor) => {
    const token = props.token

    // Check session expiration before proceeding
    if (!checkSessionExpiration()) {
        return;
    }
    try {
        const data = await axios({
            method: "get",
            responseType: "json",
            url: baseURL + "recentPayments",
            headers: { "Authorization": `Bearer ${token}` }
        })
        return data as IRecentPaymentData;
    } catch (error) {
        // Call the handleAxiosError function with the error and redirect URL
        handleAxiosError(error, "/login"); // Adjust the URL as needed
        return undefined;
    }

}
export default GetRecentPayment;