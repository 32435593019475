import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";
import { checkSessionExpiration } from "../checkSessionExpiration";

export interface IFambulDataRow {
    fullname: string,
    idCode: string,
    dob: Date,
    regDate: Date,
    details: string,
    telephone: string,
    countPayments: number,
    totalPayment: number,
    lastPayDate: string,
    imageBinary: number,
    premium: number,
    shouldHavePaid: number,
}

export interface IFambulData {
    data: IFambulDataRow[],
}

interface IGetFambul {
    token: string;
    page: number;
    isCompliant: number;
}
const GetFambulData = async (props: IGetFambul) => {
    const token = props.token
    const page = props.page
    const isCompliant = props.isCompliant
    const baseURL = apiURL()

    // Check session expiration before proceeding
    if (!checkSessionExpiration()) {
        return;
    }

    try {
        const data = await axios({
            method: "get",
            responseType: "json",
            url: baseURL + `fambulData?page=${page}&isCompliant=${isCompliant}`,
            headers: { "Authorization": `Bearer ${token}` }
        })

        return data as IFambulData;
    } catch (error) {
        // Call the handleAxiosError function with the error and redirect URL
        handleAxiosError(error, "/login"); // Adjust the URL as needed
        return undefined;
    }
}

export default GetFambulData;
