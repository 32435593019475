import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface ISignInProps {
    Email: string;
}

const baseURL = apiURL();

const GenerateActivationCode = (props: ISignInProps) => {
    return axios.post(baseURL + "Account/generateActivationCode", {
        Email: props.Email
    })
        .then((response) => {
            return response.data;
        })
        .catch((error: AxiosError) => {
            let errorMessage;
            if (error.response) {
                errorMessage = error.response.data;
            } else if (error.request) {
                console.error(error.request);
                errorMessage = `No response`;
            } else {
                errorMessage = `Axios Error`;
            }
            return errorMessage;
        });
};

export default GenerateActivationCode;
