import axios from "axios";
import apiURL from "../apiURL";


interface ICheckPolicy {
    token: string;
    policyNumber: string;
    insurancetype: number;
}

const baseURL = apiURL();

const CheckPolicy = async (props: ICheckPolicy): Promise<number> => {
    try {
        const token = props.token;
        const response = await axios.get<number>(
            `${baseURL}Claim/checkPolicy?PolicyNumber=${props.policyNumber}&Insurancetype=${props.insurancetype}`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        return response.data;
    } catch (error) {
        // Handle the error here
        console.error('Error checking for duplicates:', error);
        throw error;
    }
};

export default CheckPolicy;
