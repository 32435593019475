import React, { useEffect, useMemo } from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import useToken from "../../useToken";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { ExportToCsv } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import GetExpenseRequests, {
  IExpenseRequestRow,
} from "../../../services/API/GetExpenseRequests";
import { ISignInData } from "../../../services/API/signin";
import jwt_decode from "jwt-decode";
import AddExpenseRequest from "./AddExpenseRequest";
import GetExpenseTypesList, {
  IGetExpenseTypesListRow,
} from "../../../services/API/GetExpenseTypesList";
import InfoIcon from "@mui/icons-material/Info";
import { statusData } from "./ExpenseData";
import ColoredInfoBox from "./ColoredInfoBox";
import GetExpenseTypes, {
  IGetExpenseTypesRow,
} from "../../../services/API/GetExpenseTypes";
import UpdateExpenseType from "../../../services/API/UpdateExpenseType";
interface IExpenseType {
  type: number;
}

const Expenses = (props: IExpenseType) => {
  const columns = useMemo<MRT_ColumnDef<IExpenseRequestRow>[]>(
    () => [
      {
        accessorKey: "requestType",
        header: "Request Type",
      },
      {
        accessorKey: "purpose",
        header: "Description",
        Cell: ({ cell }) => (cell.getValue() as string).slice(0, 25),
      },
      {
        accessorKey: "requestAmount",
        header: "Requested Amount",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.secondary.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "25ch",
              p: "0.25rem",
            })}
          >
            {`${
              Number(cell.getValue()).toLocaleString("en-SL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " SLE"
            }`}
          </Box>
        ),
      },

      {
        accessorKey: "requestDate",
        header: "Request Date",
        Cell: ({ cell }) => (cell.getValue() as string).slice(0, 10),
      },
      {
        accessorKey: "requestBy",
        header: "Request By",
      },
      {
        accessorKey: "Status",
        header: "Status",
        Cell: ({ row }) => {
          const request = row.original;
          let statusColor = "orange"; // Default color
          if (request.requestStatus === "Awaiting Approval") {
            statusColor = "dodgerblue"; // A brighter blue for better visibility
          } else if (request.requestStatus === "Approved") {
            statusColor = "forestgreen"; // A deep green for a positive status
          } else if (request.requestStatus === "Completed") {
            statusColor = "mediumpurple"; // A medium purple for distinction
          } else if (request.requestStatus === "Rejected") {
            statusColor = "crimson"; // A strong red for alert
          } else if (request.requestStatus === "Closed") {
            statusColor = "dimgray"; // A medium gray for a neutral status
          } else if (request.requestStatus === "Queried") {
            statusColor = "#088da5"; // A distinct color for the Queried status
          }

          return (
            <Box
              component="span"
              sx={{
                backgroundColor: statusColor,
                borderRadius: "0.25rem",
                color: "#fff",
                width: "25ch",
                p: "0.25rem",
              }}
            >
              {request.requestStatus}
              {(request.requestStatus === "Rejected" ||
                request.requestStatus === "Closed") &&
                request.rejectionNote && (
                  <Tooltip title={request.rejectionNote} arrow>
                    <IconButton
                      size="small"
                      sx={{
                        marginLeft: "0.5rem",
                        color: "#fff",
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                )}
            </Box>
          );
        },
        width: 50,
      },
    ],
    []
  );
  const token = useToken().token;
  const userData: ISignInData = jwt_decode(token);
  const [data, setData] = React.useState<IExpenseRequestRow[]>([]);
  const [filteredData, setFilteredData] = React.useState<IExpenseRequestRow[]>(
    []
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rowSelection, setRowSelection] = React.useState<
    IExpenseRequestRow | undefined
  >(undefined);
  const [reload, setReload] = React.useState(false);
  const [selectedExpense, setSelectedExpense] = React.useState<number>(0);
  const [selectedExpenseStatus, setSelectedExpenseStatus] =
    React.useState<number>(
      userData.CanPreApproveExpense === "True" ||
        userData.CanApproveExpense === "True"
        ? 2
        : userData.CanReviewExpense === "True"
        ? 1
        : 0
    );
  const [requestData, setRequestData] = React.useState<
    IGetExpenseTypesListRow[] | undefined
  >(undefined);
  const [expenseTypes, setExpenseType] = React.useState<
    IGetExpenseTypesRow | undefined
  >(undefined);

  React.useEffect(() => {
    async function loadExpense() {
      const response = await GetExpenseTypesList({ token });
      if (response?.data) {
        const allOption = { requestType: "All", requestID: 0 };
        const modifiedData = [allOption, ...response.data];
        setRequestData(modifiedData);
      }
    }
    loadExpense();
  }, [token]);

  React.useEffect(() => {
    async function loadExpense() {
      const expenseTypeID = props.type;
      const response = await GetExpenseTypes({ token, expenseTypeID });
      if (response?.data) {
        setExpenseType(response.data[0]);
      }
      const id = userData.EmployeeID;
      const choice =
        props.type === 0
          ? "All"
          : response?.data && response.data[0].expenseType;
      GetExpenseRequests({ token, id, choice }).then((response) => {
        if (response) {
          //Hide Request from assigned Person until request is approved
          const filteredData = response.data.filter((item) => {
            const condition = !(
              item.requestAssignedID === Number(userData.EmployeeID) &&
              item.requestStatusOrder === 1
            );
            return condition;
          });
          setData(filteredData);
          setFilteredData(filteredData); // Set the filtered data on initial load
          setSelectedExpense(0);
          setSelectedExpenseStatus(
            userData.CanPreApproveExpense === "True" ||
              userData.CanApproveExpense === "True"
              ? 2
              : userData.CanReviewExpense === "True"
              ? 1
              : 0
          );
          setReload(false);
        }
      });
    }
    loadExpense();
  }, [
    token,
    userData.EmployeeID,
    userData.CanPreApproveExpense,
    userData.CanApproveExpense,
    userData.CanReviewExpense,
    reload,
    props.type,
  ]);
  const [isEditing, setIsEditing] = React.useState(false);
  const [extraAmount, setExtraAmount] = React.useState<number>(0);

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleExpenseTypeChange = (event: SelectChangeEvent<number>) => {
    setSelectedExpense(Number(event.target.value));
  };

  const handleExpenseStatusTypeChange = (event: SelectChangeEvent<number>) => {
    setSelectedExpenseStatus(Number(event.target.value));
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    let newData = data;

    if (selectedExpense !== 0) {
      newData = newData.filter((item) => item.requestID === selectedExpense);
    }

    if (selectedExpenseStatus !== 0) {
      newData = newData.filter(
        (item) => item.requestStatusOrder === selectedExpenseStatus
      );
    }

    setFilteredData(newData);
  }, [selectedExpense, selectedExpenseStatus, data]);

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    const filteredDataCSV = filteredData.map((item) => ({
      RequestType: item.requestType,
      purpose: item.purpose,
      RequestDate: item.requestDate,
      RequestAmount: item.requestAmount,
      RequestAmountGST: item.requestAmountGST,
      requestBy: item.requestBy,
      passedBy: item.passedBy,
      passedDate: item.passedDate,
      approvedBy: item.approvedBy,
      approvedDate: item.approvedDate,
      receivedBy: item.receivedBy,
      receivedDate: item.receivedDate,
    }));
    csvExporter.generateCsv(filteredDataCSV);
  };

  const HandleRowSelected = (row: IExpenseRequestRow) => {
    setRowSelection(row);
  };
  const handleClose = (val?: boolean) => {
    if (val) {
      setReload(true);
    }
    setRowSelection(undefined);
  };

  const handleSave = async () => {
    await UpdateExpenseType({
      token,
      expenseTypeID: props.type,
      originalAmount: extraAmount,
      balance: expenseTypes ? expenseTypes.balance + extraAmount : 0,
    });
    setExtraAmount(0);
    setReload(true);
    setIsEditing(false);
  };

  const handleCloseEdit = () => {
    setIsEditing(false);
    // Reset extra amount state on close
    setExtraAmount(0);
  };

  return (
    <>
      <div
        className="tabs-container"
        style={{
          marginTop: "65px",
          position: "fixed",
          width: "80%",
          zIndex: 1,
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <MaterialReactTable
            columns={columns}
            data={filteredData}
            enableMultiRowSelection={false} //use radio buttons instead of checkboxes
            enableDensityToggle={false}
            initialState={{
              density: "compact",
              pagination: { pageIndex: 0, pageSize: 15 },
            }}
            muiTablePaginationProps={{
              rowsPerPageOptions: [5, 10, 20, 50],
            }}
            muiTableBodyRowProps={({ row }) => ({
              onClick: () => HandleRowSelected(row.original),
              sx: { cursor: "pointer" },
            })}
            muiTableContainerProps={{
              sx: {
                height:
                  data.length > 10
                    ? window.innerHeight - 300 + "px"
                    : undefined,
                overflow: "auto",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                  width: "0.4em",
                  height: "0.4em",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#1976d2",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#1976d2",
                },
              },
            }}
            enableFullScreenToggle={false}
            enableHiding={false}
            rowCount={filteredData.length}
            renderTopToolbarCustomActions={() =>
              props.type === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.3rem", // Reduced gap
                    p: "0.3rem", // Reduced padding
                    flexWrap: "wrap",
                    height: "50px", // Reduced height
                  }}
                >
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="h6" gutterBottom>
                        Expenses
                      </Typography>
                      <Button
                        aria-describedby={id}
                        variant="text"
                        color="primary"
                        startIcon={<HelpCenterIcon />}
                        onClick={handleClick}
                      />
                    </div>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClosePopover}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      sx={{ width: 750 }}
                    >
                      <Typography
                        sx={{
                          padding: 1,
                          fontStyle: "italic",
                          backgroundColor: "#f0f0f0",
                          color: "red",
                          border: "1px solid #1565C0",
                          borderRadius: "10px",
                        }}
                      >
                        In this section, you can view all expense requests.
                        Click on a row to review details and take action.
                        <br />
                        - If you can pre-approve or approve expenses, your
                        default view will be expenses awaiting your approval.
                        <br />
                        - If you are reviewing expenses, it will show expenses
                        you need to review.
                        <br />
                        You can change the filter to view all if you need to.
                      </Typography>
                    </Popover>
                    <Box
                      sx={{ display: "flex", gap: "0.2rem", flexWrap: "wrap" }}
                    >
                      <Button
                        color="primary"
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        onClick={handleExportData}
                        startIcon={<FileDownloadIcon />}
                        variant="outlined"
                      >
                        Export Data
                      </Button>
                      <FormControl
                        sx={(theme) => ({
                          minWidth: "250px",
                          border: `1px solid ${theme.palette.secondary.dark}`,
                          borderRadius: "8px",
                        })}
                      >
                        <InputLabel id="dropdown-label">
                          Request Type
                        </InputLabel>
                        <Select
                          labelId="dropdown-label"
                          name="requestType"
                          value={selectedExpense}
                          label="Request Type"
                          onChange={handleExpenseTypeChange}
                          sx={{ fontSize: "0.8rem" }}
                          disabled={!requestData || requestData.length === 0}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 250,
                              },
                            },
                          }}
                        >
                          {requestData &&
                            requestData.map((item) => (
                              <MenuItem
                                key={item.requestID}
                                value={item.requestID}
                                style={{
                                  fontSize: "12px",
                                  padding: "5px 12px",
                                  lineHeight: "1",
                                }}
                              >
                                {item.requestType}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        sx={(theme) => ({
                          minWidth: "200px",
                          border: `1px solid ${theme.palette.secondary.dark}`,
                          borderRadius: "8px",
                        })}
                      >
                        <InputLabel id="dropdown-label">Status</InputLabel>
                        <Select
                          labelId="dropdown-label"
                          name="requestType"
                          value={selectedExpenseStatus}
                          label="Status"
                          sx={{ fontSize: "0.8rem" }}
                          onChange={handleExpenseStatusTypeChange}
                        >
                          {statusData &&
                            statusData.map((item) => (
                              <MenuItem
                                key={item.statusID}
                                value={item.statusID}
                              >
                                {item.statusDescription}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </>
                </Box>
              ) : (
                expenseTypes && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h6" gutterBottom>
                          {expenseTypes.expenseType}
                        </Typography>
                        <FormControl
                          sx={(theme) => ({
                            minWidth: "200px",
                            border: `1px solid ${theme.palette.secondary.dark}`,
                            borderRadius: "8px",
                            marginLeft: "16px",
                          })}
                        >
                          <InputLabel id="dropdown-label">Status</InputLabel>
                          <Select
                            labelId="dropdown-label"
                            name="requestType"
                            value={selectedExpenseStatus}
                            label="Status"
                            sx={{ fontSize: "0.8rem" }}
                            onChange={handleExpenseStatusTypeChange}
                          >
                            {statusData &&
                              statusData
                                .filter((row) => row.statusID !== 1)
                                .map((item) => (
                                  <MenuItem
                                    key={item.statusID}
                                    value={item.statusID}
                                  >
                                    {item.statusDescription}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={0.5}>
                        <Grid item xs={6}>
                          <ColoredInfoBox
                            label={`Original Amount Since ${expenseTypes.dateLastUpdated}: `}
                            value={expenseTypes.originalAmount}
                            bgColor="coral"
                            onEditClick={handleEditClick}
                            userData={userData}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <ColoredInfoBox
                            label="Balance:"
                            value={expenseTypes.balance}
                            bgColor="lightgreen"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <ColoredInfoBox
                            label="To Approval:"
                            value={filteredData
                              .filter(
                                (request) =>
                                  request.requestStatus === "Awaiting Approval"
                              )
                              .reduce(
                                (acc, request) => acc + request.requestAmount,
                                0
                              )}
                            bgColor="dodgerblue"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider style={{ margin: "2px 0" }} />
                    </Grid>
                  </Grid>
                )
              )
            }
          />
        </Container>
      </div>
      {rowSelection && (
        <AddExpenseRequest
          openUser={true}
          onClose={handleClose}
          requestRow={rowSelection}
        />
      )}
      <Dialog open={isEditing} onClose={handleCloseEdit}>
        <DialogTitle
          sx={(theme) => ({
            backgroundColor: "coral",
            color: theme.palette.primary.contrastText,
            padding: theme.spacing(2),
          })}
        >
          {`Edit ${expenseTypes && expenseTypes.expenseType}`}
        </DialogTitle>
        <DialogContent
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            minWidth: "300px",
            padding: theme.spacing(2),
          })}
        >
          <Divider style={{ margin: "2px 0" }} />
          <Typography
            sx={{ fontWeight: "bold" }}
            variant="body1"
          >{`Current Amount: ${
            expenseTypes && expenseTypes.originalAmount.toFixed(2)
          } SLE`}</Typography>
          <Divider style={{ margin: "2px 0" }} />
          <TextField
            autoFocus
            label="New Amount"
            value={extraAmount}
            onChange={(e) => setExtraAmount(Number(e.target.value))}
            type="number"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="caption">SLE</Typography>
                </InputAdornment>
              ),
            }}
          />
          <Divider style={{ margin: "2px 0" }} />
          <Typography
            sx={{ fontWeight: "bold" }}
            variant="body1"
          >{`Current Balance: ${
            expenseTypes && expenseTypes.balance.toFixed(2)
          } SLE`}</Typography>
          <Divider style={{ margin: "2px 0" }} />
          <TextField
            label="Current Balance + New Balance"
            value={expenseTypes ? expenseTypes.balance + extraAmount : 0}
            type="number"
            variant="outlined"
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="caption">SLE</Typography>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions
          sx={(theme) => ({
            padding: theme.spacing(1, 2),
          })}
        >
          <Button onClick={handleCloseEdit}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Expenses;
