import React, { useEffect, useMemo } from 'react';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import useToken from '../../useToken';
import GetDashboardUserData, { IDUserDataRow } from '../../../services/API/GetDashboardUserData';
import AddOfficeOnlyUser from './AddOfficeOnlyUser';
import { Box, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const DashboardUserData = () => {
  const columns = useMemo<MRT_ColumnDef<IDUserDataRow>[]>(
    () => [
      {
        accessorKey: 'fullname',
        header: 'Fullname',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'Gender',
        header: 'Gender',
      },
      {
        accessorKey: 'EmpAddress',
        header: 'Job Title',
      },
      {
        accessorKey: 'LoginDate',
        header: 'Last Login',
      },
    ],
    [],
  );

  const token = useToken().token;
  const [data, setData] = React.useState<IDUserDataRow[]>([]);
  const [openAdd, setOpenAdd] = React.useState<boolean>(false);
  const [reload, setReload] = React.useState<boolean>(false);

  useEffect(() => {
    GetDashboardUserData({ token }).then((response) => {
      if (response) {
        setData(response.data);
      }
    });
  }, [token]);

  const handleAddStaff = () => {
    setOpenAdd(true)
  }
  const handleClose = () => {
    setOpenAdd(false)
    setReload(true)
  }
  return <> <MaterialReactTable
    columns={columns}
    data={data}
    enableMultiRowSelection={false} //use radio buttons instead of checkboxes
    enableDensityToggle={false}
    initialState={{ density: 'compact', pagination: { pageIndex: 0, pageSize: window.innerHeight > 720 ? 15 : 10 }, }}
    muiTablePaginationProps={{
      rowsPerPageOptions: [5, 10, 20, 50],
    }}
    muiTableContainerProps={{
      sx: {
        overflow: "auto",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          width: "0.4em",
          height: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#1976d2",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#1976d2",
        },
      },
    }}
    enableFullScreenToggle={false}
    enableHiding={false}
    rowCount={data.length} 
    renderTopToolbarCustomActions={() => (
      <Box
        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
      >
        <Button
          color="info"
          onClick={handleAddStaff}
          startIcon={<AddCircleOutlineIcon />}
          variant="outlined"
        >Add New
        </Button>
      </Box>
    )}  
  />
     {openAdd && <AddOfficeOnlyUser handleClose={handleClose} />}
  </>
};
export default DashboardUserData;