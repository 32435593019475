import axios from "axios";
import apiURL from "../apiURL";


interface IGetGLDups {
    token: string;
    policyNumber: string;
    glcustomersID: number;
}

const baseURL = apiURL();

const GetGLDuplicateCount = async (props: IGetGLDups): Promise<number> => {
    try {
        const token = props.token;
        const response = await axios.get<number>(
            `${baseURL}GroupLifeEdit/GetGLDuplicateCount?policyNumber=${props.policyNumber}&glcustomersID=${props.glcustomersID}`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        return response.data;
    } catch (error) {
        // Handle the error here
        console.error('Error checking for duplicates:', error);
        throw error;
    }
};

export default GetGLDuplicateCount;
