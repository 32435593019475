import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Container } from "@mui/material";
import { TabContext } from "@mui/lab";

export default function LabTabs() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
      <Box
        sx={{ maxWidth: { xs: 600, sm: "100%" }, bgcolor: "background.paper" }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              scrollButtons
              variant="scrollable"
            >
              <Tab label="Cash ITI" value="1" />
              <Tab label="Fidelity" value="2" />
              <Tab label="Cash IV" value="3" />
              <Tab label="Burglary" value="4" />
              <Tab label="Fire" value="5" />
              <Tab label="Employee" value="6" />
              <Tab label="Marine" value="7" />
              <Tab label="Public" value="8" />
              <Tab label="Professional" value="9" />
              <Tab label="Contractor" value="10" />
            </TabList>
          </Box>
          <TabPanel value="1">CASH IN TRANSIT INSURANCE</TabPanel>
          <TabPanel value="2">FIDELITY GUARANTEE INSURANCE</TabPanel>
          <TabPanel value="3">CASH IN VAULT</TabPanel>
          <TabPanel value="4">BURGLARY</TabPanel>
          <TabPanel value="5">Fire</TabPanel>
          <TabPanel value="6">EMPLOYER'S LIABILITY</TabPanel>
          <TabPanel value="7">MARINE INSURANCE</TabPanel>
          <TabPanel value="8">PUBLIC LIABILITY</TabPanel>
          <TabPanel value="9">PROFESSIONAL INDEMNITY</TabPanel>
          <TabPanel value="10">CONTRACTORS ALL-RISKS</TabPanel>
        </TabContext>
      </Box>
    </Container>
  );
}
