import React, { useEffect, useMemo } from 'react';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import GetMotorData, { IMotorDataRow } from '../../../services/API/GetMotorData';
import useToken from '../../useToken';
import { Box, Button } from '@mui/material';
import AddNewMotor from './AddNewMotor';
import jwt_decode from "jwt-decode";
import { ISignInData } from '../../../services/API/signin';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import MotorDetails from './MotorDetails';
interface IMotorDataProp {
  isActive: boolean
}

const MotorData = (props: IMotorDataProp) => {
  const columns = useMemo<MRT_ColumnDef<IMotorDataRow>[]>(
    () => [
      {
        accessorKey: 'vehicleOwnerName', //access nested data with dot notation
        header: 'Vehicle Owner',
      },
      {
        accessorKey: 'addressDetail',
        header: 'Address',
      },
      {
        accessorKey: 'vehicleOwnerTelephone', //normal accessorKey
        header: 'Telephone',
      },
      {
        accessorKey: 'vehicleInsuranceType',
        header: 'Insurance Type',
      },
      {
        accessorKey: 'vehicleRegistration',
        header: 'Registration',
      },
      {
        accessorKey: 'vehicleInsuranceEndDate',
        header: 'End Date',
        Cell: ({ renderedCellValue, row }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: row.original.color === 1 ? theme.palette.error.light : row.original.color === 2 ? theme.palette.warning.light : theme.palette.success.light,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {(renderedCellValue as string).slice(0, 10)}
          </Box>
        ),
      },
      {
        accessorKey: 'vehicleType',
        header: 'Vehicle Type',
      },
      {
        accessorKey: 'marketerID',
        header: 'Marketer',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '25ch',
              p: '0.55rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
    ],
    [],
  );

  if (props.isActive) {
    columns.push({
      accessorKey: 'onliveDatabase',
      header: 'Status',
      Cell: ({ cell }) => (
        <Box
          component="span"
          sx={(theme) => ({
            backgroundColor: cell.getValue() === 'Online' ? theme.palette.success.dark : theme.palette.error.dark,
            borderRadius: '0.25rem',
            color: '#fff',
            maxWidth: '9ch',
            p: '0.25rem',
          })}
        >
          {cell.getValue()}
        </Box>
      ),
    },)
  }
  const token = useToken().token;
  const [rowSelection, setRowSelection] = React.useState<IMotorDataRow | undefined>(undefined);
  const [data, setData] = React.useState<IMotorDataRow[]>([]);
  const [openForm, setOpenForm] = React.useState<boolean>(false);
  const [reload, setReload] = React.useState<boolean>(false);
  const userData: ISignInData = jwt_decode(token);
  useEffect(() => {
    GetMotorData({ token }).then((response) => {
      if (response) {
        const newData = response.data.filter((rows) => rows.activeCustomer === props.isActive)
        setData(newData);
      }
    });
  }, [token, props.isActive, reload]);

  const HandleRowSelected = (row: IMotorDataRow) => {
    setOpenForm(true)
    setRowSelection(row)
  }

  const handleClose = (reload?: boolean) => {
    setOpenForm(false);
    setRowSelection(undefined)
    if (reload) {
      setReload(reload)
    }
  };

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    const filteredData = data.map((item) => ({
      Owner: item.vehicleOwnerName,
      Address: item.addressDetail,
      Telephone: item.vehicleOwnerTelephone,
      InsuranceType: item.vehicleInsuranceType,
      Registration: item.vehicleRegistration,
      End_Date: item.vehicleInsuranceEndDate,
      Vehicle_Type: item.vehicleType,
      Marketer: item.marketerID,
      Status: props.isActive ? item.onliveDatabase : '',
    }));
    csvExporter.generateCsv(filteredData);
  };
  return <> <MaterialReactTable
    columns={columns}
    data={data}
    enableMultiRowSelection={false} //use radio buttons instead of checkboxes
    muiTableBodyRowProps={({ row }) => ({
      onClick: () => HandleRowSelected(row.original),
      sx: { cursor: 'pointer' },
    })}
    enableDensityToggle={false}
    initialState={{ density: 'compact', pagination: { pageIndex: 0, pageSize: 15 }, }}
    muiTablePaginationProps={{
      rowsPerPageOptions: [5, 10, 20, 50],
    }}
    enableFullScreenToggle={false}
    enableHiding={false}
    rowCount={data.length}
    muiTableContainerProps={{
      sx: {
        height: data.length > 20 ? window.innerHeight - 300 + 'px' : undefined,
        overflow: "auto",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          width: "0.4em",
          height: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#1976d2",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#1976d2",
        },
      },
    }}
    renderTopToolbarCustomActions={() => (
      <Box
        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
      >
        <Button
          color="secondary"
          onClick={() => setOpenForm(true)}
          variant="contained"
          disabled={userData.CanEditMotorInsurance === "False"}
        >
          {props.isActive ? 'Add Motor Insurance' : 'Add Potential Customer'}
        </Button>
        <Button
          color="primary"
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
          variant="contained"
        >
          Export Motor Data
        </Button>
      </Box>
    )} />
    {openForm && !rowSelection && <AddNewMotor openUser={openForm} onClose={handleClose} existingUser={rowSelection} isActive={props.isActive} />}
    {openForm && rowSelection && <MotorDetails openUser={openForm} onClose={handleClose} existingUser={rowSelection} isActive={props.isActive} />}
  </>
};
export default MotorData;