import React, { useEffect, useMemo } from 'react';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import useToken from '../../useToken';
import { Box } from '@mui/material';
import GetEmployeeUserData, { IHOUserDataRow } from '../../../services/API/GetEmployeeUserData';

const EmployeeUserData = () => {
  const columns = useMemo<MRT_ColumnDef<IHOUserDataRow>[]>(
    () => [
      {
        accessorKey: 'MarketerID',
        header: 'MarketerID',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '25ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
        width: 80
      },
      {
        accessorKey: 'fullname',
        header: 'Fullname',
      },
      {
        accessorKey: 'Gender',
        header: 'Gender',
      },
      {
        accessorKey: 'Telephone',
        header: 'Telephone',
      },
      {
        accessorKey: 'RegDate',
        header: 'RegDate',
      },
    ],
    [],
  );

  const token = useToken().token;
  const [data, setData] = React.useState<IHOUserDataRow[]>([]);

  useEffect(() => {
    GetEmployeeUserData({ token }).then((response) => {
      if (response) {
        setData(response.data);
      }
    });
  }, [token]);

  return <> <MaterialReactTable
    columns={columns}
    data={data}
    enableMultiRowSelection={false} //use radio buttons instead of checkboxes
    enableDensityToggle={false}
    initialState={{ density: 'compact', pagination: { pageIndex: 0, pageSize: window.innerHeight > 720 ? 15 : 10 }, }}
    muiTablePaginationProps={{
      rowsPerPageOptions: [5, 10, 20, 50],
    }}
    muiTableContainerProps={{
      sx: {
        overflow: "auto",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          width: "0.4em",
          height: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#1976d2",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#1976d2",
        },
      },
    }}
    enableFullScreenToggle={false}
    enableHiding={false}
    rowCount={data.length} />
  </>
};
export default EmployeeUserData;