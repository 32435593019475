import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";

const baseURL = apiURL();

export interface IGetExpenseTypesListRow {
  requestID: number;
  requestType: string;
}

export interface IGetExpenseTypesListRowData {
  data: IGetExpenseTypesListRow[];
}
interface IGetExpenseTypesList {
  token: string;
}
const GetExpenseTypesList = async (props: IGetExpenseTypesList) => {
  const token = props.token;

  try {
    const data = await axios({
      method: "get",
      responseType: "json",
      url: baseURL + `ExpenseRequest/GetExpenseTypesList`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return data as IGetExpenseTypesListRowData;
  } catch (error) {
    // Call the handleAxiosError function with the error and redirect URL
    handleAxiosError(error, "/login"); // Adjust the URL as needed
    return undefined;
  }
};
export default GetExpenseTypesList;
