import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { BootstrapDialog, BootstrapDialogTitle } from '../Medical/AddNewUserStepper';
import { Autocomplete, Backdrop, Checkbox, CircularProgress, Container, DialogContentText, FormControlLabel, Grid, TextField } from '@mui/material';
import { IMotorDataRow } from '../../../services/API/GetMotorData';
import { IActiveUserState, MotorReducer } from './MotorReducer';
import { isMobile } from 'react-device-detect';
import AddNewMotorInsurance, { IAddNewMotorInsuranceProps } from '../../../services/API/AddNewMotorInsurance';
import useToken from '../../useToken';

interface IAddNewProps {
    openUser: boolean;
    onClose: () => void;
    existingUser?: IMotorDataRow
    isActive: boolean;
}

const steps = [
    {
        label: 'Owner & Vehicle Info',

    },
    {
        label: 'Addtional Drivers',

    },
];
const canProceed = (state: IActiveUserState) => {
    let val = true
    if (state.dataToSave.fullName === "") {
        val = false
    }
    if (state.dataToSave.telephone === "") {
        val = false
    }
    if (state.dataToSave.residentialAddress === "") {
        val = false
    }
    if (state.dataToSave.registration === "") {
        val = false
    }
    if (state.dataToSave.vehicleType === "") {
        val = false
    }
    if (state.dataToSave.insuranceType === "") {
        val = false
    }
    if (state.dataToSave.vehicleType === "None") {
        val = false
    }
    if (state.dataToSave.insuranceType === "None") {
        val = false
    }
    if (state.dataToSave.marketer === "") {
        val = false
    }
    if (state.dataToSave.marketer === "None") {
        val = false
    }
    if (state.dataToSave.isDuplicate) {
        val = false
    }

    return val
}
export default function AddNewMotor(props: IAddNewProps) {
    const token = useToken().token
    const { state, handleChange, handleDropDownChange, updateDriverList } = MotorReducer();
    const [activeStep, setActiveStep] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [displayError, setDisplayError] = React.useState<boolean>(false);
    const handleNext = () => {
        if (activeStep === 0 && !canProceed(state)) {
            setDisplayError(true)
        } else {
            setDisplayError(false)
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSave = async () => {
        const states = state.dataToSave
        setLoading(true);
        const InsuredDrivers = states.driversList.map((dep, i) => {
            return `${i}|${dep.fullname}¬${dep.driverTelephone}`;
        }).join(',');

        const dataToSend: IAddNewMotorInsuranceProps = {
            vehicleOwnerName: states.fullName,
            addressDetail: states.residentialAddress,
            vehicleOwnerTelephone: states.telephone,
            vehicleInsuranceType: states.insuranceType,
            marketerID: states.marketer,
            vehicleRegistration: states.registration,
            vehicleType: states.vehicleType,
            activeCustomer: props.isActive,
            vehicleInsuranceEndDate: states.insuranceExpiryDate,
            insuredDrivers: InsuredDrivers === "0|¬,1|¬,2|¬,3|¬" ? '' : InsuredDrivers,
            token: token
        }
        const Data = await AddNewMotorInsurance(dataToSend)
        if (Data === "success") {
            setLoading(false);
            props.onClose();
        }
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 8, mb: 9 }}>
            <BootstrapDialog
                open={props.openUser}
                maxWidth="md"
                disableEscapeKeyDown={true}
                hideBackdrop={true}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={props.onClose}
                >
                    {props.existingUser ? 'Exisitng Insurance' : props.isActive ? 'Add New Insurance' : 'Add Potential Customer'}
                </BootstrapDialogTitle>
                <div>
                    <Box sx={{ minWidth: 350, maxWidth: 400 }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel
                                        optional={
                                            index === 2 ? (
                                                <Typography variant="caption">Last step</Typography>
                                            ) : null
                                        }
                                    >
                                        {step.label}
                                    </StepLabel>
                                    <StepContent>
                                        {loading &&
                                            <Backdrop
                                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                                open={loading}
                                            >
                                                <CircularProgress color="inherit" />
                                            </Backdrop>}
                                        <div key={state.dataToSave.newPremium}>
                                            {activeStep === 0 ?
                                                (
                                                    <>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={8}>
                                                                <TextField
                                                                    required
                                                                    id="fullName"
                                                                    name="fullName"
                                                                    label="FullName"
                                                                    fullWidth
                                                                    inputProps={{ maxLength: 40 }}
                                                                    autoComplete="fullName"
                                                                    disabled={state.dataToSave.editing}
                                                                    defaultValue={state.dataToSave.fullName}
                                                                    variant="standard"
                                                                    onChange={handleChange}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    required
                                                                    id="telephone"
                                                                    name="telephone"
                                                                    label="Telephone"
                                                                    inputProps={{ maxLength: 20 }}
                                                                    disabled={state.dataToSave.editing}
                                                                    defaultValue={state.dataToSave.telephone}
                                                                    fullWidth
                                                                    autoComplete="tel-number"
                                                                    variant="standard"
                                                                    onChange={handleChange}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={8}>
                                                                <TextField
                                                                    required
                                                                    id="residentialAddress"
                                                                    name="residentialAddress"
                                                                    inputProps={{ maxLength: 40 }}
                                                                    defaultValue={state.dataToSave.residentialAddress}
                                                                    label="Address"
                                                                    fullWidth
                                                                    disabled={state.dataToSave.editing}
                                                                    autoComplete="employer-address"
                                                                    variant="standard"
                                                                    onChange={handleChange}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    required
                                                                    id="registration"
                                                                    name="registration"
                                                                    inputProps={{ maxLength: 40 }}
                                                                    defaultValue={state.dataToSave.registration}
                                                                    label="Registration"
                                                                    fullWidth
                                                                    disabled={state.dataToSave.editing}
                                                                    autoComplete="employerName-name"
                                                                    variant="standard"
                                                                    onChange={handleChange}
                                                                    error={state.dataToSave.isDuplicate}
                                                                    helperText={state.dataToSave.isDuplicate ? 'This registration is a duplicate' : ''}
                                                                />
                                                            </Grid>
                                                            <div style={{ paddingTop: '20px' }}>
                                                                <Grid container spacing={isMobile ? 1 : -11}>
                                                                    <Grid item xs={12} sm={8}>
                                                                        <form>
                                                                            <Autocomplete
                                                                                disablePortal
                                                                                id="vehicleType"
                                                                                value={state.dataToSave.vehicleType || "None"}
                                                                                disabled={state.dataToSave.editing}
                                                                                options={state.dataToSave.vehicleTypeOptions}
                                                                                sx={{ width: 170 }}
                                                                                disableClearable
                                                                                renderInput={(params) => (
                                                                                    <TextField {...params} label="vehicleType" />
                                                                                )}
                                                                                onChange={handleDropDownChange}
                                                                            />
                                                                        </form>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <form>
                                                                            <Autocomplete
                                                                                disablePortal
                                                                                id="insuranceType"
                                                                                value={state.dataToSave.insuranceType || "None"}
                                                                                disabled={state.dataToSave.editing}
                                                                                options={state.dataToSave.insuranceTypeOptions}
                                                                                disableClearable
                                                                                sx={{ width: 190 }}
                                                                                renderInput={(params) => (
                                                                                    <TextField {...params} label="insuranceType" />
                                                                                )}
                                                                                onChange={handleDropDownChange}
                                                                            />
                                                                        </form>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                            <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={12} sm={8}>
                                                                        <TextField
                                                                            id="insuranceExpiryDate"
                                                                            name="insuranceExpiryDate"
                                                                            inputProps={{ maxLength: 20 }}
                                                                            defaultValue={state.dataToSave.insuranceExpiryDate}
                                                                            disabled={state.dataToSave.editing}
                                                                            label="Exipry Date"
                                                                            type="date"
                                                                            sx={{ width: 250 }}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <TextField
                                                                            required
                                                                            id="newPremium"
                                                                            name="newPremium"
                                                                            inputProps={{ maxLength: 40 }}
                                                                            defaultValue={state.dataToSave.newPremium}
                                                                            label="Premium"
                                                                            fullWidth
                                                                            disabled={true}
                                                                            autoComplete="employerName-name"
                                                                            variant="standard"
                                                                            onChange={handleChange}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                            <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={12} sm={8}>
                                                                        <Autocomplete
                                                                            disablePortal
                                                                            id="marketer"
                                                                            value={state.dataToSave.marketer || "None"}
                                                                            disabled={state.dataToSave.editing}
                                                                            options={state.dataToSave.marketerListOptions}
                                                                            sx={{ width: 270 }}
                                                                            disableClearable
                                                                            renderInput={(params) => (
                                                                                <TextField {...params} label="marketer" />
                                                                            )}
                                                                            onChange={handleDropDownChange}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                name="hasExtraDrivers"
                                                                                defaultChecked={state.dataToSave.hasExtraDrivers}
                                                                                onChange={handleChange}
                                                                                disabled={state.dataToSave.editing}
                                                                            />
                                                                        }
                                                                        label="Extra Drivers?"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                name="activeCustomer"
                                                                                defaultChecked={props.isActive}
                                                                                onChange={handleChange}
                                                                                disabled={true}
                                                                            />
                                                                        }
                                                                        label="Active Customer?"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {displayError && <DialogContentText color={'red'}>
                                                            Please select insurance or vehicle Type and make sure all fields are completed
                                                        </DialogContentText>}
                                                    </>
                                                )
                                                : activeStep === 1 && state.dataToSave.insuranceType !== 'Third Party' ?
                                                    <>
                                                        <Typography color={'Blue'}>Your have selected "{state.dataToSave.insuranceType} insurance"</Typography>

                                                        <Typography color={'red'}>You can add insurance, but purchase will have to be completed at head office.</Typography>
                                                    </> : activeStep === 1 && state.dataToSave.insuranceType === 'Third Party' && state.dataToSave.hasExtraDrivers ?
                                                        <Box>
                                                            <div style={{ border: "1px solid #33FFF3", padding: "10px" }}>
                                                                <Typography variant="subtitle1" gutterBottom color="red">
                                                                    Extra Driver Section
                                                                </Typography>
                                                                {Array.from({ length: 4 }).map((_, index) => (
                                                                    <div key={index} style={{ paddingBottom: '15px' }}>
                                                                        <React.Fragment key={index}>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <TextField
                                                                                    required
                                                                                    id={`fullname-${index}`}
                                                                                    name={`fullname-${index}`}
                                                                                    defaultValue={state.dataToSave.driversList[index].fullname}
                                                                                    label="Fullname"
                                                                                    variant="standard"
                                                                                    inputProps={{ maxLength: 40, pattern: "[^|¬#>]" }}
                                                                                    size="small"
                                                                                    style={{ fontSize: "5px", padding: "0px", width: 150 }}
                                                                                    disabled={state.dataToSave.editing}
                                                                                    onChange={updateDriverList}
                                                                                />
                                                                                <TextField
                                                                                    required
                                                                                    id={`driverTelephone-${index}`}
                                                                                    name={`driverTelephone-${index}`}
                                                                                    defaultValue={state.dataToSave.driversList[index].driverTelephone}
                                                                                    label="Telephone"
                                                                                    inputProps={{ maxLength: 20, pattern: "[^|¬#>]" }}
                                                                                    variant="standard"
                                                                                    size="small"
                                                                                    style={{ fontSize: "5px", padding: "0px", width: 100 }}
                                                                                    disabled={state.dataToSave.editing}
                                                                                    onChange={updateDriverList}
                                                                                />
                                                                            </Grid>
                                                                        </React.Fragment>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Box> : <Typography color={'red'}>Nothing to add click finish.</Typography>
                                            }
                                        </div>
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                                </Button>
                                                <Button
                                                    disabled={index === 0}
                                                    onClick={handleBack}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                            </div>
                                        </Box>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} sx={{ p: 3 }}>
                                <Typography>All steps completed</Typography>
                                <Button variant="contained" onClick={handleSave} sx={{ mt: 1, mr: 1 }} disabled={loading}>
                                    Add New Insurance
                                </Button>
                            </Paper>
                        )}
                    </Box>
                </div>
            </BootstrapDialog >
        </Container>
    );
}