import axios from "axios";
import apiURL from "../apiURL";

export interface IEditUserProfileData {
    EmployeeID: number,
    FirstName: string,
    MiddleNames: string,
    LastName: string,
    DateOfBirth: Date,
    Gender: string,
    Email: string,
    TelephoneNumber1: string,
    FirstLineAddress: string,
    City: string,
    Country: string,
    ExtraInfromation: string,
    UserImageName: string,
    JobTtile: string,
}

export interface IUpdateUserProfileProps {
    userProfileData: IEditUserProfileData,
    token: string;
}

const baseURL = apiURL();

const UpdateUserProfile = async (props: IUpdateUserProfileProps) => {
    const { userProfileData, token } = props;
    const formData = new FormData();
    formData.append("EmployeeID", String(userProfileData.EmployeeID));
    formData.append("FirstName", userProfileData.FirstName);
    formData.append("MiddleNames", userProfileData.MiddleNames);
    formData.append("LastName", userProfileData.LastName);
    formData.append("DateOfBirth", String(userProfileData.DateOfBirth));
    formData.append("Gender", userProfileData.Gender);
    formData.append("Email", userProfileData.Email);
    formData.append("TelephoneNumber1", userProfileData.TelephoneNumber1);
    formData.append("FirstLineAddress", userProfileData.FirstLineAddress);
    formData.append("City", userProfileData.City);
    formData.append("Country", userProfileData.Country);
    formData.append("ExtraInfromation", userProfileData.ExtraInfromation);
    formData.append("JobTtile", userProfileData.JobTtile);
    formData.append("UserImageName", userProfileData.UserImageName);
    try {
        await axios.post(
            `${baseURL}users/updateUserProfile`,
            formData,
            {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                }
            }
        );
    } catch (error) {
        console.error("Error updating user profile:", error);
        throw error;
    }
};

export default UpdateUserProfile;
