import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BootstrapDialog, BootstrapDialogTitle } from '../Medical/AddNewUserStepper';
import { Autocomplete, Backdrop, Checkbox, CircularProgress, Container, FormControlLabel, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { IMotorDataRow } from '../../../services/API/GetMotorData';
import { MotorReducer } from './MotorReducer';
import useToken from '../../useToken';
import { Edit } from '@mui/icons-material';
import LockResetIcon from '@mui/icons-material/LockReset';
import EditMotorInsurance, { IEditMotorInsuranceProps } from '../../../services/API/EditMotorInsurance';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IDialogSave } from '../FrontDeskPayment/PaymentData';
import AlertDialog from '../../../Common/AlertDialog';
import DeleteDuplicateMotor from '../../../services/API/DeleteDuplicateMotor';

interface IAddNewProps {
    openUser: boolean;
    onClose: (val?: boolean) => void;
    existingUser: IMotorDataRow
    isActive: boolean;
}

export default function AddNewMotor(props: IAddNewProps) {
    const token = useToken().token
    const { state, handleChange, handleDropDownChange, updateStateForExistingUser, unlockToEdit } = MotorReducer();
    const [loading, setLoading] = React.useState(false);
    const [showDaily, setShowDaily] = React.useState<IDialogSave>({
        open: false,
        headerText: '',
        mainText: ''
    });

    React.useEffect(() => {
        async function loadData() {
            if (props.existingUser) {
                await updateStateForExistingUser(props.existingUser);
            }
        }
        loadData();
    }, []);

    const handleDelete = () => {
        setShowDaily({
            open: true,
            headerText: `Are you Sure?`,
            mainText: `You are about to delete "${props.existingUser.vehicleOwnerName + ' - ' + props.existingUser.vehicleRegistration}". This Action cannot be undone.`
        })
    }

    const handleCancel = () => {
        setShowDaily({
            open: false,
            headerText: '',
            mainText: ''
        });
    };

    const handleSubmit = () => {
        if (props.existingUser.vehicleOwnerID) {
            setLoading(true);
            DeleteDuplicateMotor({
                vehicleID: props.existingUser.vehicleID,
                vehicleOwnerID: props.existingUser.vehicleOwnerID,
                token: token,
            }).then((value) => {
                if (value === 'success') {
                    setShowDaily({
                        open: false,
                        headerText: '',
                        mainText: ''
                    });
                    setLoading(false);
                    props.onClose(true);
                }
            })
        }
    };
    const handleSave = async () => {
        const states = state.dataToSave
        if (states.VehicleOwnerID) {
            setLoading(true);
            const dataToSend: IEditMotorInsuranceProps = {
                VehicleOwnerID: states.VehicleOwnerID,
                VehicleOwnerName: states.fullName,
                VehicleOwnerAddress: states.residentialAddress,
                VehicleOwnerTelephone: states.telephone,
                VehicleInsuranceEndDate: states.insuranceExpiryDate,
                ActiveCustomer: props.isActive,
                marketerID: states.marketer,
                VehicleType: states.vehicleType,
                Notes: states.notes,
                token: token
            }
            const Data = await EditMotorInsurance(dataToSend)
            if (Data === "success") {
                setLoading(false);
                props.onClose(true);
            }
        }
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 8, mb: 9 }}>
            <BootstrapDialog
                open={props.openUser}
                maxWidth="md"
                disableEscapeKeyDown={true}
                hideBackdrop={true}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={props.onClose}
                >
                    {'Existing Insurance - ' + state.dataToSave.registration}
                </BootstrapDialogTitle>
                <div>
                    <Box sx={{ flexGrow: 1, bgcolor: '#E6E6E6', display: 'flex', height: 400, width: 800, overflow: 'Hidden', padding: 5 }}>
                        {loading &&
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={loading}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>}

                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        required
                                        id="fullName"
                                        name="fullName"
                                        label="FullName"
                                        fullWidth
                                        inputProps={{ maxLength: 40 }}
                                        autoComplete="fullName"
                                        disabled={state.dataToSave.locked}
                                        defaultValue={state.dataToSave.fullName}
                                        variant="standard"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        required
                                        id="telephone"
                                        name="telephone"
                                        label="Telephone"
                                        inputProps={{ maxLength: 20 }}
                                        disabled={state.dataToSave.locked}
                                        defaultValue={state.dataToSave.telephone}
                                        fullWidth
                                        autoComplete="tel-number"
                                        variant="standard"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        required
                                        id="residentialAddress"
                                        name="residentialAddress"
                                        inputProps={{ maxLength: 40 }}
                                        defaultValue={state.dataToSave.residentialAddress}
                                        label="Address"
                                        fullWidth
                                        disabled={state.dataToSave.locked}
                                        autoComplete="employer-address"
                                        variant="standard"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        required
                                        id="registration"
                                        name="registration"
                                        inputProps={{ maxLength: 40 }}
                                        defaultValue={state.dataToSave.registration}
                                        label="Registration"
                                        fullWidth
                                        disabled={true}
                                        autoComplete="employerName-name"
                                        variant="standard"
                                        onChange={handleChange}
                                        error={state.dataToSave.isDuplicate}
                                        helperText={state.dataToSave.isDuplicate ? 'This registration is a duplicate' : ''}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <form>
                                        <Autocomplete
                                            disablePortal
                                            id="vehicleType"
                                            value={state.dataToSave.vehicleType || "None"}
                                            disabled={state.dataToSave.locked}
                                            options={state.dataToSave.vehicleTypeOptions}
                                            sx={{ width: 170 }}
                                            disableClearable
                                            renderInput={(params) => (
                                                <TextField {...params} label="vehicleType" />
                                            )}
                                            onChange={handleDropDownChange}
                                        />
                                    </form>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <form>
                                        <Autocomplete
                                            disablePortal
                                            id="insuranceType"
                                            value={state.dataToSave.insuranceType || "None"}
                                            disabled={true}
                                            options={state.dataToSave.insuranceTypeOptions}
                                            disableClearable
                                            sx={{ width: 190 }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="insuranceType" />
                                            )}
                                            onChange={handleDropDownChange}
                                        />
                                    </form>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        id="insuranceExpiryDate"
                                        name="insuranceExpiryDate"
                                        inputProps={{ maxLength: 20 }}
                                        defaultValue={state.dataToSave.insuranceExpiryDate}
                                        disabled={state.dataToSave.locked}
                                        label="Exipry Date"
                                        type="date"
                                        sx={{ width: 250 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        id="newPremium"
                                        name="newPremium"
                                        inputProps={{ maxLength: 40 }}
                                        defaultValue={state.dataToSave.newPremium}
                                        label="Premium"
                                        fullWidth
                                        disabled={true}
                                        autoComplete="employerName-name"
                                        variant="standard"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="activeCustomer"
                                                defaultChecked={props.isActive}
                                                onChange={handleChange}
                                                disabled={state.dataToSave.locked}
                                            />
                                        }
                                        label="Now Active?"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Autocomplete
                                        disablePortal
                                        id="marketer"
                                        value={state.dataToSave.marketer || "None"}
                                        disabled={state.dataToSave.locked}
                                        options={state.dataToSave.marketerListOptions}
                                        sx={{ width: 250 }}
                                        disableClearable
                                        renderInput={(params) => (
                                            <TextField {...params} label="marketer" />
                                        )}
                                        onChange={handleDropDownChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div style={{ border: "1px solid lightgrey", padding: "10px" }}>
                                        <Typography variant="subtitle1" gutterBottom color="red">
                                            Additional Drivers
                                        </Typography>
                                        <Box
                                            component="span"
                                            sx={(theme) => ({
                                                backgroundColor: theme.palette.success.light,
                                                borderRadius: '0.25rem',
                                                color: '#fff',
                                                maxWidth: '25ch',
                                                p: '0.25rem',
                                            })}
                                        >
                                            {state.dataToSave.extraDriver}
                                        </Box>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <TextField
                                        required
                                        id="notes"
                                        name="notes"
                                        inputProps={{ maxLength: 150 }}
                                        disabled={state.dataToSave.locked}
                                        defaultValue={state.dataToSave.notes}
                                        label="Notes"
                                        multiline={true}
                                        rows={3}
                                        fullWidth
                                        variant="standard"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, paddingRight: '10px', marginBottom: '10px' }}>
                        {!state.dataToSave.locked ? (
                            <Button variant="outlined" startIcon={<Edit />} onClick={handleSave}>
                                Save
                            </Button>
                        ) : (
                            <Button variant="outlined" startIcon={<LockResetIcon />} onClick={unlockToEdit}>
                                Unlock To Edit
                            </Button>
                        )}
                        {props.existingUser.duplicate === 1 && !state.dataToSave.locked &&
                            <Tooltip arrow placement="right" title="Delete Duplicate">
                                <IconButton color="error" onClick={handleDelete}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </Box>
                </div>
            </BootstrapDialog >
            {
                showDaily && <AlertDialog open={showDaily.open} setOpen={handleCancel}
                    setSave={handleSubmit}
                    headerText={showDaily.headerText}
                    mainText={showDaily.mainText} />
            }
        </Container>
    );
}