import axios from "axios";
import apiURL from "../apiURL";
import { IFambulDataRow } from "./GetFambulData";

export interface IFambulData {
    data: IFambulDataRow[],
}

interface ISearchValues {
    token: string;
    searchValue: string;
}

const baseURL = apiURL();

const SearchFambul = async (props: ISearchValues) => {
    try {
        const token = props.token;
        const data = await axios({
            method: "get",
            responseType: "json",
            url: `${baseURL}Engine/searchFambul?idCode=${props.searchValue}`,
            headers: { "Authorization": `Bearer ${token}` }
        })

        return data as IFambulData;
    } catch (error) {
        // Handle the error here
        console.error('Error checking for duplicates:', error);
        throw error;
    }
};

export default SearchFambul;
