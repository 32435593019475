import axios from "axios";
import apiURL from "../apiURL";

export interface IGetImageData {
    data: string,
}
interface IGetImage {
    token: string;
    subfolder: string;
    imageName: string;
}
const baseURL = apiURL()
const GetImage = async (props: IGetImage) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "arraybuffer",
        url: baseURL + `images/${props.subfolder}/${props.imageName}`,
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IGetImageData;

}
export default GetImage;