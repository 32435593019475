import axios from "axios";
import apiURL from "../apiURL";

export interface ILoanPaymentsRow {
    Datepaid: Date,
    Amountpaid: number
}

export interface ILoanPaymentsData {
    data: ILoanPaymentsRow[],
}

interface ILoanPaymentsProps {
    token: string;
    PolicyNo: string;
}
const baseURL = apiURL()
const GetCustomerLoanPayments = async (props: ILoanPaymentsProps) => {
    const token = props.token

    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + `Loan/GetCustomerLoanPayments?PolicyNo=${props.PolicyNo}`,
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as ILoanPaymentsData;

}
export default GetCustomerLoanPayments;