const resizeImage = (file: File, maxWidth: number, maxHeight: number, targetSizeKB: number, callback: (resizedFile: File) => void) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        const image = new Image();
        image.onload = () => {
            const canvas = document.createElement('canvas');
            let width = image.width;
            let height = image.height;
            if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
            }
            if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
            }
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(image, 0, 0, width, height);

                // Convert the canvas content to a Blob with specified quality
                canvas.toBlob((blob) => {
                    try {
                        if (blob && blob.size > targetSizeKB * 1024) {
                            const quality = (targetSizeKB * 1024) / blob.size;
                            canvas.toBlob((compressedBlob) => {
                                if (compressedBlob) {
                                    const resizedFile = new File([compressedBlob], file.name, { type: file.type });
                                    callback(resizedFile);
                                }
                            }, file.type, quality);
                        } else if (blob) {
                            const resizedFile = new File([blob], file.name, { type: file.type });
                            callback(resizedFile);
                        }
                    } catch (error) {
                        // Handle the error, possibly fallback to the original file
                        callback(file);
                    }
                }, file.type);
            }
        };
        if (event.target) {
            image.src = event.target.result as string;
        }
    };
    reader.readAsDataURL(file);
};

export default resizeImage;