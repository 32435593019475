import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";
import { ISignInData } from "../../../services/API/signin";

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  infoBox: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    marginBottom: theme.spacing(1),
    boxShadow: theme.shadows[1],
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0.2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0.5),
    },
  },
  label: {
    fontWeight: "bold",
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.6rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
  },
}));

// Define props interface
interface ColoredInfoBoxProps {
  label: string;
  value: number | string;
  bgColor: string;
  onEditClick?: () => void; // Optional edit click handler
  userData?: ISignInData;
}

const ColoredInfoBox: React.FC<ColoredInfoBoxProps> = ({
  label,
  value,
  bgColor,
  onEditClick,
  userData,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const formatCurrency = (amount: number) => {
    return `${
      amount.toLocaleString("en-SL", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + " SLE"
    }`;
  };

  return (
    <Box
      className={classes.infoBox}
      bgcolor={bgColor}
      p={isXs ? 1 : 2}
      borderRadius={1}
      width="100%"
    >
      <Typography variant={isXs ? "body1" : "subtitle1"}>
        <span className={classes.label}>{label}</span>
        {typeof value === "number" ? formatCurrency(value) : value}
      </Typography>
      {userData && userData.CanEditExpenseType === "True" && onEditClick && (
        <Tooltip arrow placement="top" title="Edit Original Amount">
          <IconButton
            onClick={onEditClick}
            aria-label="Edit Original Amount"
            sx={{
              position: "absolute",
              top: -1,
              right: 1,
              color: "white",
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default ColoredInfoBox;
