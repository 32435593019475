import GetMotorInsurance, { IMotorInsuranceRow } from '../../../services/API/GetMotorInsurance';
import { IMotorDataRow } from '../../../services/API/GetMotorData';
import GetVehicleTypes, { IVehicleTypeRow } from '../../../services/API/GetVehicleTypes';
import useToken from '../../useToken';
import { IDriverData, driversListRow } from './AddNewMotorStateless';
import { useReducer, useEffect } from 'react';
import GetAllMarketerUserData, { IAllMUserDataRow } from '../../../services/API/GetAllMarketerUserData';
import GetDuplicatesCount, { } from '../../../services/API/GetDuplicatesCount';

interface ITextFieldData {
    editing: boolean,
    VehicleOwnerID?: number,
    insuranceStatus?: string,
    fullName: string,
    telephone: string,
    residentialAddress: string,
    city: string,
    country: string,
    insuranceExpiryDate: string,
    registration: string,
    vehicleTypeList: IVehicleTypeRow[],
    newPremium: string,
    driversList: IDriverData[],
    insuranceType: string,
    vehicleType: string,
    insuranceTypeList: IMotorInsuranceRow[],
    vehicleTypeOptions: string[],
    insuranceTypeOptions: string[],
    hasExtraDrivers: boolean,
    activeCustomer: boolean,
    marketerList: IAllMUserDataRow[],
    marketer: string,
    marketerListOptions: string[],
    isDuplicate: boolean,
    locked: boolean,
    extraDriver: string,
    notes: string,
}

export interface IActiveUserState {
    dataToSave: ITextFieldData;
}
export const initialState: IActiveUserState = {
    dataToSave: {
        editing: false,
        fullName: '',
        telephone: '',
        residentialAddress: '',
        city: '',
        country: '',
        insuranceExpiryDate: new Date().toISOString().slice(0, 10),
        newPremium: '0',
        registration: '',
        insuranceType: '',
        vehicleType: '',
        vehicleTypeList: [],
        driversList: driversListRow,
        insuranceTypeList: [],
        vehicleTypeOptions: [],
        insuranceTypeOptions: [],
        hasExtraDrivers: false,
        activeCustomer: true,
        marketerList: [],
        marketerListOptions: [],
        marketer: '',
        isDuplicate: false,
        locked: true,
        extraDriver: '',
        notes: ''
    }
}

export type ActionType =
    | { type: 'SET_DROPDOWN_STATUS'; payload: { name: any; value: any } }
    | { type: 'UPDATE_FIELD'; payload: { name: string; value: string | boolean } }
    | { type: 'SET_INSURANCE_TYPES'; payload: IMotorInsuranceRow[] }
    | { type: 'UPDATE_DRIVER_NAMES'; payload: { depName: string; depValue: string } }
    | { type: 'SET_VEHICLE_TYPES'; payload: IVehicleTypeRow[] }
    | { type: 'UPDATE_EXISTING_CUST'; payload: IMotorDataRow }
    | { type: 'SET_MARKETER_LIST'; payload: IAllMUserDataRow[] }
    | { type: 'UPDATE_DUPLICATE'; payload: { duplicate: number; newReg: string } }
    | { type: 'UNLOCK_EDIT' };


export const reducer = (state: IActiveUserState, action: ActionType) => {
    switch (action.type) {
        case 'SET_DROPDOWN_STATUS':
            let updatedDataToSave = { ...state.dataToSave };
            if (action.payload.name === 'vehicleType' && updatedDataToSave.vehicleTypeList) {
                const matchingPackage = updatedDataToSave.vehicleTypeList.find(row => row.vehicleType === action.payload.value);
                if (matchingPackage) {
                    updatedDataToSave = {
                        ...updatedDataToSave,
                        newPremium: matchingPackage.insurancePrice.toLocaleString('en-SL', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) + ' SLL'
                    };
                }
            }
            return {
                ...state,
                dataToSave: {
                    ...updatedDataToSave,
                    [action.payload.name]: action.payload.value
                }
            };
        case 'UPDATE_FIELD':
            const { name, value } = action.payload;
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    [name]: typeof value === 'boolean' ? value : typeof value === 'string' ? value.trim() : value
                },
            };
        case 'UPDATE_DUPLICATE':
            const { duplicate, newReg } = action.payload;
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    isDuplicate: duplicate >= 1, registration: newReg
                },
            };
        case 'SET_INSURANCE_TYPES':
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave, insuranceTypeList: action.payload, insuranceTypeOptions: action.payload.map((row: IMotorInsuranceRow) => {
                        return row.vehicleInsuranceType;
                    })
                },
            };
        case 'SET_MARKETER_LIST':
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave, marketerList: action.payload, marketerListOptions: action.payload.map((row: IAllMUserDataRow) => {
                        return row.fullname;
                    })
                },
            };
        case 'SET_VEHICLE_TYPES':
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave, vehicleTypeList: action.payload, vehicleTypeOptions: action.payload.map((row: IVehicleTypeRow) => {
                        return row.vehicleType;
                    })
                },
            };
        case 'UPDATE_DRIVER_NAMES':
            const { depName, depValue } = action.payload;
            const nameInData = depName.split("-")[0];
            const rowInData = depName.split("-")[1];
            const updatedDriverNames = state.dataToSave.driversList.map((dep: any, index: number) => {
                if (index === Number(rowInData)) {
                    return {
                        ...dep,
                        [nameInData]: depValue.trim()
                    }
                } else {
                    return dep;
                }
            });
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    driversList: updatedDriverNames
                },
            };
        case 'UPDATE_EXISTING_CUST':
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    editing: true,
                    VehicleOwnerID: action.payload.vehicleOwnerID,
                    fullName: action.payload.vehicleOwnerName,
                    telephone: action.payload.vehicleOwnerTelephone,
                    residentialAddress: action.payload.addressDetail,
                    insuranceExpiryDate: new Date(action.payload.vehicleInsuranceEndDate).toISOString().slice(0, 10),
                    registration: action.payload.vehicleRegistration,
                    insuranceType: action.payload.vehicleInsuranceType,
                    vehicleType: action.payload.vehicleType,
                    newPremium: action.payload.insurancePrice.toLocaleString('en-SL', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }) + ' SLL',
                    extraDriver: action.payload.extraDriver,
                    notes: action.payload.notes,
                    marketer: action.payload.marketerID,
                }
            };
        case 'UNLOCK_EDIT':
            return {
                ...state,
                dataToSave: {
                    ...state.dataToSave,
                    locked: false
                },
            };
        default:
            return state;
    }
};

export const MotorReducer = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const token = useToken().token;
    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === "registration") {
            try {
                const response = await GetDuplicatesCount({ token, value });
                const count = response;
                dispatch({ type: 'UPDATE_DUPLICATE', payload: { duplicate: count, newReg: value } });
            } catch (error) {
                console.error('Error checking for duplicates:', error);
            }
        } else {
            dispatch({ type: 'UPDATE_FIELD', payload: { name, value: e.target.type === "checkbox" ? e.target.checked : value } });
        }
    };

    const handleDropDownChange = (e: any) => {
        const text = e.target.id;
        const splitText = text.split("-");
        const splitText2 = text.split("-")[0];
        const optionIndex = parseInt(splitText[2]);
        let selectedOption
        if (splitText2 === "insuranceType") {
            selectedOption = state.dataToSave.insuranceTypeList[optionIndex].vehicleInsuranceType;
        } else if (splitText2 === "marketer") {
            selectedOption = state.dataToSave.marketerList[optionIndex].fullname;
        } else {
            selectedOption = state.dataToSave.vehicleTypeList[optionIndex].vehicleType;
        };
        dispatch({ type: 'SET_DROPDOWN_STATUS', payload: { name: splitText2, value: selectedOption } });

    };

    const fetchMedPackage = (token: string) => {
        GetMotorInsurance({ token }).then((response) => {
            if (response) {
                dispatch({ type: 'SET_INSURANCE_TYPES', payload: response.data.filter((rows) => rows.isEnabled === true) });
            }
        });
    };

    useEffect(() => {
        fetchMedPackage(token);
    }, [token]);

    const fetchMarketerPackage = (token: string) => {
        GetAllMarketerUserData({ token }).then((response) => {
            if (response) {
                dispatch({ type: 'SET_MARKETER_LIST', payload: response.data });
            }
        });
    };

    useEffect(() => {
        fetchMarketerPackage(token);
    }, [token]);

    const updateDriverList = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch({ type: 'UPDATE_DRIVER_NAMES', payload: { depName: name, depValue: value } });
    };

    const fetchVehicleTypes = (token: string) => {
        GetVehicleTypes({ token }).then((response) => {
            if (response) {
                dispatch({ type: 'SET_VEHICLE_TYPES', payload: response.data });
            }
        });
    };

    useEffect(() => {
        fetchVehicleTypes(token);
    }, [token]);

    const updateStateForExistingUser = (existingUser: IMotorDataRow) => {
        dispatch({ type: 'UPDATE_EXISTING_CUST', payload: existingUser });
    }

    const unlockToEdit = () => {
        dispatch({ type: 'UNLOCK_EDIT' });
    }

    return { state, handleChange, handleDropDownChange, updateDriverList, updateStateForExistingUser, unlockToEdit };
};

