import React, { useState, useRef, useEffect } from "react";
import {
  Backdrop,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../components/Dashboard/Medical/AddNewUserStepper";
import { denseStyle } from "../components/Dashboard/Marketer/YourCommission";
import CheckPolicy from "../services/API/CheckPolicy";
import useToken from "../components/useToken";
import AddClaim, { IClaimsProps } from "../services/API/AddClaim";
import jwt_decode from "jwt-decode";
import { ISignInData } from "../services/API/signin";
import uploadFile, { IUploadFile } from "../services/API/UploadFile";
import { generateRandomString } from "./generateRandomString";
import {
  GroupDeathCover,
  claimTypes,
  fambulDeathCover,
  itemList,
} from "./ClaimsData";
import GetPolicyInfo, { IPolicyInfoRow } from "../services/API/GetPolicyInfo";

interface IClaimWizardProps {
  openUser: boolean;
  onClose: () => void;
}
interface IPolicyInfoRowExt extends IPolicyInfoRow {
  outStanding: number;
}

const ClaimWizard: React.FC<IClaimWizardProps> = ({ onClose, openUser }) => {
  const [selectedClaimType, setSelectedClaimType] = useState<number>(0);
  const [insurancetype, setInsuranceType] = useState<number>(0);
  const [policyNumber, setPolicyNumber] = useState<string>("");
  const [claimAmount, setClaimAmount] = useState<string>("");
  const [claimDetails, setClaimDetails] = useState<string>("");
  const [evidence, setEvidence] = useState<File[]>([]);
  const [step, setStep] = useState<number>(1);
  const [isPolicyValid, setIsPolicyValid] = useState<boolean | null>(null);
  const token = useToken().token;
  const userData: ISignInData = jwt_decode(token);
  const [fileCounts, setFileCounts] = useState<{ [key: string]: number }>({});
  const fileInputRefs = useRef<{
    [key: string]: React.MutableRefObject<HTMLInputElement | null>;
  }>({});
  const [policyInfo, setPolicyInfo] = useState<IPolicyInfoRowExt | null>(null);
  const [checked, setChecked] = useState<boolean>(false);
  const [checkedOutstanding, setCheckedOutstanding] = useState<boolean>(false);
  const [checkedDeduct, setCheckedDeduct] = useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const selectedClaimTypeObj = claimTypes.find(
    (claimType) => claimType.number === selectedClaimType
  );
  const handlePolicyLookup = async () => {
    try {
      const response = await CheckPolicy({
        token,
        policyNumber,
        insurancetype,
      });
      if (response > 0) {
        const responsePI = await GetPolicyInfo({ token, policyNumber });
        const newData: IPolicyInfoRowExt = {
          ...responsePI.data[0],
          outStanding: Number(
            (
              responsePI.data[0].totalContribution -
              (responsePI.data[0].Premium || 0) *
                responsePI.data[0].numberOfWeekdayOnScheme
            ).toFixed(2)
          ),
        };
        setPolicyInfo(newData);
        const value = responsePI.data[0].maxLoanAvaliable;
        setClaimAmount(String(value));
      }
      const count = response;

      if (count > 0) {
        setIsPolicyValid(true);
      } else {
        setIsPolicyValid(false);
      }
    } catch (error) {
      setIsPolicyValid(null); // Set to null in case of an error
    }
  };

  const handleClaimTypeChange = (event: SelectChangeEvent<number>) => {
    setSelectedClaimType(Number(event.target.value));
    setStep(2);
  };

  const handleInsuranceTypeChange = (event: SelectChangeEvent<number>) => {
    setInsuranceType(Number(event.target.value));
    setStep(3);
  };

  const handlePolicyNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPolicyNumber(event.target.value);
    setStep(4);
  };

  const handleClaimAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;

    // Check if the input value is a valid number
    if (!isNaN(Number(inputValue))) {
      // If it's a number, check if it exceeds the maximum loan available
      const maxLoanAvailable = policyInfo?.maxLoanAvaliable || 0;
      if (parseFloat(inputValue) <= maxLoanAvailable || checked) {
        // If it's within the allowed range, update the state
        setClaimAmount(inputValue);
      } else {
        // If it exceeds the maximum, set the loan amount to the maximum available
        setClaimAmount(maxLoanAvailable.toString());
      }
    }
  };
  const handleClaimDetailsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClaimDetails(event.target.value);
    setStep(5);
  };

  const handleEvidenceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    document: string
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setEvidence({
        ...evidence,
        [document]: Array.from(files),
      });
      setFileCounts((prevCounts) => ({
        ...prevCounts,
        [document]: files.length,
      }));
      setStep(6);
    }
  };

  const handleUploadButtonClick = (document: string) => {
    // Get the reference to the corresponding file input based on the document name
    const fileInputRefForDocument = fileInputRefs.current[document];
    if (fileInputRefForDocument && fileInputRefForDocument.current) {
      fileInputRefForDocument.current.click();
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const uniqueClaimRef = generateRandomString(15);
    const filesToUpload: { file: File; document: string }[] = [];
    for (const document in evidence) {
      if (evidence.hasOwnProperty(document)) {
        const files = evidence[document];
        if (Array.isArray(files) && files.length > 0) {
          // Push each file and its corresponding document to filesToUpload
          filesToUpload.push(
            ...Array.from(files).map((file: File) => ({ file, document }))
          );
        }
      }
    }
    for (const { file, document } of filesToUpload) {
      const dataToUpload: IUploadFile = {
        file,
        policy: policyNumber,
        UploadFileTypeID: insurancetype,
        token,
        ClaimRef: uniqueClaimRef,
        Description: document,
      };
      await uploadFile(dataToUpload);
    }

    const value =
      getAmount() -
        (policyInfo?.loanBalance ?? 0) -
        (checkedOutstanding ? 0 : policyInfo?.outStanding ?? 0) >=
      0
        ? 0
        : Math.abs(policyInfo?.outStanding ?? 0);
    const dataToSend: IClaimsProps = {
      ClaimRef: uniqueClaimRef,
      PolicyNo: policyNumber,
      Whoentered: userData.DisplayName,
      claimDetails: claimDetails,
      ClaimType: selectedClaimType,
      ClaimAmount:
        selectedClaimType === 2
          ? value
          : selectedClaimType === 3 || selectedClaimType === 4
          ? (policyInfo?.totalContribution ?? 0) * 0.4 +
            (selectedClaimType === 4
              ? 0.15 * ((policyInfo?.totalContribution ?? 0) * 0.4)
              : 0) -
            (policyInfo?.loanBalance ?? 0) -
            (checkedOutstanding
              ? 0
              : (policyInfo?.outStanding ?? 0) >= 0
              ? 0
              : Math.abs(policyInfo?.outStanding ?? 0)) -
            (checkedDeduct ? 50 : 0)
          : claimAmount === ""
          ? 0
          : Number(claimAmount),
      token: token,
      OverRideInfo: checked
        ? `Loan Amount was overridden from ${
            policyInfo?.maxLoanAvaliable.toLocaleString("en-SL", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " SLE"
          } to ${
            Number(claimAmount).toLocaleString("en-SL", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " SLE"
          } by ${userData.DisplayName}`
        : "0",
      AmountOverRide: checked ? 1 : 0,
      OverRideOutstanding: checkedOutstanding
        ? `Outstanding has been removed by ${userData.DisplayName}`
        : "0",
      checkedOutstanding: checkedOutstanding ? 1 : 0,
      checkedDeduct: checkedDeduct ? 1 : 0,
    };

    const Data = await AddClaim(dataToSend);
    if (Data === "success") {
      setLoading(false);
      setStep(6);
      onClose();
    } else {
      setLoading(false);
    }
  };

  const getAmount = () => {
    if (insurancetype === 4) {
      return (
        GroupDeathCover.find((cover) => cover.premium === policyInfo?.Premium)
          ?.cover ?? 0
      );
    } else {
      return (
        fambulDeathCover.find(
          (cover) => cover.premium / 20 === policyInfo?.Premium
        )?.cover ?? 0
      );
    }
  };

  const handleClose = () => {
    // Reset states when closing the wizard
    setInsuranceType(0);
    setPolicyNumber("");
    setClaimDetails("");
    setEvidence([]);
    setStep(1);
    onClose();
  };

  let filteredItemList;
  if (selectedClaimType === 1) {
    // If Loans is selected, filter to show only items with onlyFor: 1 or 0
    filteredItemList = itemList.filter((item) => item.onlyFor === 1);
  } else if (selectedClaimType === 2) {
    // If any other claim type is selected, show all items
    filteredItemList = itemList.filter((item) => item.onlyForDType === 1);
  } else if (selectedClaimType === 3 || selectedClaimType === 4) {
    // If any other claim type is selected, show all items
    filteredItemList = itemList.filter((item) => item.onlyForRType === 1);
  } else {
    // If any other claim type is selected, show all items
    filteredItemList = itemList;
  }
  // Populate file input refs
  useEffect(() => {
    selectedClaimTypeObj?.requiredDocuments.forEach((document) => {
      fileInputRefs.current[document] = React.createRef<HTMLInputElement>();
    });
  }, [selectedClaimTypeObj]);

  const Calnumber = insurancetype === 1 ? 240 : 12;
  const renderClaimTypeSection = () => {
    const sizeGrid = selectedClaimType === 4 ? 2.4 : 3;
    switch (selectedClaimType) {
      case 1:
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <FormGroup sx={{ paddingTop: 2 }}>
                  <FormControlLabel
                    sx={{ paddingLeft: 1, marginY: -1 }}
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          if (!e.target.checked) {
                            // Reset claimAmount when the checkbox is unchecked
                            setClaimAmount(
                              String(policyInfo?.maxLoanAvaliable)
                            );
                          }
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Overide Amount"
                  />
                  <FormControlLabel
                    sx={{ paddingLeft: 1, marginY: -1 }}
                    required
                    control={
                      <Checkbox
                        checked={checkedOutstanding}
                        onChange={(e) => {
                          setCheckedOutstanding(e.target.checked);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Remove Outstanding"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  id="loan-amount"
                  label="Loan Amount (Must be 50% or less of Total Contribution)"
                  variant="outlined"
                  value={claimAmount}
                  onChange={handleClaimAmountChange}
                  margin="normal"
                  type="number"
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  InputProps={{
                    inputProps: {
                      max: policyInfo?.maxLoanAvaliable || 0,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <TextField
                  variant="outlined"
                  label="10%"
                  value={(Number(claimAmount) * 0.1).toFixed(2)}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={2.5}>
                <TextField
                  variant="outlined"
                  label="Loan + 10%"
                  value={(
                    Number(claimAmount) +
                    Number(claimAmount) * 0.1
                  ).toFixed(2)}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={2.5}>
                <TextField
                  variant="outlined"
                  label="New Premium"
                  value={
                    //The new premium is the claim amount plus the interest divided by the 240 for fambul or 12 for group life
                    (
                      (Number(claimAmount) + Number(claimAmount) * 0.1) /
                        Calnumber +
                      (policyInfo?.Premium || 0)
                    ).toFixed(2)
                  }
                  disabled={true}
                />
              </Grid>
              <Grid item xs={2.5}>
                <TextField
                  variant="outlined"
                  label="Outstanding"
                  value={
                    (policyInfo?.outStanding ?? 0) >= 0
                      ? 0
                      : Math.abs(policyInfo?.outStanding ?? 0)
                  }
                  disabled={true}
                  style={{
                    backgroundColor:
                      (policyInfo?.outStanding ?? 0) >= 0
                        ? "#90ee90"
                        : "#ffcccb",
                  }}
                />
              </Grid>
              <Grid item xs={2.5}>
                <TextField
                  variant="outlined"
                  label="Repayment"
                  value={(
                    Number(claimAmount) +
                    Number(claimAmount) * 0.1 +
                    (checkedOutstanding
                      ? 0
                      : (policyInfo?.outStanding ?? 0) >= 0
                      ? 0
                      : Math.abs(policyInfo?.outStanding ?? 0))
                  ).toFixed(2)}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </>
        );
      case 2:
        return (
          <div style={{ paddingTop: 10 }}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Grid container spacing={0}>
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      label="Cover Amount"
                      value={getAmount()}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">SLE</Typography>
                          </InputAdornment>
                        ),
                      }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="h4" color="green">
                      -
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container spacing={0}>
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      label="Out Loan"
                      value={policyInfo?.loanBalance}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {" "}
                            <Typography variant="caption">SLE</Typography>
                          </InputAdornment>
                        ),
                      }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="h4" color="green">
                      -
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <TextField
                      variant="outlined"
                      label="Out Balance"
                      value={
                        (policyInfo?.outStanding ?? 0) >= 0
                          ? 0
                          : Math.abs(policyInfo?.outStanding ?? 0)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">SLE</Typography>
                          </InputAdornment>
                        ),
                      }}
                      disabled={true}
                      style={{
                        backgroundColor:
                          (policyInfo?.outStanding ?? 0) >= 0
                            ? "#90ee90"
                            : "#ffcccb",
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h4" color="green">
                      =
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label="Amount to Payout"
                  value={
                    getAmount() -
                    (policyInfo?.loanBalance ?? 0) -
                    (checkedOutstanding
                      ? 0
                      : (policyInfo?.outStanding ?? 0) >= 0
                      ? 0
                      : Math.abs(policyInfo?.outStanding ?? 0))
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">SLE</Typography>
                      </InputAdornment>
                    ),
                  }}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </div>
        );
      case 3:
      case 4:
        return (
          <div style={{ paddingTop: 10 }}>
            <Grid container spacing={1}>
              <Grid item xs={sizeGrid}>
                <Grid container spacing={0}>
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      label="40% of Contribution"
                      value={(policyInfo?.totalContribution ?? 0) * 0.4}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">SLE</Typography>
                          </InputAdornment>
                        ),
                      }}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    {selectedClaimType === 3 ? (
                      <Typography variant="h4" color="green">
                        -
                      </Typography>
                    ) : (
                      <Typography variant="h4" color="green">
                        +
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {selectedClaimType === 4 && (
                <Grid item xs={sizeGrid}>
                  <Grid container spacing={0}>
                    <Grid item xs={11}>
                      <TextField
                        variant="outlined"
                        label="15%"
                        value={
                          (policyInfo?.totalContribution ?? 0) * 0.4 * 0.15
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography variant="caption">SLE</Typography>
                            </InputAdornment>
                          ),
                        }}
                        disabled={true}
                        sx={{ paddingLeft: 1 }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="h4" color="green">
                        -
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={sizeGrid}>
                <Grid container spacing={0.5}>
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      label="Out Loan"
                      value={policyInfo?.loanBalance ?? 0}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">SLE</Typography>
                          </InputAdornment>
                        ),
                      }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="h4" color="green">
                      -
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={sizeGrid}>
                <Grid container spacing={0}>
                  <Grid item xs={11}>
                    <TextField
                      variant="outlined"
                      label="Out Balance"
                      value={
                        (policyInfo?.outStanding ?? 0) >= 0
                          ? 0
                          : Math.abs(policyInfo?.outStanding ?? 0)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">SLE</Typography>
                          </InputAdornment>
                        ),
                      }}
                      disabled={true}
                      sx={{
                        backgroundColor:
                          (policyInfo?.outStanding ?? 0) >= 0
                            ? "#90ee90"
                            : "#ffcccb",
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="h4" color="green">
                      =
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={sizeGrid}>
                <TextField
                  variant="outlined"
                  label="Amount to Payout"
                  value={
                    (policyInfo?.totalContribution ?? 0) * 0.4 +
                    (selectedClaimType === 4
                      ? 0.15 * ((policyInfo?.totalContribution ?? 0) * 0.4)
                      : 0) -
                    (policyInfo?.loanBalance ?? 0) -
                    (checkedOutstanding
                      ? 0
                      : (policyInfo?.outStanding ?? 0) >= 0
                      ? 0
                      : Math.abs(policyInfo?.outStanding ?? 0)) -
                    (checkedDeduct ? 50 : 0)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="caption">SLE</Typography>
                      </InputAdornment>
                    ),
                  }}
                  disabled={true}
                  sx={{ paddingLeft: 1 }}
                />
              </Grid>
            </Grid>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <BootstrapDialog
      open={openUser}
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown={true}
      hideBackdrop={true}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        wizard="true"
      >
        <Typography>Claim Wizard - Step {step}</Typography>
      </BootstrapDialogTitle>

      <div
        className="scroll-container"
        style={{
          padding: 10,
          overflow: isMobile ? "scroll" : "auto",
          maxHeight: "80vh",
        }}
      >
        <Card>
          {loading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {step >= 1 && (
            <FormControl fullWidth>
              <InputLabel id="claims-type-label">Select Claims Type</InputLabel>
              <Select
                labelId="claims-type-label"
                id="claims-type"
                value={selectedClaimType}
                label="Select Claim Type"
                onChange={handleClaimTypeChange}
                disabled={isPolicyValid !== null}
              >
                {claimTypes.map((item) => (
                  <MenuItem
                    style={denseStyle}
                    key={item.number}
                    value={item.number}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <div style={{ marginTop: "10px" }} />
          {step >= 2 && (
            <FormControl fullWidth>
              <InputLabel id="insurance-type-label">
                Select Insurance Type
              </InputLabel>
              <Select
                labelId="insurance-type-label"
                id="insurance-type"
                value={insurancetype}
                label="Select Insurance Type"
                onChange={handleInsuranceTypeChange}
                disabled={isPolicyValid !== null}
              >
                {filteredItemList.map((item) => (
                  <MenuItem
                    style={denseStyle}
                    key={item.number}
                    value={item.number}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {step >= 3 && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                id="policy-number"
                label="Policy Number"
                variant="outlined"
                value={policyNumber}
                onChange={handlePolicyNumberChange}
                margin="normal"
                disabled={isPolicyValid === true}
                style={{ marginRight: "10px" }}
              />
              <Button
                variant="outlined"
                onClick={handlePolicyLookup}
                disabled={isPolicyValid === true}
              >
                Lookup
              </Button>
              {isPolicyValid === false && (
                <Typography sx={{ paddingLeft: "3px" }} color="error">
                  Not Found
                </Typography>
              )}
            </div>
          )}

          {step >= 4 && isPolicyValid && (
            <>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    label="fullname"
                    value={policyInfo?.fullname}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2.5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Premium"
                    value={
                      policyInfo?.Premium.toLocaleString("en-SL", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) + " SLE"
                    }
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={2.5}>
                  <TextField
                    variant="outlined"
                    label="Reg Date"
                    value={policyInfo?.RegDate.slice(0, 10)}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    label="Total Contribution"
                    value={
                      policyInfo?.totalContribution.toLocaleString("en-SL", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) + " SLE"
                    }
                    disabled={true}
                  />
                </Grid>
                {selectedClaimType !== 1 && (
                  <Grid item xs={5}>
                    <FormControlLabel
                      sx={{ paddingLeft: 1, marginY: -1 }}
                      label="Remove Outstanding"
                      control={
                        <Checkbox
                          checked={checkedOutstanding}
                          onChange={(e) => {
                            setCheckedOutstanding(e.target.checked);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                    />
                    {selectedClaimType === 3 && (
                      <FormControlLabel
                        sx={{ paddingLeft: 1, marginY: -1 }}
                        required
                        control={
                          <Checkbox
                            checked={checkedDeduct}
                            onChange={(e) => {
                              setCheckedDeduct(e.target.checked);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="-50 SLE"
                      />
                    )}
                  </Grid>
                )}
                <Grid item xs={selectedClaimType !== 1 ? 7 : 12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Address"
                    value={policyInfo?.Address}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <>{renderClaimTypeSection()}</>
              <TextField
                fullWidth
                id="claim-details"
                label="Claim Details"
                variant="outlined"
                multiline
                rows={4}
                value={claimDetails}
                onChange={handleClaimDetailsChange}
                margin="normal"
              />
            </>
          )}
          {step >= 5 &&
            selectedClaimTypeObj &&
            selectedClaimTypeObj.requiredDocuments.map(
              (document: string, index: number) => {
                return (
                  <div
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => handleUploadButtonClick(document)}
                      style={{ marginRight: "10px" }}
                      size="small"
                    >
                      Upload {document}
                    </Button>
                    <label htmlFor={`evidence-upload-${index}`}>
                      <div style={{ marginTop: "5px" }} />
                      {fileCounts[document] && fileCounts[document] > 0
                        ? `file Added`
                        : "No files selected"}
                    </label>
                    <input
                      ref={fileInputRefs.current[document]}
                      style={{ display: "none" }}
                      accept="image/*, application/pdf"
                      id={`evidence-upload-${index}`}
                      type="file"
                      onChange={(event) =>
                        handleEvidenceChange(event, document)
                      }
                    />
                  </div>
                );
              }
            )}

          {step >= 6 && (
            <Button
              sx={{ marginBottom: "10px", marginTop: "15px" }}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading}
            >
              Submit Claim
            </Button>
          )}
        </Card>
      </div>
    </BootstrapDialog>
  );
};

export default ClaimWizard;
