import axios from "axios";
import apiURL from "../apiURL";

export interface IGLPaymentsRow {
    PaymentDate: Date,
    status: string,
    PaymentAmount: number,
}

export interface IGLPaymentsData {
    data: IGLPaymentsRow[],
}
interface IGetUser {
    token: string;
    PolicyNo: string;
}
const baseURL = apiURL()
const GetGLCustomerPayment = async (props: IGetUser) => {
    const token = props.token
    const data = await axios({
        method: "get",
        responseType: "json",
        url: baseURL + `GroupLifeEdit/GetGLCustomerPayment?PolicyNo=${props.PolicyNo}`,
        headers: { "Authorization": `Bearer ${token}` }
    })
    return data as IGLPaymentsData;
}
export default GetGLCustomerPayment;