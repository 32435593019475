import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../Medical/AddNewUserStepper";
import {
  Backdrop,
  CircularProgress,
  Container,
  DialogContentText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { IPaymentState, PaymentReducer } from "./PaymentReducer";
import useToken from "../../useToken";
import AddNewPayment, {
  IAddNewPaymentProps,
} from "../../../services/API/AddNewPayment";
import jwt_decode from "jwt-decode";
import { ISignInData } from "../../../services/API/signin";

interface IAddNewProps {
  openUser: boolean;
  onClose: () => void;
  isGroupLife: boolean;
}

export default function AddPayment(props: IAddNewProps) {
  const canProceed = (state: IPaymentState) => {
    let val = true;
    if (state.dataToSave.groupPolicyNumber === "") {
      val = false;
    }
    if (state.dataToSave.receiptNumber === "") {
      val = false;
    }
    if (state.dataToSave.paymentDate === "") {
      val = false;
    }
    if (Number(state.dataToSave.amountPaid) <= 0) {
      val = false;
    }
    if (state.dataToSave.paymentReason === "") {
      val = false;
    }
    if (!props.isGroupLife) {
      if (state.dataToSave.insured === "") {
        val = false;
      }
      if (state.dataToSave.agentBroker === "") {
        val = false;
      }
      if (state.dataToSave.office === "") {
        val = false;
      }
    }
    return val;
  };
  const token = useToken().token;
  const { state, handleChange } = PaymentReducer();
  const [loading, setLoading] = React.useState(false);
  const [displayError, setDisplayError] = React.useState<boolean>(false);
  const userData: ISignInData = jwt_decode(token);

  const handleSave = async () => {
    if (!canProceed(state)) {
      setDisplayError(true);
    } else {
      setLoading(true);
      const states = state.dataToSave;
      const dataToSend: IAddNewPaymentProps = {
        groupPolicyNumber: states.groupPolicyNumber,
        receiptNumber: states.receiptNumber,
        paymentDate: states.paymentDate,
        receivedFrom: states.receivedFrom,
        amountPaid: states.amountPaid,
        paymentReason: states.paymentReason,
        chequeNumber: states.chequeNumber,
        office: states.office,
        agentBroker: states.agentBroker,
        insured: states.insured,
        enteredBy: userData.DisplayName,
        IsGroupLife: props.isGroupLife,
        token: token,
      };
      const Data = await AddNewPayment(dataToSend);
      if (Data === "success") {
        setLoading(false);
        props.onClose();
      }
    }
  };

  return (
    <div>
      <Container maxWidth="lg" sx={{ mt: 8, mb: 9 }}>
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <BootstrapDialog
          open={props.openUser}
          maxWidth="md"
          disableEscapeKeyDown={true}
          hideBackdrop={true}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={props.onClose}
          >
            {props.isGroupLife ? "Add Group Receipt" : "Add Other Receipt"}
          </BootstrapDialogTitle>
          <div>
            <Box sx={{ minWidth: 350, maxWidth: 400, padding: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    id="groupPolicyNumber"
                    name="groupPolicyNumber"
                    label="Group/Policy#"
                    fullWidth
                    inputProps={{ maxLength: 40 }}
                    autoComplete="fullName"
                    disabled={state.dataToSave.editing}
                    defaultValue={state.dataToSave.groupPolicyNumber}
                    variant="standard"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    id="receiptNumber"
                    name="receiptNumber"
                    label="Receipt#"
                    inputProps={{ maxLength: 20 }}
                    disabled={state.dataToSave.editing}
                    defaultValue={state.dataToSave.receiptNumber}
                    fullWidth
                    autoComplete="tel-number"
                    variant="standard"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    id="chequeNumber"
                    name="chequeNumber"
                    label="Cheque#"
                    fullWidth
                    inputProps={{ maxLength: 40 }}
                    autoComplete="fullName"
                    disabled={state.dataToSave.editing}
                    defaultValue={state.dataToSave.chequeNumber}
                    variant="standard"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="receivedFrom"
                    name="receivedFrom"
                    inputProps={{ maxLength: 40 }}
                    defaultValue={state.dataToSave.receivedFrom}
                    label="Received From"
                    fullWidth
                    disabled={state.dataToSave.editing}
                    autoComplete="employer-address"
                    variant="standard"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="amountPaid"
                    name="amountPaid"
                    inputProps={{ maxLength: 40 }}
                    defaultValue={state.dataToSave.amountPaid}
                    label="Amount"
                    fullWidth
                    type="number"
                    disabled={state.dataToSave.editing}
                    autoComplete="employerName-name"
                    variant="standard"
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="caption">SLE</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <div
                  style={{
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="paymentDate"
                        name="paymentDate"
                        inputProps={{ maxLength: 20 }}
                        defaultValue={state.dataToSave.paymentDate}
                        disabled={true}
                        label="Payment Date"
                        type="date"
                        sx={{ width: 150 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="enteredBy"
                        name="enteredBy"
                        inputProps={{ maxLength: 40 }}
                        defaultValue={userData.DisplayName}
                        label="Entered By"
                        fullWidth
                        disabled={true}
                        autoComplete="employerName-name"
                        variant="standard"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        required
                        id="paymentReason"
                        name="paymentReason"
                        inputProps={{ maxLength: 40 }}
                        defaultValue={state.dataToSave.paymentReason}
                        label="Reason"
                        fullWidth
                        disabled={state.dataToSave.editing}
                        autoComplete="employerName-name"
                        variant="standard"
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </div>
                {!props.isGroupLife && (
                  <>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        id="office"
                        name="office"
                        inputProps={{ maxLength: 40 }}
                        defaultValue={state.dataToSave.office}
                        label="Office"
                        fullWidth
                        disabled={state.dataToSave.editing}
                        autoComplete="employer-address"
                        variant="standard"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        id="agentBroker"
                        name="agentBroker"
                        inputProps={{ maxLength: 40 }}
                        defaultValue={state.dataToSave.agentBroker}
                        label="Agent/Broker"
                        fullWidth
                        disabled={state.dataToSave.editing}
                        autoComplete="employerName-name"
                        variant="standard"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        id="insured"
                        name="insured"
                        inputProps={{ maxLength: 40 }}
                        defaultValue={state.dataToSave.insured}
                        label="Insured"
                        fullWidth
                        disabled={state.dataToSave.editing}
                        autoComplete="employer-address"
                        variant="standard"
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleSave}
                    disabled={loading}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {"Save"}
                  </Button>
                </div>
              </Box>
            </Box>
            {displayError && (
              <DialogContentText color={"red"}>
                Please make sure all fields are completed
              </DialogContentText>
            )}
          </div>
        </BootstrapDialog>
      </Container>
    </div>
  );
}
