import React, { useEffect } from 'react';
import useToken from '../../useToken';
import { Card, CardContent, Container, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { BootstrapDialog, BootstrapDialogTitle } from '../Medical/AddNewUserStepper';
import GetCommission, { IMarketerCommissionDataRow } from '../../../services/API/GetCommission';
import { SelectChangeEvent } from '@mui/material/Select';

interface TabPanelProps {
    openUser: boolean;
    onClose: () => void;
    marketerID: string
}
const CustomerCard = (data: IMarketerCommissionDataRow) => {
    return (
        <Card key={data.IDCode} sx={{ backgroundColor: '#f5f5f5', borderRadius: '12px', marginBottom: '16px' }}>
            <CardContent>
                <Typography variant="subtitle2" gutterBottom>
                    <span style={{ fontWeight: 'bold' }}>
                        Customer ID:
                    </span>
                    <span style={{ backgroundColor: 'red', borderRadius: '4px', padding: '2px' }}>
                        {data.IDCode}
                    </span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    <span style={{ fontWeight: 'bold' }}>
                        Total Paid:
                    </span>
                    <span style={{ backgroundColor: 'lightblue', borderRadius: '4px', padding: '2px' }}>
                        {data.Paid ? Number(data.Paid).toLocaleString('en-SL', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) + ' SLL' : Number(0).toLocaleString('en-SL', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) + ' SLL'}
                    </span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    <span style={{ fontWeight: 'bold' }}>
                        Commission:
                    </span>
                    <span style={{ backgroundColor: 'lightgreen', borderRadius: '4px', padding: '2px' }}>
                        {data.Commission ? Number(data.Commission).toLocaleString('en-SL', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) + ' SLL' : Number(0).toLocaleString('en-SL', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) + ' SLL'}
                    </span>
                </Typography>
            </CardContent>
        </Card>
    );
};
export const denseStyle = {
    minHeight: "10px",
    lineHeight: "20px",      // Smaller: Height of menu item row
    fontSize: "12px",
}
const YourCommission = (props: TabPanelProps) => {
    const token = useToken().token;
    const [data, setData] = React.useState<IMarketerCommissionDataRow[] | null>(null);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<string | null>(null);
    const mobileBreakpoint = 600;
    const isMobile = window.innerWidth < mobileBreakpoint;
    const marketerID = props.marketerID;
    const [selectedMonth, setSelectedMonth] = React.useState<number>(new Date().getMonth() + 1); // Current month
    const [totalCommission, setTotalCommission] = React.useState<number>(0);
    const [YearList, setYearList] = React.useState<{ year: number }[]>([])
    const [selectedYear, setSelectedYear] = React.useState<number>(new Date().getFullYear()); // Current Year


    useEffect(() => {
        // Generate the list of years for the last 3 years
        const currentYear = new Date().getFullYear();
        const last3Years = Array.from({ length: 3 }, (_, index) => ({ year: currentYear - index }));
        setYearList(last3Years);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await GetCommission({ token, marketerID, selectedMonth, selectedYear });
                calculateTotalCommission(response.data);
                setData(response.data);
            } catch (error) {
                setError('Error fetching data. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [token, marketerID, selectedMonth, selectedYear]);

    const handleClose = () => {
        props.onClose();
    };
    const calculateTotalCommission = (commissionData: IMarketerCommissionDataRow[] | null) => {
        if (commissionData) {
            const total = commissionData.reduce((acc, item) => acc + (Number(item.Commission) || 0), 0);
            setTotalCommission(total);
        }
    };
    const handleMonthChange = (event: SelectChangeEvent<number>) => {
        setSelectedMonth(Number(event.target.value));
    };

    const handleYearChange = (event: SelectChangeEvent<number>) => {
        setSelectedYear(Number(event.target.value));
    };
    return (
        <Container maxWidth="lg" sx={{ mt: 8, mb: 9 }}>
            <BootstrapDialog
                open={props.openUser}
                fullWidth
                maxWidth="lg"
                disableEscapeKeyDown={true}
                hideBackdrop={true}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {'Your Commission'}
                </BootstrapDialogTitle>
                <div className="scroll-container" style={{ overflow: isMobile ? 'scroll' : 'auto', maxHeight: '80vh', minHeight: '70vh', padding: '5px' }}>
                    <FormControl sx={(theme) => ({ marginBottom: '20px', marginLeft: '10px', minWidth: '150px', Width: '350px', border: `1px solid ${theme.palette.primary.dark}`, borderRadius: '10px' })}>
                        <InputLabel id="select-month-label" sx={(theme) => ({ color: theme.palette.primary.dark })}>Select Month</InputLabel>
                        <Select
                            labelId="select-month-label"
                            id="select-month"
                            value={selectedMonth}
                            onChange={handleMonthChange}
                        >
                            <MenuItem style={denseStyle} value={1}>January</MenuItem>
                            <MenuItem style={denseStyle} value={2}>February</MenuItem>
                            <MenuItem style={denseStyle} value={3}>March</MenuItem>
                            <MenuItem style={denseStyle} value={4}>April</MenuItem>
                            <MenuItem style={denseStyle} value={5}>May</MenuItem>
                            <MenuItem style={denseStyle} value={6}>June</MenuItem>
                            <MenuItem style={denseStyle} value={7}>July</MenuItem>
                            <MenuItem style={denseStyle} value={8}>August</MenuItem>
                            <MenuItem style={denseStyle} value={9}>September</MenuItem>
                            <MenuItem style={denseStyle} value={10}>October</MenuItem>
                            <MenuItem style={denseStyle} value={11}>November</MenuItem>
                            <MenuItem style={denseStyle} value={12}>December</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={(theme) => ({ marginLeft: '5px', minWidth: '100px', maxWidth: '200px', border: `1px solid ${theme.palette.secondary.dark}`, borderRadius: '10px' })}>
                        <InputLabel id="select-year-label" sx={(theme) => ({ color: theme.palette.secondary.dark })} >Select Year</InputLabel>
                        <Select
                            labelId="select-year-label"
                            id="select-year"
                            value={selectedYear}
                            onChange={handleYearChange}
                        >
                            {YearList.map((yearItem) => (
                                <MenuItem key={yearItem.year} value={yearItem.year}>
                                    {yearItem.year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Typography variant="subtitle2" gutterBottom>
                        <span style={{ fontWeight: 'bold', padding: '10px' }}>
                            Total Commission:
                        </span>
                        <span style={{ backgroundColor: 'green', borderRadius: '4px', padding: '2px' }}>
                            {Number(totalCommission).toLocaleString('en-SL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} SLL
                        </span>
                    </Typography>
                    {loading && <Typography>Loading...</Typography>}
                    {error && <Typography color="error">{error}</Typography>}
                    {data?.length === 0 && <Typography>No result found</Typography>}
                    {data && data.map((rowData) => (
                        CustomerCard(rowData)
                    ))}
                </div>
            </BootstrapDialog>
        </Container >
    );
};

export default YourCommission;
