import * as React from "react";
import Tab from "@mui/material/Tab";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Box } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import FambulData from "./fambulData";
import { ManageSearch } from "@mui/icons-material";
import SearchEngine from "./SearchEngine";
import GroupLifeData from "../GroupLife/GroupLifeData";
import UmbrellaIcon from "@mui/icons-material/Umbrella";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import QualifiedV1Card from "./QualifiedV1Card";

const FambulInsurance: React.FC = () => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div
      className="tabs-container"
      style={{ marginTop: "65px", position: "fixed", width: "100%", zIndex: 1 }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              icon={<GroupAddIcon />}
              label="Fambul V1"
              value="1"
              sx={{ color: "green" }}
            />
            <Tab
              icon={<UmbrellaIcon />}
              label="Fambul V2"
              value="2"
              sx={{ color: "brown" }}
            />
            <Tab
              icon={<CardMembershipIcon />}
              label="Qualified for V1 Card"
              value="3"
              sx={{ color: "orange" }}
            />
            <Tab
              icon={<ManageSearch />}
              label="Search Engine"
              value="4"
              sx={{ color: "purple" }}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <main
            style={{
              height: window.innerHeight - 200 + "px",
              overflow: "hidden",
              marginRight: "230px",
            }}
          >
            <FambulData />
          </main>
        </TabPanel>
        <TabPanel value="2">
          <main
            style={{
              height: window.innerHeight - 200 + "px",
              overflow: "hidden",
              marginRight: "230px",
            }}
          >
            <GroupLifeData isFambulLife={1} />
          </main>
        </TabPanel>
        <TabPanel value="3">
          <main
            style={{
              height: window.innerHeight - 200 + "px",
              overflow: "hidden",
              marginRight: "230px",
            }}
          >
            <QualifiedV1Card />
          </main>
        </TabPanel>
        <TabPanel value="4">
          <main
            style={{
              height: window.innerHeight - 200 + "px",
              overflow: "hidden",
              marginRight: "230px",
            }}
          >
            <SearchEngine />
          </main>
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default FambulInsurance;
