import {
  Container,
  Grid,
  Link,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import Chart from "./Chart";
import Deposits from "./Deposits";
import Orders from "./Orders";
import FambulChart from "./FambulChart";
import MotorChart from "./MotorChart";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.slicoinsurance.com/">
        SLICO
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const DashboardPreview = () => {
  return (
    <div>
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Chart <Chart />*/}
          <Grid item xs={12} md={8} lg={9}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <Chart />
            </Paper>
          </Grid>
          {/* Recent Deposits */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 240,
              }}
            >
              <Deposits />
            </Paper>
          </Grid>
          {/* Recent Orders */}
          <Grid item xs={12} md={4} lg={4}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <FambulChart />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <MotorChart />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Orders />
            </Paper>
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    </div>
  );
};
export default DashboardPreview;
