import axios, { AxiosError } from "axios";
import apiURL from "../apiURL";

export interface IAddLoanPaymentProps {
    ClaimID: number,
    PolicyNo: string,
    AddedBy: string,
    AmountPaid: number,
    token: string,
}

const baseURL = apiURL()

const AddLoanPayment = async (props: IAddLoanPaymentProps) => {
    let value = ''
    const token = props.token
    const data = {
        ClaimID: props.ClaimID,
        PolicyNo: props.PolicyNo,
        AddedBy: props.AddedBy,
        AmountPaid: props.AmountPaid,
    }
    try {
        const response = await axios.post(baseURL + "Loan/AddLoanPayment", data, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if (response.status === 200) {
            value = "success"
        }
    } catch (error: AxiosError | any) {
        if (error.response) {
            value = error.response.data
        } else if (error.request) {
            value = "No response from server"
        } else {
            value = "Something went wrong"
        }
    }
    return value
}
export default AddLoanPayment;

