import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import { Badge, Collapse } from "@mui/material";
import DashboardPreview from "../DashboardPreview";
import MedicalInsurance from "../Medical/MedicalInsurance";
import FambulInsurance from "../Fambul/FambulInsurance";
import MotorInsurance from "../Motor/MotorInsurance";
import GroupLifeInsurance from "../GroupLife/GroupLifeInsurance";
import OtherInsurance from "../OtherInsurance/OtherInsurance";
import jwt_decode from "jwt-decode";
import { ISignInData } from "../../../services/API/signin";
import useToken from "../../useToken";
import GetDashBoardData from "../../../services/API/GetDashboardData";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FrontDeskPayment from "../FrontDeskPayment/FrontDeskPayment";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import Expenses from "../Expense/Expenses";
import { ExpandMore, ExpandLess, StarBorder } from "@mui/icons-material";
import CustomerList from "../MoneyBack/CustomerList";
import PaymentsIcon from "@mui/icons-material/Payments";
interface IMainListItemsProps {
  setButtonClick: (button: JSX.Element) => void;
}

export interface ISideCounts {
  medical?: number;
  fambul?: number;
  motor?: number;
  groupLife?: number;
  others?: number;
  frontDesk?: number;
  claimsNeedAction?: number;
  expense?: number;
}

const MainListItems = (props: IMainListItemsProps) => {
  const token = useToken().token;
  const userData: ISignInData = jwt_decode(token);
  const [counts, setCounts] = React.useState<ISideCounts>({
    medical: 0,
    fambul: 0,
    motor: 0,
    groupLife: 0,
    others: 0,
    frontDesk: 0,
    expense: 0,
  });
  const [openExpense, setOpenExpense] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await GetDashBoardData({ token });
      if (response) {
        setCounts({
          medical: response.medical ? response.medical : 0,
          fambul: response.fambul ? response.fambul : 0,
          motor: response.motor ? response.motor : 0,
          groupLife: response.groupLife ? response.groupLife : 0,
          others: response.others ? response.others : 0,
          frontDesk: response.frontDesk ? response.frontDesk : 0,
          expense: response.expense ? response.expense : 0,
        });
      } else {
        console.error("Dashboard data not found.");
      }
    };

    fetchData();
  }, [token]);

  const loadOthers = () => {
    props.setButtonClick(<OtherInsurance />);
  };
  const loadGroupLife = () => {
    props.setButtonClick(<GroupLifeInsurance />);
  };
  const loadMotor = () => {
    props.setButtonClick(<MotorInsurance />);
  };
  const loadMoneyBack = () => {
    props.setButtonClick(<CustomerList />);
  };
  const loadFambul = () => {
    props.setButtonClick(<FambulInsurance />);
  };
  const loadMed = () => {
    props.setButtonClick(<MedicalInsurance />);
  };
  const loadDash = () => {
    props.setButtonClick(<DashboardPreview />);
  };
  const loadPayment = () => {
    props.setButtonClick(<FrontDeskPayment />);
  };
  const loadExpenses = (type: number) => {
    props.setButtonClick(<Expenses type={type} />);
  };

  const openExpenseDrawer = () => {
    if (openExpense) {
      setOpenExpense(false);
    } else {
      setOpenExpense(true);
    }
  };
  return (
    <div>
      <React.Fragment>
        <ListItemButton onClick={loadDash} sx={{ paddingBottom: 0 }}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
        {userData.CanViewMedicalInsurance === "True" && (
          <ListItemButton onClick={loadMed} sx={{ paddingBottom: 0 }}>
            <ListItemIcon>
              <Badge badgeContent={counts.medical} color="error">
                <MedicalServicesIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Medical" />
          </ListItemButton>
        )}
        {userData.CanViewMotorInsurance === "True" && (
          <ListItemButton onClick={loadMotor} sx={{ paddingBottom: 0 }}>
            <ListItemIcon>
              <Badge badgeContent={counts.motor} color="error">
                <CarCrashIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Motor" />
          </ListItemButton>
        )}
        <ListItemButton onClick={loadMoneyBack} sx={{ paddingBottom: 0 }}>
          <ListItemIcon>
            <Badge>
              <PaymentsIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Money Back" />
        </ListItemButton>
        {Number(userData.CanViewInsurance) === 1 && (
          <>
            <ListItemButton onClick={loadFambul} sx={{ paddingBottom: 0 }}>
              <ListItemIcon>
                <Badge badgeContent={counts.fambul} color="error">
                  <FamilyRestroomIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary="Fambul" />
            </ListItemButton>

            <ListItemButton onClick={loadGroupLife} sx={{ paddingBottom: 0 }}>
              <ListItemIcon>
                <Badge badgeContent={counts.groupLife} color="error">
                  <BeachAccessIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary="Group Life" />
            </ListItemButton>
          </>
        )}
        {Number(userData.CanManageOtherInsurance) === 1 && (
          <>
            <ListItemButton onClick={loadOthers} sx={{ paddingBottom: 0 }}>
              <ListItemIcon>
                <Badge badgeContent={counts.others} color="error">
                  <OtherHousesIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary="Others" />
            </ListItemButton>
          </>
        )}
        {userData.CanReviewExpense === "True" && (
          <>
            <ListItemButton
              onClick={openExpenseDrawer}
              sx={{ paddingBottom: 0 }}
            >
              <ListItemIcon>
                <Badge badgeContent={counts.expense} color="error">
                  <BookOnlineIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary="Expenses" />
              {openExpense ? <ExpandMore /> : <ExpandLess />}
            </ListItemButton>
            <Collapse
              in={openExpense}
              timeout="auto"
              unmountOnExit
              sx={{ paddingLeft: "25px" }}
            >
              <ListItemButton
                onClick={(e) => loadExpenses(0)}
                sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
              >
                <ListItemText
                  primary="All"
                  primaryTypographyProps={{ style: { fontSize: "0.775rem" } }}
                  sx={{ marginLeft: "4px" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
                onClick={(e) => loadExpenses(1)}
              >
                <ListItemText
                  primary="Building"
                  primaryTypographyProps={{ style: { fontSize: "0.775rem" } }}
                  sx={{ marginLeft: "4px" }} // Adjust this value as needed
                />
              </ListItemButton>
              <ListItemButton
                sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
                onClick={(e) => loadExpenses(2)}
              >
                <ListItemText
                  primary="Office Expenditure - Life"
                  primaryTypographyProps={{ style: { fontSize: "0.775rem" } }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
                onClick={(e) => loadExpenses(5)}
              >
                <ListItemText
                  primary="Office Expenditure Non-Life"
                  primaryTypographyProps={{ style: { fontSize: "0.775rem" } }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
                onClick={(e) => loadExpenses(3)}
              >
                <ListItemText
                  primary="Office Expenditure General - NL"
                  primaryTypographyProps={{ style: { fontSize: "0.775rem" } }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
                onClick={(e) => loadExpenses(4)}
              >
                <ListItemText
                  primary="Single Cheque Voucher"
                  primaryTypographyProps={{ style: { fontSize: "0.775rem" } }}
                />
              </ListItemButton>
            </Collapse>
          </>
        )}
        {userData.CanViewPaymentReceipt === "True" && (
          <ListItemButton onClick={loadPayment}>
            <ListItemIcon>
              <Badge badgeContent={counts.frontDesk} color="error">
                <AttachMoneyIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Front-Desk Payment" />
          </ListItemButton>
        )}
      </React.Fragment>
    </div>
  );
};
export default MainListItems;
