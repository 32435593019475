import * as React from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../Medical/AddNewUserStepper";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FambulDetailsPage from "./FambulDetailsPage";
import FambulDependants from "./FambulDependants";
import FambulPayments from "./FambulPayments";
import LoanDetails from "../../../Common/LoanDetails";

interface TabPanelProps {
  openUser: boolean;
  onClose: (val?: boolean) => void;
  idCode: string;
}

const FambulDetails = (props: TabPanelProps) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClose = () => {
    props.onClose(false);
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 9 }}>
      <BootstrapDialog
        open={props.openUser}
        fullWidth
        maxWidth="lg"
        disableEscapeKeyDown={true}
        hideBackdrop={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {"Existing Customer"}
        </BootstrapDialogTitle>
        <div>
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "#E6E6E6",
              display: "flex",
              height: 540,
              width: 1200,
              overflow: "Hidden",
            }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  orientation="vertical"
                  aria-label="lab API tabs example"
                >
                  <Tab label="Details" value="1" />
                  <Tab label="Dependants" value="2" />
                  <Tab label="Payments" value="3" />
                  <Tab label="Loans" value="4" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <FambulDetailsPage
                  IDCode={props.idCode}
                  onClose={props.onClose}
                />
              </TabPanel>
              <TabPanel value="2">
                <FambulDependants IDCode={props.idCode} />
              </TabPanel>
              <TabPanel value="3">
                <FambulPayments IDCode={props.idCode} />
              </TabPanel>
              <TabPanel value="4">
                <LoanDetails policyNo={props.idCode} />
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </BootstrapDialog>
    </Container>
  );
};
export default FambulDetails;
