import axios from "axios";
import apiURL from "../apiURL";
import { checkSessionExpiration } from "../checkSessionExpiration";
import { handleAxiosError } from "../axiosErrorHandler";

const baseURL = apiURL();

export interface IExpenseRequestRow {
  expenseRequestID: number;
  choice: string;
  department?: string;
  requestBy: string;
  requestDate: Date;
  edrNo: string;
  purpose: string;
  dateLastPurchased: Date;
  requestAmount: number;
  requestAmountGST: number;
  preparedBy: string;
  passedBy?: string;
  passedDate?: Date;
  approvedBy?: string;
  approvedDate?: Date;
  receivedBy?: string;
  receivedDate?: Date;
  employeeID: number;
  chequeNo: string;
  bank: string;
  extraInfo: string;
  requestID: number;
  requestType: string;
  requestAssigned: string;
  requestAssignedID: number;
  bwipVoucher: string;
  exRequestDocRef: string;
  requestStatus: string;
  rejectionNote: string;
  rejected: boolean;
  closed: boolean;
  receiverSignature: string;
  requestStatusOrder: number;
  queried: boolean;
}

export interface IExpenseRequestData {
  data: IExpenseRequestRow[];
}
interface IGetMissing {
  token: string;
  id: number;
  choice?: string | undefined;
}
const GetExpenseRequests = async (props: IGetMissing) => {
  const { token, id, choice } = props;

  // Check session expiration before proceeding
  if (!checkSessionExpiration()) {
    return;
  }

  try {
    const url = new URL(`${baseURL}ExpenseRequest/getExpenseRequests`);
    url.searchParams.append("employeeId", id.toString());
    if (choice) {
      url.searchParams.append("choice", choice);
    }

    const data = await axios({
      method: "get",
      responseType: "json",
      url: url.toString(),
      headers: { Authorization: `Bearer ${token}` },
    });

    return data as IExpenseRequestData;
  } catch (error) {
    // Call the handleAxiosError function with the error and redirect URL
    handleAxiosError(error, "/login"); // Adjust the URL as needed
    return undefined;
  }
};

export default GetExpenseRequests;
