import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  Button,
  Stack,
  Divider,
  CircularProgress,
  styled, // Import styled from @mui/system for custom styling
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { IExpenseRequestRow } from "../services/API/GetExpenseRequests";
import {
  INotesRow,
  addExpenseNote,
  getExpenseNotes,
} from "../services/API/Notes";
import useToken from "../components/useToken";
import jwt_decode from "jwt-decode";
import { ISignInData } from "../services/API/signin";
import uploadFile, { IUploadFile } from "../services/API/UploadFile";

const formatDate = (date: Date) => {
  const formattedDate = new Date(date);
  const day = formattedDate.getDate().toString().padStart(2, "0");
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
  const year = formattedDate.getFullYear();
  const hours = formattedDate.getHours().toString().padStart(2, "0");
  const minutes = formattedDate.getMinutes().toString().padStart(2, "0");

  return `${day}-${month}-${year} ${hours}:${minutes}`;
};

// Custom style for slim scrollbar
const SlimScrollbarDialogContent = styled(DialogContent)(({ theme }) => ({
  minWidth: "450px",
  maxWidth: "500px",
  maxHeight: "500px",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "2px", // Width of the scrollbar
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#009aa6", // Color of the scrollbar thumb
    borderRadius: "8px", // Rounded corners of the thumb
  },
}));

interface NotesDialogProps {
  open: boolean;
  onClose: () => void;
  onCloseParent: (val?: boolean) => void;
  expenseData: IExpenseRequestRow | undefined;
}

const NotesDialog: React.FC<NotesDialogProps> = ({
  open,
  onClose,
  expenseData,
  onCloseParent,
}) => {
  const [notes, setNotes] = useState<INotesRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [newNote, setNewNote] = useState<string>("");
  const [noteError, setNoteError] = useState<string>(""); // State for error message
  const token = useToken().token;
  const userData: ISignInData = jwt_decode(token);
  const [filesToUpload, setFilesToUpload] = React.useState<File[]>([]);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const fetchNotes = async () => {
      if (expenseData) {
        setLoading(true);
        const dataToSend = {
          ExpenseRequestID: expenseData.expenseRequestID,
          token: token,
        };

        try {
          const response = await getExpenseNotes(dataToSend);
          if (response) {
            setNotes(response.data); // Assuming response.data contains the array of notes
          }
        } catch (error) {
          console.error("Error fetching expense notes:", error);
          // Handle error as per your application's needs
        } finally {
          setLoading(false);
        }
      }
    };

    if (open) {
      fetchNotes();
    }
  }, [token, expenseData, open]);

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFilesToUpload(Array.from(files));
    }
  };

  const handleAddNote = async () => {
    if (newNote.trim() === "") {
      setNoteError("Please enter a note."); // Set error message
      return;
    } else {
      setNoteError(""); // Clear error message
    }

    if (expenseData) {
      setLoading(true);
      const dataToSend = {
        ExpenseRequestID: expenseData.expenseRequestID,
        token: token,
        ExpenseRequestNote: newNote,
        EnteredBy: userData.DisplayName,
        Type: 3,
      };

      if (filesToUpload.length > 0) {
        for (let i = 0; i < filesToUpload.length; i++) {
          const dataToUpload: IUploadFile = {
            file: filesToUpload[i],
            policy: expenseData.exRequestDocRef,
            UploadFileTypeID: 15,
            token: token,
            OtherDocs: expenseData.exRequestDocRef,
          };
          await uploadFile(dataToUpload);
        }
      }

      try {
        await addExpenseNote(dataToSend);
        setNewNote("");
        onCloseParent(true);
        onClose();
      } catch (error) {
        console.error("Error adding expense note:", error);
        // Handle error as per your application's needs
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={true}
      hideBackdrop={true}
    >
      <DialogTitle
        sx={(theme) => ({
          background: theme.palette.grey[300],
        })}
      >
        Notes
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
          })}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <SlimScrollbarDialogContent dividers>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {notes.length === 0 ? (
              <Typography variant="body2">No notes found.</Typography>
            ) : (
              notes.map((note, index) => (
                <Stack
                  key={index}
                  direction="column"
                  spacing={1}
                  sx={{
                    mb: 2,
                    bgcolor: note.Type === 3 ? "#009aa6" : "#eeeeee",
                    p: 1,
                    borderRadius: "10px", // Rounded corners
                  }}
                >
                  <Stack direction="row" alignItems="center">
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      {note.EnteredBy}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ ml: "auto" }}
                    >
                      {formatDate(note.Date)}{" "}
                    </Typography>
                  </Stack>
                  <Divider sx={{ my: 1 }} />
                  <Typography variant="body2">
                    {note.ExpenseRequestNote}
                  </Typography>
                </Stack>
              ))
            )}
          </>
        )}
        {expenseData && expenseData.requestStatus === "Queried" && (
          <>
            <Divider sx={{ my: 2 }} />
            <Stack direction="row" spacing={2} alignItems="center">
              <TextField
                label="Add Note"
                multiline
                rows={5}
                variant="outlined"
                size="small"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                fullWidth
                error={!!noteError} // Show error state based on noteError
                helperText={noteError} // Display error message
              />
              <Stack direction="column" spacing={2} alignItems="center">
                <>
                  <input
                    type="file"
                    accept=".jpeg, .jpg, .png"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={handleFileInputChange}
                    multiple
                  />
                  <Button variant="outlined" onClick={handleUploadButtonClick}>
                    {filesToUpload.length === 0
                      ? "Add Files"
                      : `Files ${filesToUpload.length}`}
                  </Button>
                </>
                <Button variant="contained" onClick={handleAddNote}>
                  Respond
                </Button>
              </Stack>
            </Stack>
          </>
        )}
      </SlimScrollbarDialogContent>
    </Dialog>
  );
};

export default NotesDialog;
