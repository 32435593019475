import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
} from "@mui/material";

interface SignatureDialogProps {
  onClose: () => void;
  onSave: (dataUrl: string, receiver: string) => void;
}

const SignatureCapture: React.FC<SignatureDialogProps> = ({
  onClose,
  onSave,
}) => {
  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const [receiver, setReceiver] = useState<string>("");
  const [error, setError] = useState<string>("");

  const clear = () => {
    sigCanvas.current?.clear();
    setError("");
  };

  const save = () => {
    if (sigCanvas.current?.isEmpty()) {
      setError("Please provide a signature.");
    } else {
      const dataUrl = sigCanvas.current
        ?.getTrimmedCanvas()
        .toDataURL("image/png");
      if (dataUrl && receiver) {
        onSave(dataUrl, receiver);
        onClose();
      } else {
        setError("Please enter the receiver's name.");
      }
    }
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Enter Receiver's Name and Signature</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <TextField
            fullWidth
            label="Receiver's Name"
            value={receiver}
            onChange={(e) => setReceiver(e.target.value)}
            error={Boolean(error && !receiver)}
          />
        </Box>
        <Box
          border="2px dashed #000"
          borderRadius="4px"
          padding="8px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ background: "#d3d3d3" }}
        >
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            canvasProps={{
              width: 500,
              height: 200,
              className: "sigCanvas",
              style: { border: "1px solid #000" },
            }}
          />
        </Box>
        {error && (
          <Typography color="error" variant="body2" mt={2}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={clear}>
          Clear
        </Button>
        <Button variant="contained" color="secondary" onClick={save}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignatureCapture;
