import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";
import { checkSessionExpiration } from "../checkSessionExpiration";

export interface IGroupLifeDataRow {
  fullname: string;
  email: string;
  dob: Date;
  glPremiumLevel: string;
  gender: string;
  residentialAddress: string;
  telephoneNumber: string;
  occupation: string;
  employer: string;
  payrollNumber: string;
  policyNumber: string;
  firstName: string;
  lastName: string;
  countDep: number;
  country: string;
  city: string;
  glcustomersID: number;
  title: string;
  fileName: string;
  otherDocs: string;
  isFambulLife: boolean;
  markedAsPrinted: boolean;
  whoPrintNote: string;
}

export interface IGroupLifeData {
  data: IGroupLifeDataRow[];
}

interface IGetGL {
  token: string;
  page: number;
  isFambulLife: number;
}
const GetGroupLifeData = async (props: IGetGL) => {
  const token = props.token;
  const page = props.page;
  const isFambulLife = props.isFambulLife;
  const baseURL = apiURL();

  // Check session expiration before proceeding
  if (!checkSessionExpiration()) {
    return;
  }

  try {
    const data = await axios({
      method: "get",
      responseType: "json",
      url:
        baseURL + `getGroupLifeData?page=${page}&isFambulLife=${isFambulLife}`,
      headers: { Authorization: `Bearer ${token}` },
    });

    return data as IGroupLifeData;
  } catch (error) {
    // Call the handleAxiosError function with the error and redirect URL
    handleAxiosError(error, "/login"); // Adjust the URL as needed
    return undefined;
  }
};

export default GetGroupLifeData;
