import React, { useEffect, useMemo } from 'react';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import useToken from '../../useToken';
import { Box, Button, Typography } from '@mui/material';
import jwt_decode from "jwt-decode";
import { ISignInData } from '../../../services/API/signin';
import GetPaymentData, { IPaymentDataRow } from '../../../services/API/GetPaymentData';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AddPayment from './AddPayment';
import AlertDialog from '../../../Common/AlertDialog';
import AddDailySum from '../../../services/API/AddDailySum';
import { format } from 'date-fns';
import GetAlreadySubmitted from '../../../services/API/GetAlreadySubmitted';

interface IPaymentProp {
    isGroupLife: boolean
}

export interface IDialogSave {
    open: boolean;
    headerText: string;
    mainText: string
}
const PaymentData = (props: IPaymentProp) => {
    const columns = useMemo<MRT_ColumnDef<IPaymentDataRow>[]>(
        () => [
            {
                accessorKey: 'groupPolicyNumber',
                header: 'Group/Policy#',
            },
            {
                accessorKey: 'receiptNumber',
                header: 'Receipt#',
            },
            {
                accessorKey: 'receivedFrom',
                header: 'From',
            },
            {
                accessorKey: 'paymentDate',
                header: 'PaymentDate',
                Cell: ({ cell }) => (cell.getValue() as string).slice(0, 10)
            },
            {
                accessorKey: 'amountPaid',
                header: 'Amount',
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={(theme) => ({
                            backgroundColor: theme.palette.secondary.dark,
                            borderRadius: '0.25rem',
                            color: '#fff',
                            maxWidth: '25ch',
                            p: '0.25rem',
                        })}
                    >
                        {`${Number(cell.getValue()).toLocaleString('en-SL', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) + ' SLL'}`}
                    </Box>
                ),
            },
            {
                accessorKey: 'paymentReason',
                header: 'Reason',
            },
            {
                accessorKey: 'chequeNumber',
                header: 'Cheque#',
            },
            {
                accessorKey: 'isApproved',
                header: 'Acknowledged',
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                    >
                        {cell.getValue() ? <CheckBoxOutlinedIcon color='success' /> : <ClearOutlinedIcon color='error' />}
                    </Box>
                ),
            },
        ],
        [],
    );

    if (!props.isGroupLife) {
        columns.push(
            {
                accessorKey: 'office',
                header: 'Office',
            },
            {
                accessorKey: 'agentBroker',
                header: 'Agent/Broker',
            },
            {
                accessorKey: 'insured',
                header: 'Insured',
            },
        )
    }

    const isToday = (date: Date) => {
        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    }
    const token = useToken().token;
    const [data, setData] = React.useState<IPaymentDataRow[]>([]);
    const [openForm, setOpenForm] = React.useState<boolean>(false);
    const [amountAddedToday, setAmountAddedToday] = React.useState<{ [date: string]: number }>({});
    const userData: ISignInData = jwt_decode(token);
    const [showDaily, setShowDaily] = React.useState<IDialogSave>({
        open: false,
        headerText: '',
        mainText: ''
    });
    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false);
    useEffect(() => {
        if (!openForm) {
            GetPaymentData({ token }).then((response) => {
                if (response) {
                    const newData = response.data.filter((rows) => rows.isGroupLife === props.isGroupLife)
                    const summedData: { [date: string]: number } = {};
                    newData.forEach((row) => {
                        const paymentDate = new Date(row.paymentDate);
                        if (isToday(paymentDate)) {
                            const formattedDate = paymentDate.toDateString();
                            if (summedData[formattedDate]) {
                                summedData[formattedDate] += row.amountPaid;
                            } else {
                                summedData[formattedDate] = row.amountPaid;
                            }
                        }
                    });
                    if (isToday(new Date()) && !summedData.hasOwnProperty(new Date().toDateString())) {
                        summedData[new Date().toDateString()] = 0;
                    }
                    setAmountAddedToday(summedData);
                    setData(newData);
                }
            })
        }
    }, [token, openForm]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await GetAlreadySubmitted({
                    token,
                    dailyDate: format(new Date(todayDateString), 'yyyy-MM-dd'),
                    isGroupLife: props.isGroupLife,
                });
                if (response === 1) {
                    setButtonDisabled(true);
                } else {
                    setButtonDisabled(false);
                }
            } catch (error) {
                // Handle any errors that occurred during the request
                console.error(error);
            }
        };

        fetchData();
    }, [token, props.isGroupLife]);

    const handleClose = () => {
        setOpenForm(false);
    };
    const handleCancel = () => {
        setShowDaily({
            open: false,
            headerText: '',
            mainText: ''
        });
    };

    const handleSubmit = () => {
        setButtonDisabled(true);
        AddDailySum({
            DailyDate: format(new Date(todayDateString), 'yyyy-MM-dd'),
            Amount: totalAmountCollectedToday,
            SubmitBy: userData.DisplayName,
            IsGroupLife: props.isGroupLife,
            token: token,
        })
        setShowDaily({
            open: false,
            headerText: '',
            mainText: ''
        });
    };
    const today = new Date();
    const todayDateString = today.toDateString();
    const totalAmountCollectedToday = amountAddedToday[todayDateString] || 0;
    return <> <MaterialReactTable
        columns={columns}
        data={data}
        enableMultiRowSelection={false} //use radio buttons instead of checkboxes
        enableDensityToggle={false}
        initialState={{ density: 'compact', pagination: { pageIndex: 0, pageSize: window.innerHeight > 720 ? 15 : 10 }, }}
        muiTablePaginationProps={{
            rowsPerPageOptions: [5, 10, 20, 50],
        }}
        muiTableContainerProps={{
            sx: {
                overflow: "auto",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                    width: "0.4em",
                    height: "0.4em",
                },
                "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#1976d2",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                    background: "#1976d2",
                },
            },
        }}
        enableFullScreenToggle={false}
        enableHiding={false}
        rowCount={data.length}
        renderTopToolbarCustomActions={() => (
            <Box
                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
            >
                <Button
                    color="secondary"
                    onClick={() => setOpenForm(true)}
                    variant="contained"
                    disabled={userData.CanEditPaymentReceipt === "False"}
                >
                    {props.isGroupLife ? 'Add Group Receipt' : 'Add Other Receipt'}
                </Button>
                <Box bgcolor="#F3F3F3" borderRadius="25px" border="2px solid #73AD21" p={1}>
                    <Typography color="#6FA8DC">
                        {`${totalAmountCollectedToday.toLocaleString('en-SL', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })} SLL`}{' '}
                        collected today {todayDateString}
                    </Typography>
                </Box>
                <Button
                    color="info"
                    onClick={() =>
                        setShowDaily({
                            open: true,
                            headerText: `Submit ${todayDateString} Collections`,
                            mainText: `You are about to submit "${totalAmountCollectedToday.toLocaleString('en-SL', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })} SLL" in ${props.isGroupLife ? 'Group Life' : 'Other'} for acknowledgment. This can only be done once a day.`
                        })
                    }
                    variant="outlined"
                    disabled={totalAmountCollectedToday <= 0 || buttonDisabled}
                    style={{ border: "2px solid #73AD21" }}
                >
                    submit-{todayDateString}
                </Button>
            </Box >

        )} />
        {
            openForm && <AddPayment openUser={openForm} onClose={handleClose} isGroupLife={props.isGroupLife} />
        }
        {
            showDaily.open && <AlertDialog open={showDaily.open} setOpen={handleCancel}
                setSave={handleSubmit}
                headerText={showDaily.headerText}
                mainText={showDaily.mainText} />
        }
    </>
};
export default PaymentData;