import React, { useEffect, useRef } from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import useToken from '../useToken';
import jwt_decode from "jwt-decode";
import { ISignInData } from '../../services/API/signin';
import uploadFile, { IUploadFile } from '../../services/API/UploadFile';
import resizeImage from '../../Common/ResizeImage';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import PaidSharpIcon from '@mui/icons-material/PaidSharp';
import HowToRegSharpIcon from '@mui/icons-material/HowToRegSharp';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import { useNavigate } from "react-router-dom";
import GetMarketerProfile, { IMarketerProfileDataRow } from '../../services/API/GetMarketerProfile';
import GetImage from '../../services/API/GetImage';
import AddFambul from '../Dashboard/Fambul/AddFambul';
import YourFambulList from '../Dashboard/Marketer/YourMarketList';
import YourCommission from '../Dashboard/Marketer/YourCommission';

const MarketerProfilePage = () => {
    const navigate = useNavigate();
    const [userImageToDisplay, setUserImageToDisplay] = React.useState<string | undefined>(undefined);
    const token = useToken().token;
    const [data, SetData] = React.useState<IMarketerProfileDataRow>();
    const userData: ISignInData = jwt_decode(token);
    const marketerID = userData.MarketerID
    const [reload, setReload] = React.useState<boolean>(false);
    const [previewImage, setPreviewImage] = React.useState<string | null>(null);
    const [addNewCustomer, setAddNewCustomer] = React.useState<boolean>(false);
    const [showCustomer, setShowCustomer] = React.useState<boolean>(false);
    const [showCommission, setShowCommission] = React.useState<boolean>(false);
    const [open, onClose] = React.useState(false);

    const handleAdd = () => {
        setAddNewCustomer(true)
        onClose(true);
    }

    const handleClose = () => {
        setAddNewCustomer(false)
        setShowCommission(false)
        setShowCustomer(false)
        onClose(false);
    };

    const handleCustomer = () => {
        setShowCustomer(true)
        onClose(true);
    }

    const handleCommission = () => {
        setShowCommission(true)
        onClose(true);
    }
    useEffect(() => {
        const subfolder = 'Profile'
        GetMarketerProfile({ token, marketerID }).then((response) => {
            if (response) {
                const newData = response.data[0]
                const imageName = newData.UserImageName
                if (imageName) {
                    GetImage({ token, subfolder, imageName })
                        .then((imageResponse) => {
                            const blob = new Blob([imageResponse.data], { type: 'image/jpeg' || 'image/png' });
                            const imageUrl = URL.createObjectURL(blob);
                            setUserImageToDisplay(imageUrl)
                        })
                }
                SetData(newData);
            }
        })
    }, [token, marketerID, reload]);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileInputChange = async () => {
        if (fileInputRef.current && fileInputRef.current.files) {
            const file = fileInputRef.current.files[0];
            if (file.size > 30000) {
                resizeImage(file, 300, 300, 30, (resizedFile: File) => {
                    const imageUrl = URL.createObjectURL(resizedFile);
                    setPreviewImage(imageUrl);
                    handleSave(resizedFile)
                });
            } else {
                const imageUrl = URL.createObjectURL(file);
                setPreviewImage(imageUrl);
                handleSave(file)
            }
        }
    };
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleSave = async (file: File) => {
        if (file && data) {
            const dataToUpload: IUploadFile = {
                file: file,
                marketerID: marketerID,
                UploadFileTypeID: 16,
                token: token
            };
            await uploadFile(dataToUpload);
            setReload(true)
        }
    };

    const logOut = () => {
        sessionStorage.clear();
        navigate('/Login');
    };

    const ButtonStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
    return (
        <div className="scroll-container" style={{ overflow: 'hidden', border: "1px solid lightblue", height: '99vh' }}>
            {data &&
                <Card sx={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0)' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CardHeader
                            title={
                                <Typography variant="h4" color="primary">
                                    SLICO
                                </Typography>
                            } />
                        <div style={{ paddingRight: '5px' }}>
                            <Button sx={ButtonStyle} variant="text" startIcon={<LogoutSharpIcon />} onClick={logOut}>
                                Logout
                            </Button>
                        </div>
                    </div>
                    <CardHeader
                        style={{ paddingTop: '5px' }}
                        title={
                            <Typography variant="h6" color="primary">
                                Marketer Profile
                            </Typography>
                        }
                    />
                    <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ height: '230px' }}>
                            <Grid container spacing={4} sx={{ alignItems: 'center' }}>
                                <Grid item xs={12} md={4}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Avatar alt="User Avatar" src={previewImage || userImageToDisplay} sx={{ width: 160, height: 160 }} />
                                    </Box>
                                    <input
                                        type="file"
                                        accept=".jpeg, .jpg, .png"
                                        style={{ display: "none" }}
                                        ref={fileInputRef}
                                        onChange={handleFileInputChange}
                                    />
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                        <Button sx={ButtonStyle} variant="text" startIcon={<Edit />} onClick={handleButtonClick}>
                                            Change
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}>
                        <Typography variant="subtitle1">{data.DisplayName}</Typography>
                        <Typography variant="subtitle1">{data.Email}</Typography>
                        <Typography variant="subtitle1">{data.Telephone}</Typography>
                    </Box>
                    <Box sx={{ mt: 2, alignItems: 'center', bottom: '0px', display: 'flex', position: 'fixed' }}>
                        <Button sx={ButtonStyle} variant="text" startIcon={<AddCircleSharpIcon />} onClick={handleAdd}>
                            Register
                        </Button>
                        <Button sx={ButtonStyle} variant="text" startIcon={<PaidSharpIcon />} onClick={handleCommission}>
                            Commission
                        </Button>
                        <Button sx={ButtonStyle} variant="text" startIcon={<HowToRegSharpIcon />} onClick={handleCustomer}>
                            {`Insured - ${data.registerCount}`}
                        </Button>
                    </Box>
                </Card >
            }
            {addNewCustomer && <AddFambul openUser={open} onClose={handleClose} marketerID={marketerID} />}
            {showCustomer && <YourFambulList openUser={open} onClose={handleClose} marketerID={marketerID} />}
            {showCommission && <YourCommission openUser={open} onClose={handleClose} marketerID={marketerID} />}
        </div >
    );
};
export default MarketerProfilePage;
