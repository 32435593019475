import CryptoJS from "crypto-js";

export default function hashData(
  password: string,
  isExternal: boolean,
  newPassword?: string,
  confirmPassword?: string,
) {
  if (!isExternal) {
    if (password || (newPassword && confirmPassword)) {
      let valueToHash = password; // if we are just login in
      if (newPassword && confirmPassword) {
        valueToHash = newPassword; // if we are resetting password
      }
      return CryptoJS.SHA256(valueToHash).toString(CryptoJS.enc.Base64);
    }
  } else {
    //This section is for makerters
    const utf8 = CryptoJS.enc.Utf8.parse(password);
    const base64 = CryptoJS.enc.Base64.stringify(utf8);
    return base64;
  }
}
