import axios from "axios";
import apiURL from "../apiURL";
import { IGroupLifeDataRow } from "./GetGroupLifeData";

export interface IFambulData {
  data: IGroupLifeDataRow[];
}

interface ISearchValues {
  token: string;
  searchValue: string;
}

const baseURL = apiURL();

const SearchGroupLife = async (props: ISearchValues) => {
  try {
    const token = props.token;
    const data = await axios({
      method: "get",
      responseType: "json",
      url: `${baseURL}Engine/SearchGroupLife?idCode=${props.searchValue}`,
      headers: { Authorization: `Bearer ${token}` },
    });

    return data as IFambulData;
  } catch (error) {
    // Handle the error here
    console.error("Error checking for duplicates:", error);
    throw error;
  }
};

export default SearchGroupLife;
