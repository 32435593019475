import axios from "axios";
import apiURL from "../apiURL";

export interface IPolicyInfoRow {
  fullname: string;
  Address: string;
  RegDate: string;
  totalContribution: number;
  maxLoanAvaliable: number;
  Premium: number;
  numberOfWeekdayOnScheme: number;
  loanBalance: number;
}

export interface IPolicyInfoData {
  data: IPolicyInfoRow[];
}

interface ICheckPolicy {
  token: string;
  policyNumber: string;
}

const baseURL = apiURL();

const GetPolicyInfo = async (props: ICheckPolicy) => {
  const token = props.token;
  const data = await axios({
    method: "get",
    responseType: "json",
    url: `${baseURL}Claim/getPolicyInfo?PolicyNumber=${props.policyNumber}`,
    headers: { Authorization: `Bearer ${token}` },
  });
  return data as IPolicyInfoData;
};

export default GetPolicyInfo;
