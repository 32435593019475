import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const steps = [
  "Customer Info",
  "Occupation & Dependants",
  "Medical History",
];

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  wizard?: boolean | string;
  expense?: string;
}

export function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={(theme) => ({
        m: 0,
        p: 2,
        background: props.wizard
          ? theme.palette.primary.light
          : props.expense
          ? props.expense
          : theme.palette.grey[300],
      })}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) =>
              props.wizard
                ? theme.palette.secondary.main
                : theme.palette.grey[900],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export interface IActiveData {
  index: number;
  stepComponent: JSX.Element;
}
