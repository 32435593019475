import * as React from "react";
import Tab from "@mui/material/Tab";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import { Box } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { tabStyles } from "../../../Common/tabStyles";
import DashboardUserData from "./DashboardUserData";
import EmployeeUserData from "./EmployeeUserData";
import MarketerUserData from "./MarketerUserData";
import UserGroupData from "./UserGroupData";

export default function IconLabelTabs() {
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="tabs-container" style={{ marginTop: '65px', position: 'fixed', width: '100%', zIndex: 1 }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              icon={<GroupAddIcon />}
              label="Active Dasboard Users"
              value="1"
            />
            <Tab
              icon={<GroupAddIcon />}
              label="Employees"
              value="2"
            />
            <Tab
              icon={<RecordVoiceOverIcon />}
              label="Marketers"
              value="3"
            />
            <Tab icon={<CardTravelIcon />} label="User Groups" value="4" />
            <Tab icon={<SettingsSuggestIcon />} label="Reset Password" value="5" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <main style={tabStyles}>
            <DashboardUserData />
          </main>
        </TabPanel>
        <TabPanel value="2">
          <main style={tabStyles}>
            <EmployeeUserData />
          </main>
        </TabPanel>
        <TabPanel value="3">
          <main style={tabStyles}>
            <MarketerUserData />
          </main>
        </TabPanel>
        <TabPanel value="4">
          <main style={tabStyles}>
            <UserGroupData />
          </main>
        </TabPanel>
        <TabPanel value="5">
          <main style={tabStyles}>
            Coming soon
          </main>
        </TabPanel>
      </TabContext>
    </div >
  );
}
