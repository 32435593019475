import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { IFambulDependantsRow } from "../../../services/API/GetFambulDependants";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import UpdateDependant, {
  IUpdateDependantProps,
} from "../../../services/API/UpdateDependant";
import useToken from "../../useToken";
import AddDependant, {
  IAddDependantProps,
} from "../../../services/API/AddDependant";

interface IDepProp {
  openUser: boolean;
  onClose: (val: boolean) => void;
  existingUser?: IFambulDependantsRow;
  IDCode: string;
}

const AddEditDependent = (props: IDepProp) => {
  const token = useToken().token;
  const [formData, setFormData] = useState(props.existingUser);
  const [errors, setError] = React.useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData, // Ensure prevData is not undefined
      [name]: value,
    }));
  };

  const handleErrrClose = () => {
    setError(false);
  };

  const okToSave = (data: IFambulDependantsRow) => {
    let notCompleted = false;
    if (
      data.Firstname === "" ||
      data.Lastname === "" ||
      data.Gender === "" ||
      data.DOB === undefined ||
      data.Address === "" ||
      data.Telephone === "" ||
      data.Relationship === ""
    ) {
      notCompleted = true;
    }
    return notCompleted;
  };
  const handleSubmit = async () => {
    if (formData && props.existingUser) {
      if (okToSave(formData)) {
        setError(true);
      } else {
        const dataToUpdate: IUpdateDependantProps = {
          Firstname: formData.Firstname,
          Middlename: formData.Middlename || "N/A",
          Lastname: formData.Lastname,
          Gender: formData.Gender,
          DOB: formData.DOB,
          Address: formData.Address,
          Telephone: formData.Telephone,
          Relationship: formData.Relationship,
          PercentageShared: formData.PercentageShared || 100,
          Trustee: formData.Trustee || "N/A",
          TrusteeNumber: formData.TrusteeNumber || "N/A",
          NomineeID: props.existingUser.NomineeID,
          IDCode: props.IDCode,
          token: token,
        };
        const response = await UpdateDependant(dataToUpdate);
        if (response) {
          props.onClose(true);
        }
      }
    }
  };

  const HandleAddNew = async () => {
    if (formData) {
      if (okToSave(formData)) {
        setError(true);
      } else {
        const dataToAdd: IAddDependantProps = {
          Firstname: formData.Firstname,
          Middlename: formData.Middlename || "N/A",
          Lastname: formData.Lastname,
          Gender: formData.Gender,
          DOB: formData.DOB,
          Address: formData.Address,
          Telephone: formData.Telephone,
          Relationship: formData.Relationship,
          PercentageShared: formData.PercentageShared,
          Trustee: formData.Trustee || "N/A",
          TrusteeNumber: formData.TrusteeNumber || "N/A",
          IDCode: props.IDCode,
          token: token,
        };
        const response = await AddDependant(dataToAdd);
        if (response) {
          props.onClose(true);
        }
      }
    } else {
      setError(true);
    }
  };
  return (
    <>
      <Dialog open={true} onClose={props.onClose} disableEscapeKeyDown>
        <DialogTitle>
          {props.existingUser ? "Edit Dependant" : "Add New Dependant"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                id="Firstname"
                name="Firstname"
                label="First Name"
                value={formData?.Firstname || ""}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="Middlename"
                name="Middlename"
                label="Middle Name"
                value={formData?.Middlename || "N/A"}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="Lastname"
                name="Lastname"
                label="Last Name"
                value={formData?.Lastname || ""}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="Gender"
                name="Gender"
                label="Gender"
                value={formData?.Gender || ""}
                onChange={handleChange}
                fullWidth
                inputProps={{ maxLength: 6 }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="DOB"
                name="DOB"
                label="DOB"
                type="date"
                value={formData?.DOB || ""}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="Telephone"
                name="Telephone"
                label="Telephone"
                value={formData?.Telephone || ""}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="Address"
                name="Address"
                label="Address"
                value={formData?.Address || ""}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="Relationship"
                name="Relationship"
                label="Relationship"
                value={formData?.Relationship || ""}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="PercentageShared"
                name="PercentageShared"
                label="Percentage Shared"
                type="number"
                value={formData?.PercentageShared || ""}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="Trustee"
                name="Trustee"
                label="Trustee"
                value={formData?.Trustee || "N/A"}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="TrusteeNumber"
                name="TrusteeNumber"
                label="Trustee Number"
                value={formData?.TrusteeNumber || "N/A"}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={props.existingUser ? handleSubmit : HandleAddNew}
              >
                {props.existingUser ? "Save" : "Add"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {
        <Dialog open={errors} onClose={handleErrrClose}>
          <DialogTitle>Information</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The form is incomplete. Please make sure the following fields are
              completed, use N/A if not applicable.
            </DialogContentText>
            <ul>
              <li>First Name</li>
              <li>Last Name</li>
              <li>Gender</li>
              <li>DOB</li>
              <li>Address</li>
              <li>Telephone</li>
              <li>Relationship</li>
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleErrrClose}>Ok</Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
};

export default AddEditDependent;
