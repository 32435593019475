import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AdminArea from "../Admin/AdminArea";

interface IAdminListItemsProps {
  setButtonClick: (button: JSX.Element) => void;
}

const AdminListItems = (props: IAdminListItemsProps) => {
  const loadAdmin = () => {
    props.setButtonClick(<AdminArea />);
  };
  return (
    <div>
      <React.Fragment>
        <ListSubheader component="div" inset>
          Admin
        </ListSubheader>
        <ListItemButton onClick={loadAdmin}>
          <ListItemIcon>
            <AdminPanelSettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Employees" />
        </ListItemButton>
      </React.Fragment>
    </div>
  );
};
export default AdminListItems;
