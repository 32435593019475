import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { Container, DialogContent } from "@mui/material";
import AddNewUserStep1 from "./AddNewUserStep1";
import AddNewUserStep2 from "./AddNewUserStep2";
import AddNewUserStep3 from "./AddNewUserStep3";
import { MedUserReducer } from "./MedUserReducer";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  IActiveData,
  steps,
} from "./AddNewUserStepper";
import { IMedicalCustomer } from "../../../services/API/GetMedicalData";
import AddNewMedicalCustomer, {
  IAddMedicalCustomerProps,
} from "../../../services/API/AddNewMedicalCustomer";
import useToken from "../../useToken";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface IAddNewProps {
  openUser: boolean;
  onClose: (reload?: boolean) => void;
  existingUser?: IMedicalCustomer;
}

const AddNewUser = (props: IAddNewProps) => {
  const token = useToken().token;
  const {
    state,
    handleChange,
    handleDropDownChange,
    updateDependantNames,
    handleRadioChange,
    handleTextChange,
    updateStateForExistingUser,
  } = MedUserReducer();
  const [loaded, setLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errors, setError] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState<IActiveData>({
    index: -1,
    stepComponent: <></>,
  });

  React.useEffect(() => {
    async function loadData() {
      if (props.existingUser) {
        await updateStateForExistingUser(props.existingUser);
      }
      setActiveStep({
        index: 0,
        stepComponent: (
          <AddNewUserStep1
            state={state}
            handleChange={handleChange}
            handleDropDownChange={handleDropDownChange}
          />
        ),
      });
      setLoaded(true);
    }
    loadData();
  }, []);

  const handleClose = () => {
    props.onClose();
  };

  const handleErrrClose = () => {
    setError(false);
  };

  const componentToLoad: IActiveData[] = [
    {
      index: 0,
      stepComponent: (
        <AddNewUserStep1
          state={state}
          handleChange={handleChange}
          handleDropDownChange={handleDropDownChange}
        />
      ),
    },
    {
      index: 1,
      stepComponent: (
        <AddNewUserStep2
          state={state}
          handleChange={handleChange}
          handleDropDownChange={handleDropDownChange}
          updateDependantNames={updateDependantNames}
        />
      ),
    },
    {
      index: 2,
      stepComponent: (
        <AddNewUserStep3
          state={state}
          handleChange={handleChange}
          handleDropDownChange={handleDropDownChange}
          handleRadioChange={handleRadioChange}
          handleTextChange={handleTextChange}
        />
      ),
    },
  ];

  const handleNext = () => {
    const step = activeStep.index + 1;
    setActiveStep({
      index: step,
      stepComponent: componentToLoad[step].stepComponent,
    });
  };

  const handleBack = () => {
    const step = activeStep.index - 1;
    setActiveStep({
      index: step,
      stepComponent: componentToLoad[step].stepComponent,
    });
  };

  const okToSave = () => {
    let notCompleted = false;
    const states = state.dataToSave;
    if (
      states.title === "" ||
      states.initials === "" ||
      states.firstName === "" ||
      states.lastName === "" ||
      states.email === "" ||
      states.telephone === "" ||
      states.residentialAddress === "" ||
      states.country === "" ||
      states.weightKg === "" ||
      states.heightCm === "" ||
      states.city === "" ||
      states.emergencyContactName === "" ||
      states.emergencyTelephoneNumber === "" ||
      states.occupation === "" ||
      states.officeTelephone === "" ||
      states.employerName === "" ||
      states.employerAddress === ""
    ) {
      notCompleted = true;
    }
    return notCompleted;
  };

  const handleSave = async () => {
    if (okToSave()) {
      setError(true);
    } else {
      const states = state.dataToSave;
      setLoading(true);
      const medQuestionToSend = states.medQuestions.reduce(
        (acc, curr, index) => {
          const resp = states.medResponses[index]
            ? `${states.medResponses[index]}`
            : "NotAdded";
          const selected = states.medSelectedValues[index]
            ? states.medSelectedValues[index]
            : "NotSelected";
          const str = `${curr.medicalQuestionID}|${selected}¬${resp}`;
          return index === 0 ? str : `${acc},${str}`;
        },
        ""
      );

      const dependants = states.dependantNames
        .map((dep, i) => {
          return `${i}|${dep.fullname}¬${dep.relationship}#${
            dep.sex === "" ? "male" : dep.sex
          }>${dep.dob}`;
        })
        .join(",");

      const dataToSend: IAddMedicalCustomerProps = {
        firstName: states.firstName,
        Package: states.Package === "" ? "SL Standard" : states.Package,
        middleNames: states.middleName,
        lastName: states.lastName,
        initials: states.initials,
        title: states.title,
        gender: states.Sex === "" ? "male" : states.Sex,
        dateOfBirth: states.dateOB,
        issuecountry: states.issuecountry,
        passportNo: states.passportNo,
        passportExpdate: states.passportExpdate,
        residentialAddress: states.residentialAddress,
        city: states.city,
        country: states.country,
        telephoneNumber: states.telephone,
        email: states.email,
        officeTelephone: states.officeTelephone,
        employerName: states.employerName,
        employerAddress: states.employerAddress,
        dependants:
          dependants === "0|¬#female>,1|¬#female>,2|¬#female>,3|¬#female>" ||
          dependants === "0|¬#male>,1|¬#male>,2|¬#male>,3|¬#male>"
            ? ""
            : dependants,
        maritalStatus:
          states.MaritalStatus === "" ? "Married" : states.MaritalStatus,
        heightCm: states.heightCm,
        weightKg: states.weightKg,
        premium: states.premium,
        insuranceExpiryDate: states.insuranceExpiryDate,
        emergencyContactName: states.emergencyContactName,
        emergencyTelephoneNumber: states.emergencyTelephoneNumber,
        medQuestionToSend: medQuestionToSend,
        occupation: states.occupation,
        token: token,
        Fullname: "",
        InsuranceStatus: "",
        MedicalCustomersID: 0,
      };
      try {
        await AddNewMedicalCustomer(dataToSend);
        setLoading(false);
        props.onClose(true);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {loaded && (
        <>
          {" "}
          {loading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <Container maxWidth="lg" sx={{ mt: 8, mb: 9 }}>
            <BootstrapDialog
              open={props.openUser}
              fullWidth
              maxWidth="md"
              disableEscapeKeyDown={true}
              hideBackdrop={true}
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
              >
                {props.existingUser ? "Existing Customer" : "Add New Customer"}
              </BootstrapDialogTitle>
              <div>
                <DialogContent dividers>
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep.index}>
                      {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                          optional?: React.ReactNode;
                        } = {};
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    <React.Fragment>
                      {activeStep.stepComponent}
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Button
                          color="inherit"
                          disabled={activeStep.index === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button
                          disabled={loading}
                          onClick={
                            activeStep.index === steps.length - 1 &&
                            state.dataToSave.editing
                              ? handleClose
                              : activeStep.index === steps.length - 1 &&
                                !state.dataToSave.editing
                              ? handleSave
                              : handleNext
                          }
                        >
                          {activeStep.index === steps.length - 1 &&
                          state.dataToSave.editing
                            ? "Close"
                            : activeStep.index === steps.length - 1 &&
                              !state.dataToSave.editing
                            ? "Add Customer"
                            : "Next"}
                        </Button>
                      </Box>
                    </React.Fragment>
                  </Box>
                </DialogContent>
              </div>
            </BootstrapDialog>
          </Container>
          {
            <Dialog open={errors} onClose={handleErrrClose}>
              <DialogTitle>Information</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  The form is incomplete. Please make sure the following fields
                  are completed.
                </DialogContentText>
                <ul>
                  <li>Title</li>
                  <li>Initials</li>
                  <li>First Name</li>
                  <li>Last Name</li>
                  <li>Email</li>
                  <li>Telephone</li>
                  <li>Residential Address</li>
                  <li>Country</li>
                  <li>Weight (kg)</li>
                  <li>Height (cm)</li>
                  <li>City</li>
                  <li>Emergency Contact Name</li>
                  <li>Emergency Telephone Number</li>
                  <li>Occupation</li>
                  <li>Office Telephone</li>
                  <li>Employer Name</li>
                  <li>Employer Address</li>
                </ul>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleErrrClose}>Ok</Button>
              </DialogActions>
            </Dialog>
          }
        </>
      )}
    </div>
  );
};
export default React.memo(AddNewUser);
