import axios from "axios";
import apiURL from "../apiURL";
import { handleAxiosError } from "../axiosErrorHandler";

const baseURL = apiURL();

export interface IExpenseRequestListRow {
  ExpenseRequestsItemID: number;
  RequestID: number;
  Item: string;
  Quantity: number;
  UnitCost: number;
  Amount: number;
}

export interface IExpenseRequestListData {
  data: IExpenseRequestListRow[];
}
interface IGetMissing {
  token: string;
  requestID: number;
}
const GetExpenseRequestList = async (props: IGetMissing) => {
  const token = props.token;

  try {
    const data = await axios({
      method: "get",
      responseType: "json",
      url:
        baseURL +
        `ExpenseRequest/getExpenseRequestList?requestID=${props.requestID}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return data as IExpenseRequestListData;
  } catch (error) {
    // Call the handleAxiosError function with the error and redirect URL
    handleAxiosError(error, "/login"); // Adjust the URL as needed
    return undefined;
  }
};
export default GetExpenseRequestList;
