import React, { useEffect, useMemo } from 'react';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import useToken from '../../useToken';
import { Box, Button } from '@mui/material';
import GetMarketerUserData, { IMUserDataRow } from '../../../services/API/GetMarketerUserData';
import { IDialogSave } from '../FrontDeskPayment/PaymentData';
import AlertDialog from '../../../Common/AlertDialog';
import DisableMarketer from '../../../services/API/DisableMarketer';
import jwt_decode from "jwt-decode";
import { ISignInData } from '../../../services/API/signin';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RegisterMarketer from './RegisterMarketer';

const MarketerUserData = () => {
  const [showDaily, setShowDaily] = React.useState<IDialogSave>({
    open: false,
    headerText: '',
    mainText: ''
  });
  const token = useToken().token;
  const [data, setData] = React.useState<IMUserDataRow[]>([]);
  const [marketerID, setMarketerID] = React.useState<number>();
  const [reload, setReload] = React.useState<boolean>(false);
  const userData: ISignInData = jwt_decode(token);
  const [openAdd, setOpenAdd] = React.useState<boolean>(false);
  const columns = useMemo<MRT_ColumnDef<IMUserDataRow>[]>(
    () => [
      {
        accessorKey: 'MarketerID',
        header: 'MarketerID',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '25ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
        width: 80
      },
      {
        accessorKey: 'fullname',
        header: 'Fullname',
        width: 180,
      },
      {
        accessorKey: 'Username',
        header: 'Username',
      },
      {
        accessorKey: 'Email',
        header: 'Email',
      },
      {
        accessorKey: 'AccountStatus',
        header: 'Status',
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: cell.getValue() === 'ENABLED' ? theme.palette.success.light : theme.palette.error.light,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '25ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
        size: 50
      },
      {
        accessorKey: 'MarketerID',
        header: '',
        Cell: ({ cell }) => (
          cell.row.original.AccountStatus === 'ENABLED' && (
            <Button
              key={`${cell.row.original.MarketerID}_${cell.row.index}`}
              sx={{ width: '100px', height: '20px' }}
              color="error" variant="contained"
              onClick={() => handleDisable(Number(cell.getValue()), cell.row.original.fullname)}>
              Disabled
            </Button>)
        ),
        size: 50
      },
    ],
    [],
  );
  useEffect(() => {
    GetMarketerUserData({ token }).then((response) => {
      if (response) {
        setData(response.data);
      }
    });
  }, [token, reload]);

  const handleDisable = (MarketerID: number, fullname: string) => {
    setShowDaily({
      open: true,
      headerText: `Are you Sure?`,
      mainText: `You are about to disable "${fullname}". Please make sure they are no longer a valid marketer.`
    })
    setMarketerID(MarketerID)
  }

  const handleCancel = () => {
    setShowDaily({
      open: false,
      headerText: '',
      mainText: ''
    });
  };
  const handleSubmit = () => {
    if (marketerID) {
      DisableMarketer({
        marketerID: marketerID,
        disabledBy: userData.DisplayName,
        token: token,
      }).then((value) => {
        if (value === 'success') {
          setShowDaily({
            open: false,
            headerText: '',
            mainText: ''
          });
          setReload(true)
        }
      })
    }
  };
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  const handleAddMarketer = () => {
    setOpenAdd(true)
  }
  const handleClose = () => {
    setOpenAdd(false)
    setReload(true)
  }
  return <> <MaterialReactTable
    columns={columns}
    data={data}
    enableMultiRowSelection={false} //use radio buttons instead of checkboxes
    enableDensityToggle={false}
    initialState={{ density: 'compact', pagination: { pageIndex: 0, pageSize: window.innerHeight > 720 ? 15 : 10 }, }}
    muiTablePaginationProps={{
      rowsPerPageOptions: [5, 10, 20, 50],
    }}
    muiTableContainerProps={{
      sx: {
        overflow: "auto",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          width: "0.4em",
          height: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#1976d2",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#1976d2",
        },
      },
    }}
    enableFullScreenToggle={false}
    enableHiding={false}
    rowCount={data.length}
    renderTopToolbarCustomActions={() => (
      <Box
        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
      >
        <Button
          color="primary"
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
          variant="outlined"
        >
          Export Marketers
        </Button>
        <Button
          color="info"
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleAddMarketer}
          startIcon={<AddCircleOutlineIcon />}
          variant="outlined"
        >Add New</Button>
      </Box>
    )} />
    {openAdd && <RegisterMarketer handleClose={handleClose} />}
    {
      showDaily && <AlertDialog open={showDaily.open} setOpen={handleCancel}
        setSave={handleSubmit}
        headerText={showDaily.headerText}
        mainText={showDaily.mainText} />
    }
  </>
};
export default MarketerUserData;