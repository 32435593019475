import * as React from "react";
import Tab from "@mui/material/Tab";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { Box } from "@mui/material";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import PaymentData from "./PaymentData";
import { tabStyles } from "../../../Common/tabStyles";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import GroupsIcon from '@mui/icons-material/Groups';
import AcknowledgedData from "./AcknowledgedData";

const FrontDeskPayment: React.FC = () => {
    const [value, setValue] = React.useState("1");
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    return (
        <div className="tabs-container" style={{ marginTop: '65px', position: 'fixed', width: '100%', zIndex: 1 }}>
            <TabContext value={value} >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab
                            icon={<GroupsIcon />}
                            label="Group Life Payments"
                            value="1"
                        />
                        <Tab
                            icon={<GroupAddIcon />}
                            label="Other Payments"
                            value="2"
                        />
                        <Tab icon={<ThumbUpIcon />} label="Acknowledgments" value="3" />
                        <Tab icon={<SettingsSuggestIcon />} label="Settings" value="4" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <main style={tabStyles}>
                        <PaymentData isGroupLife={true} />
                    </main>
                </TabPanel>
                <TabPanel value="2">
                    <main style={tabStyles}>
                        <PaymentData isGroupLife={false} />
                    </main>
                </TabPanel>
                <TabPanel value="3" >
                    <main style={tabStyles}>
                        <AcknowledgedData />
                    </main>
                </TabPanel>
                <TabPanel value="4">Coming soon</TabPanel>
            </TabContext>
        </div>
    );
};

export default FrontDeskPayment;
